import React from "react";
import {
  Box,
  Typography,
  Container,
  Link,
  List,
  ListItem,
} from "@mui/material";
const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy for TenantIO
        </Typography>

        <Typography variant="h5" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Last updated: December 19, 2024
        </Typography>
        <Box sx={{ my: 4 }}>
          <Typography paragraph>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </Typography>
          <Typography paragraph>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Privacy Policy
            Generator.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Interpretation and Definitions
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Interpretation
          </Typography>

          <Typography paragraph>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Definitions
          </Typography>

          <Typography paragraph>
            For the purposes of this Privacy Policy:
          </Typography>
          <List sx={{ pl: 4 }}>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </Typography>
            </ListItem>

            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Application</strong> refers to TenantIO, the software
                program provided by the Company.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to TenantIO.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Country</strong> refers to: United Kingdom
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Service</strong> refers to the Application.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Collecting and Using Your Personal Data
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Types of Data Collected
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Personal Data
          </Typography>
          <Typography paragraph>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </Typography>
          <List sx={{ pl: 4 }}>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>Email address</Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>First name and last name</Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>Phone number</Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                Address, State, Province, ZIP/Postal code, City
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>Usage Data</Typography>
            </ListItem>
          </List>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
            Usage Data
          </Typography>
          <Typography paragraph>
            Usage Data is collected automatically when using the Service.
          </Typography>
          <Typography paragraph>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </Typography>
          <Typography paragraph>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </Typography>
          <Typography paragraph>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Information Collected while Using the Application
          </Typography>
          <Typography paragraph>
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </Typography>
          <List sx={{ pl: 4 }}>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                Pictures and other information from your Device's camera and
                photo library
              </Typography>
            </ListItem>
          </List>
          <Typography paragraph>
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or it
            may be simply stored on Your device.
          </Typography>
          <Typography paragraph>
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Use of Your Personal Data
          </Typography>
          <Typography paragraph>
            The Company may use Personal Data for the following purposes:
          </Typography>
          <List sx={{ pl: 4 }}>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>To manage Your Account</strong>: to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>For the performance of a contract</strong>: the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "block" }}>
              <Typography paragraph>
                <strong>To contact You</strong>: To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </Typography>
            </ListItem>
          </List>
          {/* Continue with all other sections... */}
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Security of Your Personal Data
          </Typography>
          <Typography paragraph>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Children's Privacy
          </Typography>
          <Typography paragraph>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </Typography>
          <Typography paragraph>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Links to Other Websites
          </Typography>
          <Typography paragraph>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </Typography>
          <Typography paragraph>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Changes to this Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </Typography>
          <Typography paragraph>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </Typography>
          <Typography paragraph>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </Typography>
          <Typography paragraph>
            By email:{" "}
            <Link href="mailto:support@tenantio.com">support@tenantio.com</Link>
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
            Generated using Privacy Policies Generator
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
export default PrivacyPolicy;
