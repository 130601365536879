import React, { useEffect } from "react";
import { Dialog, DialogContent, IconButton, Box, Alert } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import PricingPlans from "./PricingPlans";
import { useAuth } from "../contexts/AuthContext";
import { createSubscriptionSession } from "../services/stripe";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useOrganization } from "../contexts/OrganizationContext";

export default function BillingDialog({ open, onClose }) {
  const [error, setError] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const { organization } = useOrganization();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handlePlanSelect = async (planData) => {
    try {
      setError(null);
      if (!user) {
        throw new Error("Please sign in to upgrade your subscription");
      }

      if (!organization) {
        throw new Error("Organization not found");
      }

      // Create a Stripe checkout session
      await createSubscriptionSession(
        planData.priceId,
        organization.stripeCustomerId
      );
    } catch (error) {
      console.error("Error upgrading subscription:", error);
      setError(error.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: "background.paper",
        },
      }}
    >
      <Box sx={{ position: "absolute", right: 8, top: 8, zIndex: 1 }}>
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <PricingPlans onPlanSelect={handlePlanSelect} />
      </DialogContent>
    </Dialog>
  );
}
