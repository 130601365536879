import React, { useState, memo } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Chip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  ImageList,
  ImageListItem,
  TextField,
} from "@mui/material";
import {
  Build as BuildIcon,
  VideoLibrary as VideoIcon,
} from "@mui/icons-material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, db } from "../../../config/firebase";
import ContractorLayout from "../../contractor/ContractorLayout";

const statusColors = {
  sent: "#F59E0B",
  accepted: "#3B82F6",
  declined: "#EF4444",
  completed: "#10B981",
};

const priorityColors = {
  low: "#22c55e",
  medium: "#f59e0b",
  high: "#ef4444",
};

const InvoiceDialog = memo(
  ({
    open,
    onClose,
    selectedJob,
    invoiceDetails,
    setInvoiceDetails,
    onSubmit,
    isSubmitting,
  }) => (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "linear-gradient(90deg, #10B981 0%, #059669 100%)",
          color: "white",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: 1,
            bgcolor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box component="span" sx={{ fontSize: "1.5rem" }}>
            💰
          </Box>
        </Box>
        Submit Invoice
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Amount (£)"
                type="number"
                fullWidth
                value={invoiceDetails.amount}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setInvoiceDetails((prev) => ({
                    ...prev,
                    amount: newValue,
                  }));
                }}
                InputProps={{
                  startAdornment: "£",
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Work Description"
                multiline
                rows={3}
                fullWidth
                value={invoiceDetails.description}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setInvoiceDetails((prev) => ({
                    ...prev,
                    description: newValue,
                  }));
                }}
                placeholder="Describe the work completed..."
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "2px dashed",
                  borderColor: "rgba(16, 185, 129, 0.2)",
                  borderRadius: 2,
                  p: 3,
                  textAlign: "center",
                  cursor: "pointer",
                  transition: "all 0.2s",
                  "&:hover": {
                    borderColor: "#10B981",
                    bgcolor: "rgba(16, 185, 129, 0.05)",
                  },
                }}
              >
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                  onChange={(e) => {
                    const files = Array.from(e.target.files);
                    setInvoiceDetails((prev) => ({
                      ...prev,
                      files: [...prev.files, ...files],
                    }));
                  }}
                  style={{ display: "none" }}
                  id="invoice-file-upload"
                  multiple
                />
                <label htmlFor="invoice-file-upload">
                  <Box sx={{ mb: 2 }}>
                    <Box component="span" sx={{ fontSize: "2rem" }}>
                      📎
                    </Box>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 500, mb: 1 }}
                  >
                    Upload Invoice or Supporting Documents
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Click to upload or drag and drop
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    PDF, DOC, PNG, JPG
                  </Typography>
                </label>
              </Box>
              {invoiceDetails.files.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Uploaded Files:
                  </Typography>
                  {invoiceDetails.files.map((file, index) => (
                    <Chip
                      key={index}
                      label={file.name}
                      onDelete={() =>
                        setInvoiceDetails((prev) => ({
                          ...prev,
                          files: prev.files.filter((_, i) => i !== index),
                        }))
                      }
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2.5, gap: 1 }}>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onSubmit(selectedJob.id)}
          disabled={
            !invoiceDetails.amount ||
            !invoiceDetails.description ||
            isSubmitting
          }
          sx={{
            bgcolor: "#10B981",
            "&:hover": {
              bgcolor: "#059669",
            },
          }}
        >
          {isSubmitting ? "Submitting..." : "Submit & Complete Job"}
        </Button>
      </DialogActions>
    </Dialog>
  )
);

const ContractorJobs = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({
    amount: "",
    description: "",
    files: [],
  });
  const queryClient = useQueryClient();

  const { data: jobs = [], isLoading } = useQuery({
    queryKey: ["contractor-jobs"],
    queryFn: async () => {
      console.log("Current contractor ID:", auth.currentUser.uid);
      const contractorDoc = await getDoc(
        doc(db, "contractors", auth.currentUser.uid)
      );
      console.log(
        "Contractor document:",
        contractorDoc.exists() ? contractorDoc.data() : "Not found"
      );

      const q = query(
        collection(db, "maintenanceRequests"),
        where("contractorId", "==", auth.currentUser.uid),
        where("status", "in", ["sent", "accepted", "declined", "completed"])
      );
      const snapshot = await getDocs(q);
      console.log(
        "Found jobs:",
        snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!auth.currentUser?.uid,
  });

  console.log("Rendered jobs:", jobs);

  const handleUpdateStatus = async (jobId, newStatus) => {
    try {
      const jobRef = doc(db, "maintenanceRequests", jobId);
      await updateDoc(jobRef, {
        status: newStatus,
        updatedAt: new Date().toISOString(),
      });
      queryClient.invalidateQueries(["contractor-jobs"]);
      setViewDialogOpen(false);
    } catch (error) {
      console.error("Error updating job status:", error);
    }
  };

  const handleCompleteJob = async (jobId) => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const storage = getStorage();
      const uploadedFiles = [];

      // Upload each file to Firebase Storage
      for (const file of invoiceDetails.files) {
        const storageRef = ref(storage, `invoices/${jobId}/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        uploadedFiles.push({
          name: file.name,
          url: downloadURL,
          type: file.type,
          uploadedAt: new Date().toISOString(),
        });
      }

      const jobRef = doc(db, "maintenanceRequests", jobId);
      await updateDoc(jobRef, {
        status: "completed",
        updatedAt: new Date().toISOString(),
        completedAt: new Date().toISOString(),
        invoice: {
          amount: parseFloat(invoiceDetails.amount),
          description: invoiceDetails.description,
          files: uploadedFiles,
          status: "pending_approval",
          submittedAt: new Date().toISOString(),
        },
      });

      // Add notification for admin/user
      await addDoc(collection(db, "notifications"), {
        type: "invoice_submitted",
        requestId: jobId,
        jobTitle: selectedJob.title,
        propertyName: selectedJob.propertyName,
        contractorName: auth.currentUser.displayName || "Contractor",
        amount: parseFloat(invoiceDetails.amount),
        status: "unread",
        organizationId: selectedJob.organizationId,
        createdAt: serverTimestamp(),
        message: `New invoice submitted for ${selectedJob.title} - £${invoiceDetails.amount}`,
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "maintenance_invoice",
        requestId: jobId,
        status: "pending_approval",
        organizationId: selectedJob.organizationId,
        timestamp: serverTimestamp(),
        message: `Invoice submitted for ${selectedJob.title} - £${invoiceDetails.amount}`,
      });

      queryClient.invalidateQueries(["contractor-jobs"]);
      setInvoiceDialogOpen(false);
      setViewDialogOpen(false);
      setInvoiceDetails({ amount: "", description: "", files: [] });
    } catch (error) {
      console.error("Error completing job:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const JobCard = ({ job }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography variant="h6">{job.title}</Typography>
              <Typography color="text.secondary" gutterBottom>
                {job.propertyName} {job.unit && `- Unit ${job.unit}`}
              </Typography>
              <Typography color="text.secondary" variant="body2" gutterBottom>
                {job.propertyAddress}
              </Typography>
              <Typography color="text.secondary" variant="body2" gutterBottom>
                Tenant: {job.tenantName}{" "}
                {job.tenantPhone && `- ${job.tenantPhone}`}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Chip
                  label={job.status.toUpperCase()}
                  size="small"
                  sx={{
                    bgcolor: `${statusColors[job.status]}15`,
                    color: statusColors[job.status],
                  }}
                />
                <Chip
                  label={job.priority.toUpperCase()}
                  size="small"
                  sx={{
                    bgcolor: `${priorityColors[job.priority]}15`,
                    color: priorityColors[job.priority],
                  }}
                />
              </Stack>
            </Box>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setSelectedJob(job);
                setViewDialogOpen(true);
              }}
            >
              View Details
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <ContractorLayout>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" sx={{ mb: 4 }}>
          Maintenance Jobs
        </Typography>

        {jobs.map((job) => (
          <JobCard key={job.id} job={job} />
        ))}

        {/* Job Details Dialog */}
        <Dialog
          open={viewDialogOpen}
          onClose={() => setViewDialogOpen(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              background: "linear-gradient(180deg, #f0f7ff 0%, #FFFFFF 30.21%)",
              borderRadius: "16px",
              boxShadow: `0px 187px 75px rgba(0, 0, 0, 0.01),
                0px 105px 63px rgba(0, 0, 0, 0.05),
                0px 47px 47px rgba(0, 0, 0, 0.09),
                0px 12px 26px rgba(0, 0, 0, 0.1),
                0px 0px 0px rgba(0, 0, 0, 0.1)`,
            },
          }}
        >
          {selectedJob && (
            <>
              <DialogTitle
                sx={{
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  pb: 2,
                  background:
                    "linear-gradient(90deg, #2563eb 0%, #7c3aed 100%)",
                  color: "white",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Box
                    sx={{
                      width: 56,
                      height: 56,
                      borderRadius: 2,
                      bgcolor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexShrink: 0,
                    }}
                  >
                    <BuildIcon sx={{ fontSize: 32, color: "#2563eb" }} />
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", mb: 0.5 }}
                    >
                      {selectedJob.title}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
                      {selectedJob.propertyName}{" "}
                      {selectedJob.unit && `- Unit ${selectedJob.unit}`}
                    </Typography>
                  </Box>
                </Box>
              </DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        p: 3,
                        bgcolor: "rgba(37, 99, 235, 0.05)",
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "rgba(37, 99, 235, 0.1)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2563eb",
                          fontWeight: "bold",
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box component="span" sx={{ fontSize: "1.2rem" }}>
                          📍
                        </Box>
                        Location Details
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Property Address
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedJob.propertyAddress ||
                              "No address provided"}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Tenant Information
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedJob.tenantName ||
                              "No tenant name provided"}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {selectedJob.tenantPhone ||
                              "No contact number provided"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        p: 3,
                        bgcolor: "rgba(124, 58, 237, 0.05)",
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "rgba(124, 58, 237, 0.1)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#7c3aed",
                          fontWeight: "bold",
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box component="span" sx={{ fontSize: "1.2rem" }}>
                          🔧
                        </Box>
                        Job Details
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Status
                          </Typography>
                          <Chip
                            label={selectedJob.status.toUpperCase()}
                            sx={{
                              bgcolor: `${statusColors[selectedJob.status]}15`,
                              color: statusColors[selectedJob.status],
                              fontWeight: 500,
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Priority
                          </Typography>
                          <Chip
                            label={selectedJob.priority.toUpperCase()}
                            sx={{
                              bgcolor: `${
                                priorityColors[selectedJob.priority]
                              }15`,
                              color: priorityColors[selectedJob.priority],
                              fontWeight: 500,
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Submitted
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {new Date(selectedJob.createdAt).toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        p: 3,
                        bgcolor: "rgba(37, 99, 235, 0.02)",
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "rgba(37, 99, 235, 0.1)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2563eb",
                          fontWeight: "bold",
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box component="span" sx={{ fontSize: "1.2rem" }}>
                          📝
                        </Box>
                        Description
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          lineHeight: 1.6,
                          color: "text.secondary",
                        }}
                      >
                        {selectedJob.description}
                      </Typography>
                    </Box>
                  </Grid>

                  {selectedJob.mediaFiles &&
                    selectedJob.mediaFiles.length > 0 && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            p: 3,
                            bgcolor: "rgba(124, 58, 237, 0.02)",
                            borderRadius: 2,
                            border: "1px solid",
                            borderColor: "rgba(124, 58, 237, 0.1)",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: "#7c3aed",
                              fontWeight: "bold",
                              mb: 2,
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Box component="span" sx={{ fontSize: "1.2rem" }}>
                              📎
                            </Box>
                            Attachments
                          </Typography>
                          <ImageList cols={3} gap={16} sx={{ mt: 2 }}>
                            {selectedJob.mediaFiles.map((file, index) => (
                              <ImageListItem
                                key={index}
                                sx={{
                                  cursor: "pointer",
                                  borderRadius: 2,
                                  overflow: "hidden",
                                  "&:hover": {
                                    transform: "scale(1.02)",
                                    transition: "transform 0.2s ease-in-out",
                                  },
                                }}
                                onClick={() => window.open(file.url, "_blank")}
                              >
                                {file.type === "image" ? (
                                  <img
                                    src={file.url}
                                    alt={`Attachment ${index + 1}`}
                                    loading="lazy"
                                    style={{
                                      width: "100%",
                                      height: "200px",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "200px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      bgcolor: "grey.100",
                                    }}
                                  >
                                    <VideoIcon
                                      sx={{
                                        fontSize: 48,
                                        color: "primary.main",
                                      }}
                                    />
                                  </Box>
                                )}
                              </ImageListItem>
                            ))}
                          </ImageList>
                        </Box>
                      </Grid>
                    )}
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3,
                  borderTop: "1px solid",
                  borderColor: "divider",
                  gap: 1,
                }}
              >
                <Button onClick={() => setViewDialogOpen(false)}>Close</Button>
                {selectedJob.status === "sent" && (
                  <>
                    <Button
                      onClick={() =>
                        handleUpdateStatus(selectedJob.id, "declined")
                      }
                      variant="outlined"
                      color="error"
                      sx={{
                        borderColor: "#EF4444",
                        color: "#EF4444",
                        "&:hover": {
                          borderColor: "#DC2626",
                          bgcolor: "rgba(239, 68, 68, 0.04)",
                        },
                      }}
                    >
                      Decline Job
                    </Button>
                    <Button
                      onClick={() =>
                        handleUpdateStatus(selectedJob.id, "accepted")
                      }
                      variant="contained"
                      sx={{
                        background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                        "&:hover": {
                          background:
                            "linear-gradient(45deg, #1e40af, #5b21b6)",
                        },
                      }}
                    >
                      Accept Job
                    </Button>
                  </>
                )}
                {selectedJob.status === "accepted" && (
                  <Button
                    onClick={() => setInvoiceDialogOpen(true)}
                    variant="contained"
                    sx={{
                      bgcolor: "#10B981",
                      "&:hover": {
                        bgcolor: "#059669",
                      },
                    }}
                  >
                    Complete & Submit Invoice
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Dialog>

        {/* Add the new Invoice Dialog */}
        <InvoiceDialog
          open={invoiceDialogOpen}
          onClose={() => setInvoiceDialogOpen(false)}
          selectedJob={selectedJob}
          invoiceDetails={invoiceDetails}
          setInvoiceDetails={setInvoiceDetails}
          onSubmit={handleCompleteJob}
          isSubmitting={isSubmitting}
        />
      </Box>
    </ContractorLayout>
  );
};

export default ContractorJobs;
