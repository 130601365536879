import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  IconButton,
  Chip,
} from "@mui/material";
import {
  Add as AddIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import TenantLayout from "./TenantLayout";

const documentCategories = [
  { value: "lease", label: "Lease Agreement" },
  { value: "id", label: "Identification" },
  { value: "employment", label: "Employment Proof" },
  { value: "reference", label: "References" },
  { value: "inspection", label: "Move-in Inspection" },
  { value: "other", label: "Other" },
];

const TenantDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState("");
  const [newDocument, setNewDocument] = useState({
    title: "",
    category: "",
    description: "",
    file: null,
  });

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    if (auth.currentUser) {
      fetchAllDocuments();
    }
  }, [auth.currentUser]);

  const fetchAllDocuments = async () => {
    try {
      // Get tenant document directly using auth UID
      const tenantDoc = await getDoc(doc(db, "tenants", auth.currentUser.uid));

      if (!tenantDoc.exists()) {
        console.error("No tenant found for this user");
        return;
      }

      const tenantData = tenantDoc.data();

      // Fetch documents uploaded by tenant
      const tenantUploadsQuery = query(
        collection(db, "documents"),
        where("tenantId", "==", auth.currentUser.uid)
      );
      const tenantUploadsSnapshot = await getDocs(tenantUploadsQuery);
      const tenantUploads = tenantUploadsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        uploadedBy: "tenant",
      }));

      // Get documents from tenant's document array in tenants collection
      const adminUploads =
        tenantData.documents?.map((doc) => ({
          ...doc,
          uploadedBy: "admin",
          // Ensure we have consistent property names
          fileName: doc.name || doc.fileName,
          fileUrl: doc.url || doc.fileUrl,
          uploadedAt: doc.uploadedAt || doc.createdAt,
          category: doc.type || doc.category,
        })) || [];

      console.log("Admin uploaded documents:", adminUploads); // Debug log

      // Combine and sort all documents by date
      const allDocuments = [...tenantUploads, ...adminUploads].sort((a, b) => {
        const dateA = new Date(a.uploadedAt || a.createdAt);
        const dateB = new Date(b.uploadedAt || b.createdAt);
        return dateB - dateA;
      });

      console.log("All documents:", allDocuments); // Debug log
      setDocuments(allDocuments);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setError("Failed to load documents");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewDocument({ ...newDocument, file });
    }
  };

  const handleUpload = async () => {
    try {
      if (!newDocument.title || !newDocument.category || !newDocument.file) {
        setError("Please fill in all required fields and select a file");
        return;
      }

      const storageRef = ref(
        storage,
        `documents/${auth.currentUser.uid}/${Date.now()}_${
          newDocument.file.name
        }`
      );

      const uploadTask = uploadBytesResumable(storageRef, newDocument.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          setError("Error uploading file");
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await addDoc(collection(db, "documents"), {
            title: newDocument.title,
            category: newDocument.category,
            description: newDocument.description,
            fileUrl: downloadURL,
            fileName: newDocument.file.name,
            tenantId: auth.currentUser.uid,
            createdAt: new Date().toISOString(),
          });

          setOpenDialog(false);
          setNewDocument({
            title: "",
            category: "",
            description: "",
            file: null,
          });
          setUploadProgress(0);
          fetchAllDocuments();
        }
      );
    } catch (error) {
      console.error("Error uploading document:", error);
      setError(error.message || "Error uploading document");
    }
  };

  const handleDownload = async (document) => {
    try {
      window.open(document.fileUrl, "_blank");
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const handleDelete = async (document) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        // Delete from Storage
        const storageRef = ref(storage, document.fileUrl);
        await deleteObject(storageRef);

        // Delete from Firestore
        await deleteDoc(doc(db, "documents", document.id));
        fetchAllDocuments();
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const getCategoryLabel = (category) => {
    const found = documentCategories.find((c) => c.value === category);
    return found ? found.label : category;
  };

  const getDocumentIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return "📄";
      case "doc":
      case "docx":
        return "📝";
      case "jpg":
      case "jpeg":
      case "png":
        return "🖼️";
      default:
        return "📎";
    }
  };

  return (
    <TenantLayout>
      <Box sx={{ maxWidth: 1200, margin: "0 auto", p: 3 }}>
        <Box
          sx={{
            mb: 4,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", sm: "center" },
            gap: 2,
          }}
        >
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
              My Documents
            </Typography>
            <Typography variant="body1" color="text.secondary">
              View and manage all your property-related documents
            </Typography>
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Upload Document
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Grid container spacing={3}>
          {documents.map((document) => (
            <Grid item xs={12} sm={6} md={4} key={document.id || document.url}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: (theme) => theme.shadows[4],
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h3" sx={{ fontSize: "2rem" }}>
                      {getDocumentIcon(document.name || document.fileName)}
                    </Typography>
                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => handleDownload(document)}
                        sx={{
                          color: "primary.main",
                          "&:hover": { backgroundColor: "primary.lighter" },
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                      {document.uploadedBy === "tenant" && (
                        <IconButton
                          size="small"
                          onClick={() => handleDelete(document)}
                          sx={{
                            color: "error.main",
                            "&:hover": { backgroundColor: "error.lighter" },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>

                  <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                    {document.title || document.name || document.fileName}
                  </Typography>

                  {document.description && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      {document.description}
                    </Typography>
                  )}

                  <Box
                    sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 2 }}
                  >
                    <Chip
                      label={getCategoryLabel(
                        document.type || document.category
                      )}
                      size="small"
                      sx={{
                        bgcolor: "primary.lighter",
                        color: "primary.dark",
                        fontWeight: 500,
                      }}
                    />
                    <Chip
                      label={
                        document.uploadedBy === "admin"
                          ? "Added by Admin"
                          : "Uploaded by You"
                      }
                      size="small"
                      sx={{
                        bgcolor:
                          document.uploadedBy === "admin"
                            ? "info.lighter"
                            : "success.lighter",
                        color:
                          document.uploadedBy === "admin"
                            ? "info.dark"
                            : "success.dark",
                        fontWeight: 500,
                      }}
                    />
                  </Box>

                  <Typography variant="caption" color="text.secondary">
                    Added on{" "}
                    {new Date(
                      document.uploadedAt || document.createdAt
                    ).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {documents.length === 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: "center",
                  p: 4,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  border: "1px dashed",
                  borderColor: "divider",
                }}
              >
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  No Documents Yet
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Upload your first document or wait for documents from your
                  property manager.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Upload Document</DialogTitle>
          <DialogContent>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <TextField
              margin="dense"
              label="Title"
              fullWidth
              required
              value={newDocument.title}
              onChange={(e) =>
                setNewDocument({ ...newDocument, title: e.target.value })
              }
            />
            <TextField
              select
              margin="dense"
              label="Category"
              fullWidth
              required
              value={newDocument.category}
              onChange={(e) =>
                setNewDocument({ ...newDocument, category: e.target.value })
              }
            >
              {documentCategories.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={newDocument.description}
              onChange={(e) =>
                setNewDocument({ ...newDocument, description: e.target.value })
              }
            />
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{ mt: 2 }}
            >
              Select File
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
              />
            </Button>
            {newDocument.file && (
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                Selected file: {newDocument.file.name}
              </Typography>
            )}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                Uploading: {Math.round(uploadProgress)}%
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={handleUpload} variant="contained">
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </TenantLayout>
  );
};

export default TenantDocuments;
