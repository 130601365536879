import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Divider,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import { emailService } from "../../services/emailService";

const steps = [
  "Personal Information",
  "Employment History",
  "Address History",
  "Documents",
];

const TenantReview = () => {
  const [tenantData, setTenantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [approveDialog, setApproveDialog] = useState(false);
  const [rejectDialog, setRejectDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchTenantData = async () => {
      try {
        const tenantDoc = await getDoc(doc(db, "tenants", id));
        if (tenantDoc.exists()) {
          setTenantData({ id: tenantDoc.id, ...tenantDoc.data() });
        }
      } catch (error) {
        console.error("Error fetching tenant data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTenantData();
  }, [id]);

  const handleApprove = async () => {
    setSubmitting(true);
    try {
      console.log("Starting approval process for tenant:", tenantData.email);

      // Get the user document to get the UID
      const userQuery = query(
        collection(db, "users"),
        where("email", "==", tenantData.email)
      );
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        console.error("No user found for email:", tenantData.email);
        throw new Error("No user found for this tenant");
      }

      // Get the user document ID which is the Firebase Auth UID
      const userUid = userSnapshot.docs[0].id;
      console.log("Found user UID:", userUid);

      // Get the user's organization ID
      const userData = userSnapshot.docs[0].data();
      const organizationId = userData.organizationId;

      if (!organizationId) {
        throw new Error("No organization ID found for user");
      }

      const updateData = {
        status: "active",
        approved: true,
        reviewed: true,
        approvedAt: new Date().toISOString(),
        onboardingComplete: true,
        uid: userUid,
        organizationId: organizationId,
      };

      console.log("Updating tenant document with:", updateData);

      await updateDoc(doc(db, "tenants", id), updateData);

      // Verify the update
      const updatedTenantDoc = await getDoc(doc(db, "tenants", id));
      console.log("Updated tenant data:", updatedTenantDoc.data());

      if (tenantData.propertyId) {
        const propertyRef = doc(db, "properties", tenantData.propertyId);
        await updateDoc(propertyRef, {
          tenancy: {
            tenantId: id,
            updatedAt: new Date().toISOString(),
          },
        });
      }

      // Send welcome email
      try {
        await emailService.sendTenantWelcomeEmail(
          tenantData.email,
          `${tenantData.firstName} ${tenantData.lastName}`,
          tenantData.propertyName
        );
      } catch (emailError) {
        console.error("Error sending welcome email:", emailError);
        // Continue with the approval process even if email fails
      }

      navigate("/dashboard/tenants");
    } catch (error) {
      console.error("Error approving tenant:", error);
    } finally {
      setSubmitting(false);
      setApproveDialog(false);
    }
  };

  const handleReject = async () => {
    setSubmitting(true);
    try {
      await updateDoc(doc(db, "tenants", id), {
        status: "rejected",
        approved: false,
        rejectionReason,
        rejectedAt: new Date().toISOString(),
      });
      navigate("/dashboard/tenants");
    } catch (error) {
      console.error("Error rejecting tenant:", error);
    } finally {
      setSubmitting(false);
      setRejectDialog(false);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!tenantData) {
    return <Typography>Tenant not found</Typography>;
  }

  return (
    <Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <Avatar sx={{ mr: 2, bgcolor: "primary.main" }}>
                {tenantData?.firstName?.[0]}
              </Avatar>
              <Box>
                <Typography variant="h5">
                  {tenantData?.firstName} {tenantData?.lastName}
                </Typography>
                <Typography color="text.secondary">
                  {tenantData?.email}
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Contact Information
                </Typography>
                <Typography>Email: {tenantData?.email}</Typography>
                <Typography>Phone: {tenantData?.phone}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Property Details
                </Typography>
                <Typography>Property: {tenantData?.propertyName}</Typography>
                <Typography>
                  Unit: {tenantData?.unit || "Not specified"}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mb: 3 }}
          >
            <Button
              variant="outlined"
              color="error"
              startIcon={<CloseIcon />}
              onClick={() => setRejectDialog(true)}
              sx={{
                borderRadius: 2,
                textTransform: "none",
                px: 3,
              }}
            >
              Reject Application
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckIcon />}
              onClick={() => setApproveDialog(true)}
              sx={{
                borderRadius: 2,
                textTransform: "none",
                px: 3,
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              Approve Application
            </Button>
          </Box>

          <Grid container spacing={3}>
            {/* Personal Information */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Personal Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">Date of Birth</Typography>
                      <Typography>
                        {tenantData.personalInfo?.dateOfBirth}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">
                        National Insurance
                      </Typography>
                      <Typography>
                        {tenantData.personalInfo?.nationalInsurance}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">
                        Current Income
                      </Typography>
                      <Typography>
                        £{tenantData.personalInfo?.currentIncome}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Employment History */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Employment History
                  </Typography>
                  {tenantData.employmentHistory?.map((employment, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Typography variant="subtitle1">
                        {employment.employer}
                      </Typography>
                      <Typography variant="body2">
                        Position: {employment.position}
                      </Typography>
                      <Typography variant="body2">
                        Duration: {employment.startDate} - {employment.endDate}
                      </Typography>
                      <Typography variant="body2">
                        Contact: {employment.contactPerson} (
                        {employment.contactPhone})
                      </Typography>
                      {index < tenantData.employmentHistory.length - 1 && (
                        <Divider sx={{ my: 2 }} />
                      )}
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            {/* Address History */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Address History
                  </Typography>
                  {tenantData.addressHistory?.map((address, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Typography variant="subtitle1">
                        {address.address}
                      </Typography>
                      <Typography variant="body2">
                        {address.city}, {address.postcode}
                      </Typography>
                      <Typography variant="body2">
                        Duration: {address.startDate} - {address.endDate}
                      </Typography>
                      <Typography variant="body2">
                        Landlord: {address.landlordName} (
                        {address.landlordPhone})
                      </Typography>
                      {index < tenantData.addressHistory.length - 1 && (
                        <Divider sx={{ my: 2 }} />
                      )}
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            {/* Documents */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Uploaded Documents
                  </Typography>
                  {tenantData.documents?.map((doc, index) => (
                    <Box key={index} sx={{ mb: 1 }}>
                      <Button
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="text"
                      >
                        {doc.name}
                      </Button>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Approve Dialog */}
      <Dialog
        open={approveDialog}
        onClose={() => setApproveDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CheckIcon color="success" />
            <Typography>Approve Application</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <Typography variant="body1" gutterBottom>
              Are you sure you want to approve this tenant application?
            </Typography>
            <Typography variant="body2" color="text.secondary">
              This will:
            </Typography>
            <Box component="ul" sx={{ mt: 1, pl: 2 }}>
              <li>
                <Typography variant="body2" color="text.secondary">
                  Mark the tenant as active in the system
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="text.secondary">
                  Link them to the selected property
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="text.secondary">
                  Allow them to access the tenant portal
                </Typography>
              </li>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => setApproveDialog(false)}
            variant="outlined"
            sx={{ borderRadius: 2, textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disabled={submitting}
            onClick={handleApprove}
            variant="contained"
            sx={{
              borderRadius: 2,
              textTransform: "none",
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            {submitting ? "Approving..." : "Confirm Approval"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reject Dialog */}
      <Dialog
        open={rejectDialog}
        onClose={() => setRejectDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CloseIcon color="error" />
            <Typography>Reject Application</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <Typography variant="body1" gutterBottom>
              Please provide a reason for rejecting this application:
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              This will be visible to the tenant and stored in the system.
            </Typography>
            <TextField
              autoFocus
              multiline
              rows={4}
              fullWidth
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              label="Rejection Reason"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => setRejectDialog(false)}
            variant="outlined"
            sx={{ borderRadius: 2, textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disabled={submitting || !rejectionReason.trim()}
            onClick={handleReject}
            variant="contained"
            color="error"
            sx={{ borderRadius: 2, textTransform: "none" }}
          >
            {submitting ? "Rejecting..." : "Confirm Rejection"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TenantReview;
