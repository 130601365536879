import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { useOrganization } from "../contexts/OrganizationContext";
import PricingPlans from "./PricingPlans";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { createSubscriptionSession } from "../services/stripe";
import { loadStripe } from "@stripe/stripe-js";

const FirstTimeLogin = () => {
  const [open, setOpen] = useState(false);
  const { organization, setOrganization } = useOrganization();
  const db = getFirestore();

  useEffect(() => {
    const checkFirstTimeLogin = async () => {
      if (organization?.id) {
        const orgDoc = await getDoc(doc(db, "organizations", organization.id));
        const orgData = orgDoc.data();

        if (!orgData.plan) {
          setOpen(true);
        }
      }
    };

    checkFirstTimeLogin();
  }, [organization]);

  const handlePlanSelect = async (planData) => {
    try {
      const now = new Date();
      const isNewTrial = !organization?.plan?.startDate;

      const plan = {
        ...planData,
        status: isNewTrial ? "trial" : "active",
        startDate: now.toISOString(),
        trialEndsAt: isNewTrial
          ? new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000).toISOString()
          : null,
        trialUsed: !isNewTrial,
      };

      // Update organization with the plan
      await updateDoc(doc(db, "organizations", organization.id), {
        plan,
      });

      // Update local organization state
      setOrganization((prev) => ({
        ...prev,
        plan,
      }));

      // If it's not a trial, create a checkout session
      if (!isNewTrial && planData.priceId) {
        const { sessionId } = await createSubscriptionSession(
          planData.priceId,
          organization.stripeCustomerId
        );

        // Redirect to Stripe Checkout
        const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
        if (!stripe) throw new Error("Stripe failed to initialize");

        const result = await stripe.redirectToCheckout({
          sessionId,
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      } else {
        setOpen(false);
      }
    } catch (error) {
      console.error("Error updating organization plan:", error);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={() => {}}
    >
      <DialogTitle>Choose Your Plan</DialogTitle>
      <DialogContent>
        <PricingPlans onPlanSelect={handlePlanSelect} />
      </DialogContent>
    </Dialog>
  );
};

export default FirstTimeLogin;
