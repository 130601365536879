import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  LinearProgress,
  Chip,
  Stack,
  Alert,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useOrganization } from "../../contexts/OrganizationContext";
import PricingPlans from "../PricingPlans";
import SignatureCreditsBundles from "../SignatureCreditsBundles";
import {
  Business as BusinessIcon,
  People as PeopleIcon,
  Engineering as EngineeringIcon,
  Person as PersonIcon,
  ArrowBack,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import {
  createSubscriptionSession,
  createPortalSession,
  createStripeCustomer,
} from "../../services/stripe";
import { getAuth } from "firebase/auth";

const UsageMetric = ({ icon, title, used, limit, color }) => {
  const percentage = (used / limit) * 100;

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
          {icon}
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {used} of {limit} used
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Math.min(percentage, 100)}
            sx={{
              mt: 1,
              height: 8,
              borderRadius: 1,
              bgcolor: `${color}.lighter`,
              "& .MuiLinearProgress-bar": {
                bgcolor: `${color}.main`,
              },
            }}
          />
        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {Math.max(limit - used, 0)} remaining
        </Typography>
      </CardContent>
    </Card>
  );
};

const getTrialDaysRemaining = (startDate) => {
  if (!startDate) return 0;
  const trialLength = 14;
  const start = new Date(startDate);
  const now = new Date();
  const diffTime =
    start.getTime() + trialLength * 24 * 60 * 60 * 1000 - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.max(0, diffDays);
};

const TrialStatus = ({ daysRemaining, setShowUpgrade, sx = {} }) => {
  const progress = ((14 - daysRemaining) / 14) * 100;

  return (
    <Card
      elevation={3}
      sx={{
        mb: 4,
        background: "linear-gradient(45deg, #2563eb, #7c3aed)",
        color: "white",
        ...sx,
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h5" gutterBottom>
            Trial Status
          </Typography>

          <Box>
            <Typography variant="body1" gutterBottom>
              {daysRemaining} {daysRemaining === 1 ? "day" : "days"} remaining
              in your trial
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 10,
                borderRadius: 5,
                bgcolor: "rgba(255,255,255,0.2)",
                "& .MuiLinearProgress-bar": {
                  bgcolor: "white",
                },
              }}
            />
          </Box>

          {daysRemaining <= 3 && (
            <Alert
              severity="warning"
              sx={{
                bgcolor: "rgba(255,255,255,0.1)",
                color: "white",
                "& .MuiAlert-icon": {
                  color: "white",
                },
              }}
            >
              Your trial is ending soon! Select a plan below to continue using
              all features.
            </Alert>
          )}

          <Box>
            <Typography variant="body2" sx={{ mb: 2 }}>
              During your trial, you have access to all features from our
              Professional plan. Choose a plan that best suits your needs before
              the trial ends.
            </Typography>
            <Button
              variant="contained"
              sx={{
                bgcolor: "white",
                color: "primary.main",
                "&:hover": {
                  bgcolor: "rgba(255,255,255,0.9)",
                },
              }}
              onClick={() => setShowUpgrade(true)}
            >
              View Plans
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

const Billing = () => {
  const navigate = useNavigate();
  const { organization, setOrganization } = useOrganization();
  const [showUpgrade, setShowUpgrade] = React.useState(false);
  const [showCreditsDialog, setShowCreditsDialog] = React.useState(false);
  const [usage, setUsage] = React.useState({
    properties: 0,
    tenants: 0,
    users: 0,
    contractors: 0,
    signatureCredits: 0,
  });
  const [showCancellationDialog, setShowCancellationDialog] =
    React.useState(false);
  const auth = getAuth();
  const [userProfile, setUserProfile] = React.useState(null);

  const fetchUsage = async () => {
    try {
      const db = getFirestore();
      const [
        propertiesSnapshot,
        tenantsSnapshot,
        usersSnapshot,
        contractorsSnapshot,
      ] = await Promise.all([
        getDocs(
          query(
            collection(db, "properties"),
            where("organizationId", "==", organization.id)
          )
        ),
        getDocs(
          query(
            collection(db, "tenants"),
            where("organizationId", "==", organization.id),
            where("status", "==", "active")
          )
        ),
        getDocs(
          query(
            collection(db, "users"),
            where("organizationId", "==", organization.id),
            where("role", "in", ["admin", "user"])
          )
        ),
        getDocs(
          query(
            collection(db, "contractors"),
            where("organizationId", "==", organization.id),
            where("status", "==", "active")
          )
        ),
      ]);

      // Get signature credits from organization data
      const signatureCredits = organization?.signatureCredits || 0;

      setUsage({
        properties: propertiesSnapshot.size,
        tenants: tenantsSnapshot.size,
        users: usersSnapshot.size,
        contractors: contractorsSnapshot.size,
        signatureCredits,
      });
    } catch (error) {
      console.error("Error fetching usage:", error);
    }
  };

  // Fetch user profile to check role
  React.useEffect(() => {
    const fetchUserProfile = async () => {
      if (auth.currentUser) {
        try {
          const db = getFirestore();
          const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
          if (userDoc.exists()) {
            setUserProfile(userDoc.data());
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }
    };

    fetchUserProfile();
  }, [auth.currentUser]);

  // Check if user is admin or owner
  const isAdminOrOwner =
    auth.currentUser?.email === organization?.ownerEmail ||
    userProfile?.role === "admin";

  // Redirect non-admin users
  React.useEffect(() => {
    if (userProfile && !isAdminOrOwner) {
      navigate("/dashboard");
    }
  }, [isAdminOrOwner, navigate, userProfile]);

  React.useEffect(() => {
    if (organization?.id) {
      fetchUsage();
    }
  }, [organization]);

  React.useEffect(() => {
    if (organization?.cancellationNeedsAcknowledgment) {
      setShowCancellationDialog(true);
    }
  }, [organization?.cancellationNeedsAcknowledgment]);

  const currentPlan = {
    ...organization?.subscription,
    ...organization?.plan,
    name:
      organization?.plan?.name || organization?.subscription?.name || "Entry",
    status:
      organization?.stripePriceId ||
      organization?.subscription?.status === "active"
        ? "active"
        : organization?.plan?.status || "trial",
    stripeSubscriptionId: organization?.stripePriceId,
    currentPeriodEnd: organization?.stripeCurrentPeriodEnd,
    limits: organization?.plan?.limits || {},
  };

  const trialDaysRemaining = getTrialDaysRemaining(
    organization?.plan?.startDate
  );

  // First check if subscription is active or was cancelled
  const isSubscriptionActive = Boolean(
    organization?.stripePriceId ||
      organization?.stripeSubscriptionId ||
      organization?.subscription?.status === "active" ||
      currentPlan?.status === "active"
  );

  const isSubscriptionCancelled = Boolean(
    organization?.plan?.status === "cancelled" ||
      organization?.subscription?.status === "cancelled" ||
      organization?.subscription?.cancelAtPeriodEnd
  );

  // Only consider trial active if there's no active/cancelled subscription AND trial hasn't been used
  const isTrialActive =
    !isSubscriptionActive &&
    !isSubscriptionCancelled &&
    !organization?.trialUsed &&
    trialDaysRemaining > 0 &&
    !organization?.subscription?.status;

  if (!userProfile || !isAdminOrOwner) {
    return null;
  }

  console.log("Organization data:", {
    organization,
    subscription: organization?.subscription,
    plan: organization?.plan,
    currentPlan,
    isTrialActive,
    isSubscriptionActive,
    trialDaysRemaining,
  });

  console.log("Subscription status:", {
    currentPlan,
    isTrialActive,
    isSubscriptionActive,
    status: currentPlan?.status,
    stripeSubId: currentPlan?.stripeSubscriptionId,
    limits: currentPlan?.limits,
    trialUsed: organization?.trialUsed,
    stripePriceId: organization?.stripePriceId,
  });

  const getTrialEndDate = () => {
    if (organization?.plan?.trialEndsAt) {
      return new Date(organization.plan.trialEndsAt);
    }
    if (organization?.plan?.startDate) {
      return new Date(
        new Date(organization.plan.startDate).getTime() +
          14 * 24 * 60 * 60 * 1000
      );
    }
    return new Date(Date.now() + 14 * 24 * 60 * 60 * 1000);
  };

  const getFormattedDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? date.toLocaleDateString() : null;
  };

  const getDaysUntilDeactivation = () => {
    if (!organization?.subscription?.deactivationDate) return null;
    const deactivationDate = new Date(
      organization.subscription.deactivationDate
    );
    const now = new Date();
    const days = Math.ceil((deactivationDate - now) / (1000 * 60 * 60 * 24));
    return Math.max(0, days);
  };

  const getSubscriptionStatusText = () => {
    // Check cancelled status first
    if (
      isSubscriptionCancelled &&
      organization?.subscription?.deactivationDate
    ) {
      const deactivationDate = new Date(
        organization.subscription.deactivationDate
      );
      const now = new Date();
      if (now < deactivationDate) {
        const daysRemaining = Math.ceil(
          (deactivationDate - now) / (1000 * 60 * 60 * 24)
        );
        return `Access until ${deactivationDate.toLocaleDateString()} (${daysRemaining} days remaining)`;
      }
      return "Subscription cancelled";
    }

    // Then check active subscription
    if (isSubscriptionActive) {
      const renewalDate = getFormattedDate(
        organization?.stripeCurrentPeriodEnd
      );
      if (renewalDate) {
        return `Subscription renews on ${renewalDate}`;
      }
      return "Active subscription";
    }

    // Finally check trial status
    if (isTrialActive) {
      const trialEndDate = getTrialEndDate();
      return `Trial ends on ${trialEndDate.toLocaleDateString()}`;
    }

    return "No active subscription";
  };

  const handleAcknowledgeCancellation = async () => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, "organizations", organization.id), {
        cancellationNeedsAcknowledgment: false,
      });
      setShowCancellationDialog(false);

      // Update local state
      setOrganization((prev) => ({
        ...prev,
        cancellationNeedsAcknowledgment: false,
      }));
    } catch (error) {
      console.error("Error acknowledging cancellation:", error);
    }
  };

  const handlePlanUpdate = async (plan) => {
    try {
      const db = getFirestore();

      // Update the organization document with the new subscription data
      await updateDoc(doc(db, "organizations", organization.id), {
        subscription: {
          ...organization.subscription,
          ...plan,
          status: plan.stripeSubscriptionId ? "active" : "trial",
        },
      });

      // Update local organization state
      setOrganization((prev) => ({
        ...prev,
        subscription: {
          ...prev.subscription,
          ...plan,
          status: plan.stripeSubscriptionId ? "active" : "trial",
        },
      }));
    } catch (error) {
      console.error("Error updating plan:", error);
    }
  };

  const handlePlanSelect = async (planData) => {
    try {
      const db = getFirestore();

      // First update the organization with the selected plan
      const subscriptionData = {
        ...organization.subscription,
        plan: planData.name,
        priceId: planData.priceId,
        status: "pending", // Will be updated to "active" by webhook
        updatedAt: new Date().toISOString(),
        limits: planData.limits || {
          properties:
            planData.name === "ENTRY"
              ? 50
              : planData.name === "GROWTH"
              ? 200
              : 999999,
          tenants:
            planData.name === "ENTRY"
              ? 50
              : planData.name === "GROWTH"
              ? 200
              : 999999,
          users:
            planData.name === "ENTRY"
              ? 5
              : planData.name === "GROWTH"
              ? 15
              : 999999,
          contractors:
            planData.name === "ENTRY"
              ? 10
              : planData.name === "GROWTH"
              ? 50
              : 999999,
        },
      };

      // Update trial status if it was active
      const trialUpdate =
        organization.trial === "active" ? { trial: "ended" } : {};

      console.log("Updating subscription with:", {
        priceId: planData.priceId,
        organizationId: organization.id,
      });

      // Create or retrieve Stripe customer ID
      let customerId = organization.stripeCustomerId;
      if (!customerId) {
        const { customerId: newCustomerId } = await createStripeCustomer(
          organization.id,
          {
            email: organization.email || organization.ownerEmail,
            name: organization.name || organization.ownerName,
          }
        );
        customerId = newCustomerId;
        // Update organization with Stripe customer ID
        await updateDoc(doc(db, "organizations", organization.id), {
          stripeCustomerId: customerId,
          ...trialUpdate,
        });
      }

      // Update the organization document with subscription data
      await updateDoc(doc(db, "organizations", organization.id), {
        subscription: subscriptionData,
        ...trialUpdate,
      });

      // Update local state
      setOrganization((prev) => ({
        ...prev,
        subscription: subscriptionData,
        stripeCustomerId: customerId,
        ...trialUpdate,
      }));

      // Check if this is an upgrade (existing subscription)
      const isUpgrade = Boolean(
        organization?.stripePriceId ||
          organization?.stripeSubscriptionId ||
          organization?.subscription?.status === "active"
      );

      // Create Stripe checkout session with the appropriate mode
      const { sessionId } = await createSubscriptionSession(
        planData.priceId,
        organization.stripeCustomerId,
        isUpgrade ? organization.stripeSubscriptionId : null // Pass existing subscription ID if upgrading
      );

      // Redirect to Stripe checkout
      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
      if (!stripe) throw new Error("Stripe failed to initialize");

      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Error during plan selection:", error);
      // You might want to show an error notification here
    }
  };

  const handleManageSubscription = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/stripe/create-portal-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": import.meta.env.VITE_API_KEY,
          },
          body: JSON.stringify({
            customerId: organization.stripeCustomerId,
            returnUrl: window.location.href,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error?.message || "Failed to create portal session"
        );
      }

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error("Error creating portal session:", error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const db = getFirestore();

      // Use the current billing period end date as the deactivation date
      const deactivationDate = new Date(
        organization?.stripeCurrentPeriodEnd || Date.now()
      );

      // Update organization document
      await updateDoc(doc(db, "organizations", organization.id), {
        "subscription.status": "cancelled",
        "subscription.cancelAtPeriodEnd": true,
        "subscription.deactivationDate": deactivationDate.toISOString(),
        "subscription.cancellationNeedsAcknowledgment": true,
      });

      // Update local state
      setOrganization((prev) => ({
        ...prev,
        subscription: {
          ...prev.subscription,
          status: "cancelled",
          cancelAtPeriodEnd: true,
          deactivationDate: deactivationDate.toISOString(),
          cancellationNeedsAcknowledgment: true,
        },
      }));

      // Call the API to cancel the subscription
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/stripe/cancel-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": import.meta.env.VITE_API_KEY,
          },
          body: JSON.stringify({
            customerId: organization.id,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error?.message || "Failed to cancel subscription"
        );
      }

      setShowUpgrade(false);
    } catch (error) {
      console.error("Error cancelling subscription:", error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 4 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate("/dashboard")}
          sx={{ mb: 2 }}
        >
          Return to Dashboard
        </Button>
        <Typography variant="h4" sx={{ mb: 1, fontWeight: 700 }}>
          Billing & Usage
        </Typography>
        <Typography color="text.secondary">
          Manage your subscription and monitor resource usage
        </Typography>
      </Box>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Current Plan: {currentPlan?.name || "Entry"}
                {isTrialActive &&
                  !isSubscriptionActive &&
                  !isSubscriptionCancelled && (
                    <Chip
                      label="Trial"
                      size="small"
                      color="warning"
                      sx={{ ml: 1 }}
                    />
                  )}
                {isSubscriptionCancelled ? (
                  <Chip
                    label="Cancelling"
                    size="small"
                    color="error"
                    sx={{ ml: 1 }}
                  />
                ) : (
                  isSubscriptionActive &&
                  !organization?.subscription?.cancelAtPeriodEnd && (
                    <Chip
                      label="Active"
                      size="small"
                      color="success"
                      sx={{ ml: 1 }}
                    />
                  )
                )}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  {getSubscriptionStatusText()}
                </Typography>
              </Box>
              {organization?.plan?.status === "cancelled" &&
                organization?.plan?.deactivationDate && (
                  <Box sx={{ mt: 2 }}>
                    <Alert severity="warning">
                      Your subscription has been cancelled and will remain
                      active until{" "}
                      {new Date(
                        organization.plan.deactivationDate
                      ).toLocaleDateString()}
                      . After this date, your account will be deactivated. To
                      maintain access, please renew your subscription before the
                      cancellation date.
                    </Alert>
                  </Box>
                )}
            </Box>
            <Button
              variant="contained"
              onClick={
                isSubscriptionActive
                  ? handleManageSubscription
                  : () => setShowUpgrade(true)
              }
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              {isTrialActive
                ? "Choose Plan"
                : organization?.plan?.status === "cancelled"
                ? "Reactivate Plan"
                : isSubscriptionActive
                ? "Manage Plan"
                : "Upgrade Plan"}
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Typography variant="h6" sx={{ mb: 3 }}>
        Resource Usage
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<BusinessIcon color="primary" />}
            title="Properties"
            used={usage.properties}
            limit={currentPlan?.limits?.properties}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<PeopleIcon color="success" />}
            title="Tenants"
            used={usage.tenants}
            limit={currentPlan?.limits?.tenants}
            color="success"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<PersonIcon color="info" />}
            title="Users"
            used={usage.users}
            limit={currentPlan?.limits?.users}
            color="info"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<EngineeringIcon color="warning" />}
            title="Contractors"
            used={usage.contractors}
            limit={currentPlan?.limits?.contractors}
            color="warning"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box
                sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}
              >
                <DescriptionIcon color="secondary" />
                <Typography variant="h6" component="div">
                  E-Signature Credits
                </Typography>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="h4" color="text.primary" sx={{ mb: 1 }}>
                  {usage.signatureCredits}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Available credits
                </Typography>
              </Box>
              <Chip
                label="Coming Soon"
                color="primary"
                variant="outlined"
                size="small"
                sx={{ mt: 1 }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {showUpgrade && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setShowUpgrade(false)}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 2,
              p: 4,
              maxWidth: "1200px",
              width: "90%",
              maxHeight: "90vh",
              overflow: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {isSubscriptionActive ? (
              <Box>
                <Typography variant="h5" gutterBottom>
                  Manage Your Subscription
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  Your {currentPlan?.name} plan renews on{" "}
                  {new Date(
                    organization?.stripeCurrentPeriodEnd
                  ).toLocaleDateString()}
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCancelSubscription}
                  >
                    Cancel Subscription
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowUpgrade(false);
                      setShowUpgrade(true);
                    }}
                  >
                    Change Plan
                  </Button>
                </Box>
              </Box>
            ) : (
              <PricingPlans onPlanSelect={handlePlanSelect} />
            )}
          </Box>
        </Box>
      )}

      {showCancellationDialog && (
        <Dialog
          open={showCancellationDialog}
          onClose={handleAcknowledgeCancellation}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent sx={{ textAlign: "center", py: 4 }}>
            <Typography variant="h5" gutterBottom>
              Sorry to See You Go
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Your subscription has been cancelled and will remain active until{" "}
              {getFormattedDate(organization?.subscription?.deactivationDate)}.
              After this date, your account will be deactivated.
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              You have {getDaysUntilDeactivation()} days of access remaining.
            </Typography>
            <Button
              variant="contained"
              onClick={handleAcknowledgeCancellation}
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              I Understand
            </Button>
          </DialogContent>
        </Dialog>
      )}

      <SignatureCreditsBundles
        open={showCreditsDialog}
        onClose={() => setShowCreditsDialog(false)}
      />
    </Box>
  );
};

export default Billing;
