import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const Contracts = () => {
  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Box
        sx={{
          mb: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "linear-gradient(180deg, #f0f7ff 0%, #FFFFFF 100%)",
          p: 3,
          borderRadius: 2,
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ fontWeight: 700, color: "#1e3a8a" }}>
            Contracts
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            Manage and track your property contracts
          </Typography>
        </Box>
      </Box>

      {/* Coming Soon Message */}
      <Paper
        elevation={0}
        sx={{
          p: 6,
          textAlign: "center",
          background: "linear-gradient(180deg, #f0f7ff 0%, #FFFFFF 100%)",
          borderRadius: 2,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, color: "#1e3a8a", mb: 2 }}
        >
          🚀 Coming Soon
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We're working hard to bring you a powerful contract management system.
          This feature will be available soon!
        </Typography>
      </Paper>
    </Box>
  );
};

export default Contracts;
