import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { format } from "date-fns";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const TenantPayments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const { currentUser } = useAuth();
  const db = getFirestore();
  const auth = getAuth();

  // Handle auth state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed:", user?.uid);
      setUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const fetchTenantPayments = async () => {
      if (!user) return;

      try {
        console.log("Starting to fetch payments...");
        setLoading(true);

        // Get all payments for this tenant
        console.log("Fetching payments for tenant:", user.uid);
        const paymentsRef = collection(db, "rentPayments");
        const paymentsQuery = query(
          paymentsRef,
          where("tenantId", "==", user.uid),
          where("status", "in", [
            "payment_due",
            "awaiting_payment",
            "paid",
            "overdue",
          ])
        );

        const paymentsSnapshot = await getDocs(paymentsQuery);
        console.log("Found", paymentsSnapshot.size, "payments");

        const paymentsData = paymentsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            dueDate: data.dueDate ? new Date(data.dueDate) : new Date(),
            propertyAddress: data.propertyName || "Unknown Property",
          };
        });

        // Sort payments by due date
        const sortedPayments = paymentsData.sort(
          (a, b) => a.dueDate - b.dueDate
        );
        console.log("Processed payments:", sortedPayments.length);

        setPayments(sortedPayments);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tenant payments:", error);
        setError("Failed to load payments. Please try again later.");
        setLoading(false);
      }
    };

    if (user) {
      console.log("User authenticated, starting fetch");
      fetchTenantPayments();
    } else {
      console.log("Waiting for authentication...");
    }
  }, [user, db]);

  const getStatusColor = (status) => {
    switch (status) {
      case "paid":
        return "success";
      case "payment_due":
      case "awaiting_payment":
        return "warning";
      case "overdue":
        return "error";
      default:
        return "default";
    }
  };

  const getFormattedStatus = (status) => {
    switch (status) {
      case "paid":
        return "Paid";
      case "payment_due":
        return "Payment Due";
      case "awaiting_payment":
        return "Awaiting Payment";
      case "overdue":
        return "Overdue";
      default:
        return status;
    }
  };

  if (!user) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          gap: 2,
        }}
      >
        <CircularProgress />
        <Typography variant="body2" color="textSecondary">
          Authenticating...
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          gap: 2,
        }}
      >
        <CircularProgress />
        <Typography variant="body2" color="textSecondary">
          Loading your payments...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        My Rent Payments
      </Typography>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Upcoming Payments
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Due Date</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell>{format(payment.dueDate, "PPP")}</TableCell>
                    <TableCell align="right">
                      £{payment.amount.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        label={getFormattedStatus(payment.status)}
                        color={getStatusColor(payment.status)}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {payments.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No upcoming payments found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TenantPayments;
