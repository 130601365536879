import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Breadcrumbs,
  Link,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
  Build as BuildIcon,
  Payments as PaymentsIcon,
  Description as DescriptionIcon,
  Star as StarIcon,
  Assessment as AssessmentIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";

const guides = [
  {
    category: "Getting Started",
    icon: <StarIcon />,
    items: [
      {
        title: "Platform Overview",
        content:
          "Welcome to TenantIO:\n1. Dashboard Overview:\n   - Quick access to key metrics\n   - Property overview\n   - Recent activities\n   - Important notifications\n2. Navigation Guide:\n   - Main menu sections\n   - Quick actions\n   - Search functionality\n   - Notification center\n3. Account Setup:\n   - Profile configuration\n   - Organization settings\n   - User roles and permissions\n   - Notification preferences",
      },
      {
        title: "First Steps Guide",
        content:
          "Essential setup steps:\n1. Complete Your Profile:\n   - Add company information\n   - Upload company logo\n   - Set business hours\n   - Configure payment settings\n2. Add Your First Property:\n   - Basic property information\n   - Unit setup\n   - Document upload\n3. Invite Team Members:\n   - Add staff accounts\n   - Set permissions\n   - Assign responsibilities\n4. Configure Settings:\n   - Payment methods\n   - Lease templates\n   - Maintenance settings\n   - Communication preferences",
      },
      {
        title: "Subscription Management",
        content:
          "Managing your subscription:\n1. Upgrading Your Plan:\n   - To upgrade to a higher tier plan, you'll need to:\n   - First cancel your current subscription\n   - Wait for the current billing period to end\n   - Then purchase the new desired plan\n   - Note: Your data and settings will be preserved during this process\n2. Cancellation Process:\n   - Navigate to Billing & Usage\n   - Click on 'Manage Subscription'\n   - Select 'Cancel Subscription'\n   - Your service will continue until the end of the billing period\n3. Purchasing New Plan:\n   - Once your current subscription ends\n   - The 'Upgrade' button will appear in the dashboard\n   - Click 'Upgrade' to view available plans\n   - Select your desired new plan\n   - Complete the checkout process",
      },
    ],
  },
  {
    category: "Property Management",
    icon: <BusinessIcon />,
    items: [
      {
        title: "Adding a New Property",
        content:
          "To add a new property:\n1. Navigate to Properties section\n2. Click the 'Add Property' button\n3. Fill in property details including:\n   - Property name and address\n   - Property type (residential, commercial)\n   - Number of units\n   - Amenities and features\n   - Property manager assignment\n   - Insurance information\n   - Tax details\n4. Upload property images:\n   - Exterior photos\n   - Interior photos\n   - Amenity photos\n   - Floor plans\n5. Add property documents:\n   - Insurance certificates\n   - Property tax records\n   - Building permits\n   - Inspection reports\n6. Set property status and configurations:\n   - Active/inactive status\n   - Vacancy settings\n   - Maintenance preferences\n   - Access instructions\n7. Save and verify property details",
      },
      {
        title: "Managing Units",
        content:
          "Each property can have multiple units. To manage units:\n1. Click on a property to view details\n2. Navigate to the Units tab\n3. Add new units with details:\n   - Unit number/identifier\n   - Square footage\n   - Number of bedrooms/bathrooms\n   - Monthly rent amount\n   - Unit features and amenities\n   - Utility responsibilities\n   - Parking assignments\n   - Storage units\n4. Track unit status:\n   - Vacant: Ready for new tenants\n   - Occupied: Currently rented\n   - Maintenance: Under repair\n   - Reserved: Pending move-in\n   - Notice given: Upcoming vacancy\n5. Unit Maintenance:\n   - Schedule regular inspections\n   - Track repair history\n   - Document unit conditions\n6. Unit Financial Tracking:\n   - Rent history\n   - Maintenance costs\n   - Utility expenses\n   - ROI calculations\n7. Tenancy History:\n   - Complete history of past tenancies\n   - Payment records for each tenancy\n   - Downloadable payment history\n   - Tenant occupancy periods",
      },
      {
        title: "Property Details & History",
        content:
          "Access comprehensive property information:\n1. View property dashboard for key metrics:\n   - Occupancy rates\n   - Revenue overview\n   - Maintenance status\n   - Recent activities\n2. Track occupancy rates and vacancy periods:\n   - Historical occupancy data\n   - Seasonal trends\n   - Vacancy costs\n   - Marketing effectiveness\n3. Monitor property performance:\n   - Rental income history\n   - Maintenance costs\n   - Property value changes\n   - Expense tracking\n   - ROI analysis\n4. Access property documents:\n   - Insurance papers\n   - Inspection reports\n   - Property tax documents\n   - Maintenance records\n   - Vendor contracts\n5. Property Analytics:\n   - Market comparison\n   - Performance trends\n   - Cost analysis\n   - Future projections",
      },
    ],
  },
  {
    category: "Tenant Management",
    icon: <PeopleIcon />,
    items: [
      {
        title: "Tenant Applications",
        content:
          "Process tenant applications efficiently:\n1. Access applications in Tenants section\n2. Review application details:\n   - Personal information\n   - Contact details\n   - References\n   - Income verification\n3. Application Processing:\n   - Review application details\n   - Verify provided information\n   - Check rental criteria\n4. Decision Making:\n   - Accept application\n   - Reject application\n   - Request additional information\n5. Next Steps:\n   - Generate lease agreement\n   - Collect deposits\n   - Schedule move-in\n6. Managing Inactive Tenants:\n   - View inactive tenants in dedicated tab\n   - Access historical payment records\n   - Reactivate tenants when needed\n   - Maintain complete tenant history",
      },
      {
        title: "Tenant Communication Hub",
        content:
          "Manage tenant communications:\n1. Email Notifications:\n   - Welcome emails for new tenants\n   - Rent payment reminders\n   - Maintenance request updates\n2. Document Management:\n   - Access to lease documents\n   - View maintenance reports\n   - Store important notices\n3. Contact Information:\n   - View tenant contact details\n   - Access property manager details\n   - Emergency contact information",
      },
    ],
  },
  {
    category: "Maintenance Management",
    icon: <BuildIcon />,
    items: [
      {
        title: "Maintenance Request Lifecycle",
        content:
          "Complete maintenance process guide:\n1. Request Submission:\n   - Tenant portal submission\n   - Photo/video attachments\n   - Priority level selection\n   - Issue type selection\n2. Initial Assessment:\n   - Priority classification\n   - Issue categorization\n   - Status tracking\n3. Status Updates:\n   - Track request progress\n   - View completion status\n   - Access maintenance history\n4. Documentation:\n   - Photo evidence\n   - Maintenance notes\n   - Resolution details\n5. Historical Tracking:\n   - Complete maintenance history\n   - Unit-specific records\n   - Issue patterns tracking",
      },
      {
        title: "Preventive Maintenance Program",
        content:
          "Comprehensive preventive maintenance:\n1. Schedule Creation:\n   - Annual planning\n   - Seasonal tasks\n   - Regulatory requirements\n   - Equipment-specific maintenance\n2. Task Management:\n   - Assignment rules\n   - Resource allocation\n   - Budget planning\n   - Timeline tracking\n3. System Maintenance:\n   - HVAC systems\n   - Plumbing systems\n   - Electrical systems\n   - Building envelope\n4. Documentation:\n   - Maintenance logs\n   - Cost tracking\n   - Warranty information\n   - Service history\n5. Performance Analysis:\n   - Cost savings\n   - Problem prevention\n   - Equipment lifespan\n   - Budget optimization",
      },
    ],
  },
  {
    category: "Financial Management",
    icon: <PaymentsIcon />,
    items: [
      {
        title: "Rent Tracking & Collection",
        content:
          "Comprehensive rent management:\n1. Payment Setup:\n   - Payment methods configuration\n   - Auto-pay enrollment\n   - Late fee rules\n   - Grace period settings\n2. Payment Processing:\n   - Real-time tracking\n   - Payment verification\n   - Receipt generation\n   - Bank reconciliation\n3. Late Payment Handling:\n   - Automated reminders\n   - Late fee assessment\n   - Payment plans\n   - Collection procedures\n4. Financial Reporting:\n   - Collection rates\n   - Aging reports\n   - Payment trends\n   - Cash flow analysis\n5. Payment History Retention:\n   - Complete payment history for inactive tenants\n   - Retention of paid payment records\n   - Historical payment tracking\n   - Audit trail maintenance\n6. Tenancy End Process:\n   - Retain all paid payment records\n   - Remove only future unpaid payments\n   - Maintain payment schedules\n   - Preserve financial history",
      },
      {
        title: "Advanced Financial Analytics",
        content:
          "Deep financial insights:\n1. Property Performance:\n   - Revenue analysis\n   - Expense tracking\n   - Profitability metrics\n   - Comparison reports\n2. Budget Management:\n   - Budget creation\n   - Variance analysis\n   - Forecast modeling\n   - Cost optimization\n3. Investment Analysis:\n   - ROI calculations\n   - Property appreciation\n   - Market comparisons\n   - Investment planning\n4. Tax Management:\n   - Expense categorization\n   - Deduction tracking\n   - Tax document preparation\n   - Audit trail maintenance",
      },
    ],
  },
  {
    category: "Reporting & Analytics",
    icon: <AssessmentIcon />,
    items: [
      {
        title: "Custom Report Builder",
        content:
          "Create tailored reports:\n1. Report Types:\n   - Financial reports\n   - Occupancy reports\n   - Maintenance reports\n   - Tenant reports\n2. Customization Options:\n   - Data selection\n   - Time periods\n   - Grouping options\n   - Visualization choices\n3. Scheduling:\n   - Automated generation\n   - Email distribution\n   - Format selection\n   - Recipient management",
      },
      {
        title: "Business Intelligence",
        content:
          "Data-driven insights:\n1. Performance Metrics:\n   - KPI tracking\n   - Trend analysis\n   - Benchmark comparisons\n   - Goal monitoring\n2. Predictive Analytics:\n   - Vacancy predictions\n   - Maintenance forecasting\n   - Revenue projections\n   - Market trends\n3. Interactive Dashboards:\n   - Real-time updates\n   - Drill-down capabilities\n   - Custom views\n   - Data exports",
      },
    ],
  },
  {
    category: "System Administration",
    icon: <SettingsIcon />,
    items: [
      {
        title: "User Management",
        content:
          "Manage system access:\n1. User Roles:\n   - Role definition\n   - Permission settings\n   - Access levels\n   - Feature restrictions\n2. Security Settings:\n   - Password policies\n   - Two-factor authentication\n   - Session management\n   - Activity logging\n3. Team Management:\n   - User onboarding\n   - Training resources\n   - Performance tracking\n   - Communication settings",
      },
      {
        title: "System Configuration",
        content:
          "Platform customization:\n1. General Settings:\n   - Company information\n   - Regional settings\n   - Language preferences\n   - Notification rules\n2. Integration Management:\n   - Payment processors\n   - Accounting software\n   - Communication tools\n   - Third-party services\n3. Data Management:\n   - Backup settings\n   - Archive policies\n   - Storage management\n   - Data retention",
      },
    ],
  },
];

const KnowledgeBase = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredGuides = guides
    .map((category) => ({
      ...category,
      items: category.items.filter(
        (item) =>
          item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.content.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((category) => category.items.length > 0);

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3 }}>
        <Link color="inherit" href="/dashboard">
          Dashboard
        </Link>
        <Typography color="text.primary">Knowledge Base</Typography>
      </Breadcrumbs>

      <Typography
        variant="h4"
        sx={{
          mb: 1,
          fontWeight: 700,
          background: "linear-gradient(45deg, #2563eb, #7c3aed)",
          backgroundClip: "text",
          textFillColor: "transparent",
        }}
      >
        Knowledge Base
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        Learn how to make the most of our property management platform with
        these guides and tips.
      </Typography>

      <Card
        sx={{
          mb: 4,
          background:
            "linear-gradient(45deg, rgba(37,99,235,0.03), rgba(124,58,237,0.03))",
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <CardContent>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search guides..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "background.paper",
              },
            }}
          />
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        {filteredGuides.map((category, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                border: "1px solid",
                borderColor: "divider",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                },
              }}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Box
                    sx={{
                      mr: 2,
                      p: 1,
                      borderRadius: 1,
                      bgcolor: "primary.lighter",
                      color: "primary.main",
                    }}
                  >
                    {category.icon}
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {category.category}
                  </Typography>
                </Box>

                {category.items.map((item, itemIndex) => (
                  <Accordion
                    key={itemIndex}
                    sx={{
                      "&:before": { display: "none" },
                      boxShadow: "none",
                      border: "1px solid",
                      borderColor: "divider",
                      mb: 1,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        "&:hover": { bgcolor: "action.hover" },
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        {item.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {item.content}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default KnowledgeBase;
