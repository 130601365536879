import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

export const checkResourceLimit = async (
  organizationId,
  resourceType,
  currentPlan
) => {
  const db = getFirestore();
  const limits = currentPlan?.limits || {};

  // If no limits set for this resource type, allow creation
  if (!limits[resourceType]) return { allowed: true };

  const resourceMapping = {
    properties: "properties",
    users: "users",
    tenants: "tenants",
    contractors: "contractors",
    documents: "documents",
  };

  const collectionName = resourceMapping[resourceType];
  if (!collectionName) return { allowed: true };

  // For tenants and contractors, only count active ones
  let q;
  if (resourceType === "tenants" || resourceType === "contractors") {
    q = query(
      collection(db, collectionName),
      where("organizationId", "==", organizationId),
      where("status", "==", "active")
    );
  } else if (resourceType === "users") {
    // For users, only count those with role "user" or "admin"
    q = query(
      collection(db, collectionName),
      where("organizationId", "==", organizationId),
      where("role", "in", ["user", "admin"])
    );
  } else {
    q = query(
      collection(db, collectionName),
      where("organizationId", "==", organizationId)
    );
  }

  const snapshot = await getDocs(q);
  const currentCount = snapshot.size;

  return {
    allowed: currentCount < limits[resourceType],
    current: currentCount,
    limit: limits[resourceType],
    message: `You cannot create any more ${resourceType} on this plan. Your plan allows ${limits[resourceType]} ${resourceType}, and you currently have ${currentCount}.`,
  };
};
