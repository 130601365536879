import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Divider,
  Alert,
  Chip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  PersonAdd as PersonAddIcon,
  Save as SaveIcon,
  Warning as WarningIcon,
  BugReport as BugReportIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  setDoc,
  writeBatch,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useOrganization } from "../../contexts/OrganizationContext";
import { checkResourceLimit } from "../../utils/planLimits";
import { useNavigate } from "react-router-dom";
import { emailService } from "../../services/emailService";

const Account = () => {
  const [editing, setEditing] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);
  const [deleteConfirmText, setDeleteConfirmText] = useState("");
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [reportIssueDialogOpen, setReportIssueDialogOpen] = useState(false);
  const [issueDescription, setIssueDescription] = useState("");
  const [isSubmittingIssue, setIsSubmittingIssue] = useState(false);
  const navigate = useNavigate();

  const { organization, setOrganization } = useOrganization();
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    if (organization) {
      setOrgName(organization.name);
      fetchUsers();
    }
  }, [organization]);

  const fetchUsers = async () => {
    try {
      // First get all users for this organization
      const baseQuery = query(
        collection(db, "users"),
        where("organizationId", "==", organization.id)
      );
      const snapshot = await getDocs(baseQuery);
      const usersList = [];

      // Get current user's document to check role
      const currentUserDoc = await getDoc(
        doc(db, "users", auth.currentUser.uid)
      );
      const currentUserRole = currentUserDoc.data()?.role;

      // Filter out removed users and include only those with admin/user roles
      snapshot.forEach((doc) => {
        const userData = doc.data();
        // Include user if: status is not 'removed' AND (no status field exists OR role is admin/user)
        if (
          userData.status !== "removed" &&
          (userData.role === "admin" || userData.role === "user")
        ) {
          usersList.push({
            id: doc.id,
            ...userData,
            isCurrentUser: doc.id === auth.currentUser.uid,
            currentUserRole: currentUserRole,
          });
        }
      });

      setUsers(usersList);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to load users");
    }
  };

  const handleUpdateOrgName = async () => {
    try {
      if (!organization) {
        setError("Organization data not available");
        setEditing(false);
        return;
      }

      // Only allow admin/owner to edit organization details
      if (
        auth.currentUser?.email !== organization?.ownerEmail &&
        organization?.role !== "admin"
      ) {
        setError("Only organization admin can edit organization details");
        setEditing(false);
        return;
      }

      await updateDoc(doc(db, "organizations", organization.id), {
        name: orgName,
      });
      setOrganization({ ...organization, name: orgName });
      setEditing(false);
      setSuccess("Organization name updated successfully");
    } catch (error) {
      console.error("Error updating organization name:", error);
      setError("Failed to update organization name");
    }
  };

  const handleAddUser = async () => {
    try {
      setSubmitting(true);
      setError("");

      // Check resource limit
      const limitCheck = await checkResourceLimit(
        organization.id,
        "users",
        organization.plan
      );
      if (!limitCheck.allowed) {
        setError(limitCheck.message);
        return;
      }

      if (!newUser.name || !newUser.email) {
        setError("Please fill in all required fields");
        return;
      }

      const auth = getAuth();

      // Create user account with temporary password
      const tempPassword = Math.random().toString(36).slice(-8);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        tempPassword
      );

      // Create user document
      await setDoc(doc(db, "users", userCredential.user.uid), {
        name: newUser.name,
        email: newUser.email,
        organizationId: organization.id,
        role: newUser.role,
        status: "active",
        createdAt: new Date().toISOString(),
      });

      // Update organization's user count
      const orgRef = doc(db, "organizations", organization.id);
      const orgDoc = await getDoc(orgRef);
      const currentUserCount = orgDoc.data()?.userCount || 0;

      await updateDoc(orgRef, {
        userCount: currentUserCount + 1,
      });

      // Update local organization state
      setOrganization((prev) => ({
        ...prev,
        userCount: (prev.userCount || 0) + 1,
      }));

      // Send password reset email
      await sendPasswordResetEmail(auth, newUser.email, {
        url: window.location.origin + "/login",
        handleCodeInApp: true,
      });

      // Send welcome email
      try {
        await emailService.sendTeamMemberWelcomeEmail(
          newUser.email,
          newUser.name,
          organization.name
        );
      } catch (emailError) {
        console.error("Error sending welcome email:", emailError);
        // Continue with the process even if welcome email fails
      }

      setOpenDialog(false);
      setNewUser({
        name: "",
        email: "",
        password: "",
        role: "user",
      });
      fetchUsers();
      setSuccess(
        "Team member account created successfully! Welcome emails have been sent with setup instructions."
      );
    } catch (error) {
      console.error("Error adding user:", error);
      setError(error.message || "Error adding user");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteUser = (user) => {
    try {
      if (!organization) {
        setError("Organization data not available");
        return;
      }

      console.log("Current user:", {
        email: auth.currentUser?.email,
        organizationEmail: organization?.ownerEmail,
        userRole: user.role,
        currentUserRole: user.currentUserRole,
      });

      // Check if current user is owner or admin
      const isOwner = auth.currentUser?.email === organization?.ownerEmail;
      const isAdmin = user.currentUserRole === "admin";

      // Only allow admin/owner to delete users
      if (!isOwner && !isAdmin) {
        setError("Only organization admin or owner can delete users");
        return;
      }

      // Prevent deletion of organization owner
      if (user.email === organization?.ownerEmail) {
        setError("Cannot remove the organization owner account");
        return;
      }

      // Prevent regular users from deleting admins
      if (!isOwner && !isAdmin && user.role === "admin") {
        setError("You don't have permission to remove admin users");
        return;
      }

      // Prevent self-deletion
      if (user.email === auth.currentUser?.email) {
        setError("You cannot remove your own account");
        return;
      }

      setUserToDelete(user);
      setDeleteDialogOpen(true);
    } catch (error) {
      console.error("Error in handleDeleteUser:", error);
      setError("An error occurred while processing your request");
    }
  };

  const confirmDelete = async () => {
    try {
      setSubmitting(true);
      const db = getFirestore();

      // Create a batch for atomic operations
      const batch = writeBatch(db);

      // 1. Update user document
      const userRef = doc(db, "users", userToDelete.id);
      batch.update(userRef, {
        status: "removed",
        role: "removed",
        isDisabled: true,
        deletedAt: new Date().toISOString(),
        deletedBy: auth.currentUser?.email,
        organizationId: null, // Remove organization association
      });

      // 2. Update organization's user count
      const orgRef = doc(db, "organizations", organization.id);
      const orgDoc = await getDoc(orgRef);
      const currentUserCount = orgDoc.data()?.userCount || 0;
      batch.update(orgRef, {
        userCount: Math.max(0, currentUserCount - 1),
      });

      // 3. Add to activity log
      const activityLogRef = collection(db, "activityLog");
      batch.set(doc(activityLogRef), {
        type: "user_removed",
        userId: userToDelete.id,
        userEmail: userToDelete.email,
        organizationId: organization.id,
        performedBy: auth.currentUser?.email,
        timestamp: serverTimestamp(),
        details: `User ${userToDelete.email} was removed from the organization`,
      });

      // 4. Update user's custom claims via a special document that cloud functions will watch
      const userClaimsRef = doc(db, "userClaims", userToDelete.id);
      batch.set(userClaimsRef, {
        action: "disable",
        organizationId: organization.id,
        timestamp: serverTimestamp(),
        processedAt: null,
        status: "pending",
      });

      // Commit all changes
      await batch.commit();

      // Update local organization state
      setOrganization((prev) => ({
        ...prev,
        userCount: Math.max(0, (prev.userCount || 0) - 1),
      }));

      fetchUsers(); // Refresh the users list
      setSuccess("User removed successfully");
      setDeleteDialogOpen(false);
      setUserToDelete(null);
    } catch (error) {
      console.error("Error removing user:", error);
      setError("Failed to remove user: " + error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (auth.currentUser?.email !== organization?.ownerEmail) {
      setError("Only the organization owner can delete the organization");
      setDeleteAccountDialogOpen(false);
      return;
    }

    if (deleteConfirmText !== organization.name) {
      setError(
        "Please type the organization name correctly to confirm deletion"
      );
      return;
    }

    try {
      setIsDeletingAccount(true);
      const db = getFirestore();
      const batch = writeBatch(db);

      // Update organization status to cancelled
      const orgRef = doc(db, "organizations", organization.id);
      batch.update(orgRef, {
        status: "cancelled",
        isDisabled: true,
        deletedAt: new Date().toISOString(),
      });

      // Fetch and update all users to disabled
      const usersQuery = query(
        collection(db, "users"),
        where("organizationId", "==", organization.id)
      );
      const usersSnapshot = await getDocs(usersQuery);
      usersSnapshot.forEach((userDoc) => {
        batch.update(doc(db, "users", userDoc.id), {
          isDisabled: true,
          deletedAt: new Date().toISOString(),
        });
      });

      // Optional: Delete or mark as deleted other associated data
      // Properties
      const propertiesQuery = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const propertiesSnapshot = await getDocs(propertiesQuery);
      propertiesSnapshot.forEach((propertyDoc) => {
        batch.delete(doc(db, "properties", propertyDoc.id));
      });

      // Tenants
      const tenantsQuery = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id)
      );
      const tenantsSnapshot = await getDocs(tenantsQuery);
      tenantsSnapshot.forEach((tenantDoc) => {
        batch.delete(doc(db, "tenants", tenantDoc.id));
      });

      // Files
      const filesQuery = query(
        collection(db, "files"),
        where("organizationId", "==", organization.id)
      );
      const filesSnapshot = await getDocs(filesQuery);
      filesSnapshot.forEach((fileDoc) => {
        batch.delete(doc(db, "files", fileDoc.id));
      });

      // Commit all changes
      await batch.commit();

      // Sign out and redirect to login
      const auth = getAuth();
      await auth.signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error deleting account:", error);
      setError("Failed to delete account. Please try again later.");
    } finally {
      setIsDeletingAccount(false);
      setDeleteAccountDialogOpen(false);
    }
  };

  const handleReportIssue = async () => {
    if (!issueDescription.trim()) {
      setError("Please provide a description of the issue");
      return;
    }

    try {
      setIsSubmittingIssue(true);
      setError("");

      // Get current user's details
      const currentUserDoc = await getDoc(
        doc(db, "users", auth.currentUser.uid)
      );
      const userData = currentUserDoc.data();

      await emailService.sendIssueReport(
        userData.name,
        auth.currentUser.email,
        { description: issueDescription },
        organization.id
      );

      setSuccess("Issue reported successfully. Our team will look into it.");
      setReportIssueDialogOpen(false);
      setIssueDescription("");
    } catch (error) {
      console.error("Error reporting issue:", error);
      setError("Failed to report issue. Please try again.");
    } finally {
      setIsSubmittingIssue(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", py: 4, px: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          Account Settings
        </Typography>
        <Button
          variant="outlined"
          startIcon={<BugReportIcon />}
          onClick={() => setReportIssueDialogOpen(true)}
        >
          Report Issue
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {success}
        </Alert>
      )}

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
            Organization Details
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
            {editing ? (
              <>
                <TextField
                  fullWidth
                  label="Organization Name"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleUpdateOrgName}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1">
                  {organization?.name || "Loading..."}
                </Typography>
                {organization &&
                  (auth.currentUser?.email === organization?.ownerEmail ||
                    organization?.role === "admin") && (
                    <IconButton
                      size="small"
                      onClick={() => setEditing(true)}
                      sx={{ color: "primary.main" }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
              </>
            )}
          </Box>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Users
            </Typography>
            <Button
              variant="contained"
              startIcon={<PersonAddIcon />}
              onClick={() => setOpenDialog(true)}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Add User
            </Button>
          </Box>

          <List sx={{ width: "100%" }}>
            {users.map((user, index) => (
              <React.Fragment key={user.id}>
                <ListItem
                  sx={{
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                      height: 8,
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                      borderRadius: 4,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#888",
                      borderRadius: 4,
                      "&:hover": {
                        background: "#666",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      minWidth: { xs: "fit-content", md: "auto" },
                      width: "100%",
                    }}
                  >
                    <Avatar
                      sx={{
                        flexShrink: 0,
                        bgcolor: "primary.main",
                      }}
                    >
                      {user.name?.[0]}
                    </Avatar>
                    <Box sx={{ minWidth: { xs: "fit-content", md: "auto" } }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {user.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        {user.email}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        ml: "auto",
                        flexShrink: 0,
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        label={user.role || "user"}
                        size="small"
                        color={
                          user.role === "admin"
                            ? "primary"
                            : user.role === "user"
                            ? "info"
                            : user.role === "tenant"
                            ? "success"
                            : user.role === "contractor"
                            ? "warning"
                            : "default"
                        }
                        sx={{
                          textTransform: "capitalize",
                          mr: 1,
                        }}
                      />
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteUser(user)}
                        disabled={
                          !organization ||
                          user.email === organization?.ownerEmail ||
                          user.email === auth.currentUser?.email ||
                          (organization?.role !== "admin" &&
                            user.role === "admin")
                        }
                        sx={{
                          color: "error.main",
                          flexShrink: 0,
                          opacity:
                            !organization ||
                            user.email === organization?.ownerEmail ||
                            user.email === auth.currentUser?.email ||
                            (organization?.role !== "admin" &&
                              user.role === "admin")
                              ? 0.5
                              : 1,
                          "&:hover": {
                            cursor:
                              !organization ||
                              user.email === organization?.ownerEmail ||
                              user.email === auth.currentUser?.email ||
                              (organization?.role !== "admin" &&
                                user.role === "admin")
                                ? "not-allowed"
                                : "pointer",
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </ListItem>
                {index < users.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>

      {/* Debug information */}
      {console.log("Organization object:", organization)}
      {console.log("Auth current user:", auth.currentUser)}
      {/* Show delete card for organization owner */}
      {organization && auth.currentUser && (
        <Card sx={{ mt: 4, bgcolor: "error.lighter" }}>
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <WarningIcon color="error" />
              <Typography variant="h6" color="error" sx={{ fontWeight: 600 }}>
                Delete Organization
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Permanently delete your organization account and all associated
              data. This action cannot be undone.
            </Typography>
            <Button
              variant="contained"
              color="error"
              onClick={() => setDeleteAccountDialogOpen(true)}
            >
              Delete Organization Account
            </Button>
          </CardContent>
        </Card>
      )}

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setNewUser({
            name: "",
            email: "",
            password: "",
            role: "user",
          });
          setError("");
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            background: "linear-gradient(180deg, #f8fafc 0%, #ffffff 30.21%)",
            boxShadow: "0px 12px 26px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <DialogTitle
            sx={{
              fontSize: "1.5rem",
              fontWeight: 600,
              textAlign: "center",
              pb: 1,
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              color: "white",
              borderRadius: "16px 16px 0 0",
            }}
          >
            Add Team Member
          </DialogTitle>
          <DialogContent sx={{ mt: 2, px: { xs: 2, sm: 4 } }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              The team member will receive an email with instructions to set up
              their account.
            </Typography>
            <Box component="form" sx={{ mt: 1 }}>
              <TextField
                autoFocus
                margin="dense"
                label="Full Name"
                fullWidth
                required
                value={newUser.name}
                onChange={(e) =>
                  setNewUser({ ...newUser, name: e.target.value })
                }
                sx={{ mb: 2 }}
              />
              <TextField
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                required
                value={newUser.email}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
                sx={{ mb: 2 }}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ px: 3, py: 3, borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}
          >
            <Button
              onClick={() => {
                setOpenDialog(false);
                setNewUser({
                  name: "",
                  email: "",
                  password: "",
                  role: "user",
                });
              }}
              sx={{
                color: "text.secondary",
                "&:hover": {
                  bgcolor: "rgba(0, 0, 0, 0.05)",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddUser}
              variant="contained"
              disabled={submitting || !newUser.name || !newUser.email}
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                boxShadow: "0px 2px 4px rgba(37, 99, 235, 0.2)",
                px: 4,
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              {submitting ? "Creating Account..." : "Add Team Member"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setUserToDelete(null);
        }}
      >
        <DialogTitle sx={{ color: "error.main" }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this user?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This will:
          </Typography>
          <Box component="ul" sx={{ mt: 1, pl: 2 }}>
            <li>
              <Typography variant="body2" color="text.secondary">
                Remove their access to the dashboard
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                Delete their user account
              </Typography>
            </li>
          </Box>
          {userToDelete && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Deleting: {userToDelete.name}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setUserToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={confirmDelete}
            disabled={submitting}
          >
            {submitting ? "Deleting..." : "Delete User"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteAccountDialogOpen}
        onClose={() => !isDeletingAccount && setDeleteAccountDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            color: "error.main",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <WarningIcon color="error" />
          Delete Organization Account
        </DialogTitle>
        <DialogContent>
          {auth.currentUser?.email !== organization?.ownerEmail ? (
            <Alert severity="error" sx={{ mb: 3 }}>
              Only the organization owner can perform the deletion. Please
              contact them to proceed with account deletion.
            </Alert>
          ) : (
            <Alert severity="error" sx={{ mb: 3 }}>
              This action is permanent and cannot be undone!
            </Alert>
          )}
          <Typography variant="body1" paragraph>
            This will:
          </Typography>
          <Box component="ul" sx={{ mb: 3 }}>
            <li>
              <Typography variant="body2">
                Permanently delete all organization data
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Delete all properties and tenant information
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Remove access for all users
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Delete all uploaded files and documents
              </Typography>
            </li>
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            To confirm, please type your organization name:
            <Box component="span" sx={{ fontWeight: "bold" }}>
              {" "}
              {organization?.name}
            </Box>
          </Typography>
          <TextField
            fullWidth
            value={deleteConfirmText}
            onChange={(e) => setDeleteConfirmText(e.target.value)}
            placeholder="Type organization name to confirm"
            error={
              deleteConfirmText !== "" &&
              deleteConfirmText !== organization?.name
            }
            helperText={
              deleteConfirmText !== "" &&
              deleteConfirmText !== organization?.name
                ? "Organization name doesn't match"
                : ""
            }
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => setDeleteAccountDialogOpen(false)}
            disabled={isDeletingAccount}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteAccount}
            disabled={
              deleteConfirmText !== organization?.name || isDeletingAccount
            }
          >
            {isDeletingAccount ? "Deleting..." : "Permanently Delete Account"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={reportIssueDialogOpen}
        onClose={() => {
          setReportIssueDialogOpen(false);
          setIssueDescription("");
          setError("");
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            background: "linear-gradient(180deg, #f8fafc 0%, #ffffff 30.21%)",
            boxShadow: "0px 12px 26px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.5rem",
            fontWeight: 600,
            textAlign: "center",
            pb: 1,
            background: "linear-gradient(45deg, #2563eb, #7c3aed)",
            color: "white",
            borderRadius: "16px 16px 0 0",
          }}
        >
          Report an Issue
        </DialogTitle>
        <DialogContent sx={{ mt: 2, px: { xs: 2, sm: 4 } }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Please describe the issue you're experiencing. Our team will look
            into it and get back to you if needed.
          </Typography>
          <TextField
            autoFocus
            multiline
            rows={4}
            fullWidth
            label="Issue Description"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            placeholder="Please provide as much detail as possible..."
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions
          sx={{ px: 3, py: 3, borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}
        >
          <Button
            onClick={() => {
              setReportIssueDialogOpen(false);
              setIssueDescription("");
            }}
            sx={{
              color: "text.secondary",
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.05)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleReportIssue}
            variant="contained"
            disabled={isSubmittingIssue || !issueDescription.trim()}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              boxShadow: "0px 2px 4px rgba(37, 99, 235, 0.2)",
              px: 4,
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            {isSubmittingIssue ? "Submitting..." : "Submit Report"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Account;
