import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Stack,
  Chip,
} from "@mui/material";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { auth, db } from "../../../config/firebase";
import ContractorLayout from "../../contractor/ContractorLayout";
import {
  Build as BuildIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
} from "@mui/icons-material";

const statusColors = {
  pending: "#9CA3AF",
  assigned: "#F59E0B",
  in_progress: "#3B82F6",
  completed: "#10B981",
  cancelled: "#EF4444",
};

const ContractorDashboard = () => {
  const [stats, setStats] = useState({
    activeJobs: 0,
    completedJobs: 0,
    pendingJobs: 0,
  });
  const [recentJobs, setRecentJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const activeQuery = query(
        collection(db, "maintenanceRequests"),
        where("contractorId", "==", auth.currentUser.uid),
        where("status", "in", ["assigned", "in_progress"])
      );

      const completedQuery = query(
        collection(db, "maintenanceRequests"),
        where("contractorId", "==", auth.currentUser.uid),
        where("status", "==", "completed")
      );

      const recentQuery = query(
        collection(db, "maintenanceRequests"),
        where("contractorId", "==", auth.currentUser.uid),
        orderBy("createdAt", "desc"),
        limit(5)
      );

      const [activeSnapshot, completedSnapshot, recentSnapshot] =
        await Promise.all([
          getDocs(activeQuery),
          getDocs(completedQuery),
          getDocs(recentQuery),
        ]);

      const activeJobs = activeSnapshot.docs.length;
      const completedJobs = completedSnapshot.docs;

      setStats({
        activeJobs,
        completedJobs: completedJobs.length,
        pendingJobs: activeJobs,
      });

      setRecentJobs(
        recentSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <ContractorLayout>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" sx={{ mb: 4 }}>
          Dashboard
        </Typography>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ bgcolor: "primary.lighter", height: "100%" }}>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography color="primary.main" gutterBottom>
                      Active Jobs
                    </Typography>
                    <Typography variant="h4" color="primary.main">
                      {stats.activeJobs}
                    </Typography>
                  </Box>
                  <BuildIcon color="primary" sx={{ fontSize: 40 }} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ bgcolor: "success.lighter", height: "100%" }}>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography color="success.main" gutterBottom>
                      Completed Jobs
                    </Typography>
                    <Typography variant="h4" color="success.main">
                      {stats.completedJobs}
                    </Typography>
                  </Box>
                  <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ bgcolor: "warning.lighter", height: "100%" }}>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography color="warning.main" gutterBottom>
                      Pending Jobs
                    </Typography>
                    <Typography variant="h4" color="warning.main">
                      {stats.pendingJobs}
                    </Typography>
                  </Box>
                  <ScheduleIcon color="warning" sx={{ fontSize: 40 }} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Recent Jobs
        </Typography>

        <Stack spacing={2}>
          {recentJobs.map((job) => (
            <Card key={job.id}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box>
                    <Typography variant="h6">{job.title}</Typography>
                    <Typography color="text.secondary" gutterBottom>
                      {job.propertyName} {job.unit && `- Unit ${job.unit}`}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label={job.status.toUpperCase()}
                        size="small"
                        sx={{
                          bgcolor: `${statusColors[job.status]}15`,
                          color: statusColors[job.status],
                        }}
                      />
                      <Chip
                        label={job.priority}
                        size="small"
                        variant="outlined"
                      />
                    </Stack>
                  </Box>
                  <Typography variant="subtitle1" color="primary">
                    {job.payment ? `$${job.payment}` : "Price TBD"}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
          {recentJobs.length === 0 && (
            <Typography color="text.secondary" align="center">
              No recent jobs
            </Typography>
          )}
        </Stack>
      </Box>
    </ContractorLayout>
  );
};

export default ContractorDashboard;
