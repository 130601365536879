import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Chip,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  Tabs,
  Tab,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import {
  Person as PersonIcon,
  Business as BusinessIcon,
  Assignment as AssignmentIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenInNewIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import FileUpload from "../common/FileUpload";

const TenantDetails = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const db = getFirestore();

  const [tenant, setTenant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (organization?.id && tenantId) {
      fetchTenantDetails();
      fetchPayments();
    }
  }, [organization, tenantId]);

  const fetchTenantDetails = async () => {
    try {
      const tenantDoc = await getDoc(doc(db, "tenants", tenantId));
      if (tenantDoc.exists()) {
        setTenant({ id: tenantDoc.id, ...tenantDoc.data() });
      } else {
        setError("Tenant not found");
      }
    } catch (error) {
      console.error("Error fetching tenant details:", error);
      setError("Failed to load tenant details");
    } finally {
      setLoading(false);
    }
  };

  const fetchPayments = async () => {
    try {
      // Query payments from rentPayments collection - include all payments regardless of tenant status
      const paymentsQuery = query(
        collection(db, "rentPayments"),
        where("tenantId", "==", tenantId),
        orderBy("dueDate", "desc")
      );
      const paymentsSnapshot = await getDocs(paymentsQuery);

      // Transform payment documents into consistent format
      const rentPayments = paymentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        dueDate: doc.data().dueDate,
        amount: doc.data().amount,
        status: doc.data().status,
        type: doc.data().paymentType || "rent",
        paidAt: doc.data().paidAt,
        ...doc.data(),
      }));

      setPayments(rentPayments);
    } catch (error) {
      console.error("Error fetching payments:", error);
      setError("Failed to load payment history");
    }
  };

  const handleUploadComplete = async (uploadedFiles) => {
    try {
      if (!tenant || !tenantId) {
        setError("Tenant information not found");
        return;
      }

      if (
        !uploadedFiles ||
        !Array.isArray(uploadedFiles) ||
        uploadedFiles.length === 0
      ) {
        setError("No valid files were uploaded");
        return;
      }

      const tenantRef = doc(db, "tenants", tenantId);
      const tenantDoc = await getDoc(tenantRef);

      if (!tenantDoc.exists()) {
        setError("Tenant document not found");
        return;
      }

      const currentData = tenantDoc.data();
      const documents = currentData.documents || [];

      const newDocuments = uploadedFiles.map((file) => ({
        ...file,
        type: documentType,
        description: documentDescription,
        uploadedAt: new Date().toISOString(),
      }));

      await updateDoc(tenantRef, {
        documents: [...documents, ...newDocuments],
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "document_added",
        message: `Documents uploaded for tenant ${tenant.firstName} ${tenant.lastName}`,
        entityId: tenantId,
        entityType: "tenant",
        organizationId: organization.id,
        timestamp: new Date(),
        status: "completed",
      });

      setSuccessMessage("Documents uploaded successfully");
      setUploadDialogOpen(false);
      fetchTenantDetails(); // Refresh tenant details
    } catch (error) {
      console.error("Error updating tenant documents:", error);
      setError("Failed to update tenant documents");
    }
  };

  const handleDeleteDocument = async (documentToDelete) => {
    try {
      const tenantRef = doc(db, "tenants", tenantId);
      const updatedDocuments = tenant.documents.filter(
        (doc) => doc.url !== documentToDelete.url
      );

      await updateDoc(tenantRef, {
        documents: updatedDocuments,
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "document_deleted",
        message: `Document deleted for tenant ${tenant.firstName} ${tenant.lastName}`,
        entityId: tenantId,
        entityType: "tenant",
        organizationId: organization.id,
        timestamp: new Date(),
        status: "completed",
      });

      setSuccessMessage("Document deleted successfully");
      fetchTenantDetails(); // Refresh tenant details
    } catch (error) {
      console.error("Error deleting document:", error);
      setError("Failed to delete document");
    }
  };

  const handleApproveApplication = async () => {
    try {
      const tenantRef = doc(db, "tenants", tenantId);
      await updateDoc(tenantRef, {
        status: "active",
        approvedAt: new Date().toISOString(),
        approvedBy: organization.id,
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "application_approved",
        message: `Application approved for tenant ${tenant.firstName} ${tenant.lastName}`,
        entityId: tenantId,
        entityType: "tenant",
        organizationId: organization.id,
        timestamp: new Date(),
        status: "completed",
      });

      setSuccessMessage("Application approved successfully");
      fetchTenantDetails();
    } catch (error) {
      console.error("Error approving application:", error);
      setError("Failed to approve application");
    }
  };

  const handleDeclineApplication = async () => {
    try {
      const tenantRef = doc(db, "tenants", tenantId);
      await updateDoc(tenantRef, {
        status: "rejected",
        rejectedAt: new Date().toISOString(),
        rejectedBy: organization.id,
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "application_rejected",
        message: `Application rejected for tenant ${tenant.firstName} ${tenant.lastName}`,
        entityId: tenantId,
        entityType: "tenant",
        organizationId: organization.id,
        timestamp: new Date(),
        status: "completed",
      });

      setSuccessMessage("Application declined successfully");
      fetchTenantDetails();
    } catch (error) {
      console.error("Error declining application:", error);
      setError("Failed to decline application");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "paid":
        return "success";
      case "payment_due":
      case "awaiting_payment":
        return "warning";
      case "overdue":
        return "error";
      default:
        return "default";
    }
  };

  const getPaymentStatus = (status) => {
    switch (status) {
      case "paid":
        return "Paid";
      case "payment_due":
        return "Payment Due";
      case "awaiting_payment":
        return "Awaiting Payment";
      case "overdue":
        return "Overdue";
      default:
        return status;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!tenant) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        Tenant not found
      </Alert>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {successMessage && (
        <Alert
          severity="success"
          sx={{ mb: 3 }}
          onClose={() => setSuccessMessage("")}
        >
          {successMessage}
        </Alert>
      )}

      {/* Breadcrumb Navigation */}
      <Box sx={{ mb: 3 }}>
        <Button
          onClick={() => navigate("/dashboard/tenants")}
          startIcon={<ArrowBackIcon />}
          sx={{
            color: "text.secondary",
            "&:hover": {
              bgcolor: "transparent",
              color: "primary.main",
            },
          }}
        >
          Return to Tenants
        </Button>
      </Box>

      {/* Header */}
      <Box
        sx={{
          mb: 4,
          p: 3,
          borderRadius: 2,
          bgcolor: "background.paper",
          border: "1px solid",
          borderColor: "divider",
          boxShadow:
            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Avatar
            sx={{
              bgcolor: "primary.lighter",
              color: "primary.main",
              width: 64,
              height: 64,
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {tenant.firstName?.[0]}
          </Avatar>
          <Box>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", mb: 0.5, color: "text.primary" }}
            >
              {tenant.firstName} {tenant.lastName}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Chip
                label={
                  tenant.status === "active" ? "Active" : "Application Pending"
                }
                color={tenant.status === "active" ? "success" : "warning"}
              />
              {tenant.propertyName && (
                <Chip
                  icon={<BusinessIcon />}
                  label={`${tenant.propertyName} - Unit ${tenant.unit}`}
                  size="small"
                  sx={{
                    bgcolor: "background.default",
                    "& .MuiChip-icon": {
                      color: "primary.main",
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {tenant.status === "pending_approval" && (
        <Box
          sx={{
            p: 3,
            mb: 4,
            bgcolor: "rgba(234, 179, 8, 0.1)",
            borderRadius: 2,
            border: "1px solid",
            borderColor: "rgba(234, 179, 8, 0.2)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ color: "#854d0e", mb: 1 }}>
                Application Review Required
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Please review the application details and make a decision.
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
              onClick={() => navigate(`/dashboard/tenants/review/${tenant.id}`)}
            >
              Review Application
            </Button>
          </Box>
        </Box>
      )}

      {/* Tabs */}
      <Box sx={{ mb: 4 }}>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
            "& .MuiTabs-indicator": {
              backgroundColor: "#2563eb",
              height: 3,
            },
            "& .MuiTab-root": {
              fontWeight: 600,
              fontSize: "0.95rem",
              textTransform: "none",
              minWidth: 120,
              "&.Mui-selected": {
                color: "#2563eb",
              },
            },
          }}
        >
          <Tab label="Overview" />
          <Tab label="Application" />
          <Tab label="Documents" />
          <Tab label="Payment History" />
        </Tabs>
      </Box>

      {/* Tab Content */}
      {selectedTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 3,
                bgcolor: "rgba(37, 99, 235, 0.05)",
                borderRadius: 2,
                border: "1px solid",
                borderColor: "rgba(37, 99, 235, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#2563eb",
                  fontWeight: "bold",
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <PersonIcon />
                Contact Information
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Email Address
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {tenant.email}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Phone Number
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {tenant.phone || "Not provided"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: 3,
                bgcolor: "rgba(124, 58, 237, 0.05)",
                borderRadius: 2,
                border: "1px solid",
                borderColor: "rgba(124, 58, 237, 0.1)",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#7c3aed",
                  fontWeight: "bold",
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <BusinessIcon />
                Property Details
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Property
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {tenant.propertyName || "Not assigned"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Unit Number
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {tenant.unit || "Not assigned"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {selectedTab === 1 && (
        <Grid container spacing={4}>
          {!tenant.personalInfo &&
          !tenant.employmentHistory &&
          !tenant.addressHistory ? (
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 4,
                  textAlign: "center",
                  bgcolor: "rgba(37, 99, 235, 0.02)",
                  borderRadius: 2,
                  border: "1px solid",
                  borderColor: "rgba(37, 99, 235, 0.1)",
                }}
              >
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  No Application Data Available
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {tenant.status === "active"
                    ? "This tenant was added manually and has not completed an application."
                    : "The tenant has not submitted their application yet."}
                </Typography>
              </Box>
            </Grid>
          ) : (
            <>
              {/* Personal Information */}
              {tenant.personalInfo && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      bgcolor: "rgba(37, 99, 235, 0.02)",
                      borderRadius: 2,
                      border: "1px solid",
                      borderColor: "rgba(37, 99, 235, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2563eb",
                        fontWeight: "bold",
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      Personal Information
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          gutterBottom
                        >
                          Date of Birth
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {tenant.personalInfo?.dateOfBirth || "Not provided"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          gutterBottom
                        >
                          National Insurance
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {tenant.personalInfo?.nationalInsurance ||
                            "Not provided"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          gutterBottom
                        >
                          Current Income
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {tenant.personalInfo?.currentIncome
                            ? `£${tenant.personalInfo.currentIncome}`
                            : "Not provided"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}

              {/* Employment History */}
              {tenant.employmentHistory &&
                tenant.employmentHistory.length > 0 && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        p: 3,
                        bgcolor: "rgba(37, 99, 235, 0.02)",
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "rgba(37, 99, 235, 0.1)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2563eb",
                          fontWeight: "bold",
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        Employment History
                      </Typography>
                      {tenant.employmentHistory.map((employment, index) => (
                        <Box
                          key={index}
                          sx={{
                            mb: 3,
                            p: 2,
                            bgcolor: "white",
                            borderRadius: 1,
                            border: "1px solid",
                            borderColor: "rgba(37, 99, 235, 0.1)",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600, mb: 1 }}
                          >
                            {employment.employer}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                gutterBottom
                              >
                                Position
                              </Typography>
                              <Typography variant="body2">
                                {employment.position}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                gutterBottom
                              >
                                Duration
                              </Typography>
                              <Typography variant="body2">
                                {employment.startDate} -{" "}
                                {employment.endDate || "Present"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                gutterBottom
                              >
                                Contact Person
                              </Typography>
                              <Typography variant="body2">
                                {employment.contactPerson}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                gutterBottom
                              >
                                Contact Phone
                              </Typography>
                              <Typography variant="body2">
                                {employment.contactPhone}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}

              {/* Address History */}
              {tenant.addressHistory && tenant.addressHistory.length > 0 && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      bgcolor: "rgba(37, 99, 235, 0.02)",
                      borderRadius: 2,
                      border: "1px solid",
                      borderColor: "rgba(37, 99, 235, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2563eb",
                        fontWeight: "bold",
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      Address History
                    </Typography>
                    {tenant.addressHistory.map((address, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: 3,
                          p: 2,
                          bgcolor: "white",
                          borderRadius: 1,
                          border: "1px solid",
                          borderColor: "rgba(37, 99, 235, 0.1)",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600, mb: 1 }}
                        >
                          {address.address}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              gutterBottom
                            >
                              City
                            </Typography>
                            <Typography variant="body2">
                              {address.city}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              gutterBottom
                            >
                              Postcode
                            </Typography>
                            <Typography variant="body2">
                              {address.postcode}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              gutterBottom
                            >
                              Duration
                            </Typography>
                            <Typography variant="body2">
                              {address.startDate} -{" "}
                              {address.endDate || "Present"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              gutterBottom
                            >
                              Landlord
                            </Typography>
                            <Typography variant="body2">
                              {address.landlordName} ({address.landlordPhone})
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}

      {selectedTab === 2 && (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#2563eb",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <AssignmentIcon />
              Documents
            </Typography>
            <Button
              variant="contained"
              onClick={() => setUploadDialogOpen(true)}
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              Upload Documents
            </Button>
          </Box>

          <Grid container spacing={2}>
            {tenant.documents?.map((doc, index) => (
              <Grid item xs={12} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 2,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <AssignmentIcon sx={{ color: "#2563eb" }} />
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {doc.name}
                      </Typography>
                      {doc.description && (
                        <Typography variant="body2" color="text.secondary">
                          {doc.description}
                        </Typography>
                      )}
                      <Typography variant="caption" color="text.secondary">
                        {new Date(doc.uploadedAt).toLocaleDateString()} -{" "}
                        {doc.type}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                      size="small"
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: "#2563eb" }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteDocument(doc)}
                      sx={{ color: "error.main" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))}
            {(!tenant.documents || tenant.documents.length === 0) && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 4,
                    textAlign: "center",
                    bgcolor: "rgba(37, 99, 235, 0.02)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "rgba(37, 99, 235, 0.1)",
                  }}
                >
                  <Typography variant="body1" color="text.secondary">
                    No documents uploaded yet
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {selectedTab === 3 && (
        <Box>
          {payments.length === 0 ? (
            <Box
              sx={{
                p: 4,
                textAlign: "center",
                bgcolor: "rgba(37, 99, 235, 0.02)",
                borderRadius: 2,
                border: "1px solid",
                borderColor: "rgba(37, 99, 235, 0.1)",
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom>
                No Payment Schedule Available
              </Typography>
              <Typography variant="body1" color="text.secondary">
                A payment schedule will be generated when this tenant is
                assigned to a property.
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={3}>
              {/* Payment Overview */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 3,
                    bgcolor: "rgba(37, 99, 235, 0.02)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "rgba(37, 99, 235, 0.1)",
                    mb: 3,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#2563eb",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    Payment Overview
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Monthly Rent
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: "success.main", fontWeight: 600 }}
                      >
                        £{tenant?.rentAmount?.toLocaleString() || "0"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Property
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {payments[0]?.propertyName || "Not set"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* Payment History */}
              <Grid item xs={12}>
                <Card sx={{ mb: 3 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Payment History
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Due Date</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Payment Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {payments.map((payment, index) => (
                            <TableRow key={payment.id || index}>
                              <TableCell>
                                {new Date(payment.dueDate).toLocaleDateString()}
                              </TableCell>
                              <TableCell>
                                {payment.type === "deposit"
                                  ? "Security Deposit"
                                  : "Rent Payment"}
                              </TableCell>
                              <TableCell align="right">
                                £{payment.amount.toLocaleString()}
                              </TableCell>
                              <TableCell align="center">
                                <Chip
                                  label={getPaymentStatus(payment.status)}
                                  color={getStatusColor(payment.status)}
                                  size="small"
                                  sx={{
                                    minWidth: 100,
                                    fontWeight: 500,
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {payment.paidAt
                                  ? new Date(
                                      payment.paidAt
                                    ).toLocaleDateString()
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          ))}
                          {payments.length === 0 && (
                            <TableRow>
                              <TableCell colSpan={5} align="center">
                                No payment history available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Box>
      )}

      {/* Document Upload Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Documents</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Document Type</InputLabel>
              <Select
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                label="Document Type"
              >
                <MenuItem value="identification">Identification</MenuItem>
                <MenuItem value="proof_of_income">Proof of Income</MenuItem>
                <MenuItem value="references">References</MenuItem>
                <MenuItem value="contract">Contract</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Description"
              value={documentDescription}
              onChange={(e) => setDocumentDescription(e.target.value)}
              sx={{ mb: 3 }}
            />

            <FileUpload
              onUploadComplete={handleUploadComplete}
              folder={`tenants/${tenant?.id}/documents`}
              entityId={tenant?.id}
              multiple={true}
              acceptedFiles=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
              additionalData={{
                tenantId: tenant?.id,
                organizationId: organization.id,
                type: "tenant_document",
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TenantDetails;
