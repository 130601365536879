import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Alert,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import FileUpload from "../common/FileUpload";

const ManualTenantApplication = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const db = getFirestore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [formData, setFormData] = useState({
    personalInfo: {
      dateOfBirth: "",
      nationalInsurance: "",
      currentIncome: "",
    },
    employmentHistory: [
      {
        employer: "",
        position: "",
        startDate: "",
        endDate: "",
        contactPerson: "",
        contactPhone: "",
      },
    ],
    addressHistory: [
      {
        address: "",
        city: "",
        postcode: "",
        startDate: "",
        endDate: "",
        landlordName: "",
        landlordPhone: "",
      },
    ],
    documents: [],
  });

  useEffect(() => {
    const fetchTenant = async () => {
      try {
        const tenantDoc = await getDoc(doc(db, "tenants", tenantId));
        if (!tenantDoc.exists()) {
          setError("Tenant not found");
          return;
        }
        setTenant(tenantDoc.data());
        // If tenant already has application data, populate it
        if (tenantDoc.data().personalInfo) {
          setFormData({
            personalInfo:
              tenantDoc.data().personalInfo || formData.personalInfo,
            employmentHistory:
              tenantDoc.data().employmentHistory || formData.employmentHistory,
            addressHistory:
              tenantDoc.data().addressHistory || formData.addressHistory,
            documents: tenantDoc.data().documents || formData.documents,
          });
        }
      } catch (error) {
        console.error("Error fetching tenant:", error);
        setError("Error loading tenant data");
      } finally {
        setLoading(false);
      }
    };

    if (tenantId) {
      fetchTenant();
    }
  }, [tenantId, db]);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const tenantRef = doc(db, "tenants", tenantId);

      // First get the current tenant data
      const tenantDoc = await getDoc(tenantRef);
      if (!tenantDoc.exists()) {
        throw new Error("Tenant not found");
      }

      // Update the tenant document
      await updateDoc(tenantRef, {
        personalInfo: formData.personalInfo,
        employmentHistory: formData.employmentHistory,
        addressHistory: formData.addressHistory,
        documents: formData.documents,
        onboardingComplete: true,
        status: "pending_approval",
        updatedAt: new Date().toISOString(),
        applicationFilledBy: "admin",
        applicationSubmittedAt: new Date().toISOString(),
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "application_submitted",
        message: `Application filled by admin for tenant ${
          tenantDoc.data().firstName
        } ${tenantDoc.data().lastName}`,
        entityId: tenantId,
        entityType: "tenant",
        organizationId: tenantDoc.data().organizationId,
        timestamp: new Date(),
        status: "completed",
      });

      navigate("/dashboard/tenants?tab=applications");
    } catch (error) {
      console.error("Error submitting application:", error);
      setError("Failed to submit application");
    } finally {
      setSubmitting(false);
    }
  };

  const addEmploymentHistory = () => {
    setFormData({
      ...formData,
      employmentHistory: [
        ...formData.employmentHistory,
        {
          employer: "",
          position: "",
          startDate: "",
          endDate: "",
          contactPerson: "",
          contactPhone: "",
        },
      ],
    });
  };

  const addAddressHistory = () => {
    setFormData({
      ...formData,
      addressHistory: [
        ...formData.addressHistory,
        {
          address: "",
          city: "",
          postcode: "",
          startDate: "",
          endDate: "",
          landlordName: "",
          landlordPhone: "",
        },
      ],
    });
  };

  const handleEmploymentChange = (index, field, value) => {
    const newHistory = [...formData.employmentHistory];
    newHistory[index] = { ...newHistory[index], [field]: value };
    setFormData({ ...formData, employmentHistory: newHistory });
  };

  const handleAddressChange = (index, field, value) => {
    const newHistory = [...formData.addressHistory];
    newHistory[index] = { ...newHistory[index], [field]: value };
    setFormData({ ...formData, addressHistory: newHistory });
  };

  const removeEmploymentHistory = (index) => {
    const newHistory = formData.employmentHistory.filter((_, i) => i !== index);
    setFormData({ ...formData, employmentHistory: newHistory });
  };

  const removeAddressHistory = (index) => {
    const newHistory = formData.addressHistory.filter((_, i) => i !== index);
    setFormData({ ...formData, addressHistory: newHistory });
  };

  const handleFileUpload = (uploadedFiles) => {
    setFormData({
      ...formData,
      documents: [
        ...formData.documents,
        ...uploadedFiles.map((file) => ({
          name: file.name,
          url: file.url,
          type: file.type,
          size: file.size,
          uploadedAt: file.uploadedAt,
        })),
      ],
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: "auto" }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Manual Application Form - {tenant?.firstName} {tenant?.lastName}
        </Typography>

        {/* Personal Information */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Personal Information
        </Typography>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Date of Birth"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.personalInfo.dateOfBirth}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  personalInfo: {
                    ...formData.personalInfo,
                    dateOfBirth: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="National Insurance Number"
              value={formData.personalInfo.nationalInsurance}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  personalInfo: {
                    ...formData.personalInfo,
                    nationalInsurance: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Current Income"
              type="number"
              value={formData.personalInfo.currentIncome}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  personalInfo: {
                    ...formData.personalInfo,
                    currentIncome: e.target.value,
                  },
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* Employment History */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Employment History
        </Typography>
        {formData.employmentHistory.map((job, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">
                  Employment {index + 1}
                </Typography>
                {index > 0 && (
                  <IconButton
                    onClick={() => removeEmploymentHistory(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Employer"
                value={job.employer}
                onChange={(e) =>
                  handleEmploymentChange(index, "employer", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Position"
                value={job.position}
                onChange={(e) =>
                  handleEmploymentChange(index, "position", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={job.startDate}
                onChange={(e) =>
                  handleEmploymentChange(index, "startDate", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={job.endDate}
                onChange={(e) =>
                  handleEmploymentChange(index, "endDate", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact Person"
                value={job.contactPerson}
                onChange={(e) =>
                  handleEmploymentChange(index, "contactPerson", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact Phone"
                value={job.contactPhone}
                onChange={(e) =>
                  handleEmploymentChange(index, "contactPhone", e.target.value)
                }
              />
            </Grid>
          </Grid>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={addEmploymentHistory}
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
        >
          Add Employment History
        </Button>

        {/* Address History */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Address History
        </Typography>
        {formData.addressHistory.map((address, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">Address {index + 1}</Typography>
                {index > 0 && (
                  <IconButton
                    onClick={() => removeAddressHistory(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                value={address.address}
                onChange={(e) =>
                  handleAddressChange(index, "address", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                value={address.city}
                onChange={(e) =>
                  handleAddressChange(index, "city", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Postcode"
                value={address.postcode}
                onChange={(e) =>
                  handleAddressChange(index, "postcode", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={address.startDate}
                onChange={(e) =>
                  handleAddressChange(index, "startDate", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={address.endDate}
                onChange={(e) =>
                  handleAddressChange(index, "endDate", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Landlord Name"
                value={address.landlordName}
                onChange={(e) =>
                  handleAddressChange(index, "landlordName", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Landlord Phone"
                value={address.landlordPhone}
                onChange={(e) =>
                  handleAddressChange(index, "landlordPhone", e.target.value)
                }
              />
            </Grid>
          </Grid>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={addAddressHistory}
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
        >
          Add Address History
        </Button>

        {/* Documents Upload */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Documents
        </Typography>
        <FileUpload
          onUpload={handleFileUpload}
          accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
          multiple
          folder="tenant-documents"
          entityId={tenantId}
        />

        {formData.documents.length > 0 && (
          <Box sx={{ mt: 2, mb: 4 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Uploaded Documents:
            </Typography>
            <ul>
              {formData.documents.map((doc, index) => (
                <li key={index}>
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">
                    {doc.name}
                  </a>
                </li>
              ))}
            </ul>
          </Box>
        )}

        {/* Submit Button */}
        <Box sx={{ display: "flex", gap: 2, mt: 4 }}>
          <Button onClick={() => navigate("/dashboard/tenants")}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? "Submitting..." : "Submit Application"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ManualTenantApplication;
