import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  Timestamp,
} from "firebase/firestore";

export const rateLimit = async (email) => {
  const db = getFirestore();

  // Temporarily return true to bypass rate limiting during testing
  return true;

  // Rest of the code commented out for now
  /*
  const now = Timestamp.now();
  const ipAddress = await fetch("https://api.ipify.org?format=json")
    .then((res) => res.json())
    .then((data) => data.ip);
  ...
  */
};
