import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
  LinearProgress,
  Stack,
} from "@mui/material";
import {
  AttachMoney as MoneyIcon,
  CalendarToday as CalendarIcon,
  ErrorOutline as ErrorIcon,
  Build as BuildIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import FirstTimeLogin from "../FirstTimeLogin";
import { useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [rentPayments, setRentPayments] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);

  const { organization, isLoading: orgLoading } = useOrganization();
  const db = getFirestore();
  const navigate = useNavigate();

  // Add time filter state
  const [timeFilter, setTimeFilter] = useState("current_month");
  const [dateRange, setDateRange] = useState({
    start: dayjs().startOf("month"),
    end: dayjs().endOf("month"),
  });

  const [chartView, setChartView] = useState("all");

  useEffect(() => {
    if (organization?.id) {
      fetchData();
    }
  }, [organization, dateRange]); // Add dateRange as dependency

  const fetchData = async () => {
    try {
      setLoading(true);
      const db = getFirestore();

      // Fetch rent payments
      const paymentsQuery = query(
        collection(db, "rentPayments"),
        where("organizationId", "==", organization.id)
      );
      const paymentsSnapshot = await getDocs(paymentsQuery);
      const paymentsList = paymentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        dueDate: doc.data().dueDate ? dayjs(doc.data().dueDate) : null,
        paymentDate: doc.data().paymentDate
          ? dayjs(doc.data().paymentDate)
          : null,
      }));
      setRentPayments(paymentsList);

      // Fetch expenses
      const expensesQuery = query(
        collection(db, "expenses"),
        where("organizationId", "==", organization.id)
      );
      const expensesSnapshot = await getDocs(expensesQuery);
      const expensesList = expensesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate
          ? dayjs(doc.data().date.toDate())
          : dayjs(),
      }));
      setExpenses(expensesList);

      // Fetch maintenance requests
      const maintenanceQuery = query(
        collection(db, "maintenanceRequests"),
        where("organizationId", "==", organization.id),
        where("status", "in", ["pending", "accepted"])
      );
      const maintenanceSnapshot = await getDocs(maintenanceQuery);
      const maintenanceList = maintenanceSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMaintenanceRequests(maintenanceList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle time filter changes
  const handleTimeFilterChange = (filter) => {
    setTimeFilter(filter);
    switch (filter) {
      case "current_month":
        setDateRange({
          start: dayjs().startOf("month"),
          end: dayjs().endOf("month"),
        });
        break;
      case "current_year":
        setDateRange({
          start: dayjs().startOf("year"),
          end: dayjs().endOf("year"),
        });
        break;
      // Custom range will be handled by the date picker directly
    }
  };

  const getFilteredPayments = () => {
    if (!rentPayments.length) return [];

    return rentPayments.filter((payment) => {
      const dueDate = dayjs(payment.dueDate);
      return (
        dueDate.isSameOrAfter(dateRange.start, "day") &&
        dueDate.isSameOrBefore(dateRange.end, "day")
      );
    });
  };

  const getFilteredExpenses = () => {
    if (!expenses.length) return [];

    return expenses.filter((expense) => {
      const expenseDate = dayjs(expense.date);
      return (
        expenseDate.isSameOrAfter(dateRange.start, "day") &&
        expenseDate.isSameOrBefore(dateRange.end, "day")
      );
    });
  };

  const getRentSummary = () => {
    const filteredPayments = getFilteredPayments();
    return {
      totalDue: filteredPayments.reduce(
        (sum, payment) =>
          payment.paymentType !== "deposit"
            ? sum + Number(payment.amount)
            : sum,
        0
      ),
      totalCollected: filteredPayments.reduce(
        (sum, payment) =>
          payment.status === "paid" && payment.paymentType !== "deposit"
            ? sum + Number(payment.amount)
            : sum,
        0
      ),
      totalOverdue: filteredPayments.reduce(
        (sum, payment) =>
          payment.status === "overdue" && payment.paymentType !== "deposit"
            ? sum + Number(payment.amount)
            : sum,
        0
      ),
    };
  };

  const getExpensesSummary = () => {
    const filteredExpenses = getFilteredExpenses();
    return {
      total: filteredExpenses.reduce(
        (sum, expense) => sum + Number(expense.amount),
        0
      ),
    };
  };

  const getMaintenanceSummary = () => {
    return {
      pending: maintenanceRequests.filter(
        (request) => request.status === "pending"
      ).length,
      inProgress: maintenanceRequests.filter(
        (request) => request.status === "accepted"
      ).length,
    };
  };

  // Update cashflow data to use real data
  const getCashflowData = () => {
    const monthsInRange = [];
    let current = dateRange.start.startOf("month");
    while (
      current.isBefore(dateRange.end) ||
      current.isSame(dateRange.end, "month")
    ) {
      monthsInRange.push(current.format("MMM YYYY"));
      current = current.add(1, "month");
    }

    const incomeByMonth = {};
    const expensesByMonth = {};

    // Initialize months
    monthsInRange.forEach((month) => {
      incomeByMonth[month] = 0;
      expensesByMonth[month] = 0;
    });

    // Calculate income by month
    getFilteredPayments().forEach((payment) => {
      if (payment.status === "paid" && payment.paymentType !== "deposit") {
        const month = dayjs(payment.paymentDate).format("MMM YYYY");
        if (incomeByMonth[month] !== undefined) {
          incomeByMonth[month] += Number(payment.amount);
        }
      }
    });

    // Calculate expenses by month
    getFilteredExpenses().forEach((expense) => {
      const month = dayjs(expense.date).format("MMM YYYY");
      if (expensesByMonth[month] !== undefined) {
        expensesByMonth[month] += Number(expense.amount);
      }
    });

    return {
      labels: monthsInRange,
      datasets: [
        ...(chartView !== "expenses"
          ? [
              {
                label: "Income",
                data: monthsInRange.map((month) => incomeByMonth[month]),
                backgroundColor: "rgba(34, 197, 94, 0.5)",
                borderRadius: 8,
                maxBarThickness: 35,
                order: 1,
              },
            ]
          : []),
        ...(chartView !== "income"
          ? [
              {
                label: "Expenses",
                data: monthsInRange.map((month) => expensesByMonth[month]),
                backgroundColor: "rgba(59, 130, 246, 0.5)",
                borderRadius: 8,
                maxBarThickness: 35,
                order: 2,
              },
            ]
          : []),
      ],
    };
  };

  // Chart options configuration
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
        },
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        padding: 12,
        titleFont: {
          size: 14,
          weight: "bold",
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          label: function (context) {
            return `${
              context.dataset.label
            }: £${context.parsed.y.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
          drawBorder: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          callback: function (value) {
            return "£" + value.toLocaleString();
          },
        },
      },
    },
    animation: {
      duration: 750,
      easing: "easeInOutQuart",
    },
    barPercentage: 0.7,
  };

  if (loading || orgLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: "8px 24px", bgcolor: "#f8fafc" }}>
      <FirstTimeLogin />

      {/* Time Filter Controls */}
      <Box sx={{ mt: 0, mb: 2 }}>
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <Button
            variant={timeFilter === "current_month" ? "contained" : "outlined"}
            onClick={() => handleTimeFilterChange("current_month")}
          >
            This Month
          </Button>
          <Button
            variant={timeFilter === "current_year" ? "contained" : "outlined"}
            onClick={() => handleTimeFilterChange("current_year")}
          >
            This Year
          </Button>
          <Button
            variant={timeFilter === "custom_range" ? "contained" : "outlined"}
            onClick={() => handleTimeFilterChange("custom_range")}
          >
            Custom Range
          </Button>
        </Stack>

        {timeFilter === "custom_range" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <DatePicker
                label="From"
                value={dateRange.start}
                onChange={(newValue) =>
                  setDateRange((prev) => ({ ...prev, start: newValue }))
                }
                slotProps={{
                  textField: {
                    size: "small",
                    sx: { width: { xs: "100%", sm: 200 } },
                  },
                }}
              />
              <DatePicker
                label="To"
                value={dateRange.end}
                onChange={(newValue) =>
                  setDateRange((prev) => ({ ...prev, end: newValue }))
                }
                slotProps={{
                  textField: {
                    size: "small",
                    sx: { width: { xs: "100%", sm: 200 } },
                  },
                }}
              />
            </Stack>
          </LocalizationProvider>
        )}
      </Box>

      {/* Rent Status Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              boxShadow: "none",
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <MoneyIcon
                  sx={{ color: "success.main", mr: 1, fontSize: "1.2rem" }}
                />
                <Typography variant="body2" color="text.secondary">
                  Rent received
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ mb: 2 }}>
                £{getRentSummary().totalCollected.toFixed(2)}
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  "&:hover .MuiBox-root": {
                    opacity: 1,
                    visibility: "visible",
                  },
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={
                    (getRentSummary().totalCollected /
                      (getRentSummary().totalDue || 1)) *
                    100
                  }
                  sx={{
                    height: 12,
                    borderRadius: 6,
                    bgcolor: "rgba(34, 197, 94, 0.2)",
                    mb: 1,
                    cursor: "pointer",
                    "& .MuiLinearProgress-bar": {
                      bgcolor: "success.main",
                      borderRadius: 6,
                    },
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bgcolor: "rgba(0, 0, 0, 0.8)",
                    color: "white",
                    p: 1,
                    borderRadius: 1,
                    fontSize: "0.875rem",
                    opacity: 0,
                    visibility: "hidden",
                    transition: "all 0.2s ease-in-out",
                    whiteSpace: "nowrap",
                    zIndex: 1,
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: "100%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      border: "6px solid transparent",
                      borderTopColor: "rgba(0, 0, 0, 0.8)",
                    },
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <span>Received:</span>
                      <span style={{ fontWeight: 600 }}>
                        £{getRentSummary().totalCollected.toFixed(2)}
                      </span>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                      }}
                    >
                      <span>Outstanding:</span>
                      <span style={{ fontWeight: 600 }}>
                        £
                        {(
                          getRentSummary().totalDue -
                          getRentSummary().totalCollected
                        ).toFixed(2)}
                      </span>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        borderTop: "1px solid rgba(255,255,255,0.2)",
                        pt: 0.5,
                      }}
                    >
                      <span>Total:</span>
                      <span style={{ fontWeight: 600 }}>
                        £{getRentSummary().totalDue.toFixed(2)}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {(
                  (getRentSummary().totalCollected /
                    (getRentSummary().totalDue || 1)) *
                  100
                ).toFixed(0)}
                % collected
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              boxShadow: "none",
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CalendarIcon
                  sx={{ color: "info.main", mr: 1, fontSize: "1.2rem" }}
                />
                <Typography variant="body2" color="text.secondary">
                  Upcoming payments
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ mb: 1 }}>
                £
                {(
                  getRentSummary().totalDue -
                  getRentSummary().totalCollected -
                  getRentSummary().totalOverdue
                ).toFixed(2)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {
                  getFilteredPayments().filter(
                    (p) =>
                      p.status === "payment_due" && p.paymentType === "rent"
                  ).length
                }{" "}
                this month
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card
            sx={{
              boxShadow: "none",
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <ErrorIcon
                  sx={{ color: "error.main", mr: 1, fontSize: "1.2rem" }}
                />
                <Typography variant="body2" color="text.secondary">
                  Rent overdue
                </Typography>
              </Box>
              <Typography variant="h5" sx={{ mb: 1 }}>
                £{getRentSummary().totalOverdue.toFixed(2)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {
                  getFilteredPayments().filter(
                    (p) => p.status === "overdue" && p.paymentType === "rent"
                  ).length
                }{" "}
                overdue
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Cashflow Chart */}
      <Card
        sx={{
          mb: 4,
          boxShadow: "none",
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Cashflow
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant={chartView === "all" ? "contained" : "outlined"}
                size="small"
                onClick={() => setChartView("all")}
                sx={{
                  minWidth: 80,
                  bgcolor: chartView === "all" ? "primary.main" : "transparent",
                  "&:hover": {
                    bgcolor:
                      chartView === "all"
                        ? "primary.dark"
                        : "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                All
              </Button>
              <Button
                variant={chartView === "income" ? "contained" : "outlined"}
                size="small"
                onClick={() => setChartView("income")}
                sx={{
                  minWidth: 100,
                  bgcolor:
                    chartView === "income" ? "primary.main" : "transparent",
                  "&:hover": {
                    bgcolor:
                      chartView === "income"
                        ? "primary.dark"
                        : "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                Income
              </Button>
              <Button
                variant={chartView === "expenses" ? "contained" : "outlined"}
                size="small"
                onClick={() => setChartView("expenses")}
                sx={{
                  minWidth: 100,
                  bgcolor:
                    chartView === "expenses" ? "error.main" : "transparent",
                  "&:hover": {
                    bgcolor:
                      chartView === "expenses"
                        ? "error.dark"
                        : "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                Expenses
              </Button>
            </Box>
          </Box>
          <Box sx={{ height: 400, p: 1 }}>
            <Bar data={getCashflowData()} options={chartOptions} />
          </Box>
        </CardContent>
      </Card>

      {/* Maintenance Status Boxes */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 3,
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 1,
              bgcolor: "background.paper",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <BuildIcon sx={{ color: "warning.main", mr: 1 }} />
              <Typography variant="h6">
                Pending Maintenance ({getMaintenanceSummary().pending})
              </Typography>
            </Box>
            {maintenanceRequests
              .filter((request) => request.status === "pending")
              .map((request) => (
                <Box
                  key={request.id}
                  sx={{
                    p: 2,
                    mb: 1,
                    bgcolor: "grey.50",
                    borderRadius: 1,
                    "&:last-child": { mb: 0 },
                  }}
                >
                  <Typography variant="subtitle2">{request.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {request.propertyName}
                    {request.unit && ` - Unit ${request.unit}`}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 3,
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 1,
              bgcolor: "background.paper",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <BuildIcon sx={{ color: "info.main", mr: 1 }} />
              <Typography variant="h6">
                Maintenance in Progress ({getMaintenanceSummary().inProgress})
              </Typography>
            </Box>
            {maintenanceRequests
              .filter((request) => request.status === "accepted")
              .map((request) => (
                <Box
                  key={request.id}
                  sx={{
                    p: 2,
                    mb: 1,
                    bgcolor: "grey.50",
                    borderRadius: 1,
                    "&:last-child": { mb: 0 },
                  }}
                >
                  <Typography variant="subtitle2">{request.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {request.propertyName}
                    {request.unit && ` - Unit ${request.unit}`}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
