import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Stack,
  Paper,
  Tooltip,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import {
  Receipt as ReceiptIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
  deleteDoc,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import FileUpload from "../common/FileUpload";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const expenseCategories = [
  { id: "maintenance", label: "Maintenance & Repairs" },
  { id: "utilities", label: "Utilities" },
  { id: "insurance", label: "Insurance" },
  { id: "taxes", label: "Property Taxes" },
  { id: "mortgage", label: "Mortgage" },
  { id: "management", label: "Property Management" },
  { id: "legal", label: "Legal & Professional Fees" },
  { id: "marketing", label: "Marketing & Advertising" },
  { id: "supplies", label: "Supplies & Materials" },
  { id: "other", label: "Other" },
];

const expenseTypes = [
  { id: "general", label: "General" },
  { id: "property", label: "Property" },
  { id: "business", label: "Business" },
  { id: "other", label: "Other" },
];

const Expenses = () => {
  const { organization } = useOrganization();
  const [expenses, setExpenses] = useState([]);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [timeFilter, setTimeFilter] = useState("current_month");
  const [dateRange, setDateRange] = useState({
    start: dayjs().startOf("month"),
    end: dayjs().endOf("month"),
  });

  const [newExpense, setNewExpense] = useState({
    expenseType: "general",
    propertyId: "",
    category: "",
    amount: "",
    date: dayjs(),
    description: "",
    receipt: null,
    notes: "",
    status: "pending",
  });

  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [downloadDateRange, setDownloadDateRange] = useState({
    start: dayjs().startOf("month"),
    end: dayjs().endOf("month"),
  });

  useEffect(() => {
    if (organization?.id) {
      fetchData();
    }
  }, [organization]);

  const fetchData = async () => {
    try {
      const db = getFirestore();

      // Fetch properties
      const propertiesQuery = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const propertiesSnapshot = await getDocs(propertiesQuery);
      const propertiesList = propertiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProperties(propertiesList);

      // Fetch expenses
      const expensesQuery = query(
        collection(db, "expenses"),
        where("organizationId", "==", organization.id)
      );
      const expensesSnapshot = await getDocs(expensesQuery);
      const expensesList = expensesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate
          ? dayjs(doc.data().date.toDate())
          : dayjs(),
      }));

      // Sort expenses by date
      expensesList.sort((a, b) => b.date.diff(a.date));
      setExpenses(expensesList);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load expenses data");
    } finally {
      setLoading(false);
    }
  };

  const handleAddExpense = async () => {
    try {
      const db = getFirestore();
      const property = properties.find((p) => p.id === newExpense.propertyId);

      const expenseData = {
        ...newExpense,
        date: Timestamp.fromDate(newExpense.date.toDate()),
        organizationId: organization.id,
        propertyName: property?.name || "General",
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      await addDoc(collection(db, "expenses"), expenseData);
      setOpenDialog(false);
      setNewExpense({
        expenseType: "general",
        propertyId: "",
        category: "",
        amount: "",
        date: dayjs(),
        description: "",
        receipt: null,
        notes: "",
        status: "pending",
      });
      fetchData();
    } catch (error) {
      console.error("Error adding expense:", error);
      setError("Failed to add expense");
    }
  };

  const handleDeleteExpense = async (expenseId) => {
    try {
      const db = getFirestore();
      await deleteDoc(doc(db, "expenses", expenseId));
      fetchData();
    } catch (error) {
      console.error("Error deleting expense:", error);
      setError("Failed to delete expense");
    }
  };

  const handleFileUpload = (uploadedFiles) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      setNewExpense({
        ...newExpense,
        receipt: uploadedFiles[0],
      });
    }
  };

  const getFilteredExpenses = () => {
    if (!expenses.length) return [];

    return expenses.filter((expense) => {
      const expenseDate = dayjs(expense.date);
      return (
        expenseDate.isSameOrAfter(dateRange.start, "day") &&
        expenseDate.isSameOrBefore(dateRange.end, "day")
      );
    });
  };

  const getExpensesSummary = () => {
    const filteredExpenses = getFilteredExpenses();
    const summary = {
      total: 0,
      byCategory: {},
    };

    filteredExpenses.forEach((expense) => {
      const amount = Number(expense.amount) || 0;
      summary.total += amount;
      summary.byCategory[expense.category] =
        (summary.byCategory[expense.category] || 0) + amount;
    });

    return summary;
  };

  const handleTimeFilterChange = (filter) => {
    setTimeFilter(filter);
    switch (filter) {
      case "current_month":
        setDateRange({
          start: dayjs().startOf("month"),
          end: dayjs().endOf("month"),
        });
        break;
      case "current_year":
        setDateRange({
          start: dayjs().startOf("year"),
          end: dayjs().endOf("year"),
        });
        break;
      // Custom range handled by date picker
    }
  };

  const handleDownloadExpenses = () => {
    // Get expenses within the selected date range
    const expensesToDownload = expenses.filter((expense) => {
      const expenseDate = dayjs(expense.date);
      return (
        expenseDate.isSameOrAfter(downloadDateRange.start, "day") &&
        expenseDate.isSameOrBefore(downloadDateRange.end, "day")
      );
    });

    // Format expenses for CSV
    const csvData = expensesToDownload.map((expense) => ({
      Date: dayjs(expense.date).format("YYYY-MM-DD"),
      Property: expense.propertyName,
      Category:
        expenseCategories.find((cat) => cat.id === expense.category)?.label ||
        expense.category,
      Description: expense.description,
      Amount: expense.amount,
      Notes: expense.notes,
      Status: expense.status,
      "Has Receipt": expense.receipt ? "Yes" : "No",
    }));

    // Convert to CSV string
    const headers = Object.keys(csvData[0]);
    const csvString = [
      headers.join(","),
      ...csvData.map((row) =>
        headers
          .map((header) => {
            let cell = row[header]?.toString() || "";
            // Escape commas and quotes in the cell content
            if (
              cell.includes(",") ||
              cell.includes('"') ||
              cell.includes("\n")
            ) {
              cell = `"${cell.replace(/"/g, '""')}"`;
            }
            return cell;
          })
          .join(",")
      ),
    ].join("\n");

    // Create and trigger download
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `expenses_${dayjs(downloadDateRange.start).format(
        "YYYY-MM-DD"
      )}_to_${dayjs(downloadDateRange.end).format("YYYY-MM-DD")}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadDialogOpen(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Add Expense
          </Button>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={() => setDownloadDialogOpen(true)}
          >
            Download Expenses
          </Button>
        </Box>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
          <Button
            variant={timeFilter === "current_month" ? "contained" : "outlined"}
            onClick={() => handleTimeFilterChange("current_month")}
          >
            This Month
          </Button>
          <Button
            variant={timeFilter === "current_year" ? "contained" : "outlined"}
            onClick={() => handleTimeFilterChange("current_year")}
          >
            This Year
          </Button>
          <Button
            variant={timeFilter === "custom_range" ? "contained" : "outlined"}
            onClick={() => handleTimeFilterChange("custom_range")}
          >
            Custom Range
          </Button>
        </Stack>

        {timeFilter === "custom_range" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <DatePicker
                label="From"
                value={dateRange.start}
                onChange={(newValue) =>
                  setDateRange((prev) => ({ ...prev, start: newValue }))
                }
              />
              <DatePicker
                label="To"
                value={dateRange.end}
                onChange={(newValue) =>
                  setDateRange((prev) => ({ ...prev, end: newValue }))
                }
              />
            </Stack>
          </LocalizationProvider>
        )}
      </Box>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <ReceiptIcon color="primary" />
                <Typography variant="h6">Total Expenses</Typography>
              </Box>
              <Typography variant="h4" sx={{ mb: 1 }}>
                £{getExpensesSummary().total.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {getFilteredExpenses().length} expenses recorded
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Property</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Receipt</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getFilteredExpenses().map((expense) => (
              <TableRow key={expense.id}>
                <TableCell>
                  {dayjs(expense.date).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>{expense.propertyName}</TableCell>
                <TableCell>
                  <Chip
                    label={
                      expenseCategories.find(
                        (cat) => cat.id === expense.category
                      )?.label || expense.category
                    }
                    size="small"
                  />
                </TableCell>
                <TableCell>{expense.description}</TableCell>
                <TableCell>
                  £{Number(expense.amount).toLocaleString()}
                </TableCell>
                <TableCell>
                  {expense.receipt ? (
                    <Tooltip title="Download Receipt">
                      <IconButton
                        size="small"
                        onClick={() =>
                          window.open(expense.receipt.url, "_blank")
                        }
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Typography variant="caption" color="text.secondary">
                      No receipt
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedExpense(expense);
                      setOpenDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteExpense(expense.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add/Edit Expense Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setSelectedExpense(null);
          setNewExpense({
            expenseType: "general",
            propertyId: "",
            category: "",
            amount: "",
            date: dayjs(),
            description: "",
            receipt: null,
            notes: "",
            status: "pending",
          });
        }}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            background: "linear-gradient(180deg, #f8fafc 0%, #ffffff 30.21%)",
            boxShadow: "0px 12px 26px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <DialogTitle
            sx={{
              fontSize: "1.5rem",
              fontWeight: 600,
              textAlign: "center",
              pb: 1,
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              color: "white",
              borderRadius: "16px 16px 0 0",
            }}
          >
            {selectedExpense ? "Edit Expense" : "Add New Expense"}
          </DialogTitle>
          <DialogContent sx={{ mt: 2, px: { xs: 2, sm: 4 } }}>
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={
                      selectedExpense?.expenseType || newExpense.expenseType
                    }
                    onChange={(e) =>
                      setNewExpense({
                        ...newExpense,
                        expenseType: e.target.value,
                      })
                    }
                    label="Type"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.15)",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2563eb",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2563eb",
                      },
                    }}
                  >
                    {expenseTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Property</InputLabel>
                  <Select
                    value={selectedExpense?.propertyId || newExpense.propertyId}
                    onChange={(e) =>
                      setNewExpense({
                        ...newExpense,
                        propertyId: e.target.value,
                      })
                    }
                    label="Property"
                    disabled={newExpense.expenseType !== "property"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.15)",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2563eb",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2563eb",
                      },
                    }}
                  >
                    {properties.map((property) => (
                      <MenuItem key={property.id} value={property.id}>
                        {property.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={selectedExpense?.category || newExpense.category}
                    onChange={(e) =>
                      setNewExpense({ ...newExpense, category: e.target.value })
                    }
                    label="Category"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 0.15)",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2563eb",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2563eb",
                      },
                    }}
                  >
                    {expenseCategories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Amount"
                  type="number"
                  value={selectedExpense?.amount || newExpense.amount}
                  onChange={(e) =>
                    setNewExpense({ ...newExpense, amount: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.15)",
                      },
                      "&:hover fieldset": {
                        borderColor: "#2563eb",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#2563eb",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={dayjs(selectedExpense?.date || newExpense.date)}
                    onChange={(newValue) =>
                      setNewExpense({ ...newExpense, date: newValue })
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        sx: {
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 0.15)",
                            },
                            "&:hover fieldset": {
                              borderColor: "#2563eb",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#2563eb",
                            },
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  value={selectedExpense?.description || newExpense.description}
                  onChange={(e) =>
                    setNewExpense({
                      ...newExpense,
                      description: e.target.value,
                    })
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.15)",
                      },
                      "&:hover fieldset": {
                        borderColor: "#2563eb",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#2563eb",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Notes"
                  multiline
                  rows={3}
                  value={selectedExpense?.notes || newExpense.notes}
                  onChange={(e) =>
                    setNewExpense({ ...newExpense, notes: e.target.value })
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.15)",
                      },
                      "&:hover fieldset": {
                        borderColor: "#2563eb",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#2563eb",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, fontWeight: 600 }}
                  >
                    Receipt Upload
                  </Typography>
                  <FileUpload
                    onUpload={handleFileUpload}
                    accept=".pdf,.png,.jpg,.jpeg"
                    folder="expense-receipts"
                    entityId={organization?.id}
                  />
                </Box>
                {(selectedExpense?.receipt || newExpense.receipt) && (
                  <Box
                    sx={{
                      mt: 1,
                      p: 2,
                      borderRadius: 1,
                      bgcolor: "rgba(37, 99, 235, 0.05)",
                      border: "1px solid rgba(37, 99, 235, 0.1)",
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      Current receipt:{" "}
                      {selectedExpense?.receipt?.name ||
                        newExpense.receipt?.name}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ px: 3, py: 3, borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}
          >
            <Button
              onClick={() => {
                setOpenDialog(false);
                setSelectedExpense(null);
                setNewExpense({
                  expenseType: "general",
                  propertyId: "",
                  category: "",
                  amount: "",
                  date: dayjs(),
                  description: "",
                  receipt: null,
                  notes: "",
                  status: "pending",
                });
              }}
              sx={{
                color: "text.secondary",
                "&:hover": {
                  bgcolor: "rgba(0, 0, 0, 0.05)",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddExpense}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                boxShadow: "0px 2px 4px rgba(37, 99, 235, 0.2)",
                px: 4,
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              {selectedExpense ? "Save Changes" : "Add Expense"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* Download Dialog */}
      <Dialog
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Download Expenses</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Select the date range for the expenses you want to download. The
            file will be downloaded in CSV format, compatible with accounting
            software like QuickBooks and Xero.
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DatePicker
                label="Start Date"
                value={downloadDateRange.start}
                onChange={(newValue) =>
                  setDownloadDateRange((prev) => ({ ...prev, start: newValue }))
                }
                slotProps={{
                  textField: { fullWidth: true },
                }}
              />
              <DatePicker
                label="End Date"
                value={downloadDateRange.end}
                onChange={(newValue) =>
                  setDownloadDateRange((prev) => ({ ...prev, end: newValue }))
                }
                slotProps={{
                  textField: { fullWidth: true },
                }}
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDownloadDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleDownloadExpenses}
            variant="contained"
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Expenses;
