import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Stack,
  ButtonGroup,
  Checkbox,
  Paper,
} from "@mui/material";
import {
  Receipt as ReceiptIcon,
  AccountBalance as AccountBalanceIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Download as DownloadIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
  writeBatch,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const RentTracking = () => {
  const { organization } = useOrganization();
  const [rentPayments, setRentPayments] = useState([]);
  const [properties, setProperties] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const [newPayment, setNewPayment] = useState({
    tenantId: "",
    propertyId: "",
    amount: "",
    dueDate: "",
    paymentDate: "",
    paymentMethod: "",
    status: "payment_due",
    reference: "",
    notes: "",
  });

  const [timeFilter, setTimeFilter] = useState("current_month"); // 'current_month', 'current_year', 'custom_range'
  const [dateRange, setDateRange] = useState({
    start: dayjs().startOf("month"),
    end: dayjs().endOf("month"),
  });

  const [selectedPayments, setSelectedPayments] = useState([]);

  const paymentMethods = [
    { value: "bank_transfer", label: "Bank Transfer" },
    { value: "card", label: "Credit/Debit Card" },
    { value: "cash", label: "Cash" },
    { value: "check", label: "Check" },
    { value: "direct_debit", label: "Direct Debit" },
  ];

  const paymentStatuses = {
    payment_due: { label: "Payment Due", color: "warning" },
    paid: { label: "Paid", color: "success" },
    overdue: { label: "Overdue", color: "error" },
  };

  const paymentTypes = {
    rent: { label: "Monthly Rent", color: "primary" },
    deposit: { label: "Security Deposit", color: "secondary" },
  };

  const timeFilterOptions = [
    { value: "current_month", label: "This Month" },
    { value: "current_year", label: "This Year" },
    { value: "custom_range", label: "Custom Range" },
  ];

  useEffect(() => {
    if (organization?.id) {
      fetchRentData();
    }
  }, [organization]);

  const getFilteredPayments = () => {
    if (!rentPayments.length) return [];

    return rentPayments.filter((payment) => {
      const dueDate = dayjs(payment.dueDate);
      return (
        dueDate.isSameOrAfter(dateRange.start, "day") &&
        dueDate.isSameOrBefore(dateRange.end, "day")
      );
    });
  };

  const fetchRentData = async () => {
    try {
      const db = getFirestore();

      // Fetch properties
      const propertiesQuery = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const propertiesSnapshot = await getDocs(propertiesQuery);
      const propertiesList = propertiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProperties(propertiesList);

      // Fetch active tenants
      const tenantsQuery = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id),
        where("status", "==", "active")
      );
      const tenantsSnapshot = await getDocs(tenantsQuery);
      const tenantsList = tenantsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTenants(tenantsList);

      // Fetch rent payments
      const paymentsQuery = query(
        collection(db, "rentPayments"),
        where("organizationId", "==", organization.id)
      );
      const paymentsSnapshot = await getDocs(paymentsQuery);
      const paymentsList = paymentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        dueDate: doc.data().dueDate
          ? dayjs(doc.data().dueDate).format("YYYY-MM-DD")
          : null,
        paymentDate: doc.data().paymentDate
          ? dayjs(doc.data().paymentDate).format("YYYY-MM-DD")
          : null,
      }));

      // Sort payments by due date
      paymentsList.sort((a, b) => dayjs(a.dueDate).diff(dayjs(b.dueDate)));
      setRentPayments(paymentsList);
    } catch (error) {
      console.error("Error fetching rent data:", error);
      setError("Failed to load rent tracking data");
    } finally {
      setLoading(false);
    }
  };

  const handleAddPayment = async () => {
    try {
      const db = getFirestore();
      const tenant = tenants.find((t) => t.id === newPayment.tenantId);
      const property = properties.find((p) => p.id === newPayment.propertyId);

      const paymentData = {
        ...newPayment,
        organizationId: organization.id,
        tenantName: `${tenant.firstName} ${tenant.lastName}`,
        propertyName: property.name,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      await addDoc(collection(db, "rentPayments"), paymentData);
      setOpenDialog(false);
      fetchRentData();
    } catch (error) {
      console.error("Error adding payment:", error);
      setError("Failed to add payment");
    }
  };

  const getFilteredSummary = () => {
    const filteredPayments = getFilteredPayments();
    return {
      totalDue: filteredPayments.reduce(
        (sum, payment) =>
          payment.paymentType !== "deposit"
            ? sum + Number(payment.amount)
            : sum,
        0
      ),
      totalCollected: filteredPayments.reduce(
        (sum, payment) =>
          payment.status === "paid" && payment.paymentType !== "deposit"
            ? sum + Number(payment.amount)
            : sum,
        0
      ),
      totalOverdue: filteredPayments.reduce(
        (sum, payment) =>
          payment.status === "overdue" && payment.paymentType !== "deposit"
            ? sum + Number(payment.amount)
            : sum,
        0
      ),
      totalDeposits: filteredPayments.reduce(
        (sum, payment) =>
          payment.paymentType === "deposit"
            ? sum + Number(payment.amount)
            : sum,
        0
      ),
    };
  };

  const handleTimeFilterChange = (filter) => {
    setTimeFilter(filter);
    switch (filter) {
      case "current_month":
        setDateRange({
          start: dayjs().startOf("month"),
          end: dayjs().endOf("month"),
        });
        break;
      case "current_year":
        setDateRange({
          start: dayjs().startOf("year"),
          end: dayjs().endOf("year"),
        });
        break;
      // Custom range will be handled by the date picker directly
    }
  };

  const handleBulkStatusUpdate = async (
    newStatus,
    paymentIds = selectedPayments
  ) => {
    try {
      const db = getFirestore();
      const batch = writeBatch(db);

      paymentIds.forEach((paymentId) => {
        const paymentRef = doc(db, "rentPayments", paymentId);
        batch.update(paymentRef, {
          status: newStatus,
          paymentDate: newStatus === "paid" ? new Date().toISOString() : null,
          updatedAt: new Date().toISOString(),
        });
      });

      await batch.commit();
      await fetchRentData();
      setSelectedPayments([]); // Clear selection after update
      setOpenDialog(false); // Close dialog if open
    } catch (error) {
      console.error("Error updating payments:", error);
      setError("Failed to update payments");
    }
  };

  const handleSelectPayment = (paymentId) => {
    setSelectedPayments((prev) =>
      prev.includes(paymentId)
        ? prev.filter((id) => id !== paymentId)
        : [...prev, paymentId]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedPayments(getFilteredPayments().map((p) => p.id));
    } else {
      setSelectedPayments([]);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Record Payment
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          mb: 3,
          alignItems: { xs: "stretch", md: "center" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            overflowX: "auto",
            pb: 1,
            "&::-webkit-scrollbar": {
              height: 8,
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: 4,
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: 4,
            },
          }}
        >
          {timeFilterOptions.map((option) => (
            <Button
              key={option.value}
              variant={timeFilter === option.value ? "contained" : "outlined"}
              onClick={() => handleTimeFilterChange(option.value)}
              sx={{ flexShrink: 0 }}
            >
              {option.label}
            </Button>
          ))}
        </Box>

        {timeFilter === "custom_range" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
                alignItems: "center",
                backgroundColor: "background.paper",
                p: 1,
                borderRadius: 2,
                border: 1,
                borderColor: "divider",
              }}
            >
              <DatePicker
                label="From"
                value={dateRange.start}
                onChange={(newValue) =>
                  setDateRange((prev) => ({ ...prev, start: newValue }))
                }
                slotProps={{
                  textField: {
                    size: "small",
                    sx: { width: { xs: "100%", sm: 150 } },
                  },
                }}
              />
              <Typography color="text.secondary">to</Typography>
              <DatePicker
                label="To"
                value={dateRange.end}
                onChange={(newValue) =>
                  setDateRange((prev) => ({ ...prev, end: newValue }))
                }
                slotProps={{
                  textField: {
                    size: "small",
                    sx: { width: { xs: "100%", sm: 150 } },
                  },
                }}
              />
            </Box>
          </LocalizationProvider>
        )}
      </Box>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <AccountBalanceIcon color="primary" />
                <Typography variant="h6">Total Rent Due</Typography>
              </Box>
              <Typography variant="h4" sx={{ mb: 1 }}>
                £{getFilteredSummary().totalDue.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {
                  getFilteredPayments().filter(
                    (p) =>
                      p.status === "payment_due" && p.paymentType === "rent"
                  ).length
                }{" "}
                pending payments
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <ReceiptIcon color="success" />
                <Typography variant="h6">Rent Collected</Typography>
              </Box>
              <Typography variant="h4" sx={{ mb: 1 }}>
                £{getFilteredSummary().totalCollected.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {
                  getFilteredPayments().filter(
                    (p) => p.status === "paid" && p.paymentType === "rent"
                  ).length
                }{" "}
                payments received
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <ReceiptIcon color="error" />
                <Typography variant="h6">Overdue Rent</Typography>
              </Box>
              <Typography variant="h4" sx={{ mb: 1 }}>
                £{getFilteredSummary().totalOverdue.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {
                  getFilteredPayments().filter(
                    (p) => p.status === "overdue" && p.paymentType === "rent"
                  ).length
                }{" "}
                overdue payments
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <AccountBalanceIcon color="secondary" />
                <Typography variant="h6">Security Deposits</Typography>
              </Box>
              <Typography variant="h4" sx={{ mb: 1 }}>
                £{getFilteredSummary().totalDeposits.toLocaleString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {
                  getFilteredPayments().filter(
                    (p) => p.paymentType === "deposit"
                  ).length
                }{" "}
                deposits tracked
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {selectedPayments.length > 0 && (
        <Box
          sx={{
            position: "sticky",
            bottom: 16,
            left: 0,
            right: 0,
            zIndex: 2,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 3,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            alignItems: "center",
            justifyContent: "space-between",
            mx: { xs: 2, md: 0 },
          }}
        >
          <Typography>
            {selectedPayments.length} payment
            {selectedPayments.length > 1 ? "s" : ""} selected
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row" },
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <Button
              startIcon={<DoneIcon />}
              onClick={() => handleBulkStatusUpdate("paid")}
              sx={{
                bgcolor: "success.main",
                color: "white",
                "&:hover": { bgcolor: "success.dark" },
              }}
            >
              Mark as Paid
            </Button>
            <Button
              onClick={() => handleBulkStatusUpdate("overdue")}
              sx={{
                bgcolor: "error.main",
                color: "white",
                "&:hover": { bgcolor: "error.dark" },
              }}
            >
              Mark as Overdue
            </Button>
            <Button
              onClick={() => handleBulkStatusUpdate("payment_due")}
              sx={{
                bgcolor: "primary.main",
                color: "white",
                "&:hover": { bgcolor: "primary.dark" },
              }}
            >
              Mark as Due
            </Button>
          </Box>
        </Box>
      )}

      <TableContainer
        component={Paper}
        sx={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: 8,
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: 4,
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: 4,
            "&:hover": {
              background: "#666",
            },
          },
        }}
      >
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={
                    selectedPayments.length === getFilteredPayments().length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Tenant</TableCell>
              <TableCell>Property Address</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getFilteredPayments().map((payment) => (
              <TableRow
                key={payment.id}
                selected={selectedPayments.includes(payment.id)}
                hover
                sx={{
                  cursor: "pointer",
                  "&.Mui-selected": {
                    backgroundColor: "primary.lighter",
                  },
                }}
                onClick={() => handleSelectPayment(payment.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPayments.includes(payment.id)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleSelectPayment(payment.id)}
                  />
                </TableCell>
                <TableCell>{payment.tenantName}</TableCell>
                <TableCell>{payment.propertyAddress}</TableCell>
                <TableCell>{payment.unitNumber}</TableCell>
                <TableCell>
                  <Chip
                    label={
                      paymentTypes[payment.paymentType]?.label || "Monthly Rent"
                    }
                    color={
                      paymentTypes[payment.paymentType]?.color || "primary"
                    }
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  £{Number(payment.amount).toLocaleString()}
                </TableCell>
                <TableCell>
                  {payment.dueDate
                    ? dayjs(payment.dueDate).format("DD/MM/YYYY")
                    : "-"}
                </TableCell>
                <TableCell>
                  {payment.paymentDate
                    ? dayjs(payment.paymentDate).format("DD/MM/YYYY")
                    : "-"}
                </TableCell>
                <TableCell>
                  <Chip
                    label={
                      paymentStatuses[payment.status]?.label || payment.status
                    }
                    color={paymentStatuses[payment.status]?.color || "default"}
                    size="small"
                  />
                </TableCell>
                <TableCell>{payment.paymentMethod}</TableCell>
                <TableCell>
                  {payment.status !== "paid" && (
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBulkStatusUpdate("paid", [payment.id]);
                      }}
                      sx={{ mr: 1 }}
                    >
                      Mark Paid
                    </Button>
                  )}
                  {payment.status === "payment_due" && (
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBulkStatusUpdate("overdue", [payment.id]);
                      }}
                      sx={{ mr: 1 }}
                    >
                      Mark Overdue
                    </Button>
                  )}
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedPayment(payment);
                      setOpenDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small">
                    <DownloadIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedPayment ? "Edit Payment" : "Record New Payment"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Tenant</InputLabel>
                <Select
                  value={selectedPayment?.tenantId || newPayment.tenantId}
                  onChange={(e) =>
                    setNewPayment({ ...newPayment, tenantId: e.target.value })
                  }
                  label="Tenant"
                >
                  {tenants.map((tenant) => (
                    <MenuItem key={tenant.id} value={tenant.id}>
                      {tenant.firstName} {tenant.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Property</InputLabel>
                <Select
                  value={selectedPayment?.propertyId || newPayment.propertyId}
                  onChange={(e) =>
                    setNewPayment({ ...newPayment, propertyId: e.target.value })
                  }
                  label="Property"
                >
                  {properties.map((property) => (
                    <MenuItem key={property.id} value={property.id}>
                      {property.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Amount"
                type="number"
                value={selectedPayment?.amount || newPayment.amount}
                onChange={(e) =>
                  setNewPayment({ ...newPayment, amount: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Payment Method</InputLabel>
                <Select
                  value={
                    selectedPayment?.paymentMethod || newPayment.paymentMethod
                  }
                  onChange={(e) =>
                    setNewPayment({
                      ...newPayment,
                      paymentMethod: e.target.value,
                    })
                  }
                  label="Payment Method"
                >
                  {paymentMethods.map((method) => (
                    <MenuItem key={method.value} value={method.value}>
                      {method.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Due Date"
                type="date"
                value={selectedPayment?.dueDate || newPayment.dueDate}
                onChange={(e) =>
                  setNewPayment({ ...newPayment, dueDate: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Payment Date"
                type="date"
                value={selectedPayment?.paymentDate || newPayment.paymentDate}
                onChange={(e) =>
                  setNewPayment({ ...newPayment, paymentDate: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Reference"
                value={selectedPayment?.reference || newPayment.reference}
                onChange={(e) =>
                  setNewPayment({ ...newPayment, reference: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={selectedPayment?.status || newPayment.status}
                  onChange={(e) => {
                    if (selectedPayment) {
                      handleBulkStatusUpdate(e.target.value, [
                        selectedPayment.id,
                      ]);
                    } else {
                      setNewPayment({ ...newPayment, status: e.target.value });
                    }
                  }}
                  label="Status"
                >
                  {Object.entries(paymentStatuses).map(([value, { label }]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notes"
                multiline
                rows={3}
                value={selectedPayment?.notes || newPayment.notes}
                onChange={(e) =>
                  setNewPayment({ ...newPayment, notes: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleAddPayment}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            {selectedPayment ? "Save Changes" : "Record Payment"}
          </Button>
        </DialogActions>
      </Dialog>

      <style jsx>{`
        .tabs {
          display: flex;
          position: relative;
          background-color: #fff;
          box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
            0 6px 12px 0 rgba(24, 94, 224, 0.15);
          padding: 0.75rem;
          border-radius: 99px;
        }

        .tabs * {
          z-index: 2;
        }

        .container input[type="radio"] {
          display: none;
        }

        .tab {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 120px;
          font-size: 0.9rem;
          font-weight: 500;
          border-radius: 99px;
          cursor: pointer;
          transition: color 0.15s ease-in;
        }

        .container input[type="radio"]:checked + label {
          color: #185ee0;
        }

        .glider {
          position: absolute;
          display: flex;
          height: 30px;
          width: 120px;
          background-color: #e6eef9;
          z-index: 1;
          border-radius: 99px;
          transition: 0.25s ease-out;
        }

        .container input[id="current_month"]:checked ~ .glider {
          transform: translateX(0);
        }

        .container input[id="current_year"]:checked ~ .glider {
          transform: translateX(120px);
        }

        .container input[id="custom_range"]:checked ~ .glider {
          transform: translateX(240px);
        }
      `}</style>
    </Box>
  );
};

export default RentTracking;
