import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
  Chip,
} from "@mui/material";
import {
  Check as CheckIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { useOrganization } from "../contexts/OrganizationContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createSubscriptionSession } from "../services/stripe";
import { loadStripe } from "@stripe/stripe-js";
import { createStripeCustomer } from "../services/stripe";

const plans = [
  {
    name: "Starter",
    monthlyPrice: "£9.99",
    annualPrice: "£101.90", // £8.49 * 12 (rounded)
    features: [
      "14-day free trial",
      "Up to 5 properties",
      "Single user",
      "Essential tenant management",
      "Rent tracking",
      "Basic expense management",
      "Automated rent reminders",
      "Email support",
    ],
    limits: {
      users: 1,
      properties: 5,
      tenants: 10,
      contractors: 5,
      documents: 50,
    },
    priceIds: {
      monthly: "price_1QjOlFP4UrRjtMjoH5EC3yl7",
      annual: "price_1QjOm6P4UrRjtMjoEhLwhwxs",
    },
  },
  {
    name: "Professional",
    monthlyPrice: "£14.99",
    annualPrice: "£152.90", // £12.74 * 12 (rounded)
    features: [
      "14-day free trial",
      "Up to 20 properties",
      "3 team members",
      "Advanced tenant management",
      "Analytics dashboard (Coming Soon)",
      "Team access controls",
      "Priority support",
      "Free setup assistance",
    ],
    limits: {
      users: 3,
      properties: 20,
      tenants: 40,
      contractors: 20,
      documents: 200,
    },
    highlighted: true,
    priceIds: {
      monthly: "price_1QjOn9P4UrRjtMjoK2BTEfLh",
      annual: "price_1QjOnrP4UrRjtMjox5j3fuS5",
    },
  },
  {
    name: "Enterprise",
    monthlyPrice: "£34.99",
    annualPrice: "£356.90", // £29.74 * 12 (rounded)
    features: [
      "14-day free trial",
      "Unlimited properties",
      "Unlimited team members",
      "Full management suite",
      "Custom workflows (Coming Soon)",
      "Advanced analytics & reporting (Coming Soon)",
      "Dedicated account manager",
      "Premium onboarding & training",
    ],
    limits: {
      users: 999999,
      properties: 999999,
      tenants: 999999,
      contractors: 999999,
      documents: 999999,
    },
    priceIds: {
      monthly: "price_1QjOpYP4UrRjtMjo3VOC7MiF",
      annual: "price_1QjOp9P4UrRjtMjoDI2OsIxS",
    },
  },
];

const getTrialDaysRemaining = (startDate) => {
  if (!startDate) return 0;
  const trialLength = 14; // 14 day trial
  const start = new Date(startDate);
  const now = new Date();
  const diffTime =
    start.getTime() + trialLength * 24 * 60 * 60 * 1000 - now.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.max(0, diffDays);
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

const PricingPlans = ({ onPlanSelect, isProcessing }) => {
  const { organization } = useOrganization();
  const [user, setUser] = React.useState(null);
  const [localProcessing, setLocalProcessing] = React.useState(false);

  // Get current plan name from either plan or subscription
  const currentPlan =
    organization?.plan?.name?.toLowerCase() ||
    organization?.subscription?.name?.toLowerCase() ||
    "";

  const [billingCycle, setBillingCycle] = React.useState("monthly");

  // First check if subscription is active
  const isSubscriptionActive = Boolean(
    organization?.stripePriceId ||
      organization?.stripeSubscriptionId ||
      organization?.subscription?.status === "active" ||
      organization?.plan?.status === "active"
  );

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const trialDaysRemaining = getTrialDaysRemaining(
    organization?.plan?.startDate
  );

  // Only consider trial active if there's no active subscription
  const isTrialActive =
    !isSubscriptionActive && !organization?.trialUsed && trialDaysRemaining > 0;

  const handlePlanSelect = async (plan) => {
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    if (!organization?.id) {
      console.error("No organization found");
      return;
    }

    const now = new Date();
    const isNewTrial =
      !organization?.trialUsed &&
      !organization?.subscription?.status &&
      !organization?.stripePriceId &&
      !organization?.stripeSubscriptionId &&
      (!organization?.plan?.trialEndsAt ||
        new Date(organization?.plan?.trialEndsAt) > now) &&
      !isTrialActive;

    try {
      setLocalProcessing(true);
      // Create or retrieve Stripe customer ID first
      let stripeCustomerId = organization.stripeCustomerId;
      if (!stripeCustomerId) {
        console.log("Creating new Stripe customer");
        const { customerId } = await createStripeCustomer(organization.id, {
          email: organization.email,
          name: organization.name || organization.ownerName,
        });
        stripeCustomerId = customerId;
        console.log("Created Stripe customer:", customerId);
      } else {
        console.log("Using existing Stripe customer:", stripeCustomerId);
      }

      const planData = {
        name: plan.name,
        limits: plan.limits,
        status: isSubscriptionActive
          ? "pending_upgrade"
          : isNewTrial
          ? "trial"
          : "active",
        startDate: now.toISOString(),
        trialEndsAt: isNewTrial
          ? new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000).toISOString()
          : null,
        trialUsed: !isNewTrial || isTrialActive || isSubscriptionActive,
        priceId:
          billingCycle === "monthly"
            ? plan.priceIds.monthly
            : plan.priceIds.annual,
        stripeCustomerId: stripeCustomerId,
      };

      console.log("Setting up plan:", {
        isNewTrial,
        planName: plan.name,
        status: planData.status,
        stripeCustomerId,
        isTrialActive,
        isSubscriptionActive,
      });

      // Update the organization with the selected plan
      await onPlanSelect(planData);

      // Create checkout session and redirect for paid plans or upgrades
      if (!isNewTrial || isTrialActive || isSubscriptionActive) {
        console.log("Creating subscription for upgrade:", {
          priceId: planData.priceId,
          customerId: organization.stripeCustomerId,
        });

        // Create checkout session and redirect for paid plans
        const { sessionId } = await createSubscriptionSession(
          planData.priceId,
          organization.stripeCustomerId
        );

        const stripe = await stripePromise;
        if (!stripe) throw new Error("Stripe failed to initialize");

        const result = await stripe.redirectToCheckout({
          sessionId,
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      } else {
        console.log("Free trial activated - no payment required");
      }
    } catch (error) {
      console.error("Error during plan selection:", error);
      // You might want to show an error notification here
    } finally {
      if (isNewTrial) {
        setLocalProcessing(false);
      }
    }
  };

  return (
    <Box sx={{ py: 3 }}>
      {isTrialActive && (
        <Box
          sx={{
            mb: 4,
            p: 2,
            bgcolor: "info.light",
            borderRadius: 1,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Trial Period Active
          </Typography>
          <Typography>
            {trialDaysRemaining} {trialDaysRemaining === 1 ? "day" : "days"}{" "}
            remaining in your trial
          </Typography>
          {trialDaysRemaining <= 3 && (
            <Typography
              sx={{
                color: "error.main",
                mt: 1,
                fontWeight: "bold",
              }}
            >
              Your trial is ending soon! Select a plan to continue using all
              features.
            </Typography>
          )}
        </Box>
      )}

      <Box sx={{ mb: 4, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Choose Your Plan
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 3 }}>
          {isTrialActive
            ? "Start your subscription now or continue with the trial"
            : "Select a plan that works best for you"}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 4 }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant={billingCycle === "monthly" ? "contained" : "outlined"}
              onClick={() => setBillingCycle("monthly")}
            >
              Monthly
            </Button>
            <Button
              variant={billingCycle === "annual" ? "contained" : "outlined"}
              onClick={() => setBillingCycle("annual")}
              endIcon={
                <Box
                  component="span"
                  sx={{
                    bgcolor: "success.main",
                    color: "white",
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    fontSize: "0.75rem",
                  }}
                >
                  Save 15%
                </Box>
              }
            >
              Annual
            </Button>
          </Box>
        </Box>
      </Box>

      <Grid container spacing={3} justifyContent="center">
        {plans.map((plan) => {
          const isCurrentPlan = currentPlan === plan.name.toLowerCase();
          const isDisabled = isCurrentPlan && isSubscriptionActive;
          const buttonText = isCurrentPlan
            ? isTrialActive
              ? "Start Subscription Now"
              : isSubscriptionActive
              ? "Current Plan"
              : "Select Plan"
            : "Select Plan";

          return (
            <Grid item xs={12} md={4} key={plan.name}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  ...(isCurrentPlan && {
                    border: 2,
                    borderColor: "primary.main",
                    boxShadow: "0 0 20px rgba(37, 99, 235, 0.2)",
                    transform: "translateY(-8px)",
                  }),
                  ...(plan.highlighted &&
                    !isCurrentPlan && {
                      transform: "scale(1.02)",
                      boxShadow: 6,
                    }),
                  transition: "all 0.2s ease-in-out",
                }}
              >
                {isCurrentPlan && (
                  <>
                    <Box
                      sx={{
                        position: "absolute",
                        top: -4,
                        left: "50%",
                        transform: "translateX(-50%)",
                        bgcolor: "primary.main",
                        color: "white",
                        px: 3,
                        py: 1,
                        borderRadius: "20px",
                        fontWeight: "bold",
                        zIndex: 1,
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        fontSize: "0.875rem",
                        letterSpacing: "0.5px",
                        border: "2px solid white",
                        marginTop: 1,
                      }}
                    >
                      Current Plan
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        height: "6px",
                        background: "linear-gradient(90deg, #2563eb, #7c3aed)",
                        borderRadius: "4px 4px 0 0",
                      }}
                    />
                  </>
                )}

                <CardContent
                  sx={{
                    flexGrow: 1,
                    pt: isCurrentPlan ? 7 : 2,
                    mt: isCurrentPlan ? 4 : 0,
                  }}
                >
                  <Box sx={{ position: "relative", mb: 2 }}>
                    <Typography
                      variant="h5"
                      component="div"
                      gutterBottom
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: isCurrentPlan ? "primary.main" : "inherit",
                        fontWeight: isCurrentPlan ? 700 : 600,
                      }}
                    >
                      {plan.name}
                      {isCurrentPlan && isTrialActive && (
                        <Chip
                          label="Trial"
                          size="small"
                          color="warning"
                          sx={{ ml: 1, verticalAlign: "middle" }}
                        />
                      )}
                      {isCurrentPlan &&
                        !isTrialActive &&
                        isSubscriptionActive && (
                          <Chip
                            label="Active"
                            size="small"
                            color="success"
                            sx={{ ml: 1, verticalAlign: "middle" }}
                          />
                        )}
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      {billingCycle === "monthly"
                        ? plan.monthlyPrice
                        : plan.annualPrice}
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="text.secondary"
                      >
                        /{billingCycle === "monthly" ? "mo" : "year"}
                      </Typography>
                    </Typography>
                  </Box>
                  <List>
                    {plan.features.map((feature, index) => (
                      <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <CheckIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                  {plan.addOns && (
                    <>
                      <Typography
                        variant="subtitle2"
                        sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
                      >
                        Available Add-ons:
                      </Typography>
                      <List dense>
                        {plan.addOns.map((addon, index) => (
                          <ListItem key={index} sx={{ py: 0.5 }}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                              <AddIcon color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                              primary={addon}
                              primaryTypographyProps={{
                                variant: "body2",
                                color: "text.secondary",
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </CardContent>
                <Box sx={{ p: 2, pt: 0 }}>
                  <Button
                    fullWidth
                    variant={isDisabled ? "outlined" : "contained"}
                    onClick={() => handlePlanSelect(plan)}
                    disabled={isDisabled || isProcessing || localProcessing}
                    sx={{
                      ...(!isDisabled && {
                        background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                        "&:hover": {
                          background:
                            "linear-gradient(45deg, #1e40af, #5b21b6)",
                        },
                      }),
                    }}
                  >
                    {isProcessing || localProcessing
                      ? "Processing..."
                      : buttonText}
                  </Button>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PricingPlans;
