import React, { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Divider,
  Button,
  Badge,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
  Build as BuildIcon,
  Description as DescriptionIcon,
  Engineering as EngineeringIcon,
  AccountCircle,
  Notifications,
  KeyboardArrowDown,
  Payments as PaymentsIcon,
  AccountBox as AccountBoxIcon,
  Payment as PaymentIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Star as StarIcon,
  Receipt as ReceiptIcon,
  Edit as EditIcon,
  ManageAccounts as ManageAccountsIcon,
  Folder as FolderIcon,
  Person as PersonIcon,
  BugReport as BugReportIcon,
  School as SchoolIcon,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useOrganization } from "../../contexts/OrganizationContext";
import {
  getDoc,
  doc,
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import BillingDialog from "../BillingDialog";
import { emailService } from "../../services/emailService";

const drawerWidth = 280;

const DashboardLayout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [billingDialogOpen, setBillingDialogOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const db = getFirestore();
  const { organization } = useOrganization();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [reportIssueDialogOpen, setReportIssueDialogOpen] = useState(false);
  const [issueDescription, setIssueDescription] = useState("");
  const [isSubmittingIssue, setIsSubmittingIssue] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
          if (userDoc.exists()) {
            setUserProfile(userDoc.data());
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }
    };

    fetchUserProfile();
  }, [auth.currentUser, db]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Check if user is admin or owner
  const isAdminOrOwner =
    auth.currentUser?.email === organization?.ownerEmail ||
    userProfile?.role === "admin";

  // Add check for active subscription
  const hasActiveSubscription = Boolean(
    organization?.stripePriceId ||
      organization?.stripeSubscriptionId ||
      organization?.subscription?.status === "active" ||
      organization?.plan?.status === "active"
  );

  const menuItems = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Properties",
      path: "/dashboard/properties",
      icon: <BusinessIcon />,
    },
    {
      title: "Tenants",
      path: "/dashboard/tenants",
      icon: <PeopleIcon />,
    },
    {
      title: "Rent Tracking",
      path: "/dashboard/rent-tracking",
      icon: <PaymentsIcon />,
    },
    {
      title: "Maintenance",
      path: "/dashboard/maintenance",
      icon: <BuildIcon />,
    },
    {
      title: "Contractors",
      path: "/dashboard/contractors",
      icon: <EngineeringIcon />,
    },
    {
      title: "Expenses",
      path: "/dashboard/expenses",
      icon: <ReceiptIcon />,
    },
    {
      title: "Documents",
      path: "/dashboard/documents",
      icon: <FolderIcon />,
    },
    {
      title: "Contracts",
      path: "/dashboard/contracts",
      icon: <EditIcon />,
    },
  ];

  // Add admin-only menu items
  if (isAdminOrOwner) {
    menuItems.push(
      {
        title: "Billing & Usage",
        path: "/dashboard/billing",
        icon: <PaymentsIcon />,
      },
      {
        title: "Account",
        path: "/dashboard/account",
        icon: <PersonIcon />,
      }
    );
  }

  // Fetch notifications
  useEffect(() => {
    if (!organization?.id) return;

    const q = query(
      collection(db, "notifications"),
      where("organizationId", "==", organization.id),
      where("status", "==", "unread")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notificationsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsList);
    });

    return () => unsubscribe();
  }, [organization?.id, db]);

  const handleNotificationClick = async (notification) => {
    try {
      await handleNotificationRead(notification.id);
      if (notification.type === "invoice_submitted") {
        navigate(`/dashboard/maintenance`);
      } else if (notification.type === "application_submitted") {
        navigate(`/dashboard/tenants/review/${notification.entityId}`);
      }
      handleNotificationClose();
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleNotificationRead = async (notificationId) => {
    try {
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, {
        status: "read",
        readAt: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleReportIssue = async () => {
    if (!issueDescription.trim()) {
      setError("Please provide a description of the issue");
      return;
    }

    try {
      setIsSubmittingIssue(true);
      setError("");

      // Get current user's details
      const currentUserDoc = await getDoc(
        doc(db, "users", auth.currentUser.uid)
      );
      const userData = currentUserDoc.data();

      await emailService.sendIssueReport(
        userData.name,
        auth.currentUser.email,
        { description: issueDescription },
        organization.id
      );

      setSuccess("Issue reported successfully. Our team will look into it.");
      setReportIssueDialogOpen(false);
      setIssueDescription("");
    } catch (error) {
      console.error("Error reporting issue:", error);
      setError("Failed to report issue. Please try again.");
    } finally {
      setIsSubmittingIssue(false);
    }
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <AppBar
        position="fixed"
        sx={{
          display: { md: "none" },
          bgcolor: "background.paper",
          backdropFilter: "blur(6px)",
          borderBottom: "1px solid",
          borderColor: "divider",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              "&:hover": {
                bgcolor: "primary.lighter",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              backgroundClip: "text",
              color: "transparent",
              fontWeight: 600,
            }}
          >
            {organization?.name || "TenantIO"}
          </Typography>
          {isAdminOrOwner && !hasActiveSubscription && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<StarIcon />}
              onClick={() => setBillingDialogOpen(true)}
              sx={{
                mr: 2,
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              Upgrade
            </Button>
          )}
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              bgcolor: "primary.lighter",
              "&:hover": {
                bgcolor: "primary.light",
              },
            }}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {userProfile?.name?.[0] || "U"}
            </Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>

      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          display: { xs: "none", md: "block" },
          bgcolor: "background.paper",
          backdropFilter: "blur(6px)",
          borderBottom: "1px solid",
          borderColor: "divider",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "text.primary",
              fontWeight: 600,
              fontSize: "1.2rem",
            }}
          >
            {menuItems.find((item) => item.path === location.pathname)?.title ||
              "Dashboard"}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {isAdminOrOwner && !hasActiveSubscription && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<StarIcon />}
                onClick={() => setBillingDialogOpen(true)}
                sx={{
                  background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                  "&:hover": {
                    background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                  },
                }}
              >
                Upgrade
              </Button>
            )}
            <IconButton
              size="large"
              onClick={(e) => setNotificationAnchorEl(e.currentTarget)}
              sx={{
                background: "rgba(0,0,0,0.04)",
                "&:hover": { background: "rgba(0,0,0,0.08)" },
              }}
            >
              <Badge badgeContent={notifications.length} color="error">
                <Notifications />
              </Badge>
            </IconButton>

            {/* Notification Popover */}
            <Popover
              open={Boolean(notificationAnchorEl)}
              anchorEl={notificationAnchorEl}
              onClose={handleNotificationClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                sx: {
                  width: 400,
                  maxHeight: 400,
                  overflowY: "auto",
                  mt: 1,
                  boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                },
              }}
            >
              <Box
                sx={{ p: 2, borderBottom: "1px solid", borderColor: "divider" }}
              >
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Notifications
                </Typography>
              </Box>
              {notifications.length === 0 ? (
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <Typography color="text.secondary">
                    No new notifications
                  </Typography>
                </Box>
              ) : (
                <List sx={{ p: 0 }}>
                  {notifications.map((notification) => (
                    <ListItem
                      key={notification.id}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        "&:hover": { bgcolor: "action.hover" },
                      }}
                      button
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <Box sx={{ width: "100%", py: 1 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: 600, mb: 0.5 }}
                        >
                          {notification.type === "invoice_submitted"
                            ? "New Invoice Submitted"
                            : notification.type === "application_submitted"
                            ? "New Tenant Application"
                            : notification.type}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {notification.message}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ mt: 0.5, display: "block" }}
                        >
                          {new Date(
                            notification.createdAt?.seconds * 1000
                          ).toLocaleString()}
                        </Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              )}
            </Popover>

            <Box
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
                padding: "6px 8px",
                borderRadius: 2,
                "&:hover": { bgcolor: "rgba(0,0,0,0.04)" },
              }}
            >
              <Avatar sx={{ bgcolor: "primary.main", width: 36, height: 36 }}>
                {userProfile?.name?.[0] || organization?.name?.[0] || (
                  <AccountCircle />
                )}
              </Avatar>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600, color: "text.primary" }}
                >
                  {userProfile?.name || organization?.name || "User"}
                </Typography>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {userProfile?.role || "User"}
                </Typography>
              </Box>
              <KeyboardArrowDown sx={{ color: "text.secondary" }} />
            </Box>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                mt: 1,
                minWidth: 200,
                boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
              },
            }}
          >
            {[
              ...(isAdminOrOwner
                ? [
                    {
                      label: "Account Settings",
                      onClick: () => {
                        navigate("/dashboard/account");
                        setAnchorEl(null);
                      },
                    },
                    {
                      label: "Billing & Usage",
                      onClick: () => {
                        navigate("/dashboard/billing");
                        setAnchorEl(null);
                      },
                    },
                  ]
                : []),
              {
                label: "Logout",
                onClick: handleLogout,
              },
            ].map((item) => (
              <MenuItem key={item.label} onClick={item.onClick}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRight: "1px solid",
            borderColor: "divider",
            bgcolor: "background.paper",
          },
        }}
      >
        <Box sx={{ p: 2, display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="h6" noWrap>
            TenantIO
          </Typography>
          <IconButton onClick={handleDrawerToggle} sx={{ ml: "auto" }}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />

        <List sx={{ px: 2 }}>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.title}
              onClick={() => navigate(item.path)}
              selected={location.pathname === item.path}
              sx={{
                borderRadius: 2,
                mb: 1,
                "&.Mui-selected": {
                  bgcolor: "primary.lighter",
                  "&:hover": {
                    bgcolor: "primary.lighter",
                  },
                },
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.04)",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === item.path
                      ? "primary.main"
                      : "inherit",
                  minWidth: 40,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  fontSize: "0.875rem",
                  fontWeight: location.pathname === item.path ? 600 : 500,
                  color:
                    location.pathname === item.path
                      ? "primary.main"
                      : "text.primary",
                }}
              />
            </ListItem>
          ))}
        </List>

        <Divider sx={{ mx: 2, mb: 2 }} />

        <List sx={{ px: 2, mt: "auto" }}>
          <ListItem
            button
            onClick={() => navigate("/dashboard/knowledge-base")}
            sx={{
              borderRadius: 2,
              mb: 1,
              "&:hover": {
                bgcolor: "rgba(0,0,0,0.04)",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText
              primary="Knowledge Base"
              primaryTypographyProps={{
                fontSize: "0.875rem",
                fontWeight: 500,
              }}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => setReportIssueDialogOpen(true)}
            sx={{
              borderRadius: 2,
              mb: 1,
              "&:hover": {
                bgcolor: "rgba(0,0,0,0.04)",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText
              primary="Report Issue"
              primaryTypographyProps={{
                fontSize: "0.875rem",
                fontWeight: 500,
              }}
            />
          </ListItem>
        </List>
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "1px solid",
            borderColor: "divider",
            bgcolor: "background.paper",
            height: "100vh",
            position: "fixed",
          },
        }}
        open
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              backgroundClip: "text",
              textFillColor: "transparent",
              letterSpacing: "-0.5px",
            }}
          >
            {organization?.name || "TenantIO"}
          </Typography>
        </Box>

        <Divider sx={{ mx: 2, mb: 2 }} />

        <List sx={{ px: 2 }}>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.title}
              onClick={() => navigate(item.path)}
              selected={location.pathname === item.path}
              sx={{
                borderRadius: 2,
                mb: 1,
                "&.Mui-selected": {
                  bgcolor: "primary.lighter",
                  "&:hover": {
                    bgcolor: "primary.lighter",
                  },
                },
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.04)",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === item.path
                      ? "primary.main"
                      : "inherit",
                  minWidth: 40,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  fontSize: "0.875rem",
                  fontWeight: location.pathname === item.path ? 600 : 500,
                  color:
                    location.pathname === item.path
                      ? "primary.main"
                      : "text.primary",
                }}
              />
            </ListItem>
          ))}
        </List>

        <Divider sx={{ mx: 2, mb: 2 }} />

        <List sx={{ px: 2, mt: "auto" }}>
          <ListItem
            button
            onClick={() => navigate("/dashboard/knowledge-base")}
            sx={{
              borderRadius: 2,
              mb: 1,
              "&:hover": {
                bgcolor: "rgba(0,0,0,0.04)",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText
              primary="Knowledge Base"
              primaryTypographyProps={{
                fontSize: "0.875rem",
                fontWeight: 500,
              }}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => setReportIssueDialogOpen(true)}
            sx={{
              borderRadius: 2,
              mb: 1,
              "&:hover": {
                bgcolor: "rgba(0,0,0,0.04)",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText
              primary="Report Issue"
              primaryTypographyProps={{
                fontSize: "0.875rem",
                fontWeight: 500,
              }}
            />
          </ListItem>
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          width: { xs: "100%", md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          mt: { xs: "48px", sm: "56px" },
        }}
      >
        {success && (
          <Alert
            severity="success"
            sx={{ mb: 3 }}
            onClose={() => setSuccess("")}
          >
            {success}
          </Alert>
        )}
        {children}
      </Box>

      <BillingDialog
        open={billingDialogOpen}
        onClose={() => setBillingDialogOpen(false)}
      />

      <Dialog
        open={reportIssueDialogOpen}
        onClose={() => {
          setReportIssueDialogOpen(false);
          setIssueDescription("");
          setError("");
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            background: "linear-gradient(180deg, #f8fafc 0%, #ffffff 30.21%)",
            boxShadow: "0px 12px 26px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.5rem",
            fontWeight: 600,
            textAlign: "center",
            pb: 1,
            background: "linear-gradient(45deg, #2563eb, #7c3aed)",
            color: "white",
            borderRadius: "16px 16px 0 0",
          }}
        >
          Report an Issue
        </DialogTitle>
        <DialogContent sx={{ mt: 2, px: { xs: 2, sm: 4 } }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Please describe the issue you're experiencing. Our team will look
            into it and get back to you if needed.
          </Typography>
          <TextField
            autoFocus
            multiline
            rows={4}
            fullWidth
            label="Issue Description"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            placeholder="Please provide as much detail as possible..."
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions
          sx={{ px: 3, py: 3, borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}
        >
          <Button
            onClick={() => {
              setReportIssueDialogOpen(false);
              setIssueDescription("");
            }}
            sx={{
              color: "text.secondary",
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.05)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleReportIssue}
            variant="contained"
            disabled={isSubmittingIssue || !issueDescription.trim()}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              boxShadow: "0px 2px 4px rgba(37, 99, 235, 0.2)",
              px: 4,
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            {isSubmittingIssue ? "Submitting..." : "Submit Report"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DashboardLayout;
