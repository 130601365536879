import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  Alert,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import { getFirestore, doc, setDoc, updateDoc } from "firebase/firestore";
import { createStripeCustomer } from "../services/stripe";
import { Link as RouterLink } from "react-router-dom";

const OrganizationSetup = ({ open, onClose, user, onComplete }) => {
  const [formData, setFormData] = useState({
    organizationName: "",
    adminEmail: user?.adminEmail || "",
    name: user?.displayName || "",
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    try {
      if (
        !formData.organizationName.trim() ||
        !formData.adminEmail.trim() ||
        !formData.name.trim()
      ) {
        throw new Error("Please fill in all required fields");
      }

      if (!acceptedPrivacyPolicy) {
        throw new Error(
          "Please accept the Privacy Policy and Terms of Service to continue"
        );
      }

      const db = getFirestore();

      // Create organization ID from organization name
      const orgId =
        formData.organizationName
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "-")
          .replace(/-+/g, "-")
          .replace(/^-|-$/g, "") +
        "-" +
        Math.random().toString(36).substring(2, 7);

      // Create organization document
      const orgRef = doc(db, "organizations", orgId);
      const orgData = {
        id: orgId,
        name: formData.organizationName,
        createdAt: new Date().toISOString(),
        ownerId: user.uid,
        ownerName: formData.name,
        email: formData.adminEmail,
        docuSealTemplateId: null,
        members: [
          {
            userId: user.uid,
            email: formData.adminEmail,
            name: formData.name,
            role: "admin",
            joinedAt: new Date().toISOString(),
          },
        ],
        trial: "active",
        signatureCredits: 0,
      };

      // Create user profile
      const userRef = doc(db, "users", user.uid);
      const userData = {
        name: formData.name,
        email: formData.adminEmail,
        organizationId: orgId,
        role: "admin",
        createdAt: new Date().toISOString(),
      };

      // First create the organization and user documents
      await Promise.all([setDoc(orgRef, orgData), setDoc(userRef, userData)]);

      // Then create Stripe customer and update the organization
      try {
        const { customerId } = await createStripeCustomer(orgId, {
          email: formData.adminEmail,
          name: formData.name,
        });

        // Update the organization with the Stripe customer ID
        await updateDoc(orgRef, {
          stripeCustomerId: customerId,
        });

        orgData.stripeCustomerId = customerId;
      } catch (stripeError) {
        console.error("Error creating Stripe customer:", stripeError);
        // Don't throw here - we want to keep the organization even if Stripe fails
        // The customer can retry Stripe setup later
      }

      // Pass the organization data back to the parent
      onComplete(orgData);
    } catch (error) {
      console.error("Error setting up organization:", error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Set Up Your Organization</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body1" gutterBottom>
              Welcome! Let's set up your organization.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              This information will help us configure your account properly.
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          <TextField
            margin="dense"
            name="name"
            label="Your Full Name"
            type="text"
            fullWidth
            required
            value={formData.name}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />

          <TextField
            margin="dense"
            name="adminEmail"
            label="Admin Email"
            type="email"
            fullWidth
            required
            value={formData.adminEmail}
            onChange={handleInputChange}
            helperText="This email will be used for all administrative communications"
            sx={{ mb: 2 }}
          />

          <TextField
            margin="dense"
            name="organizationName"
            label="Organization Name"
            type="text"
            fullWidth
            required
            value={formData.organizationName}
            onChange={handleInputChange}
            sx={{ mb: 3 }}
          />

          <FormControlLabel
            required
            control={
              <Checkbox
                checked={acceptedPrivacyPolicy}
                onChange={(e) => setAcceptedPrivacyPolicy(e.target.checked)}
              />
            }
            label={
              <Typography variant="body2">
                I agree to the{" "}
                <Link
                  component={RouterLink}
                  to="/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link
                  component={RouterLink}
                  to="/terms-of-service"
                  target="_blank"
                >
                  Terms of Service
                </Link>
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {isSubmitting ? "Setting Up..." : "Create Organization"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OrganizationSetup;
