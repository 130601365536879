import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, onSnapshot } from "firebase/firestore";

const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    let unsubscribeOrg = null;

    const setupOrganizationListener = async () => {
      if (!auth.currentUser) {
        setOrganization(null);
        setIsLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          const organizationId = userDoc.data().organizationId;

          // Set up real-time listener for organization
          unsubscribeOrg = onSnapshot(
            doc(db, "organizations", organizationId),
            (orgDoc) => {
              if (orgDoc.exists()) {
                setOrganization({ id: orgDoc.id, ...orgDoc.data() });
              }
              setIsLoading(false);
            },
            (error) => {
              console.error("Error listening to organization updates:", error);
              setIsLoading(false);
            }
          );
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching organization:", error);
        setIsLoading(false);
      }
    };

    setupOrganizationListener();

    // Cleanup function
    return () => {
      if (unsubscribeOrg) {
        unsubscribeOrg();
      }
    };
  }, [auth.currentUser]);

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        setOrganization,
        isLoading,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error(
      "useOrganization must be used within an OrganizationProvider"
    );
  }
  return context;
};
