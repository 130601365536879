import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Paper,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from "@mui/material";
import { Google as GoogleIcon } from "@mui/icons-material";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useOrganization } from "../contexts/OrganizationContext";
import { Helmet } from "react-helmet-async";

const Login = () => {
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const { setOrganization } = useOrganization();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email.trim(),
        formData.password
      );

      // Check if email is verified
      if (!userCredential.user.emailVerified) {
        await signOut(auth);
        setError(
          "Please verify your email address before logging in. Check your inbox for the verification link."
        );
        setIsSubmitting(false);
        return;
      }

      // First check if user is a tenant
      const tenantQuery = query(
        collection(db, "tenants"),
        where("uid", "==", userCredential.user.uid)
      );
      const tenantSnapshot = await getDocs(tenantQuery);

      console.log("Login attempt - User UID:", userCredential.user.uid);
      console.log("Tenant snapshot empty?", tenantSnapshot.empty);

      if (!tenantSnapshot.empty) {
        // User is a tenant
        const tenantDoc = tenantSnapshot.docs[0];
        const tenantData = tenantDoc.data();

        console.log("Tenant data:", {
          status: tenantData.status,
          onboardingComplete: tenantData.onboardingComplete,
          uid: tenantData.uid,
          organizationId: tenantData.organizationId,
        });

        // Check if tenant is deleted
        if (tenantData.status === "deleted" || tenantData.role === "deleted") {
          setError(
            "Your access has been revoked. Please contact support at support@tenantio.cloud"
          );
          await signOut(auth);
          setIsSubmitting(false);
          return;
        }

        // Check if tenant's application is under review
        if (
          tenantData.status === "pending_approval" ||
          tenantData.status === "pending_onboarding"
        ) {
          console.log("Tenant status check failed:", {
            status: tenantData.status,
            approved: tenantData.approved,
            onboardingComplete: tenantData.onboardingComplete,
          });

          setError(
            "Your application is currently under review. You will receive an email once your application has been approved."
          );
          await signOut(auth);
          setIsSubmitting(false);
          return;
        }

        // Get organization status for all tenant flows
        const orgDoc = await getDoc(
          doc(db, "organizations", tenantData.organizationId)
        );

        console.log("Organization check:", {
          exists: orgDoc.exists(),
          organizationId: tenantData.organizationId,
        });

        if (!orgDoc.exists()) {
          setError("Organization not found. Please contact support.");
          setIsSubmitting(false);
          await signOut(auth);
          return;
        }

        const orgData = orgDoc.data();
        // Check if organization has an active subscription or is in trial
        const isActive = orgData.plan?.status === "active";
        const isInTrial =
          orgData.plan?.status === "trial" &&
          new Date(orgData.plan.trialEndsAt) > new Date();

        console.log("Organization status:", {
          isActive,
          isInTrial,
          planStatus: orgData.plan?.status,
        });

        if (!isActive && !isInTrial) {
          setError(
            "This organization's subscription is inactive. Please contact your landlord to restore access."
          );
          setIsSubmitting(false);
          await signOut(auth);
          return;
        }

        // Handle different tenant statuses
        if (tenantData.status === "active") {
          console.log("Tenant is active, redirecting to dashboard");
          setIsSubmitting(false); // Set this before navigation
          navigate("/tenant/dashboard");
          return;
        } else if (tenantData.status === "application_sent") {
          console.log("Application sent, redirecting to onboarding");
          setIsSubmitting(false); // Set this before navigation
          navigate("/tenant/onboarding");
          return;
        }

        // If we get here, something is wrong with the tenant's status
        console.error("Unexpected tenant status:", tenantData.status);
        setError(
          "Unable to determine your access level. Please contact support."
        );
        await signOut(auth);
        setIsSubmitting(false);
        return;
      }

      // Check if user is a contractor
      const contractorQuery = query(
        collection(db, "contractors"),
        where("uid", "==", userCredential.user.uid)
      );
      const contractorSnapshot = await getDocs(contractorQuery);

      if (!contractorSnapshot.empty) {
        const contractorDoc = contractorSnapshot.docs[0];
        const contractorData = contractorDoc.data();

        // Check if contractor data exists and has required fields
        if (!contractorData || !contractorData.organizationId) {
          setError("Invalid contractor profile. Please contact support.");
          setIsSubmitting(false);
          await signOut(auth);
          return;
        }

        // Check if contractor is deleted
        if (
          contractorData.status === "deleted" ||
          contractorData.role === "deleted"
        ) {
          setError(
            "Your access has been revoked. Please contact support at support@tenantio.cloud"
          );
          await signOut(auth);
          setIsSubmitting(false);
          return;
        }

        try {
          // Get organization status
          const orgDoc = await getDoc(
            doc(db, "organizations", contractorData.organizationId)
          );
          if (!orgDoc.exists()) {
            setError("Organization not found. Please contact support.");
            setIsSubmitting(false);
            await signOut(auth);
            return;
          }

          const orgData = orgDoc.data();
          // Check if organization has an active subscription or is in trial
          const isActive = orgData.plan?.status === "active";
          const isInTrial =
            orgData.plan?.status === "trial" &&
            new Date(orgData.plan.trialEndsAt) > new Date();

          if (!isActive && !isInTrial) {
            setError(
              "This organization's subscription is inactive. Please contact the administrator to restore access."
            );
            setIsSubmitting(false);
            await signOut(auth);
            return;
          }

          // Set organization context for contractor
          setOrganization(contractorData.organizationId);

          // If we get here, the organization is active or in trial
          navigate("/contractor/dashboard");
          return;
        } catch (error) {
          console.error("Error checking contractor organization:", error);
          setError("Error checking organization status. Please try again.");
          setIsSubmitting(false);
          await signOut(auth);
          return;
        }
      }

      // If not a tenant or contractor, check regular user
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));

      if (!userDoc.exists()) {
        setError("User profile not found. Please contact support.");
        await signOut(auth);
        return;
      }

      const userData = userDoc.data();

      // Check if user is deleted or disabled
      if (
        userData.status === "removed" ||
        userData.role === "removed" ||
        userData.isDisabled
      ) {
        setError(
          "Your access has been revoked. Please contact support at support@tenantio.cloud"
        );
        await signOut(auth);
        setIsSubmitting(false);
        return;
      }

      setOrganization(userData.organizationId);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error signing in:", error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setIsSubmitting(true);
      setError("");

      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user already exists
      const userDoc = await getDoc(doc(db, "users", user.uid));

      if (!userDoc.exists()) {
        // New user - redirect to registration
        await signOut(auth);
        navigate("/register");
      } else {
        // Existing user - redirect to dashboard
        const userData = userDoc.data();
        setOrganization(userData.organizationId);
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error with Google sign in:", error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      setIsResetting(true);
      setError("");

      await sendPasswordResetEmail(auth, resetEmail);
      setResetSuccess(true);
    } catch (error) {
      console.error("Error resetting password:", error);
      setError(error.message);
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | TenantIO - Property Management Software</title>
        <meta
          name="description"
          content="Sign in to TenantIO's property management platform. Streamline rent collection, maintenance, and tenant communication."
        />
      </Helmet>

      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          background: "linear-gradient(135deg, #f6f9fc 0%, #ecf0f5 100%)",
          position: "relative",
          overflow: { xs: "hidden", md: "hidden" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -100,
            right: -100,
            width: { xs: 300, md: 400 },
            height: { xs: 300, md: 400 },
            borderRadius: "50%",
            background: "linear-gradient(45deg, #2563eb33, #7c3aed33)",
            filter: "blur(80px)",
            display: { xs: "none", md: "block" },
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: -100,
            left: -100,
            width: { xs: 300, md: 400 },
            height: { xs: 300, md: 400 },
            borderRadius: "50%",
            background: "linear-gradient(45deg, #7c3aed33, #2563eb33)",
            filter: "blur(80px)",
            display: { xs: "none", md: "block" },
          }}
        />

        <Container
          maxWidth="sm"
          sx={{
            py: { xs: 4, md: 8 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            elevation={24}
            sx={{
              p: 4,
              width: "100%",
              borderRadius: 3,
              bgcolor: "rgba(255, 255, 255, 0.9)",
              backdropFilter: "blur(20px)",
            }}
          >
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <Box
                component="img"
                src="/logo.png"
                alt="TenantIO Logo"
                sx={{
                  width: 180,
                  height: "auto",
                  mb: 3,
                  mx: "auto",
                  display: "block",
                }}
              />
              <Typography
                component="h1"
                variant="h4"
                sx={{
                  fontWeight: 700,
                  background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                  backgroundClip: "text",
                  color: "transparent",
                  mb: 1,
                }}
              >
                Welcome Back
              </Typography>
              <Typography color="text.secondary" variant="body2">
                Sign in to manage your properties
              </Typography>
            </Box>

            {error && (
              <Alert
                severity="error"
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  "& .MuiAlert-message": {
                    width: "100%",
                  },
                }}
              >
                {error}
              </Alert>
            )}

            <Box component="form" onSubmit={handleLogin}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleInputChange}
              />

              <Box sx={{ mt: 1, textAlign: "right" }}>
                <Button
                  onClick={() => setResetPasswordDialog(true)}
                  sx={{ textTransform: "none" }}
                >
                  Forgot Password?
                </Button>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  mt: 3,
                  mb: 2,
                  height: 48,
                  background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                  "&:hover": {
                    background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                  },
                }}
              >
                {isSubmitting ? "Signing In..." : "Sign In"}
              </Button>

              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Don't have an account?{" "}
                  <Link component={RouterLink} to="/register">
                    Create one
                  </Link>
                </Typography>
              </Box>

              <Box sx={{ my: 2, textAlign: "center" }}>
                <Typography variant="body2" color="text.secondary">
                  OR
                </Typography>
              </Box>

              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleLogin}
                sx={{
                  height: 48,
                  borderColor: "divider",
                  "&:hover": {
                    borderColor: "primary.main",
                    background: "rgba(37, 99, 235, 0.04)",
                  },
                }}
              >
                Continue with Google
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>

      <Dialog
        open={resetPasswordDialog}
        onClose={() => {
          setResetPasswordDialog(false);
          setResetSuccess(false);
          setResetEmail("");
          setError("");
          setIsResetting(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {resetSuccess ? "Check Your Email" : "Reset Password"}
        </DialogTitle>
        <DialogContent>
          {resetSuccess ? (
            <Typography>
              We've sent password reset instructions to your email address.
              Please check your inbox.
            </Typography>
          ) : (
            <>
              <Typography sx={{ mb: 2 }}>
                Enter your email address and we'll send you instructions to
                reset your password.
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                error={!!error}
                helperText={error}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setResetPasswordDialog(false);
              setResetSuccess(false);
              setResetEmail("");
              setError("");
              setIsResetting(false);
            }}
          >
            {resetSuccess ? "Close" : "Cancel"}
          </Button>
          {!resetSuccess && (
            <Button
              onClick={handleResetPassword}
              variant="contained"
              disabled={isResetting}
            >
              {isResetting ? "Sending..." : "Reset Password"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Login;
