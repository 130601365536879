import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  Stack,
} from "@mui/material";
import { useOrganization } from "../../contexts/OrganizationContext";
import { useAuth } from "../../contexts/AuthContext";
import PricingPlans from "../PricingPlans";

const AccessRestricted = () => {
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const { user } = useAuth();
  const [showPlans, setShowPlans] = React.useState(false);

  const isAdminOrOwner =
    user?.email === organization?.ownerEmail || user?.isAdmin;

  const getRestrictionReason = () => {
    if (organization?.subscription?.status === "cancelled") {
      return {
        title: "Subscription Cancelled",
        message: `Your subscription was cancelled and access expired on ${new Date(
          organization?.subscription?.deactivationDate
        ).toLocaleDateString()}.`,
        subMessage: isAdminOrOwner
          ? "To restore access, please renew your subscription."
          : "Please contact your organization administrator to restore access.",
      };
    }

    if (organization?.trial === "ended") {
      return {
        title: "Trial Period Ended",
        message: "Your free trial period has ended.",
        subMessage: isAdminOrOwner
          ? "To continue using all features, please select a subscription plan."
          : "Please contact your organization administrator to activate a subscription.",
      };
    }

    return {
      title: "Access Restricted",
      message: "Your access to this organization has been restricted.",
      subMessage:
        "Please contact your organization administrator for assistance.",
    };
  };

  const restrictionInfo = getRestrictionReason();

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 4,
        }}
      >
        <Card sx={{ width: "100%", textAlign: "center" }}>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h4" component="h1" gutterBottom>
                {restrictionInfo.title}
              </Typography>

              <Typography variant="h6" color="text.secondary">
                {restrictionInfo.message}
              </Typography>

              <Typography variant="body1" color="text.secondary">
                {restrictionInfo.subMessage}
              </Typography>

              {isAdminOrOwner ? (
                <Box sx={{ mt: 4 }}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => setShowPlans(true)}
                    sx={{
                      background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                      "&:hover": {
                        background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                      },
                    }}
                  >
                    View Subscription Plans
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => navigate("/dashboard")}
                  sx={{ mt: 4 }}
                >
                  Return to Dashboard
                </Button>
              )}
            </Stack>
          </CardContent>
        </Card>

        {showPlans && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: "rgba(0,0,0,0.5)",
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setShowPlans(false)}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: 2,
                p: 4,
                maxWidth: "1200px",
                width: "90%",
                maxHeight: "90vh",
                overflow: "auto",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <PricingPlans onClose={() => setShowPlans(false)} />
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AccessRestricted;
