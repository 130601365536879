import { loadStripe } from "@stripe/stripe-js";
import { getFirestore, updateDoc, doc } from "firebase/firestore";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

// Use the environment variable for the API URL
const API_URL = import.meta.env.VITE_BACKEND_URL;

console.log("Stripe API URL:", API_URL);

// Secure API client utility
const secureApiCall = async (endpoint, options = {}) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-API-Key": import.meta.env.VITE_API_KEY,
    // Add CSRF token if you implement it later
  };

  const response = await fetch(`${API_URL}${endpoint}`, {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  });

  const textResponse = await response.text();
  let data;

  try {
    data = JSON.parse(textResponse);
  } catch (e) {
    console.error("Failed to parse response as JSON:", textResponse);
    throw new Error(textResponse || "Invalid server response");
  }

  if (!response.ok) {
    let errorMessage = "Unknown error occurred";
    if (data.error) {
      errorMessage =
        typeof data.error === "object"
          ? data.error.message || JSON.stringify(data.error)
          : data.error;
    }
    console.error("Server error details:", data);
    throw new Error(errorMessage);
  }

  return data;
};

const PRICE_IDS = {
  ENTRY: {
    monthly: "price_1QjOlFP4UrRjtMjoH5EC3yl7",
    annual: "price_1QjOm6P4UrRjtMjoEhLwhwxs",
  },
  GROWTH: {
    monthly: "price_1QjOn9P4UrRjtMjoK2BTEfLh",
    annual: "price_1QjOnrP4UrRjtMjox5j3fuS5",
  },
  PROFESSIONAL: {
    monthly: "price_1QjOpYP4UrRjtMjo3VOC7MiF",
    annual: "price_1QjOp9P4UrRjtMjoDI2OsIxS",
  },
};

export const SUBSCRIPTION_PLANS = [
  {
    name: "Starter",
    monthlyPrice: 9.99,
    annualPrice: 8.49 * 12, // £8.49/mo billed annually
    features: [
      "Up to 5 properties",
      "Single user",
      "Essential tenant management",
      "Email support",
    ],
    priceIds: PRICE_IDS.ENTRY,
  },
  {
    name: "Professional",
    monthlyPrice: 14.99,
    annualPrice: 12.74 * 12, // £12.74/mo billed annually
    features: [
      "Up to 20 properties",
      "3 team members",
      "Advanced tenant management",
      "Analytics dashboard",
      "Priority support",
    ],
    priceIds: PRICE_IDS.GROWTH,
  },
  {
    name: "Enterprise",
    monthlyPrice: 34.99,
    annualPrice: 29.74 * 12, // £29.74/mo billed annually
    features: [
      "Unlimited properties",
      "Unlimited team members",
      "Full management suite",
      "API access",
      "Dedicated support",
    ],
    priceIds: PRICE_IDS.PROFESSIONAL,
  },
];

export const createStripeCustomer = async (
  organizationId,
  organizationData
) => {
  if (!organizationData.email) {
    throw new Error("Missing required field: email is required");
  }

  try {
    console.log("Creating Stripe customer with data:", {
      email: organizationData.email,
      name: organizationData.name || organizationData.ownerName,
      organizationId,
    });

    const data = await secureApiCall("/api/stripe/create-customer", {
      method: "POST",
      body: JSON.stringify({
        name: organizationData.name || organizationData.ownerName,
        email: organizationData.email,
        organizationId,
      }),
    });

    // Update the organization with the Stripe customer ID
    const db = getFirestore();
    await updateDoc(doc(db, "organizations", organizationId), {
      stripeCustomerId: data.customerId,
      updatedAt: new Date().toISOString(),
    });

    console.log("Stripe customer created successfully:", data);
    return data;
  } catch (error) {
    console.error("Error creating Stripe customer:", error);
    throw error;
  }
};

export const createSubscriptionSession = async (
  priceId,
  customerId,
  existingSubscriptionId = null
) => {
  try {
    console.log("Creating subscription session for:", {
      priceId,
      customerId,
      existingSubscriptionId,
    });

    const response = await fetch(
      `${API_URL}/api/stripe/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": import.meta.env.VITE_API_KEY,
        },
        body: JSON.stringify({
          priceId,
          customerId,
          existingSubscriptionId,
        }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.error?.message || "Failed to create checkout session"
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating subscription session:", error);
    throw error;
  }
};

export const createPortalSession = async (organization, returnUrl) => {
  try {
    if (!organization.stripeCustomerId) {
      throw new Error("No Stripe customer ID found for this organization");
    }

    const data = await secureApiCall("/stripe/create-portal-session", {
      method: "POST",
      body: JSON.stringify({
        customerId: organization.stripeCustomerId,
        returnUrl,
      }),
    });

    return data;
  } catch (error) {
    console.error("Error creating portal session:", error);
    throw error;
  }
};

export const createCreditsPurchaseSession = async (
  priceId,
  customerId,
  credits,
  organizationId
) => {
  try {
    console.log("Creating credits purchase session:", {
      priceId,
      customerId,
      credits,
      organizationId,
    });

    const data = await secureApiCall("/stripe/create-credits-session", {
      method: "POST",
      body: JSON.stringify({
        priceId,
        customerId,
        credits,
        organizationId,
      }),
    });

    return data;
  } catch (error) {
    console.error("Error creating credits purchase session:", error);
    throw error;
  }
};

export const getStripeInstance = () => stripePromise;

export const createCheckoutSession = async (priceId, customerId, returnUrl) => {
  try {
    const response = await fetch(
      `${API_URL}/api/stripe/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": import.meta.env.VITE_API_KEY,
        },
        body: JSON.stringify({
          priceId,
          customerId,
          returnUrl,
        }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.error?.message || "Failed to create checkout session"
      );
    }

    const { sessionId } = await response.json();
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

export const createCustomer = async (organizationId, { email, name }) => {
  try {
    console.log("Creating Stripe customer:", {
      email: "***@***.***",
      name,
      organizationId,
    });

    const response = await fetch(`${API_URL}/api/stripe/create-customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": import.meta.env.VITE_API_KEY,
      },
      body: JSON.stringify({
        email,
        name,
        organizationId,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error?.message || "Failed to create customer");
    }

    const data = await response.json();
    console.log("Successfully created Stripe customer:", data.customerId);
    return data;
  } catch (error) {
    console.error("Error creating customer:", error);
    throw error;
  }
};
