const baseTemplate = (content) => `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>TenantIO Email</title>
  <style>
    body {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
      line-height: 1.6;
      color: #111827;
      margin: 0;
      padding: 0;
      background-color: #f8fafc;
    }
    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .header {
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px solid #e5e7eb;
    }
    .logo {
      font-size: 24px;
      font-weight: bold;
      color: #2563eb;
      text-decoration: none;
    }
    .content {
      padding: 30px 20px;
    }
    .button {
      display: inline-block;
      padding: 12px 24px;
      background-color: #ffffff;
      color: #000000;
      text-decoration: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;
      margin: 20px 0;
      border: 1px solid #e5e7eb;
    }
    .footer {
      text-align: center;
      padding: 20px;
      color: #4b5563;
      font-size: 14px;
      border-top: 1px solid #e5e7eb;
    }
    .help-text {
      font-size: 14px;
      color: #4b5563;
      margin-top: 20px;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="header">
      <div class="logo">TenantIO</div>
    </div>
    ${content}
    <div class="footer">
      <p>© ${new Date().getFullYear()} TenantIO. All rights reserved.</p>
      <p>If you didn't request this email, please ignore it or contact support.</p>
    </div>
  </div>
</body>
</html>
`;

export const emailTemplates = {
  passwordReset: (resetLink) => {
    const content = `
      <div class="content">
        <h2 style="margin-top: 0; color: #1f2937;">Reset Your Password</h2>
        <p>Hello,</p>
        <p>We received a request to reset your password for your TenantIO account. Click the button below to set a new password:</p>
        
        <div style="text-align: center;">
          <a href="${resetLink}" class="button">Reset Password</a>
        </div>
        
        <p class="help-text">This password reset link will expire in 1 hour for security reasons.</p>
        
        <p class="help-text">If you can't click the button, copy and paste this link into your browser:</p>
        <p style="font-size: 14px; word-break: break-all; color: #4b5563;">${resetLink}</p>
        
        <p style="margin-top: 30px;">Best regards,<br>The TenantIO Team</p>
      </div>
    `;

    return {
      subject: "Reset Your TenantIO Password",
      html: baseTemplate(content),
      text: `
Reset Your TenantIO Password

Hello,

We received a request to reset your password for your TenantIO account. Click the link below to set a new password:

${resetLink}

This password reset link will expire in 1 hour for security reasons.

Best regards,
The TenantIO Team
      `.trim(),
    };
  },
  newJobNotification: (contractorName, jobDetails, loginLink) => {
    const content = `
      <div class="content">
        <h2 style="margin-top: 0; color: #1f2937;">New Job Available</h2>
        <p>Hello ${contractorName},</p>
        <p>You have a new maintenance request waiting for your review:</p>
        
        <div style="background-color: #f8fafc; padding: 20px; border-radius: 8px; margin: 20px 0;">
          <p style="margin: 0; font-weight: 500;">Job Details:</p>
          <ul style="margin: 10px 0; padding-left: 20px;">
            <li>Property: ${jobDetails.propertyName}</li>
            <li>Issue Type: ${jobDetails.issueType}</li>
            <li>Priority: ${jobDetails.priority}</li>
            <li>Description: ${jobDetails.description}</li>
          </ul>
        </div>
        
        <p>Please log in to your contractor portal to review and respond to this request:</p>
        
        <div style="text-align: center;">
          <a href="${loginLink}" class="button">View Job Details</a>
        </div>
        
        <p class="help-text">Quick response helps us provide better service to our tenants.</p>
        
        <p style="margin-top: 30px;">Best regards,<br>The TenantIO Team</p>
      </div>
    `;

    return {
      subject: "New Maintenance Job Available - TenantIO",
      html: baseTemplate(content),
      text: `
New Maintenance Job Available - TenantIO

Hello ${contractorName},

You have a new maintenance request waiting for your review:

Job Details:
- Property: ${jobDetails.propertyName}
- Issue Type: ${jobDetails.issueType}
- Priority: ${jobDetails.priority}
- Description: ${jobDetails.description}

Please log in to review and respond to this request:
${loginLink}

Quick response helps us provide better service to our tenants.

Best regards,
The TenantIO Team
      `.trim(),
    };
  },
  issueReport: (userName, userEmail, issueDetails, organizationId) => {
    const content = `
      <div class="content">
        <h2 style="margin-top: 0; color: #1f2937;">New Issue Report</h2>
        <p>A user has reported an issue in the platform:</p>
        
        <div style="background-color: #f8fafc; padding: 20px; border-radius: 8px; margin: 20px 0;">
          <p style="margin: 0; font-weight: 500;">Reporter Details:</p>
          <ul style="margin: 10px 0; padding-left: 20px;">
            <li>Name: ${userName}</li>
            <li>Email: ${userEmail}</li>
            <li>Organization ID: ${organizationId}</li>
          </ul>
          
          <p style="margin: 10px 0; font-weight: 500;">Issue Details:</p>
          <div style="background-color: #ffffff; padding: 15px; border-radius: 6px; margin-top: 10px;">
            ${issueDetails.description}
          </div>
        </div>
        
        <p style="margin-top: 30px;">Best regards,<br>TenantIO Platform</p>
      </div>
    `;

    return {
      subject: "New Issue Report - TenantIO Platform",
      html: baseTemplate(content),
      text: `
New Issue Report - TenantIO Platform

A user has reported an issue in the platform:

Reporter Details:
- Name: ${userName}
- Email: ${userEmail}
- Organization ID: ${organizationId}

Issue Details:
${issueDetails.description}

Best regards,
TenantIO Platform
      `.trim(),
    };
  },
  welcomeEmail: (name, verificationLink) => {
    const content = `
      <div class="content">
        <h2 style="margin-top: 0; color: #1f2937;">Welcome to TenantIO! 🎉</h2>
        <p>Hello ${name},</p>
        <p>Thank you for joining TenantIO! We're excited to have you on board.</p>
        
        <div style="background-color: #f8fafc; padding: 20px; border-radius: 8px; margin: 20px 0;">
          <p style="margin: 0; font-weight: 500;">Getting Started:</p>
          <ul style="margin: 10px 0; padding-left: 20px;">
            <li>Please verify your email by clicking the button below</li>
            <li>Check out our <a href="https://www.tenantio.org/how-to-guides" style="color: #2563eb;">How-to Guides</a> to make the most of your account</li>
            <li>Set up your profile and preferences</li>
          </ul>
        </div>
        
        <div style="text-align: center;">
          <a href="${verificationLink}" class="button">Verify Email</a>
        </div>
        
        <p class="help-text">This verification link will expire in 1 hour for security reasons.</p>
        
        <p class="help-text">If you can't click the button, copy and paste this link into your browser:</p>
        <p style="font-size: 14px; word-break: break-all; color: #4b5563;">${verificationLink}</p>
        
        <p style="margin-top: 30px;">Best regards,<br>The TenantIO Team</p>
      </div>
    `;

    return {
      subject: "Welcome to TenantIO! 🏠",
      html: baseTemplate(content),
      text: `
Welcome to TenantIO! 🏠

Hello ${name},

Thank you for joining TenantIO! We're excited to have you on board.

Getting Started:
- Please verify your email using this link: ${verificationLink}
- Check out our How-to Guides: https://www.tenantio.org/how-to-guides
- Set up your profile and preferences

Best regards,
The TenantIO Team
      `.trim(),
    };
  },
};
