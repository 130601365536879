import React, { useState, useEffect, Fragment, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Chip,
  IconButton,
  Card,
  CardContent,
  Avatar,
  Grid,
  Tabs,
  Tab,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  ListItemAvatar,
  ListItemSecondaryAction,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Person as PersonIcon,
  Assignment as AssignmentIcon,
  Business as BusinessIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import DataList from "../common/DataList";
import FileUpload from "../common/FileUpload";
import {
  getAuth,
  deleteUser,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import { checkResourceLimit } from "../../utils/planLimits";

const statusOptions = [
  { value: "active", label: "Active", color: "#22c55e" },
  { value: "inactive", label: "Applicant", color: "#7c3aed" },
];

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const [properties, setProperties] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editingTenant, setEditingTenant] = useState(null);
  const [showSubmittedOnly, setShowSubmittedOnly] = useState(false);
  const [newTenant, setNewTenant] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    propertyId: "",
    unit: "",
    status: "active",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [success, setSuccess] = useState("");
  const [viewMode, setViewMode] = useState("tenants");
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");

  const navigate = useNavigate();
  const { organization } = useOrganization();
  const db = getFirestore();

  useEffect(() => {
    if (organization?.id) {
      fetchProperties();
      fetchTenants();
    }
  }, [organization, viewMode]);

  const fetchProperties = async () => {
    try {
      const q = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const querySnapshot = await getDocs(q);
      const propertiesList = [];
      querySnapshot.forEach((doc) => {
        propertiesList.push({ id: doc.id, ...doc.data() });
      });
      setProperties(propertiesList);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const fetchTenants = async () => {
    try {
      let q;
      console.log("Fetching tenants with viewMode:", viewMode);
      if (viewMode === "tenants") {
        q = query(
          collection(db, "tenants"),
          where("organizationId", "==", organization.id),
          where("status", "==", "active")
        );
        console.log(
          "Querying for active tenants with organizationId:",
          organization.id
        );
      } else if (viewMode === "inactive") {
        q = query(
          collection(db, "tenants"),
          where("organizationId", "==", organization.id),
          where("status", "==", "inactive")
        );
      } else {
        q = query(
          collection(db, "tenants"),
          where("organizationId", "==", organization.id),
          where("status", "in", [
            "pending",
            "pending_approval",
            "rejected",
            "application_sent",
            "pending_onboarding",
            "pending_review",
          ])
        );
      }

      const querySnapshot = await getDocs(q);
      console.log("Query snapshot size:", querySnapshot.size);
      const tenantsList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        console.log("Tenant document data:", { id: doc.id, ...data });
        if (!data.isDisabled) {
          tenantsList.push({ id: doc.id, ...data });
        }
      });

      console.log("Final filtered tenants list:", tenantsList);

      if (viewMode === "applications") {
        tenantsList.sort((a, b) => {
          const dateA = a.applicationSubmittedAt || a.createdAt;
          const dateB = b.applicationSubmittedAt || b.createdAt;
          return new Date(dateB) - new Date(dateA);
        });
      }

      setTenants(tenantsList);
    } catch (error) {
      console.error("Error fetching tenants:", error);
      setError("Failed to load tenants");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setError("");
    setNewTenant({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      propertyId: "",
      unit: "",
    });
  };

  const handleAddTenant = async () => {
    try {
      // Check resource limit
      const limitCheck = await checkResourceLimit(
        organization.id,
        "tenants",
        organization.plan
      );
      if (!limitCheck.allowed) {
        setError(limitCheck.message);
        return;
      }

      if (
        !newTenant.firstName ||
        !newTenant.lastName ||
        !newTenant.email ||
        !newTenant.propertyId ||
        !newTenant.unit
      ) {
        setError("Please fill in all required fields");
        return;
      }

      setSubmitting(true);

      // Create tenant user through backend API
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/auth/create-tenant-user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": import.meta.env.VITE_API_KEY,
          },
          body: JSON.stringify({
            email: newTenant.email,
            organizationId: organization.id,
            sendPasswordReset: true,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create tenant user");
      }

      const { uid } = await response.json();

      const propertyDetails = properties.find(
        (p) => p.id === newTenant.propertyId
      );

      // Create tenant document with uid as the document ID
      const tenantRef = doc(db, "tenants", uid);
      await setDoc(tenantRef, {
        uid: uid,
        firstName: newTenant.firstName,
        lastName: newTenant.lastName,
        email: newTenant.email,
        phone: newTenant.phone,
        propertyId: newTenant.propertyId,
        propertyName: propertyDetails?.name || "",
        unit: newTenant.unit,
        organizationId: organization.id,
        status: "application_sent",
        onboardingComplete: false,
        createdAt: new Date().toISOString(),
      });

      // Create user document
      await setDoc(doc(db, "users", uid), {
        firstName: newTenant.firstName,
        lastName: newTenant.lastName,
        email: newTenant.email,
        role: "tenant",
        organizationId: organization.id,
        createdAt: new Date().toISOString(),
        type: "tenant",
      });

      setOpenDialog(false);
      setSuccessMessage(
        `Tenant added successfully! A password reset email has been sent to ${newTenant.email}.`
      );
      setViewMode("applications");
      fetchTenants();
    } catch (error) {
      console.error("Error adding tenant:", error);
      setError(error.message || "Error adding tenant");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteTenant = async (tenant) => {
    setTenantToDelete(tenant);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      setSubmitting(true);

      // Update tenant document to mark as deleted
      await updateDoc(doc(db, "tenants", tenantToDelete.id), {
        status: "deleted",
        isDisabled: true,
        deletedAt: new Date().toISOString(),
        deletedBy: organization.id,
      });

      // Update user document to mark as deleted (using the tenant's uid)
      if (tenantToDelete.uid) {
        await updateDoc(doc(db, "users", tenantToDelete.uid), {
          status: "deleted",
          role: "deleted",
          isDisabled: true,
          deletedAt: new Date().toISOString(),
          deletedBy: organization.id,
        });

        // Update the user's auth claims to revoke access
        try {
          await fetch(
            `${import.meta.env.VITE_BACKEND_URL}/api/auth/disable-user`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-api-key": import.meta.env.VITE_API_KEY,
              },
              body: JSON.stringify({
                uid: tenantToDelete.uid,
                organizationId: organization.id,
              }),
            }
          );
        } catch (error) {
          console.error("Error disabling user auth:", error);
        }
      }

      setSuccessMessage("Tenant access revoked successfully");
      setDeleteDialogOpen(false);
      setTenantToDelete(null);
      fetchTenants();
    } catch (error) {
      console.error("Error deleting tenant:", error);
      setError("Failed to revoke tenant access. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleViewTenant = async (tenant) => {
    try {
      const tenantDoc = await getDoc(doc(db, "tenants", tenant.id));
      setSelectedTenant({ id: tenantDoc.id, ...tenantDoc.data() });
      setViewDialogOpen(true);
    } catch (error) {
      console.error("Error fetching tenant details:", error);
    }
  };

  const handleEdit = (tenant) => {
    setEditingTenant(tenant);
    setOpenDialog(true);
  };

  const handleUpdateTenant = async () => {
    try {
      if (!editingTenant.unit) {
        setError("Unit is required");
        return;
      }

      const tenantRef = doc(db, "tenants", editingTenant.id);
      const propertyDetails = properties.find(
        (p) => p.id === editingTenant.propertyId
      );

      await updateDoc(tenantRef, {
        firstName: editingTenant.firstName,
        lastName: editingTenant.lastName,
        email: editingTenant.email,
        phone: editingTenant.phone,
        propertyId: editingTenant.propertyId,
        propertyName: propertyDetails?.name || "",
        unit: editingTenant.unit,
        updatedAt: new Date().toISOString(),
      });

      setOpenDialog(false);
      setEditingTenant(null);
      fetchTenants();
    } catch (error) {
      console.error("Error updating tenant:", error);
      setError(error.message || "Error updating tenant");
    }
  };

  const handlePropertyChange = (propertyId) => {
    const selectedProperty = properties.find((p) => p.id === propertyId);
    if (selectedProperty) {
      if (editingTenant) {
        setEditingTenant({
          ...editingTenant,
          propertyId,
          propertyName: selectedProperty.name,
          unit: "", // Reset unit when property changes
        });
      } else {
        setNewTenant({
          ...newTenant,
          propertyId,
          propertyName: selectedProperty.name,
          unit: "", // Reset unit when property changes
        });
      }
    }
  };

  const getAvailableUnits = (propertyId) => {
    const property = properties.find((p) => p.id === propertyId);
    return property?.units || [];
  };

  const handlePasswordReset = async (email) => {
    try {
      setSubmitting(true);
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email, {
        url: `${window.location.origin}/login`,
        handleCodeInApp: true,
      });
      setSuccessMessage(`Password reset email sent to ${email}`);
    } catch (error) {
      console.error("Error sending password reset:", error);
      setError("Failed to send password reset email");
    } finally {
      setSubmitting(false);
    }
  };

  const handleUploadComplete = async (uploadedFiles) => {
    try {
      // Update tenant document with new files
      const tenantRef = doc(db, "tenants", selectedTenant.id);
      const documents = selectedTenant.documents || [];

      const newDocuments = uploadedFiles.map((file) => ({
        ...file,
        type: documentType,
        description: documentDescription,
        uploadedAt: new Date().toISOString(),
      }));

      await updateDoc(tenantRef, {
        documents: [...documents, ...newDocuments],
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "document_added",
        message: `Documents uploaded for tenant ${selectedTenant.firstName} ${selectedTenant.lastName}`,
        entityId: selectedTenant.id,
        entityType: "tenant",
        organizationId: organization.id,
        timestamp: new Date(),
        status: "completed",
      });

      setSuccessMessage("Documents uploaded successfully");
      setUploadDialogOpen(false);
      handleViewTenant(selectedTenant); // Refresh tenant details
    } catch (error) {
      console.error("Error updating tenant documents:", error);
      setError("Failed to update tenant documents");
    }
  };

  const handleDeleteDocument = async (documentToDelete) => {
    try {
      const tenantRef = doc(db, "tenants", selectedTenant.id);
      const updatedDocuments = selectedTenant.documents.filter(
        (doc) => doc.url !== documentToDelete.url
      );

      await updateDoc(tenantRef, {
        documents: updatedDocuments,
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "document_deleted",
        message: `Document deleted for tenant ${selectedTenant.firstName} ${selectedTenant.lastName}`,
        entityId: selectedTenant.id,
        entityType: "tenant",
        organizationId: organization.id,
        timestamp: new Date(),
        status: "completed",
      });

      setSuccessMessage("Document deleted successfully");
      handleViewTenant(selectedTenant); // Refresh tenant details
    } catch (error) {
      console.error("Error deleting document:", error);
      setError("Failed to delete document");
    }
  };

  const handleReactivateTenant = async (tenant) => {
    try {
      setSubmitting(true);
      const tenantRef = doc(db, "tenants", tenant.id);

      // Reactivate tenant document
      await updateDoc(tenantRef, {
        status: "active",
        isDisabled: false,
        reactivatedAt: new Date().toISOString(),
      });

      // Reactivate user account if it exists
      if (tenant.uid) {
        await updateDoc(doc(db, "users", tenant.uid), {
          status: "active",
          role: "tenant",
          isDisabled: false,
          reactivatedAt: new Date().toISOString(),
        });

        // Reactivate user auth
        try {
          await fetch(
            `${import.meta.env.VITE_BACKEND_URL}/api/auth/enable-user`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-api-key": import.meta.env.VITE_API_KEY,
              },
              body: JSON.stringify({
                uid: tenant.uid,
                organizationId: organization.id,
              }),
            }
          );
        } catch (error) {
          console.error("Error enabling user auth:", error);
        }
      }

      setSuccessMessage("Tenant reactivated successfully");
      fetchTenants();
    } catch (error) {
      console.error("Error reactivating tenant:", error);
      setError("Failed to reactivate tenant. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const filteredTenants = useMemo(() => {
    return tenants.filter((tenant) => {
      if (viewMode === "applications") {
        if (showSubmittedOnly) {
          return (
            tenant.status === "pending_approval" ||
            tenant.onboardingComplete ||
            tenant.status === "pending_onboarding" ||
            tenant.status === "pending_review"
          );
        }
        return (
          tenant.status !== "active" &&
          tenant.status !== "deleted" &&
          tenant.status !== "inactive" &&
          !tenant.isDisabled
        );
      } else if (viewMode === "inactive") {
        return tenant.status === "inactive" && !tenant.isDisabled;
      }
      return tenant.status === "active" && !tenant.isDisabled;
    });
  }, [tenants, viewMode, showSubmittedOnly]);

  const renderTenantDetails = () => {
    if (!selectedTenant) return null;

    return (
      <Dialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            background: "linear-gradient(180deg, #f0f7ff 0%, #FFFFFF 30.21%)",
            borderRadius: "16px",
            boxShadow: `0px 187px 75px rgba(0, 0, 0, 0.01),
              0px 105px 63px rgba(0, 0, 0, 0.05),
              0px 47px 47px rgba(0, 0, 0, 0.09),
              0px 12px 26px rgba(0, 0, 0, 0.1),
              0px 0px 0px rgba(0, 0, 0, 0.1)`,
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
            pb: 2,
            background: "linear-gradient(90deg, #2563eb 0%, #7c3aed 100%)",
            color: "white",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              sx={{
                bgcolor: "white",
                color: "#2563eb",
                width: 56,
                height: 56,
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              {selectedTenant?.firstName?.[0]}
            </Avatar>
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 0.5 }}>
                {selectedTenant?.firstName} {selectedTenant?.lastName}
              </Typography>
              {selectedTenant?.propertyName && (
                <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
                  {selectedTenant.propertyName} - Unit {selectedTenant.unit}
                </Typography>
              )}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            sx={{
              mb: 4,
              borderBottom: "1px solid",
              borderColor: "divider",
              "& .MuiTabs-indicator": {
                backgroundColor: "#2563eb",
                height: 3,
              },
              "& .MuiTab-root": {
                fontWeight: 600,
                fontSize: "0.95rem",
                textTransform: "none",
                minWidth: 100,
                "&.Mui-selected": {
                  color: "#2563eb",
                },
              },
            }}
          >
            <Tab label="Overview" />
            <Tab label="Application" />
            <Tab label="Documents" />
          </Tabs>

          {selectedTab === 0 && (
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    bgcolor: "rgba(37, 99, 235, 0.05)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "rgba(37, 99, 235, 0.1)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#2563eb",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box component="span" sx={{ fontSize: "1.2rem" }}>
                      📞
                    </Box>
                    Contact Information
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Email Address
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {selectedTenant?.email}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Phone Number
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {selectedTenant?.phone || "Not provided"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    bgcolor: "rgba(124, 58, 237, 0.05)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "rgba(124, 58, 237, 0.1)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#7c3aed",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box component="span" sx={{ fontSize: "1.2rem" }}>
                      🏠
                    </Box>
                    Property Details
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Property
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {selectedTenant?.propertyName || "Not assigned"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Unit Number
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {selectedTenant?.unit || "Not assigned"}
                      </Typography>
                    </Box>
                    {selectedTenant?.rentAmount && (
                      <Box>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          gutterBottom
                        >
                          Monthly Rent
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 600,
                            color: "success.main",
                          }}
                        >
                          £{selectedTenant.rentAmount.toLocaleString()}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}

          {selectedTab === 1 && (
            <Grid container spacing={4}>
              {!selectedTenant?.personalInfo ||
              !selectedTenant?.onboardingComplete ||
              selectedTenant?.status !== "pending_approval" ? (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 4,
                      textAlign: "center",
                      bgcolor: "rgba(37, 99, 235, 0.02)",
                      borderRadius: 2,
                      border: "1px solid",
                      borderColor: "rgba(37, 99, 235, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      gutterBottom
                    >
                      No Application Data Available
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {selectedTenant?.status === "active"
                        ? "This tenant was added manually and has not completed an application."
                        : "The tenant has not submitted their application yet."}
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <>
                  {selectedTenant?.status === "pending_approval" && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          p: 3,
                          bgcolor: "rgba(234, 179, 8, 0.1)",
                          borderRadius: 2,
                          border: "1px solid",
                          borderColor: "rgba(234, 179, 8, 0.2)",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ color: "#854d0e", mb: 1 }}
                            >
                              Application Review Required
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              This application is pending your review. Please
                              review the details and make a decision.
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                // Handle decline
                                navigate(
                                  `/dashboard/tenants/review/${selectedTenant.id}?action=decline`
                                );
                              }}
                            >
                              Decline
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                background:
                                  "linear-gradient(45deg, #22c55e, #16a34a)",
                                "&:hover": {
                                  background:
                                    "linear-gradient(45deg, #16a34a, #15803d)",
                                },
                              }}
                              onClick={() => {
                                // Handle approve
                                navigate(
                                  `/dashboard/tenants/review/${selectedTenant.id}?action=approve`
                                );
                              }}
                            >
                              Approve Application
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        p: 3,
                        bgcolor: "rgba(37, 99, 235, 0.02)",
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "rgba(37, 99, 235, 0.1)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2563eb",
                          fontWeight: "bold",
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box component="span" sx={{ fontSize: "1.2rem" }}>
                          👤
                        </Box>
                        Personal Information
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Date of Birth
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedTenant.personalInfo?.dateOfBirth ||
                              "Not provided"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            National Insurance
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedTenant.personalInfo?.nationalInsurance ||
                              "Not provided"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Current Income
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 600,
                              color: "success.main",
                            }}
                          >
                            £
                            {selectedTenant.personalInfo?.currentIncome?.toLocaleString() ||
                              "Not provided"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Marital Status
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedTenant.personalInfo?.maritalStatus ||
                              "Not provided"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Dependents
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedTenant.personalInfo?.dependents || "None"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Smoker
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedTenant.personalInfo?.isSmoker
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Pets
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedTenant.personalInfo?.hasPets
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        p: 3,
                        bgcolor: "rgba(124, 58, 237, 0.02)",
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "rgba(124, 58, 237, 0.1)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#7c3aed",
                          fontWeight: "bold",
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box component="span" sx={{ fontSize: "1.2rem" }}>
                          💼
                        </Box>
                        Employment History
                      </Typography>
                      {selectedTenant.employmentHistory?.map(
                        (employment, index) => (
                          <Box
                            key={index}
                            sx={{
                              mb: 3,
                              p: 2,
                              bgcolor: "white",
                              borderRadius: 1,
                              border: "1px solid",
                              borderColor: "rgba(124, 58, 237, 0.1)",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600, mb: 1 }}
                            >
                              {employment.employer}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Position
                                </Typography>
                                <Typography variant="body2">
                                  {employment.position}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Duration
                                </Typography>
                                <Typography variant="body2">
                                  {employment.startDate} -{" "}
                                  {employment.endDate || "Present"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Contact
                                </Typography>
                                <Typography variant="body2">
                                  {employment.contactPerson} (
                                  {employment.contactPhone})
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        )
                      )}
                      {(!selectedTenant.employmentHistory ||
                        selectedTenant.employmentHistory.length === 0) && (
                        <Typography
                          color="text.secondary"
                          sx={{ fontStyle: "italic" }}
                        >
                          No employment history provided
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        p: 3,
                        bgcolor: "rgba(37, 99, 235, 0.02)",
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "rgba(37, 99, 235, 0.1)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2563eb",
                          fontWeight: "bold",
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box component="span" sx={{ fontSize: "1.2rem" }}>
                          📍
                        </Box>
                        Address History
                      </Typography>
                      {selectedTenant.addressHistory?.map((address, index) => (
                        <Box
                          key={index}
                          sx={{
                            mb: 3,
                            p: 2,
                            bgcolor: "white",
                            borderRadius: 1,
                            border: "1px solid",
                            borderColor: "rgba(37, 99, 235, 0.1)",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600, mb: 1 }}
                          >
                            {address.address}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                gutterBottom
                              >
                                Location
                              </Typography>
                              <Typography variant="body2">
                                {address.city}, {address.postcode}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                gutterBottom
                              >
                                Duration
                              </Typography>
                              <Typography variant="body2">
                                {address.startDate} -{" "}
                                {address.endDate || "Present"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                gutterBottom
                              >
                                Previous Landlord
                              </Typography>
                              <Typography variant="body2">
                                {address.landlordName
                                  ? `${address.landlordName} (${address.landlordPhone})`
                                  : "Not provided"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                      {(!selectedTenant.addressHistory ||
                        selectedTenant.addressHistory.length === 0) && (
                        <Typography
                          color="text.secondary"
                          sx={{ fontStyle: "italic" }}
                        >
                          No address history provided
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        p: 3,
                        bgcolor: "rgba(124, 58, 237, 0.02)",
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "rgba(124, 58, 237, 0.1)",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#7c3aed",
                          fontWeight: "bold",
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box component="span" sx={{ fontSize: "1.2rem" }}>
                          📝
                        </Box>
                        Additional Information
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Reason for Moving
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedTenant.additionalInfo?.reasonForMoving ||
                              "Not provided"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            gutterBottom
                          >
                            Additional Notes
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            {selectedTenant.additionalInfo?.notes ||
                              "No additional notes"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          )}

          {selectedTab === 2 && (
            <Box
              sx={{
                p: 3,
                bgcolor: "rgba(37, 99, 235, 0.02)",
                borderRadius: 2,
                border: "1px solid",
                borderColor: "rgba(37, 99, 235, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2563eb",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box component="span" sx={{ fontSize: "1.2rem" }}>
                    📄
                  </Box>
                  Documents
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setUploadDialogOpen(true)}
                  sx={{
                    background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                    "&:hover": {
                      background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                    },
                  }}
                >
                  Upload Documents
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {selectedTenant?.documents?.map((doc, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 2,
                      bgcolor: "white",
                      borderRadius: 1,
                      border: "1px solid",
                      borderColor: "rgba(37, 99, 235, 0.1)",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <AssignmentIcon sx={{ color: "#2563eb" }} />
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500 }}
                        >
                          {doc.name}
                        </Typography>
                        {doc.description && (
                          <Typography variant="body2" color="text.secondary">
                            {doc.description}
                          </Typography>
                        )}
                        <Typography variant="caption" color="text.secondary">
                          {new Date(doc.uploadedAt).toLocaleDateString()} -{" "}
                          {doc.type}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="small"
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: "#2563eb" }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteDocument(doc)}
                        sx={{ color: "error.main" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
                {(!selectedTenant?.documents ||
                  selectedTenant.documents.length === 0) && (
                  <Typography
                    color="text.secondary"
                    sx={{ fontStyle: "italic" }}
                  >
                    No documents uploaded yet
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{ p: 3, borderTop: "1px solid", borderColor: "divider" }}
        >
          {selectedTenant?.status === "pending_approval" ? (
            <>
              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  navigate(`/dashboard/tenants/review/${selectedTenant.id}`)
                }
              >
                Review Application
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/dashboard/tenants/review/${selectedTenant.id}`)
                }
                sx={{
                  background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                  "&:hover": {
                    background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                  },
                }}
              >
                Make Decision
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setViewDialogOpen(false)}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                px: 4,
                py: 1,
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  if (loading) return null;

  return (
    <Box>
      {successMessage && (
        <Alert
          severity="success"
          sx={{ mb: 3 }}
          onClose={() => setSuccessMessage("")}
        >
          {successMessage}
        </Alert>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              backgroundColor: "background.paper",
              borderRadius: 1,
              p: 0.5,
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <Button
              onClick={() => setViewMode("tenants")}
              variant={viewMode === "tenants" ? "contained" : "text"}
              sx={{
                minWidth: 100,
                mr: 1,
                ...(viewMode === "tenants" && {
                  background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                  "&:hover": {
                    background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                  },
                }),
              }}
            >
              Active
            </Button>
            <Button
              onClick={() => setViewMode("applications")}
              variant={viewMode === "applications" ? "contained" : "text"}
              sx={{
                minWidth: 100,
                mr: 1,
                ...(viewMode === "applications" && {
                  background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                  "&:hover": {
                    background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                  },
                }),
              }}
            >
              Applications
            </Button>
            <Button
              onClick={() => setViewMode("inactive")}
              variant={viewMode === "inactive" ? "contained" : "text"}
              sx={{
                minWidth: 100,
                ...(viewMode === "inactive" && {
                  background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                  "&:hover": {
                    background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                  },
                }),
              }}
            >
              Inactive
            </Button>
          </Box>
          {viewMode === "applications" && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={showSubmittedOnly}
                  onChange={(e) => setShowSubmittedOnly(e.target.checked)}
                  sx={{
                    color: "#7c3aed",
                    "&.Mui-checked": {
                      color: "#7c3aed",
                    },
                  }}
                />
              }
              label="Show submitted applications only"
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "text.secondary",
                  fontSize: "0.875rem",
                },
              }}
            />
          )}
        </Box>

        {viewMode === "tenants" && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Add Tenant
          </Button>
        )}
      </Box>

      <DataList
        items={filteredTenants}
        renderItem={(tenant) => (
          <ListItem
            component="div"
            key={tenant.id}
            sx={{
              mb: 2,
              height: 100,
              borderRadius: 1,
              border: "1px solid",
              borderColor: "divider",
              listStyle: "none",
              "&::marker": {
                display: "none",
              },
              overflow: "hidden",
              p: 0,
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
                p: 2,
                overflowX: { xs: "auto", md: "visible" },
                "&::-webkit-scrollbar": {
                  height: 8,
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius: 4,
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: 4,
                  "&:hover": {
                    background: "#666",
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  minWidth: { xs: "fit-content", md: "auto" },
                  flexGrow: 1,
                }}
              >
                <ListItemAvatar sx={{ flexShrink: 0, minWidth: 48 }}>
                  <Avatar>{tenant.firstName?.[0]}</Avatar>
                </ListItemAvatar>

                <Box sx={{ minWidth: { xs: "fit-content", md: "auto" } }}>
                  <Typography sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                    {tenant.firstName} {tenant.lastName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: 0.5,
                      minWidth: "fit-content",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {tenant.email}
                    </Typography>
                    <Chip
                      label={
                        tenant.status === "active"
                          ? "Active"
                          : tenant.status === "pending_approval"
                          ? "Review Pending"
                          : tenant.status === "pending_onboarding"
                          ? "Application In Progress"
                          : tenant.status === "application_sent"
                          ? "Application Sent"
                          : "Applicant"
                      }
                      color={
                        tenant.status === "active"
                          ? "success"
                          : tenant.status === "pending_approval"
                          ? "warning"
                          : "secondary"
                      }
                      size="small"
                      sx={{
                        flexShrink: 0,
                        ...(tenant.status === "active" && {
                          backgroundColor: "#22c55e",
                          color: "white",
                        }),
                        ...(tenant.status === "pending_approval" && {
                          backgroundColor: "#eab308",
                          color: "white",
                        }),
                        ...(tenant.status !== "active" &&
                          tenant.status !== "pending_approval" && {
                            backgroundColor: "#7c3aed",
                            color: "white",
                          }),
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  ml: 2,
                  flexShrink: 0,
                }}
              >
                {viewMode === "applications" && (
                  <>
                    {tenant.status === "application_sent" && (
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/dashboard/tenants/manual-application/${tenant.id}`
                          );
                        }}
                        sx={{ flexShrink: 0 }}
                      >
                        Fill Manually
                      </Button>
                    )}
                  </>
                )}
                {viewMode === "inactive" && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleReactivateTenant(tenant);
                    }}
                    sx={{
                      flexShrink: 0,
                      background: "#fff",
                      color: "#22c55e",
                      border: "1px solid #22c55e",
                      textTransform: "none",
                      px: 3,
                      "&:hover": {
                        background: "#f0fdf4",
                        border: "1px solid #22c55e",
                      },
                    }}
                  >
                    Reactivate
                  </Button>
                )}
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/dashboard/tenants/${tenant.id}`);
                  }}
                  sx={{
                    flexShrink: 0,
                    background: "#fff",
                    color: "#2563eb",
                    border: "1px solid #2563eb",
                    textTransform: "none",
                    px: 3,
                    "&:hover": {
                      background: "#f8fafc",
                      border: "1px solid #2563eb",
                    },
                  }}
                >
                  View
                </Button>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteTenant(tenant);
                  }}
                  sx={{ color: "error.main", flexShrink: 0 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </ListItem>
        )}
        searchPlaceholder="Search tenants..."
        searchField="firstName"
        pageSize={10}
      />

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setEditingTenant(null);
          setError("");
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingTenant ? "Edit Tenant" : "Add New Tenant"}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="First Name"
                fullWidth
                required
                value={
                  editingTenant ? editingTenant.firstName : newTenant.firstName
                }
                onChange={(e) =>
                  editingTenant
                    ? setEditingTenant({
                        ...editingTenant,
                        firstName: e.target.value,
                      })
                    : setNewTenant({ ...newTenant, firstName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                required
                value={
                  editingTenant ? editingTenant.lastName : newTenant.lastName
                }
                onChange={(e) =>
                  editingTenant
                    ? setEditingTenant({
                        ...editingTenant,
                        lastName: e.target.value,
                      })
                    : setNewTenant({ ...newTenant, lastName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                required
                value={editingTenant ? editingTenant.email : newTenant.email}
                onChange={(e) =>
                  editingTenant
                    ? setEditingTenant({
                        ...editingTenant,
                        email: e.target.value,
                      })
                    : setNewTenant({ ...newTenant, email: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Phone"
                fullWidth
                required
                value={editingTenant ? editingTenant.phone : newTenant.phone}
                onChange={(e) =>
                  editingTenant
                    ? setEditingTenant({
                        ...editingTenant,
                        phone: e.target.value,
                      })
                    : setNewTenant({ ...newTenant, phone: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Property</InputLabel>
                <Select
                  value={
                    editingTenant
                      ? editingTenant.propertyId
                      : newTenant.propertyId
                  }
                  onChange={(e) => handlePropertyChange(e.target.value)}
                  label="Property"
                  required
                >
                  {properties.map((property) => (
                    <MenuItem key={property.id} value={property.id}>
                      {property.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                margin="dense"
                disabled={
                  !(editingTenant
                    ? editingTenant.propertyId
                    : newTenant.propertyId)
                }
              >
                <InputLabel>Unit</InputLabel>
                <Select
                  value={editingTenant ? editingTenant.unit : newTenant.unit}
                  onChange={(e) => {
                    if (editingTenant) {
                      setEditingTenant({
                        ...editingTenant,
                        unit: e.target.value,
                      });
                    } else {
                      setNewTenant({
                        ...newTenant,
                        unit: e.target.value,
                      });
                    }
                  }}
                  label="Unit"
                  required
                >
                  {getAvailableUnits(
                    editingTenant
                      ? editingTenant.propertyId
                      : newTenant.propertyId
                  ).map((unit) => (
                    <MenuItem key={unit.id} value={unit.id}>
                      Unit {unit.number} ({unit.type})
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {!(editingTenant
                    ? editingTenant.propertyId
                    : newTenant.propertyId)
                    ? "Select a property first"
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setEditingTenant(null);
              setError("");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={editingTenant ? handleUpdateTenant : handleAddTenant}
            variant="contained"
            disabled={submitting}
          >
            {submitting
              ? "Sending..."
              : editingTenant
              ? "Save Changes"
              : "Add Tenant"}
          </Button>
        </DialogActions>
      </Dialog>

      {renderTenantDetails()}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setTenantToDelete(null);
        }}
      >
        <DialogTitle sx={{ color: "error.main" }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this{" "}
            {tenantToDelete?.status === "active" ? "tenant" : "applicant"}?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This will:
          </Typography>
          <Box component="ul" sx={{ mt: 1, pl: 2 }}>
            <li>
              <Typography variant="body2" color="text.secondary">
                Delete all{" "}
                {tenantToDelete?.status === "active" ? "tenant" : "applicant"}{" "}
                data and documents
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                Deactivate their account access
              </Typography>
            </li>
            {tenantToDelete?.status === "active" && (
              <li>
                <Typography variant="body2" color="text.secondary">
                  Remove them from the property
                </Typography>
              </li>
            )}
          </Box>
          {tenantToDelete && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Deleting: {tenantToDelete.firstName} {tenantToDelete.lastName}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setTenantToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={confirmDelete}
            disabled={submitting}
          >
            {submitting ? "Deleting..." : "Delete Tenant"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Document Upload Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Documents</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Document Type</InputLabel>
              <Select
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                label="Document Type"
              >
                <MenuItem value="identification">Identification</MenuItem>
                <MenuItem value="proof_of_income">Proof of Income</MenuItem>
                <MenuItem value="references">References</MenuItem>
                <MenuItem value="contract">Contract</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Description"
              value={documentDescription}
              onChange={(e) => setDocumentDescription(e.target.value)}
              sx={{ mb: 3 }}
            />

            <FileUpload
              onUploadComplete={handleUploadComplete}
              folder={`tenants/${selectedTenant?.id}/documents`}
              entityId={selectedTenant?.id}
              multiple={true}
              acceptedFiles=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
              additionalData={{
                tenantId: selectedTenant?.id,
                organizationId: organization.id,
                type: "tenant_document",
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Tenants;
