import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Chip,
  IconButton,
  Card,
  CardContent,
  Grid,
  ImageList,
  ImageListItem,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Stack,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Build as BuildIcon,
  VideoLibrary as VideoIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import DataList from "../common/DataList";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { emailService } from "../../services/emailService";

const priorityOptions = [
  { value: "low", label: "Low", color: "#22c55e" },
  { value: "medium", label: "Medium", color: "#f59e0b" },
  { value: "high", label: "High", color: "#ef4444" },
];

const statusOptions = [
  { value: "pending", label: "Pending", color: "#9CA3AF" },
  { value: "sent", label: "Sent", color: "#F59E0B" },
  { value: "accepted", label: "In Progress", color: "#3B82F6" },
  { value: "declined", label: "Declined", color: "#EF4444" },
  { value: "completed", label: "Completed", color: "#22c55e" },
];

const statusColors = {
  pending: "#9CA3AF",
  sent: "#F59E0B",
  accepted: "#3B82F6",
  declined: "#EF4444",
  completed: "#10B981",
};

const Maintenance = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [error, setError] = useState("");
  const [newRequest, setNewRequest] = useState({
    propertyId: "",
    title: "",
    description: "",
    priority: "medium",
    status: "pending",
    unit: "",
    tenantName: "",
    tenantPhone: "",
    propertyAddress: "",
  });
  const [units, setUnits] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const { organization } = useOrganization();
  const db = getFirestore();
  const queryClient = useQueryClient();

  // Fetch properties for the dropdown
  const { data: properties = [] } = useQuery({
    queryKey: ["properties", organization?.id],
    queryFn: async () => {
      if (!organization?.id) return [];
      const q = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!organization?.id,
  });

  // Fetch maintenance requests
  const { data: maintenanceRequests = [], isLoading } = useQuery({
    queryKey: ["maintenanceRequests", organization?.id],
    queryFn: async () => {
      if (!organization?.id) return [];
      const q = query(
        collection(db, "maintenanceRequests"),
        where("organizationId", "==", organization.id)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!organization?.id,
  });

  // Fetch units when property is selected
  useEffect(() => {
    const fetchUnits = async () => {
      if (!newRequest.propertyId) {
        setUnits([]);
        return;
      }

      try {
        const propertyDoc = await getDoc(
          doc(db, "properties", newRequest.propertyId)
        );
        if (propertyDoc.exists()) {
          const propertyData = propertyDoc.data();
          const propertyUnits = propertyData.units || [];
          setUnits(propertyUnits.map((unit) => unit.number).sort());
        }
      } catch (error) {
        console.error("Error fetching units:", error);
        setUnits([]);
      }
    };

    fetchUnits();
  }, [newRequest.propertyId, db]);

  const handleAddRequest = async () => {
    try {
      if (
        !newRequest.propertyId ||
        !newRequest.title ||
        !newRequest.description
      ) {
        setError("Please fill in all required fields");
        return;
      }

      const property = properties.find((p) => p.id === newRequest.propertyId);
      const propertyDoc = await getDoc(
        doc(db, "properties", newRequest.propertyId)
      );
      const propertyData = propertyDoc.data();

      let tenantData = {};
      if (newRequest.unit) {
        const tenantsQuery = query(
          collection(db, "tenants"),
          where("propertyId", "==", newRequest.propertyId),
          where("unit", "==", newRequest.unit),
          where("status", "==", "active")
        );
        const tenantSnapshot = await getDocs(tenantsQuery);
        if (!tenantSnapshot.empty) {
          tenantData = tenantSnapshot.docs[0].data();
        }
      }

      const requestData = {
        ...newRequest,
        organizationId: organization.id,
        propertyName: property?.name || "",
        propertyAddress: propertyData?.address || "",
        tenantName: tenantData?.name || "",
        tenantPhone: tenantData?.phone || "",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      // Add the maintenance request
      const docRef = await addDoc(
        collection(db, "maintenanceRequests"),
        requestData
      );

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "maintenance_request",
        requestId: docRef.id,
        status: "pending",
        organizationId: organization.id,
        timestamp: serverTimestamp(),
        message: `New maintenance request: ${requestData.title}`,
      });

      setOpenDialog(false);
      setNewRequest({
        propertyId: "",
        title: "",
        description: "",
        priority: "medium",
        status: "pending",
        unit: "",
        tenantName: "",
        tenantPhone: "",
        propertyAddress: "",
      });
      queryClient.invalidateQueries(["maintenanceRequests"]);
      queryClient.invalidateQueries(["activityLog"]);
    } catch (error) {
      console.error("Error adding maintenance request:", error);
      setError(error.message || "Error adding maintenance request");
    }
  };

  const handleUpdateStatus = async (requestId, newStatus) => {
    try {
      const requestRef = doc(db, "maintenanceRequests", requestId);
      const updateData = {
        status: newStatus,
        updatedAt: new Date().toISOString(),
      };

      if (newStatus === "completed") {
        updateData.completedAt = new Date().toISOString();
      }

      await updateDoc(requestRef, updateData);
      queryClient.invalidateQueries(["maintenanceRequests"]);
    } catch (error) {
      console.error("Error updating request status:", error);
    }
  };

  const handleViewRequest = (request) => {
    setSelectedRequest(request);
    setViewDialogOpen(true);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const AssignContractorDialog = ({ open, onClose, request, onAssign }) => {
    const [contractors, setContractors] = useState([]);
    const [selectedContractor, setSelectedContractor] = useState("");
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
      const fetchContractors = async () => {
        try {
          const q = query(
            collection(db, "contractors"),
            where("organizationId", "==", request.organizationId),
            where("status", "==", "active")
          );
          const snapshot = await getDocs(q);
          setContractors(
            snapshot.docs.map((doc) => ({
              id: doc.data().uid,
              ...doc.data(),
            }))
          );
        } catch (error) {
          console.error("Error fetching contractors:", error);
        } finally {
          setLoading(false);
        }
      };

      if (open) {
        fetchContractors();
      }
    }, [open, request.organizationId]);

    const handleAssign = async () => {
      try {
        setError("");
        setSending(true);

        // Get the selected contractor's details
        const contractor = contractors.find((c) => c.id === selectedContractor);
        if (!contractor) {
          throw new Error("Selected contractor not found");
        }

        const requestRef = doc(db, "maintenanceRequests", request.id);

        // Update the maintenance request
        await updateDoc(requestRef, {
          contractorId: selectedContractor,
          status: "sent",
          assignedAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });

        // Send email notification to contractor
        await emailService.sendJobNotification(
          contractor.email,
          contractor.name,
          {
            propertyName: request.propertyName,
            issueType: request.title,
            priority: request.priority,
            description: request.description,
          }
        );

        setNotification({
          open: true,
          message: "Job assigned and email notification sent successfully",
          severity: "success",
        });

        onAssign();
        onClose();
      } catch (error) {
        console.error("Error assigning contractor:", error);
        setError(error.message || "Failed to assign contractor");
        setNotification({
          open: true,
          message: "Failed to assign contractor: " + error.message,
          severity: "error",
        });
      } finally {
        setSending(false);
      }
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Assign Contractor</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Select Contractor</InputLabel>
            <Select
              value={selectedContractor}
              onChange={(e) => setSelectedContractor(e.target.value)}
              label="Select Contractor"
              error={!!error}
            >
              {contractors.map((contractor) => (
                <MenuItem key={contractor.id} value={contractor.id}>
                  {contractor.businessName || contractor.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleAssign}
            variant="contained"
            disabled={!selectedContractor || sending}
          >
            {sending ? "Sending..." : "Assign"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const MaintenanceRequestCard = ({ request, onUpdate }) => {
    if (!request) return null;

    const [assignDialogOpen, setAssignDialogOpen] = useState(false);
    const [contractorInfo, setContractorInfo] = useState(null);
    const priorityInfo =
      priorityOptions.find((p) => p.value === request?.priority) ||
      priorityOptions[0];
    const statusInfo =
      statusOptions.find((s) => s.value === request?.status) ||
      statusOptions[0];

    useEffect(() => {
      const fetchContractorInfo = async () => {
        if (request.status === "completed" && request.contractorId) {
          try {
            const contractorDoc = await getDoc(
              doc(db, "contractors", request.contractorId)
            );
            if (contractorDoc.exists()) {
              setContractorInfo(contractorDoc.data());
            }
          } catch (error) {
            console.error("Error fetching contractor info:", error);
          }
        }
      };

      fetchContractorInfo();
    }, [request.status, request.contractorId]);

    return (
      <Card
        key={request.id}
        sx={{
          mb: 3,
          border: "1px solid",
          borderColor: "divider",
          boxShadow: "none",
          "&:hover": {
            borderColor: "primary.main",
            bgcolor: "background.paper",
          },
          overflow: "hidden",
        }}
      >
        <CardContent
          sx={{
            overflowX: { xs: "auto", md: "visible" },
            "&::-webkit-scrollbar": {
              height: 8,
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: 4,
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: 4,
              "&:hover": {
                background: "#666",
              },
            },
            p: { xs: 1, md: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: { xs: "fit-content", md: "auto" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                minWidth: { xs: "fit-content", md: "auto" },
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 2,
                  bgcolor: `${priorityInfo.color}15`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexShrink: 0,
                }}
              >
                <BuildIcon sx={{ color: priorityInfo.color }} />
              </Box>
              <Box sx={{ minWidth: { xs: "fit-content", md: "auto" } }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                  }}
                >
                  {request.title || "Untitled Request"}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {request.propertyName || "No Property"}{" "}
                  {request.unit && `- Unit ${request.unit}`}
                </Typography>
                {request.status === "completed" && contractorInfo && (
                  <Typography
                    variant="body2"
                    color="success.main"
                    sx={{
                      mt: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Completed by:{" "}
                    {contractorInfo.businessName || contractorInfo.name}
                    {request.completedAt && (
                      <>
                        {" "}
                        on {new Date(request.completedAt).toLocaleDateString()}
                      </>
                    )}
                  </Typography>
                )}
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    gap: 1,
                    flexWrap: { xs: "nowrap", md: "wrap" },
                    minWidth: "fit-content",
                  }}
                >
                  <Chip
                    label={priorityInfo.label}
                    size="small"
                    sx={{
                      bgcolor: `${priorityInfo.color}15`,
                      color: priorityInfo.color,
                      flexShrink: 0,
                    }}
                  />
                  <Chip
                    label={statusInfo.label}
                    size="small"
                    sx={{
                      bgcolor: `${statusInfo.color}15`,
                      color: statusInfo.color,
                      flexShrink: 0,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                ml: 2,
                flexShrink: 0,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleViewRequest(request)}
                sx={{ flexShrink: 0 }}
              >
                View
              </Button>
              {statusOptions.map((status) => (
                <Button
                  key={status.value}
                  size="small"
                  variant={
                    request.status === status.value ? "contained" : "outlined"
                  }
                  onClick={() => handleUpdateStatus(request.id, status.value)}
                  sx={{
                    borderColor: status.color,
                    color:
                      request.status === status.value ? "white" : status.color,
                    bgcolor:
                      request.status === status.value
                        ? status.color
                        : "transparent",
                    "&:hover": {
                      bgcolor:
                        request.status === status.value
                          ? status.color
                          : `${status.color}15`,
                    },
                    flexShrink: 0,
                  }}
                >
                  {status.label}
                </Button>
              ))}
              {request.status === "pending" && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setAssignDialogOpen(true)}
                  sx={{ flexShrink: 0 }}
                >
                  Assign Contractor
                </Button>
              )}
              {request.status === "declined" && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setAssignDialogOpen(true)}
                  sx={{ flexShrink: 0 }}
                >
                  Reassign Contractor
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
        <AssignContractorDialog
          open={assignDialogOpen}
          onClose={() => setAssignDialogOpen(false)}
          request={request}
          onAssign={onUpdate}
        />
      </Card>
    );
  };

  const getStatusDisplay = (status) => {
    const statusInfo = {
      pending: {
        label: "Pending",
        color: statusColors.pending,
        description: "Waiting to be assigned",
      },
      sent: {
        label: "Sent to Contractor",
        color: statusColors.sent,
        description: "Waiting for contractor response",
      },
      accepted: {
        label: "In Progress",
        color: statusColors.accepted,
        description: "Work in progress",
      },
      declined: {
        label: "Declined",
        color: statusColors.declined,
        description: "Contractor declined",
      },
      completed: {
        label: "Completed",
        color: statusColors.completed,
        description: "Work completed",
      },
    };

    return (
      <Box>
        <Chip
          label={statusInfo[status].label}
          sx={{
            bgcolor: `${statusInfo[status].color}15`,
            color: statusInfo[status].color,
            fontWeight: 500,
          }}
        />
        <Typography variant="caption" display="block" sx={{ mt: 0.5 }}>
          {statusInfo[status].description}
        </Typography>
      </Box>
    );
  };

  if (isLoading) return null;

  return (
    <Box>
      <DataList
        items={maintenanceRequests}
        renderItem={(request) => (
          <MaintenanceRequestCard
            key={request.id}
            request={request}
            onUpdate={() =>
              queryClient.invalidateQueries(["maintenanceRequests"])
            }
          />
        )}
        searchPlaceholder="Search maintenance requests..."
        searchField="title"
        pageSize={8}
        filters={[
          { label: "All Requests", value: "all" },
          { label: "Pending", value: "pending" },
          { label: "Sent", value: "sent" },
          { label: "In Progress", value: "accepted" },
          { label: "Declined", value: "declined" },
          { label: "Completed", value: "completed" },
        ]}
        actionButtons={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            New Request
          </Button>
        }
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>New Maintenance Request</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="dense"
            label="Property"
            fullWidth
            required
            value={newRequest.propertyId}
            onChange={(e) =>
              setNewRequest({ ...newRequest, propertyId: e.target.value })
            }
          >
            {properties.map((property) => (
              <MenuItem key={property.id} value={property.id}>
                {property.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin="dense"
            label="Unit Number"
            fullWidth
            value={newRequest.unit}
            onChange={(e) =>
              setNewRequest({ ...newRequest, unit: e.target.value })
            }
          >
            <MenuItem value="">No Unit</MenuItem>
            {units.map((unit) => (
              <MenuItem key={unit} value={unit}>
                Unit {unit}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Title"
            fullWidth
            required
            value={newRequest.title}
            onChange={(e) =>
              setNewRequest({ ...newRequest, title: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            required
            multiline
            rows={3}
            value={newRequest.description}
            onChange={(e) =>
              setNewRequest({ ...newRequest, description: e.target.value })
            }
          />
          <TextField
            select
            margin="dense"
            label="Priority"
            fullWidth
            value={newRequest.priority}
            onChange={(e) =>
              setNewRequest({ ...newRequest, priority: e.target.value })
            }
          >
            {priorityOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddRequest} variant="contained">
            Create Request
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "16px",
            background: "linear-gradient(180deg, #f0f7ff 0%, #FFFFFF 30.21%)",
            boxShadow: `0px 187px 75px rgba(0, 0, 0, 0.01),
              0px 105px 63px rgba(0, 0, 0, 0.05),
              0px 47px 47px rgba(0, 0, 0, 0.09),
              0px 12px 26px rgba(0, 0, 0, 0.1),
              0px 0px 0px rgba(0, 0, 0, 0.1)`,
          },
        }}
      >
        {selectedRequest && (
          <>
            <DialogTitle
              sx={{
                background: "linear-gradient(90deg, #2563eb 0%, #7c3aed 100%)",
                color: "white",
                display: "flex",
                alignItems: "center",
                gap: 2,
                borderBottom: "1px solid",
                borderColor: "divider",
                pb: 2,
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 2,
                  bgcolor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexShrink: 0,
                }}
              >
                <BuildIcon sx={{ color: "#2563eb", fontSize: 28 }} />
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 0.5 }}>
                  {selectedRequest.title}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <Chip
                    label={selectedRequest.priority.toUpperCase()}
                    size="small"
                    sx={{
                      bgcolor: "rgba(255, 255, 255, 0.2)",
                      color: "white",
                      fontWeight: 500,
                    }}
                  />
                  <Chip
                    label={selectedRequest.status.toUpperCase()}
                    size="small"
                    sx={{
                      bgcolor: "rgba(255, 255, 255, 0.2)",
                      color: "white",
                      fontWeight: 500,
                    }}
                  />
                </Stack>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ p: 3, mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      bgcolor: "rgba(37, 99, 235, 0.02)",
                      borderRadius: 2,
                      border: "1px solid",
                      borderColor: "rgba(37, 99, 235, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2563eb",
                        fontWeight: "bold",
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box component="span" sx={{ fontSize: "1.2rem" }}>
                        📍
                      </Box>
                      Location Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Property
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          {selectedRequest.propertyName}
                          {selectedRequest.unit &&
                            ` - Unit ${selectedRequest.unit}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Address
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          {selectedRequest.propertyAddress}
                        </Typography>
                      </Grid>
                      {selectedRequest.tenantName && (
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Tenant Contact
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {selectedRequest.tenantName}
                            {selectedRequest.tenantPhone &&
                              ` • ${selectedRequest.tenantPhone}`}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      bgcolor: "rgba(124, 58, 237, 0.02)",
                      borderRadius: 2,
                      border: "1px solid",
                      borderColor: "rgba(124, 58, 237, 0.1)",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#7c3aed",
                        fontWeight: "bold",
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box component="span" sx={{ fontSize: "1.2rem" }}>
                        📝
                      </Box>
                      Request Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Description
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ mb: 2, whiteSpace: "pre-wrap" }}
                        >
                          {selectedRequest.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Submitted
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          {new Date(selectedRequest.createdAt).toLocaleString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Last Updated
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          {new Date(selectedRequest.updatedAt).toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                {selectedRequest.mediaFiles &&
                  selectedRequest.mediaFiles.length > 0 && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          p: 3,
                          bgcolor: "rgba(37, 99, 235, 0.02)",
                          borderRadius: 2,
                          border: "1px solid",
                          borderColor: "rgba(37, 99, 235, 0.1)",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            color: "#2563eb",
                            fontWeight: "bold",
                            mb: 2,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box component="span" sx={{ fontSize: "1.2rem" }}>
                            📎
                          </Box>
                          Attachments
                        </Typography>
                        <ImageList cols={3} gap={16} sx={{ mt: 2 }}>
                          {selectedRequest.mediaFiles.map((file, index) => (
                            <ImageListItem
                              key={index}
                              sx={{
                                cursor: "pointer",
                                overflow: "hidden",
                                borderRadius: 2,
                                border: "1px solid",
                                borderColor: "divider",
                                "&:hover": {
                                  borderColor: "primary.main",
                                  "& img": {
                                    transform: "scale(1.05)",
                                  },
                                },
                              }}
                              onClick={() => window.open(file.url, "_blank")}
                            >
                              {file.type === "image" ? (
                                <img
                                  src={file.url}
                                  alt={`Attachment ${index + 1}`}
                                  loading="lazy"
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    objectFit: "cover",
                                    borderRadius: "4px",
                                  }}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "200px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "grey.100",
                                    borderRadius: 1,
                                  }}
                                >
                                  <VideoIcon
                                    sx={{ fontSize: 48, color: "primary.main" }}
                                  />
                                </Box>
                              )}
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </Box>
                    </Grid>
                  )}

                {selectedRequest.status === "completed" &&
                  selectedRequest.invoice && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          p: 3,
                          bgcolor: "rgba(16, 185, 129, 0.05)",
                          borderRadius: 2,
                          border: "1px solid",
                          borderColor: "rgba(16, 185, 129, 0.2)",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            color: "#10B981",
                            fontWeight: "bold",
                            mb: 2,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box component="span" sx={{ fontSize: "1.2rem" }}>
                            💰
                          </Box>
                          Invoice Details
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                            >
                              Amount
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                              £{selectedRequest.invoice.amount.toFixed(2)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                            >
                              Work Description
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                              {selectedRequest.invoice.description}
                            </Typography>
                          </Grid>
                          {selectedRequest.invoice.files &&
                            selectedRequest.invoice.files.length > 0 && (
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  sx={{ mb: 1 }}
                                >
                                  Invoice Files
                                </Typography>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  flexWrap="wrap"
                                >
                                  {selectedRequest.invoice.files.map(
                                    (file, index) => (
                                      <Chip
                                        key={index}
                                        label={file.name}
                                        onClick={() =>
                                          window.open(file.url, "_blank")
                                        }
                                        sx={{
                                          mb: 1,
                                          cursor: "pointer",
                                          "&:hover": {
                                            bgcolor: "rgba(16, 185, 129, 0.1)",
                                          },
                                        }}
                                      />
                                    )
                                  )}
                                </Stack>
                              </Grid>
                            )}
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                            >
                              Submitted
                            </Typography>
                            <Typography variant="body1">
                              {new Date(
                                selectedRequest.invoice.submittedAt
                              ).toLocaleString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{ p: 2.5, borderTop: "1px solid", borderColor: "divider" }}
            >
              <Button onClick={() => setViewDialogOpen(false)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Maintenance;
