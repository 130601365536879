import React, { useState } from "react";
import { Box, Button, LinearProgress, Typography, Alert } from "@mui/material";
import { Upload as UploadIcon } from "@mui/icons-material";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const FileUpload = ({
  onUpload,
  onUploadComplete,
  accept,
  multiple = false,
  folder,
  entityId,
  isPhoto = false,
  acceptedFiles,
  additionalData,
}) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  const auth = getAuth();
  const storage = getStorage();
  const db = getFirestore();

  const handleUpload = async (event) => {
    const files = Array.from(event.target.files);
    setError("");
    setUploading(true);

    try {
      const uploadedFiles = [];

      for (const file of files) {
        // Create a reference to the file in Firebase Storage
        const storageRef = ref(
          storage,
          `${folder}/${entityId}/${Date.now()}_${file.name}`
        );

        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Wait for the upload to complete
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(progress);
            },
            (error) => {
              console.error("Upload error:", error);
              reject(error);
            },
            async () => {
              try {
                const downloadURL = await getDownloadURL(
                  uploadTask.snapshot.ref
                );

                // Create file document in Firestore
                await addDoc(collection(db, "files"), {
                  fileName: file.name,
                  fileUrl: downloadURL,
                  filePath: storageRef.fullPath,
                  uploadDate: new Date().toISOString(),
                  entityId: entityId,
                  folder: folder,
                  type: file.type,
                  size: file.size,
                  uploadedBy: auth.currentUser.uid,
                  ...additionalData,
                });

                uploadedFiles.push({
                  name: file.name,
                  url: downloadURL,
                  type: file.type,
                  size: file.size,
                  uploadedAt: new Date().toISOString(),
                });
                resolve();
              } catch (error) {
                console.error("Error getting download URL:", error);
                reject(error);
              }
            }
          );
        });
      }

      if (onUpload) {
        onUpload(uploadedFiles);
      }
      if (onUploadComplete) {
        onUploadComplete(uploadedFiles);
      }
      setProgress(0);
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Failed to upload file. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Button
        variant="outlined"
        component="label"
        fullWidth
        disabled={uploading}
        startIcon={<UploadIcon />}
        sx={{
          height: 56,
          borderStyle: "dashed",
          borderWidth: 2,
          "&:hover": {
            borderStyle: "dashed",
            borderWidth: 2,
          },
        }}
      >
        {uploading ? "Uploading..." : "Choose Files"}
        <input
          type="file"
          hidden
          accept={acceptedFiles || accept}
          multiple={multiple}
          onChange={handleUpload}
        />
      </Button>

      {uploading && (
        <Box sx={{ mt: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 1 }}
          >
            {Math.round(progress)}%
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
