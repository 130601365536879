import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Chip,
  IconButton,
  Card,
  CardContent,
  Avatar,
  Grid,
  Autocomplete,
  ListItem,
  ListItemAvatar,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Engineering as EngineeringIcon,
  Business as BusinessIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import DataList from "../common/DataList";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  deleteUser,
} from "firebase/auth";
import { checkResourceLimit } from "../../utils/planLimits";
import { getFunctions, httpsCallable } from "firebase/functions";
import { emailService } from "../../services/emailService";

const specialtyOptions = [
  "Plumbing",
  "Electrical",
  "HVAC",
  "Carpentry",
  "Painting",
  "Landscaping",
  "General Maintenance",
  "Roofing",
  "Cleaning",
  "Pest Control",
];

const Contractors = () => {
  const [contractors, setContractors] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newContractor, setNewContractor] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    specialties: [],
    rate: "",
    notes: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [contractorToDelete, setContractorToDelete] = useState(null);
  const [editingContractor, setEditingContractor] = useState(null);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);

  const { organization } = useOrganization();
  const db = getFirestore();

  useEffect(() => {
    if (organization?.id) {
      fetchContractors();
    }
  }, [organization]);

  const fetchContractors = async () => {
    try {
      const q = query(
        collection(db, "contractors"),
        where("organizationId", "==", organization.id)
      );
      const querySnapshot = await getDocs(q);
      const contractorsList = [];
      querySnapshot.forEach((doc) => {
        contractorsList.push({ id: doc.id, ...doc.data() });
      });
      setContractors(contractorsList);
    } catch (error) {
      console.error("Error fetching contractors:", error);
      setError("Failed to load contractors");
    } finally {
      setLoading(false);
    }
  };

  const handleAddContractor = async () => {
    try {
      setSubmitting(true);
      setError("");

      // Validate required fields
      if (
        !newContractor.name ||
        !newContractor.email ||
        !newContractor.specialties.length
      ) {
        setError("Please fill in all required fields");
        return;
      }

      // Check resource limit
      const limitCheck = await checkResourceLimit(
        organization.id,
        "contractors",
        organization.plan
      );
      if (!limitCheck.allowed) {
        setError(limitCheck.message);
        return;
      }

      const auth = getAuth();

      // Create user account with temporary password
      const tempPassword = Math.random().toString(36).slice(-8);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newContractor.email,
        tempPassword
      );

      // Create contractor document
      await addDoc(collection(db, "contractors"), {
        ...newContractor,
        organizationId: organization.id,
        uid: userCredential.user.uid,
        status: "active",
        createdAt: new Date().toISOString(),
        rate: parseFloat(newContractor.rate) || 0,
      });

      // Create user document with contractor role
      await setDoc(doc(db, "users", userCredential.user.uid), {
        name: newContractor.name,
        email: newContractor.email,
        role: "contractor",
        organizationId: organization.id,
        createdAt: new Date().toISOString(),
      });

      // Send password reset email
      await sendPasswordResetEmail(auth, newContractor.email, {
        url: window.location.origin + "/login",
        handleCodeInApp: true,
      });

      // Send welcome email
      try {
        await emailService.sendContractorWelcomeEmail(
          newContractor.email,
          newContractor.name,
          newContractor.specialties
        );
      } catch (emailError) {
        console.error("Error sending welcome email:", emailError);
        // Continue with the process even if welcome email fails
      }

      // Reset form and close dialog
      setOpenDialog(false);
      setNewContractor({
        name: "",
        company: "",
        email: "",
        phone: "",
        specialties: [],
        rate: "",
        notes: "",
      });

      // Show success message
      setSuccessMessage(
        "Contractor account created successfully! Welcome emails have been sent with setup instructions."
      );

      // Refresh contractors list
      fetchContractors();
    } catch (error) {
      console.error("Error adding contractor:", error);
      setError(error.message || "Failed to create contractor account");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteContractor = async (contractor) => {
    setContractorToDelete(contractor);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      setSubmitting(true);

      // Update contractor document to mark as deleted
      await updateDoc(doc(db, "contractors", contractorToDelete.id), {
        status: "deleted",
        role: "deleted",
        isDisabled: true,
        deletedAt: new Date().toISOString(),
        deletedBy: organization.id,
      });

      // Update user document to mark as deleted
      await updateDoc(doc(db, "users", contractorToDelete.id), {
        status: "deleted",
        role: "deleted",
        isDisabled: true,
        deletedAt: new Date().toISOString(),
        deletedBy: organization.id,
      });

      // Update the user's auth claims to revoke access
      try {
        await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/auth/disable-user`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": import.meta.env.VITE_API_KEY,
            },
            body: JSON.stringify({
              uid: contractorToDelete.uid,
              organizationId: organization.id,
            }),
          }
        );
      } catch (error) {
        console.error("Error disabling user auth:", error);
      }

      setSuccessMessage("Contractor access revoked successfully");
      setDeleteDialogOpen(false);
      setContractorToDelete(null);
      fetchContractors();
    } catch (error) {
      console.error("Error deleting contractor:", error);
      setError("Failed to revoke contractor access. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleViewContractor = async (contractor) => {
    try {
      const contractorDoc = await getDoc(doc(db, "contractors", contractor.id));
      setSelectedContractor({ id: contractorDoc.id, ...contractorDoc.data() });
      setViewDialogOpen(true);
    } catch (error) {
      console.error("Error fetching contractor details:", error);
    }
  };

  const handleEdit = (contractor) => {
    setEditingContractor(contractor);
    setOpenDialog(true);
  };

  const handleUpdateContractor = async () => {
    try {
      setSubmitting(true);
      setError("");

      if (
        !editingContractor.name ||
        !editingContractor.email ||
        !editingContractor.specialties.length
      ) {
        setError("Please fill in all required fields");
        return;
      }

      // Update contractor document
      await updateDoc(doc(db, "contractors", editingContractor.id), {
        name: editingContractor.name,
        company: editingContractor.company,
        phone: editingContractor.phone,
        specialties: editingContractor.specialties,
        rate: parseFloat(editingContractor.rate) || 0,
        notes: editingContractor.notes,
        updatedAt: new Date().toISOString(),
      });

      setOpenDialog(false);
      setEditingContractor(null);
      setSuccessMessage("Contractor updated successfully");
      fetchContractors();
    } catch (error) {
      console.error("Error updating contractor:", error);
      setError(error.message || "Failed to update contractor");
    } finally {
      setSubmitting(false);
    }
  };

  const renderContractorCard = (contractor) => (
    <ListItem
      component="div"
      key={contractor.id}
      sx={{
        mb: 2,
        height: 100,
        borderRadius: 1,
        border: "1px solid",
        borderColor: "divider",
        listStyle: "none",
        "&::marker": {
          display: "none",
        },
        overflow: "hidden",
        p: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
          p: 2,
          overflowX: { xs: "auto", md: "visible" },
          "&::-webkit-scrollbar": {
            height: 8,
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
            borderRadius: 4,
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: 4,
            "&:hover": {
              background: "#666",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            minWidth: { xs: "fit-content", md: "auto" },
            flexGrow: 1,
          }}
        >
          <ListItemAvatar sx={{ flexShrink: 0, minWidth: 48 }}>
            <Avatar>{contractor.name?.[0]}</Avatar>
          </ListItemAvatar>

          <Box sx={{ minWidth: { xs: "fit-content", md: "auto" } }}>
            <Typography sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
              {contractor.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: 0.5,
                minWidth: "fit-content",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ whiteSpace: "nowrap" }}
              >
                {contractor.company}
              </Typography>
              {contractor.rate && (
                <Chip
                  label={`£${contractor.rate}/hr`}
                  size="small"
                  sx={{
                    bgcolor: "success.lighter",
                    color: "success.main",
                    flexShrink: 0,
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            ml: 2,
            flexShrink: 0,
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleViewContractor(contractor)}
            sx={{ flexShrink: 0 }}
          >
            View
          </Button>
          <IconButton
            size="small"
            onClick={() => handleEdit(contractor)}
            sx={{ color: "primary.main", flexShrink: 0 }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleDeleteContractor(contractor)}
            sx={{ color: "error.main", flexShrink: 0 }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  );

  if (loading) return null;

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}
      <DataList
        items={contractors}
        renderItem={renderContractorCard}
        searchPlaceholder="Search contractors..."
        searchField="name"
        pageSize={8}
        filters={[
          { label: "All Specialties", value: "all" },
          { label: "Plumbing", value: "plumbing" },
          { label: "Electrical", value: "electrical" },
          { label: "HVAC", value: "hvac" },
        ]}
        actionButtons={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Add Contractor
          </Button>
        }
      />

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setEditingContractor(null);
          setError("");
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingContractor ? "Edit Contractor" : "Add New Contractor"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Name"
                fullWidth
                required
                value={
                  editingContractor
                    ? editingContractor.name
                    : newContractor.name
                }
                onChange={(e) => {
                  if (editingContractor) {
                    setEditingContractor({
                      ...editingContractor,
                      name: e.target.value,
                    });
                  } else {
                    setNewContractor({
                      ...newContractor,
                      name: e.target.value,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Company"
                fullWidth
                value={
                  editingContractor
                    ? editingContractor.company
                    : newContractor.company
                }
                onChange={(e) => {
                  if (editingContractor) {
                    setEditingContractor({
                      ...editingContractor,
                      company: e.target.value,
                    });
                  } else {
                    setNewContractor({
                      ...newContractor,
                      company: e.target.value,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                required
                value={
                  editingContractor
                    ? editingContractor.email
                    : newContractor.email
                }
                onChange={(e) => {
                  if (editingContractor) {
                    setEditingContractor({
                      ...editingContractor,
                      email: e.target.value,
                    });
                  } else {
                    setNewContractor({
                      ...newContractor,
                      email: e.target.value,
                    });
                  }
                }}
                disabled={!!editingContractor}
                helperText={editingContractor ? "Email cannot be changed" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Phone"
                fullWidth
                value={
                  editingContractor
                    ? editingContractor.phone
                    : newContractor.phone
                }
                onChange={(e) => {
                  if (editingContractor) {
                    setEditingContractor({
                      ...editingContractor,
                      phone: e.target.value,
                    });
                  } else {
                    setNewContractor({
                      ...newContractor,
                      phone: e.target.value,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={specialtyOptions}
                value={
                  editingContractor
                    ? editingContractor.specialties
                    : newContractor.specialties
                }
                onChange={(_, newValue) => {
                  if (editingContractor) {
                    setEditingContractor({
                      ...editingContractor,
                      specialties: newValue,
                    });
                  } else {
                    setNewContractor({
                      ...newContractor,
                      specialties: newValue,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Specialties"
                    required
                    margin="dense"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Hourly Rate"
                type="number"
                fullWidth
                value={
                  editingContractor
                    ? editingContractor.rate
                    : newContractor.rate
                }
                onChange={(e) => {
                  if (editingContractor) {
                    setEditingContractor({
                      ...editingContractor,
                      rate: e.target.value,
                    });
                  } else {
                    setNewContractor({
                      ...newContractor,
                      rate: e.target.value,
                    });
                  }
                }}
                InputProps={{
                  startAdornment: "£",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Notes"
                fullWidth
                multiline
                rows={3}
                value={
                  editingContractor
                    ? editingContractor.notes
                    : newContractor.notes
                }
                onChange={(e) => {
                  if (editingContractor) {
                    setEditingContractor({
                      ...editingContractor,
                      notes: e.target.value,
                    });
                  } else {
                    setNewContractor({
                      ...newContractor,
                      notes: e.target.value,
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setEditingContractor(null);
              setError("");
            }}
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={
              editingContractor ? handleUpdateContractor : handleAddContractor
            }
            variant="contained"
            disabled={
              submitting ||
              !(editingContractor || newContractor).name ||
              !(editingContractor || newContractor).email ||
              !(editingContractor || newContractor).specialties.length
            }
          >
            {submitting
              ? editingContractor
                ? "Saving..."
                : "Creating Account..."
              : editingContractor
              ? "Save Changes"
              : "Add Contractor"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setContractorToDelete(null);
        }}
      >
        <DialogTitle sx={{ color: "error.main" }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this contractor?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This will:
          </Typography>
          <Box component="ul" sx={{ mt: 1, pl: 2 }}>
            <li>
              <Typography variant="body2" color="text.secondary">
                Delete all contractor data and documents
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                Deactivate their account access
              </Typography>
            </li>
          </Box>
          {contractorToDelete && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Deleting: {contractorToDelete.name}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              setContractorToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={confirmDelete}
            disabled={submitting}
          >
            {submitting ? "Deleting..." : "Delete Contractor"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            background: "linear-gradient(180deg, #f0f7ff 0%, #FFFFFF 30.21%)",
            borderRadius: "16px",
            boxShadow: `0px 187px 75px rgba(0, 0, 0, 0.01),
              0px 105px 63px rgba(0, 0, 0, 0.05),
              0px 47px 47px rgba(0, 0, 0, 0.09),
              0px 12px 26px rgba(0, 0, 0, 0.1),
              0px 0px 0px rgba(0, 0, 0, 0.1)`,
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
            pb: 2,
            background: "linear-gradient(90deg, #2563eb 0%, #7c3aed 100%)",
            color: "white",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              sx={{
                bgcolor: "white",
                color: "#2563eb",
                width: 56,
                height: 56,
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              {selectedContractor?.name?.[0]}
            </Avatar>
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 0.5 }}>
                {selectedContractor?.name}
              </Typography>
              {selectedContractor?.company && (
                <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
                  {selectedContractor.company}
                </Typography>
              )}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(37, 99, 235, 0.05)",
                  borderRadius: 2,
                  border: "1px solid",
                  borderColor: "rgba(37, 99, 235, 0.1)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2563eb",
                    fontWeight: "bold",
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box component="span" sx={{ fontSize: "1.2rem" }}>
                    📞
                  </Box>
                  Contact Information
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Email Address
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {selectedContractor?.email}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Phone Number
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {selectedContractor?.phone || "Not provided"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(124, 58, 237, 0.05)",
                  borderRadius: 2,
                  border: "1px solid",
                  borderColor: "rgba(124, 58, 237, 0.1)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#7c3aed",
                    fontWeight: "bold",
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box component="span" sx={{ fontSize: "1.2rem" }}>
                    💼
                  </Box>
                  Business Details
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Company Name
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {selectedContractor?.company || "Independent Contractor"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Hourly Rate
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        color: "success.main",
                      }}
                    >
                      £{selectedContractor?.rate || "0"}/hr
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 3,
                  bgcolor: "rgba(37, 99, 235, 0.02)",
                  borderRadius: 2,
                  border: "1px solid",
                  borderColor: "rgba(37, 99, 235, 0.1)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2563eb",
                    fontWeight: "bold",
                    mb: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box component="span" sx={{ fontSize: "1.2rem" }}>
                    🛠️
                  </Box>
                  Specialties
                </Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  {selectedContractor?.specialties.map((specialty) => (
                    <Chip
                      key={specialty}
                      label={specialty}
                      sx={{
                        bgcolor: "white",
                        border: "1px solid",
                        borderColor: "primary.main",
                        color: "primary.main",
                        fontWeight: 500,
                        "&:hover": {
                          bgcolor: "primary.main",
                          color: "white",
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
            {selectedContractor?.notes && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 3,
                    bgcolor: "rgba(124, 58, 237, 0.02)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "rgba(124, 58, 237, 0.1)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#7c3aed",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box component="span" sx={{ fontSize: "1.2rem" }}>
                      📝
                    </Box>
                    Additional Notes
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: 1.6,
                      color: "text.secondary",
                    }}
                  >
                    {selectedContractor.notes}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ p: 3, borderTop: "1px solid", borderColor: "divider" }}
        >
          <Button
            onClick={() => setViewDialogOpen(false)}
            variant="contained"
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              px: 4,
              py: 1,
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Contractors;
