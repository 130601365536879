import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Alert,
} from "@mui/material";
import {
  Close as CloseIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { useOrganization } from "../contexts/OrganizationContext";
import { loadStripe } from "@stripe/stripe-js";
import { createCreditsPurchaseSession } from "../services/stripe";

const creditBundles = [
  {
    credits: 10,
    price: "£25",
    priceId: "price_1QbLOtP4UrRjtMjo4KIaAXAl",
    description: "Perfect for small property portfolios",
    savings: null,
  },
  {
    credits: 20,
    price: "£45",
    priceId: "price_1QbLPrP4UrRjtMjoT70TL2fn",
    description: "Most popular for growing businesses",
    savings: "10% savings",
    highlighted: true,
  },
  {
    credits: 50,
    price: "£100",
    priceId: "price_1QbLQtP4UrRjtMjo0tbikji4",
    description: "Best value for larger organizations",
    savings: "20% savings",
  },
];

const SignatureCreditsBundles = ({ open, onClose }) => {
  const { organization } = useOrganization();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handlePurchaseCredits = async (bundle) => {
    try {
      setLoading(true);
      setError("");

      if (!organization?.stripeCustomerId) {
        setError("No payment method found. Please set up billing first.");
        return;
      }

      console.log("Creating credits purchase session:", {
        priceId: bundle.priceId,
        customerId: organization.stripeCustomerId,
        credits: bundle.credits,
      });

      // Create checkout session using the new endpoint
      const { sessionId } = await createCreditsPurchaseSession(
        bundle.priceId,
        organization.stripeCustomerId,
        bundle.credits,
        organization.id
      );

      // Initialize Stripe
      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
      if (!stripe) {
        throw new Error("Failed to load Stripe");
      }

      // Redirect to checkout
      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Error purchasing credits:", error);
      setError(error.message || "Failed to process purchase");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          background: "linear-gradient(to bottom, #ffffff, #f8fafc)",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <DescriptionIcon color="primary" />
          <Typography variant="h6" component="span">
            Purchase Signature Credits
          </Typography>
        </Box>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError("")}>
            {error}
          </Alert>
        )}

        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Purchase signature credits to send contracts to your tenants and
          contractors. Credits never expire and can be used at any time.
        </Typography>

        <Grid container spacing={2}>
          {creditBundles.map((bundle) => (
            <Grid item xs={12} md={4} key={bundle.credits}>
              <Card
                sx={{
                  height: "100%",
                  position: "relative",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                  },
                  ...(bundle.highlighted && {
                    border: "2px solid",
                    borderColor: "primary.main",
                    boxShadow: "0 4px 12px rgba(37, 99, 235, 0.1)",
                  }),
                }}
              >
                {bundle.highlighted && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "primary.main",
                      color: "white",
                      px: 2,
                      py: 0.5,
                      borderRadius: "16px",
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      zIndex: 1,
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      mt: 3,
                    }}
                  >
                    Most Popular
                  </Box>
                )}
                <CardContent sx={{ pt: 4 }}>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    {bundle.credits}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Signature Credits
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold", mb: 1 }}
                  >
                    {bundle.price}
                  </Typography>
                  {bundle.savings && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: "success.main",
                        display: "block",
                        mb: 1,
                        fontWeight: "bold",
                      }}
                    >
                      {bundle.savings}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {bundle.description}
                  </Typography>
                  <Button
                    fullWidth
                    variant={bundle.highlighted ? "contained" : "outlined"}
                    onClick={() => handlePurchaseCredits(bundle)}
                    disabled={loading}
                    sx={{
                      ...(bundle.highlighted && {
                        background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                        "&:hover": {
                          background:
                            "linear-gradient(45deg, #1e40af, #5b21b6)",
                        },
                      }),
                    }}
                  >
                    {loading ? "Processing..." : "Purchase Credits"}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 3, textAlign: "center" }}>
          <Typography variant="body2" color="text.secondary">
            Need more credits? Contact our sales team for custom packages.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SignatureCreditsBundles;
