import React, { useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Pagination,
  Typography,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";

const DataList = ({
  items,
  renderItem,
  searchPlaceholder = "Search...",
  searchField = "name",
  pageSize = 10,
  filters = [],
  onFilterChange,
  actionButtons,
}) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  // Filter items based on search query
  const filteredItems = items.filter((item) =>
    item[searchField]?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Paginate items
  const totalPages = Math.ceil(filteredItems.length / pageSize);
  const paginatedItems = filteredItems.slice(
    (page - 1) * pageSize,
    page * pageSize
  );

  return (
    <Card
      sx={{
        p: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          gap: 2,
          flexWrap: "wrap",
          p: 2,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <TextField
          placeholder={searchPlaceholder}
          size="small"
          sx={{
            minWidth: 300,
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              bgcolor: "background.paper",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "text.secondary" }} />
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <Box sx={{ display: "flex", gap: 2 }}>
          {filters.length > 0 && (
            <Tooltip title="Filters">
              <IconButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{
                  bgcolor: "background.paper",
                  "&:hover": { bgcolor: "action.hover" },
                }}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
          {actionButtons}
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            sx: {
              mt: 1,
              boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
              minWidth: 200,
            },
          }}
        >
          {filters.map((filter) => (
            <MenuItem
              key={filter.value}
              onClick={() => {
                onFilterChange?.(filter.value);
                setAnchorEl(null);
              }}
            >
              {filter.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box
        component="div"
        sx={{
          flex: 1,
          overflow: "auto",
          p: 2,
          "& .MuiListItem-root": {
            listStyle: "none",
          },
          "& ul": {
            listStyle: "none",
            padding: 0,
            margin: 0,
          },
          "& li": {
            listStyle: "none",
            "&::marker": {
              display: "none",
            },
          },
        }}
      >
        <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
          {paginatedItems.length > 0 ? (
            paginatedItems.map((item) => renderItem(item))
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography color="text.secondary">No items found</Typography>
            </Box>
          )}
        </Box>
      </Box>

      {totalPages > 1 && (
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, newPage) => setPage(newPage)}
            color="primary"
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: 1,
              },
            }}
          />
        </Box>
      )}
    </Card>
  );
};

export default DataList;
