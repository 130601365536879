import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useState } from "react";
import { Analytics } from "@vercel/analytics/react";
import Login from "./components/Login";
import DashboardLayout from "./components/layout/DashboardLayout";
import Dashboard from "./components/pages/Dashboard";
import Properties from "./components/pages/Properties";
import PropertyDetails from "./components/pages/PropertyDetails";
import Maintenance from "./components/pages/Maintenance";
import Tenants from "./components/pages/Tenants";
import TenantDetails from "./components/pages/TenantDetails";
import Contractors from "./components/pages/Contractors";
import Documents from "./components/pages/Documents";
import Expenses from "./components/pages/Expenses";
import Contracts from "./components/pages/Contracts";
import KnowledgeBase from "./components/pages/KnowledgeBase";
import { OrganizationProvider } from "./contexts/OrganizationContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import EmailVerification from "./components/EmailVerification";
import Account from "./components/pages/Account";
import TrialCheck from "./components/TrialCheck";
import TenantDashboard from "./components/tenant/TenantDashboard";
import TenantMaintenance from "./components/tenant/TenantMaintenance";
import TenantOnboarding from "./components/tenant/TenantOnboarding";
import TenantDocuments from "./components/tenant/TenantDocuments";
import SetPassword from "./components/tenant/SetPassword";
import TenantPasswordSetup from "./components/tenant/TenantPasswordSetup";
import TenantReview from "./components/pages/TenantReview";
import Billing from "./components/pages/Billing";
import ContractorJobs from "./components/pages/contractor/ContractorJobs";
import ProtectedRoute from "./components/common/ProtectedRoute";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import ContractorDashboard from "./components/pages/contractor/ContractorDashboard";
import RentTracking from "./components/pages/RentTracking";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfService from "./components/pages/TermsOfService";
import AccessRestricted from "./components/pages/AccessRestricted";
import TenantPayments from "./components/pages/TenantPayments";
import TenantLayout from "./components/tenant/TenantLayout";
import AdminRoute from "./components/common/AdminRoute";
import Register from "./components/Register";
import ManualTenantApplication from "./components/pages/ManualTenantApplication";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});

const AppRoutes = () => {
  const { user, userRole } = useAuth();

  if (userRole === "contractor") {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route
            path="/dashboard/contractor"
            element={
              <ProtectedRoute allowedRoles={["contractor"]}>
                <ContractorDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/jobs"
            element={
              <ProtectedRoute allowedRoles={["contractor"]}>
                <ContractorJobs />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/dashboard/contractor" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <TrialCheck />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/properties"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <Properties />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/properties/:propertyId"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <PropertyDetails />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/maintenance"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <Maintenance />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/tenants"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <Tenants />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/tenants/:tenantId"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <TenantDetails />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/contractors"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <Contractors />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/documents"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <Documents />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/contracts"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <Contracts />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/expenses"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <Expenses />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/account"
          element={
            <ProtectedRoute allowedRoles={["admin"]}>
              <DashboardLayout>
                <Account />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/tenant/set-password" element={<SetPassword />} />
        <Route
          path="/tenant/onboarding"
          element={user ? <TenantOnboarding /> : <Navigate to="/login" />}
        />
        <Route
          path="/tenant/dashboard"
          element={user ? <TenantDashboard /> : <Navigate to="/login" />}
        />
        <Route
          path="/tenant/maintenance"
          element={user ? <TenantMaintenance /> : <Navigate to="/login" />}
        />
        <Route
          path="/tenant/documents"
          element={user ? <TenantDocuments /> : <Navigate to="/login" />}
        />
        <Route
          path="/tenant/setup-password"
          element={<TenantPasswordSetup />}
        />
        <Route
          path="/dashboard/tenants/review/:id"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <TenantReview />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/tenants/manual-application/:tenantId"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <ManualTenantApplication />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/billing"
          element={
            <ProtectedRoute allowedRoles={["admin"]}>
              <DashboardLayout>
                <Billing />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/rent-tracking"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <RentTracking />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/knowledge-base"
          element={
            <ProtectedRoute allowedRoles={["admin", "user"]}>
              <DashboardLayout>
                <KnowledgeBase />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/access-restricted" element={<AccessRestricted />} />
        <Route
          path="/"
          element={
            <ProtectedRoute
              allowedRoles={["admin", "user", "tenant", "contractor"]}
            >
              {({ userRole }) => {
                if (userRole === "contractor") return <Navigate to="/jobs" />;
                if (userRole === "tenant")
                  return <Navigate to="/tenant/dashboard" />;
                return <Navigate to="/dashboard" />;
              }}
            </ProtectedRoute>
          }
        />
        <Route
          path="/tenant/payments"
          element={
            <ProtectedRoute allowedRoles={["tenant"]}>
              <TenantLayout>
                <TenantPayments />
              </TenantLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <OrganizationProvider>
          <AppRoutes />
          <Analytics />
        </OrganizationProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
