import { emailTemplates } from "./emailTemplates";

const sendCustomEmail = async (emailData) => {
  const apiUrl = `${import.meta.env.VITE_BACKEND_URL}/api/email`;
  const apiKey = import.meta.env.VITE_API_KEY;

  // Validate API key
  if (!apiKey) {
    console.error(
      "API Key is missing. Please check your environment variables."
    );
    throw new Error("API Key is not configured");
  }

  console.log("Sending email to:", apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": apiKey,
      },
      body: JSON.stringify(emailData),
    });

    const contentType = response.headers.get("content-type");
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server Error Response:", errorText);
      throw new Error(
        contentType?.includes("json")
          ? JSON.parse(errorText).error
          : `Failed to send email: ${errorText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Email Service Error:", error);
    throw new Error(error.message || "Failed to send email");
  }
};

export const emailService = {
  async sendPasswordReset(email, resetLink) {
    const template = emailTemplates.passwordReset(resetLink);

    return sendCustomEmail({
      to: email,
      subject: template.subject,
      html: template.html,
      text: template.text,
    });
  },

  async sendWelcomeEmail(email, name, verificationLink) {
    const template = emailTemplates.welcomeEmail(name, verificationLink);
    return sendCustomEmail({
      to: email,
      subject: template.subject,
      html: template.html,
      text: template.text,
    });
  },

  async sendJobNotification(contractorEmail, contractorName, jobDetails) {
    const loginLink = `${window.location.origin}/login`;
    const template = emailTemplates.newJobNotification(
      contractorName,
      jobDetails,
      loginLink
    );

    return sendCustomEmail({
      to: contractorEmail,
      subject: template.subject,
      html: template.html,
      text: template.text,
    });
  },

  async sendRentReminder(tenantEmail, tenantName, paymentDetails) {
    const html = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h2>Rent Payment Reminder</h2>
        <p>Hello ${tenantName},</p>
        <p>This is a friendly reminder that your rent payment is due in 3 working days.</p>
        <div style="background-color: #f5f5f5; padding: 15px; border-radius: 5px; margin: 20px 0;">
          <p><strong>Payment Details:</strong></p>
          <ul style="list-style: none; padding: 0;">
            <li>Property: ${paymentDetails.propertyAddress}</li>
            <li>Amount Due: £${paymentDetails.amount.toFixed(2)}</li>
            <li>Due Date: ${paymentDetails.dueDate}</li>
          </ul>
        </div>
        <p>Please ensure your payment is made on time to avoid any late fees.</p>
        <p>If you have already made this payment, please disregard this reminder.</p>
        <p>Thank you for your prompt attention to this matter.</p>
      </div>
    `;

    return sendCustomEmail({
      to: tenantEmail,
      subject: "Rent Payment Reminder",
      html,
      text: `Rent Payment Reminder for ${paymentDetails.propertyAddress}
      
Hello ${tenantName},

This is a friendly reminder that your rent payment is due in 3 working days.

Payment Details:
- Property: ${paymentDetails.propertyAddress}
- Amount Due: £${paymentDetails.amount.toFixed(2)}
- Due Date: ${paymentDetails.dueDate}

Please ensure your payment is made on time to avoid any late fees.
If you have already made this payment, please disregard this reminder.

Thank you for your prompt attention to this matter.`,
    });
  },

  async sendTenantWelcomeEmail(tenantEmail, tenantName, propertyName) {
    const html = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h2>Welcome to Your Tenant Portal! 🎉</h2>
        <p>Dear ${tenantName},</p>
        
        <p>Great news! Your application for ${propertyName} has been approved. We're excited to welcome you to your new home and introduce you to your tenant portal.</p>

        <div style="background-color: #f5f5f5; padding: 20px; border-radius: 8px; margin: 20px 0;">
          <h3 style="margin-top: 0;">Your Portal Features:</h3>
          <ul style="list-style-type: none; padding: 0;">
            <li style="margin-bottom: 10px;">📱 <strong>Rent Payments:</strong> View upcoming payments and payment history</li>
            <li style="margin-bottom: 10px;">🔧 <strong>Maintenance:</strong> Submit and track maintenance requests</li>
            <li style="margin-bottom: 10px;">📄 <strong>Documents:</strong> Access important documents and upload new ones</li>
            <li style="margin-bottom: 10px;">📞 <strong>Communication:</strong> Easy communication with property management</li>
          </ul>
        </div>

        <div style="margin: 20px 0;">
          <h3>Getting Started:</h3>
          <ol style="padding-left: 20px;">
            <li>Log in to your portal at ${window.location.origin}/login</li>
            <li>Review your upcoming rent payments</li>
            <li>Upload any remaining required documents</li>
            <li>Set up your maintenance preferences</li>
          </ol>
        </div>

        <p>Need help? Don't hesitate to reach out to our support team.</p>

        <p>Best regards,<br>Your Property Management Team</p>
      </div>
    `;

    return sendCustomEmail({
      to: tenantEmail,
      subject: "Welcome to Your Tenant Portal! 🏠",
      html,
      text: `Welcome to Your Tenant Portal!

Dear ${tenantName},

Great news! Your application for ${propertyName} has been approved. We're excited to welcome you to your new home and introduce you to your tenant portal.

Your Portal Features:
- Rent Payments: View upcoming payments and payment history
- Maintenance: Submit and track maintenance requests
- Documents: Access important documents and upload new ones
- Communication: Easy communication with property management

Getting Started:
1. Log in to your portal at ${window.location.origin}/login
2. Review your upcoming rent payments
3. Upload any remaining required documents
4. Set up your maintenance preferences

Need help? Don't hesitate to reach out to our support team.

Best regards,
Your Property Management Team`,
    });
  },

  async sendContractorWelcomeEmail(
    contractorEmail,
    contractorName,
    specialties
  ) {
    const html = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h2>Welcome to the Contractor Portal! 🛠️</h2>
        <p>Dear ${contractorName},</p>
        
        <p>Welcome aboard! Your contractor account has been successfully created. We're excited to have you join our network of professional contractors.</p>

        <div style="background-color: #f5f5f5; padding: 20px; border-radius: 8px; margin: 20px 0;">
          <h3 style="margin-top: 0;">Your Portal Features:</h3>
          <ul style="list-style-type: none; padding: 0;">
            <li style="margin-bottom: 10px;">🔧 <strong>Job Management:</strong> View and manage maintenance requests</li>
            <li style="margin-bottom: 10px;">📅 <strong>Scheduling:</strong> Manage your availability and appointments</li>
            <li style="margin-bottom: 10px;">📄 <strong>Documentation:</strong> Access and upload job-related documents</li>
            <li style="margin-bottom: 10px;">💰 <strong>Payments:</strong> Track your jobs and payments</li>
          </ul>
        </div>

        <div style="background-color: #e8f5e9; padding: 20px; border-radius: 8px; margin: 20px 0;">
          <h3 style="margin-top: 0; color: #2e7d32;">Your Registered Specialties:</h3>
          <ul style="list-style-type: none; padding: 0;">
            ${specialties
              .map(
                (specialty) =>
                  `<li style="margin-bottom: 8px;">✓ ${specialty}</li>`
              )
              .join("")}
          </ul>
        </div>

        <div style="margin: 20px 0;">
          <h3>Getting Started:</h3>
          <ol style="padding-left: 20px;">
            <li>Log in to your portal at ${window.location.origin}/login</li>
            <li>Complete your profile and availability</li>
            <li>Review any pending maintenance requests</li>
            <li>Set up your payment preferences</li>
          </ol>
        </div>

        <p>If you have any questions or need assistance, our support team is here to help!</p>

        <p>Best regards,<br>Your Property Management Team</p>
      </div>
    `;

    return sendCustomEmail({
      to: contractorEmail,
      subject: "Welcome to the Contractor Portal! 🛠️",
      html,
      text: `Welcome to the Contractor Portal!

Dear ${contractorName},

Welcome aboard! Your contractor account has been successfully created. We're excited to have you join our network of professional contractors.

Your Portal Features:
- Job Management: View and manage maintenance requests
- Scheduling: Manage your availability and appointments
- Documentation: Access and upload job-related documents
- Payments: Track your jobs and payments

Your Registered Specialties:
${specialties.map((specialty) => `✓ ${specialty}`).join("\n")}

Getting Started:
1. Log in to your portal at ${window.location.origin}/login
2. Complete your profile and availability
3. Review any pending maintenance requests
4. Set up your payment preferences

If you have any questions or need assistance, our support team is here to help!

Best regards,
Your Property Management Team`,
    });
  },

  async sendContractSignedEmail(organizationEmail, contractDetails) {
    const html = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h2>Contract Signed! ✍️</h2>
        <p>A contract has been signed by ${contractDetails.firstName} ${
      contractDetails.lastName
    }.</p>
        
        <div style="background-color: #f5f5f5; padding: 20px; border-radius: 8px; margin: 20px 0;">
          <h3 style="margin-top: 0;">Contract Details:</h3>
          <ul style="list-style-type: none; padding: 0;">
            <li style="margin-bottom: 10px;"><strong>Tenant:</strong> ${
              contractDetails.firstName
            } ${contractDetails.lastName}</li>
            <li style="margin-bottom: 10px;"><strong>Email:</strong> ${
              contractDetails.email
            }</li>
            <li style="margin-bottom: 10px;"><strong>Property:</strong> ${
              contractDetails.propertyAddress
            }</li>
            <li style="margin-bottom: 10px;"><strong>Signed On:</strong> ${new Date(
              contractDetails.signedAt
            ).toLocaleDateString()}</li>
          </ul>
        </div>

        <p>You can view the signed contract in your dashboard under the Contracts section.</p>
      </div>
    `;

    const emailData = {
      to: organizationEmail,
      subject: `Contract Signed - ${contractDetails.firstName} ${contractDetails.lastName}`,
      html,
    };

    try {
      await this.sendEmail(emailData);
    } catch (error) {
      console.error("Error sending contract signed email:", error);
      throw error;
    }
  },

  async sendIssueReport(userName, userEmail, issueDetails, organizationId) {
    const template = emailTemplates.issueReport(
      userName,
      userEmail,
      issueDetails,
      organizationId
    );

    return sendCustomEmail({
      to: "support@tenantio.cloud",
      subject: template.subject,
      html: template.html,
      text: template.text,
    });
  },

  // Add more email templates as needed
};
