import { addDoc, collection, getFirestore } from "firebase/firestore";

export const generateRentPayments = async (tenancyData, organizationId) => {
  const db = getFirestore();
  const {
    rentDueDate,
    leaseStart,
    leaseEnd,
    propertyId,
    propertyName,
    propertyAddress,
    unit,
    unitNumber,
    tenantId,
    tenantName,
    rentAmount,
    isSharedPayment,
    rentPortion,
    isLeadTenant,
  } = tenancyData;

  // Convert dates to Date objects
  const startDate = new Date(leaseStart);
  const endDate = new Date(leaseEnd);

  // Generate a payment for each month of the tenancy
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    // Set the payment due date to the specified day of the month
    const dueDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      rentDueDate
    );

    const payment = {
      organizationId,
      propertyId,
      propertyName,
      propertyAddress,
      unit,
      unitNumber,
      tenantId,
      tenantName,
      amount: rentAmount,
      dueDate: dueDate.toISOString(),
      status: "payment_due",
      paymentType: "rent",
      isSharedPayment,
      rentPortion,
      isLeadTenant,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    try {
      await addDoc(collection(db, "rentPayments"), payment);
    } catch (error) {
      console.error("Error creating rent payment:", error);
      throw error;
    }

    // Move to next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
};

export const validateTenancyPeriod = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate months difference
  const months =
    (end.getFullYear() - start.getFullYear()) * 12 +
    (end.getMonth() - start.getMonth());

  // Ensure it's a full month period
  if (start.getDate() !== 1) {
    return {
      valid: false,
      message: "Start date must be the first day of the month",
    };
  }

  // Ensure it's a multiple of 6 months
  if (months % 6 !== 0 || months === 0) {
    return {
      valid: false,
      message: "Tenancy period must be in multiples of 6 months",
    };
  }

  return { valid: true, months };
};
