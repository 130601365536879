import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Add as AddIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Description as DocumentIcon,
  Folder as FolderIcon,
  Share as ShareIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getAuth } from "firebase/auth";

const templateCategories = [
  {
    id: "tenancy_agreements",
    title: "Tenancy Agreements",
    description: "Standard templates for creating new tenancy agreements",
    icon: <DocumentIcon />,
  },
  {
    id: "guides",
    title: "How to Rent Guides",
    description: "Official government and custom guidance documents",
    icon: <FolderIcon />,
  },
  {
    id: "inspection_forms",
    title: "Property Inspection Forms",
    description: "Templates for various property inspections",
    icon: <DocumentIcon />,
  },
  {
    id: "maintenance",
    title: "Maintenance Request Forms",
    description: "Standardized maintenance and repair documentation",
    icon: <DocumentIcon />,
  },
  {
    id: "notices",
    title: "Notice Templates",
    description: "Various legal notices and communications",
    icon: <DocumentIcon />,
  },
  {
    id: "inventory",
    title: "Inventory Checklists",
    description: "Comprehensive inventory documentation",
    icon: <DocumentIcon />,
  },
];

const Documents = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [templates, setTemplates] = useState({});
  const [newTemplate, setNewTemplate] = useState({
    title: "",
    category: "",
    description: "",
    file: null,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success", // or "error"
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    template: null,
  });

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    if (auth.currentUser) {
      fetchTemplates();
    }
  }, [auth.currentUser]);

  const fetchTemplates = async () => {
    try {
      const q = query(
        collection(db, "templates"),
        where("organizationId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      // Group templates by category
      const templatesByCategory = {};
      querySnapshot.forEach((doc) => {
        const template = { id: doc.id, ...doc.data() };
        if (!templatesByCategory[template.category]) {
          templatesByCategory[template.category] = [];
        }
        templatesByCategory[template.category].push(template);
      });

      setTemplates(templatesByCategory);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewTemplate({ ...newTemplate, file });
    }
  };

  const handleUploadTemplate = async () => {
    if (!newTemplate.file || !newTemplate.category) return;
    setIsUploading(true);

    try {
      const timestamp = new Date().getTime();
      const fileName = `organizations/${auth.currentUser.uid}/templates/${newTemplate.category}/${timestamp}_${newTemplate.file.name}`;
      const storageRef = ref(storage, fileName);

      const uploadTask = uploadBytesResumable(storageRef, newTemplate.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
          setNotification({
            open: true,
            message: "Failed to upload template. Please try again.",
            severity: "error",
          });
          setIsUploading(false);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(storageRef);

            await addDoc(collection(db, "templates"), {
              title: newTemplate.title,
              category: newTemplate.category,
              description: newTemplate.description,
              fileName: newTemplate.file.name,
              fileUrl: downloadURL,
              filePath: fileName,
              uploadDate: new Date().toISOString(),
              organizationId: auth.currentUser.uid,
              size: newTemplate.file.size,
              type: newTemplate.file.type,
            });

            setNotification({
              open: true,
              message: "Template uploaded successfully!",
              severity: "success",
            });
            setOpenDialog(false);
            setNewTemplate({
              title: "",
              category: "",
              description: "",
              file: null,
            });
            setUploadProgress(0);
            fetchTemplates();
          } catch (error) {
            console.error("Error saving template details:", error);
            setNotification({
              open: true,
              message: "Failed to save template details. Please try again.",
              severity: "error",
            });
          }
          setIsUploading(false);
        }
      );
    } catch (error) {
      console.error("Error uploading template:", error);
      setNotification({
        open: true,
        message: "Failed to upload template. Please try again.",
        severity: "error",
      });
      setIsUploading(false);
    }
  };

  const handleDeleteTemplate = async (template) => {
    try {
      setIsUploading(true);
      const storageRef = ref(storage, template.filePath);

      await deleteObject(storageRef);

      await deleteDoc(doc(db, "templates", template.id));

      await fetchTemplates();

      setNotification({
        open: true,
        message: "Template deleted successfully",
        severity: "success",
      });

      setDeleteConfirmation({ open: false, template: null });
    } catch (error) {
      console.error("Error deleting template:", error);
      setNotification({
        open: true,
        message: "Failed to delete template. Please try again.",
        severity: "error",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteClick = (template, event) => {
    event.stopPropagation();
    setDeleteConfirmation({
      open: true,
      template,
    });
  };

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{
            background: "linear-gradient(45deg, #2563eb, #7c3aed)",
            "&:hover": {
              background: "linear-gradient(45deg, #1e40af, #5b21b6)",
            },
          }}
        >
          Upload Template
        </Button>
      </Box>

      <Grid container spacing={4}>
        {templateCategories.map((category) => (
          <Grid item xs={12} md={6} key={category.id}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: (theme) => theme.shadows[2],
                "&:hover": {
                  boxShadow: (theme) => theme.shadows[6],
                  transform: "translateY(-4px)",
                  transition: "all 0.3s ease-in-out",
                },
              }}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Box
                    sx={{
                      mr: 2,
                      display: "flex",
                      alignItems: "center",
                      color: "primary.main",
                    }}
                  >
                    {category.icon}
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      {category.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {category.description}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ mt: 2 }}>
                  {templates[category.id]?.map((template) => (
                    <Box
                      key={template.id}
                      sx={{
                        p: 2,
                        mb: 1,
                        border: 1,
                        borderColor: "divider",
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          {template.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {template.description}
                        </Typography>
                      </Box>
                      <Box>
                        <Tooltip title="Download">
                          <IconButton
                            size="small"
                            onClick={() =>
                              window.open(template.fileUrl, "_blank")
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            onClick={(e) => handleDeleteClick(template, e)}
                            disabled={isUploading}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  ))}
                  {(!templates[category.id] ||
                    templates[category.id].length === 0) && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textAlign: "center", py: 2 }}
                    >
                      No templates uploaded yet
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => {
          if (!isUploading) {
            setOpenDialog(false);
            setNewTemplate({
              title: "",
              category: "",
              description: "",
              file: null,
            });
          }
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload New Template</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Template Title"
            fullWidth
            value={newTemplate.title}
            onChange={(e) =>
              setNewTemplate({ ...newTemplate, title: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Category</InputLabel>
            <Select
              value={newTemplate.category}
              label="Category"
              onChange={(e) =>
                setNewTemplate({ ...newTemplate, category: e.target.value })
              }
            >
              {templateCategories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={newTemplate.description}
            onChange={(e) =>
              setNewTemplate({ ...newTemplate, description: e.target.value })
            }
          />
          <Button
            component="label"
            variant="outlined"
            startIcon={<UploadIcon />}
            sx={{ mt: 2 }}
            fullWidth
          >
            Select File
            <input
              type="file"
              hidden
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.txt,.xls,.xlsx"
            />
          </Button>
          {newTemplate.file && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected: {newTemplate.file.name}
            </Typography>
          )}
          {uploadProgress > 0 && uploadProgress < 100 && (
            <Box sx={{ mt: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} disabled={isUploading}>
            Cancel
          </Button>
          <Button
            onClick={handleUploadTemplate}
            variant="contained"
            disabled={
              isUploading ||
              !newTemplate.file ||
              !newTemplate.title ||
              !newTemplate.category
            }
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, template: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete "
            {deleteConfirmation.template?.title}"? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setDeleteConfirmation({ open: false, template: null })
            }
            disabled={isUploading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteTemplate(deleteConfirmation.template)}
            color="error"
            variant="contained"
            disabled={isUploading}
          >
            {isUploading ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ top: { xs: 16, sm: 24 } }}
      >
        <Alert
          onClose={handleNotificationClose}
          severity={notification.severity}
          variant="filled"
          sx={{
            width: "100%",
            boxShadow: (theme) => theme.shadows[3],
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Documents;
