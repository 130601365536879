import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Paper,
  Alert,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Google as GoogleIcon,
  Lock as LockIcon,
  VerifiedUser as VerifiedUserIcon,
  Security as SecurityIcon,
  Apartment as ApartmentIcon,
  AccountBalance as AccountBalanceIcon,
  Build as BuildIcon,
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  Timeline as TimelineIcon,
} from "@mui/icons-material";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useOrganization } from "../contexts/OrganizationContext";
import OrganizationSetup from "./OrganizationSetup";
import { rateLimit } from "../utils/rateLimit";
import ReCAPTCHA from "react-google-recaptcha";
import { createStripeCustomer } from "../services/stripe";
import { Helmet } from "react-helmet-async";

const Register = () => {
  const [error, setError] = useState("");
  const [showOrgSetup, setShowOrgSetup] = useState(false);
  const [pendingUser, setPendingUser] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    companyName: "",
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const { setOrganization } = useOrganization();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGoogleLogin = async () => {
    try {
      setIsSubmitting(true);
      setError("");

      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if user already exists
      const userDoc = await getDoc(doc(db, "users", user.uid));

      if (!userDoc.exists()) {
        // New user - show organization setup
        setPendingUser(user);
        setShowOrgSetup(true);
      } else {
        // Existing user - redirect to dashboard
        const userData = userDoc.data();
        setOrganization(userData.organizationId);
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error with Google sign in:", error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      setError("Please complete the reCAPTCHA verification");
      return;
    }

    if (!acceptedPrivacyPolicy) {
      setError("Please accept the Privacy Policy to continue");
      return;
    }

    try {
      setIsSubmitting(true);
      setError("");

      // Rate limit check - temporarily disabled
      const rateLimitResult = await rateLimit(formData.email);
      // Rate limiting is bypassed during testing, so we don't need to check the result

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email.trim(),
        formData.password
      );

      // Send verification email
      await sendEmailVerification(userCredential.user);

      // Create organization ID from company name
      const orgId =
        formData.companyName
          .toLowerCase()
          .replace(/[^a-z0-9]/g, "-")
          .replace(/-+/g, "-")
          .replace(/^-|-$/g, "") +
        "-" +
        Math.random().toString(36).substring(2, 7);

      // First create organization document
      const orgRef = doc(db, "organizations", orgId);
      await setDoc(orgRef, {
        id: orgId,
        name: formData.companyName,
        createdAt: Timestamp.now(),
        createdBy: userCredential.user.uid,
        plan: {
          status: "pending_plan",
          createdAt: Timestamp.now(),
        },
      });

      // Create user document
      const userRef = doc(db, "users", userCredential.user.uid);
      await setDoc(userRef, {
        email: userCredential.user.email,
        name: formData.name,
        organizationId: orgId,
        role: "admin",
        createdAt: Timestamp.now(),
      });

      // Send welcome email
      try {
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-API-Key": import.meta.env.VITE_API_KEY,
            },
            body: JSON.stringify({
              to: userCredential.user.email,
              subject: "Welcome to TenantIO! 🏠",
              html: `
              <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
                <h2>Welcome to TenantIO! 🎉</h2>
                <p>Hello ${formData.name},</p>
                <p>Thank you for joining TenantIO! We're excited to have you on board.</p>
                
                <div style="background-color: #f8fafc; padding: 20px; border-radius: 8px; margin: 20px 0;">
                  <h3 style="margin-top: 0;">Getting Started:</h3>
                  <ul style="margin: 10px 0;">
                    <li>Check out our <a href="https://www.tenantio.org/how-to-guides" style="color: #2563eb;">How-to Guides</a> to make the most of your account</li>
                    <li>Set up your profile and preferences</li>
                    <li>Explore the platform features</li>
                  </ul>
                </div>

                <p>Need help? Our support team is here to assist you!</p>
                <p style="margin-top: 30px;">Best regards,<br>The TenantIO Team</p>
              </div>
            `,
            }),
          }
        );

        if (!response.ok) {
          console.error("Failed to send welcome email");
        }
      } catch (emailError) {
        console.error("Error sending welcome email:", emailError);
      }

      // Then create Stripe customer and update organization
      const stripeCustomer = await createStripeCustomer(orgId, {
        email: userCredential.user.email,
        name: formData.name,
        organizationId: orgId,
      });

      // Update organization with Stripe customer ID
      await updateDoc(orgRef, {
        stripeCustomerId: stripeCustomer.customerId,
        updatedAt: new Date().toISOString(),
      });

      // Show verification dialog and don't automatically log in
      setShowVerificationDialog(true);
      // Remove automatic login and navigation
      // setOrganization(orgId);
      // navigate("/dashboard");
    } catch (error) {
      console.error("Error registering:", error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOrgSetupComplete = async (orgData) => {
    try {
      if (!pendingUser) {
        throw new Error("No pending user found");
      }

      // Create organization document
      const orgRef = doc(db, "organizations", orgData.id);
      await setDoc(orgRef, {
        ...orgData,
        createdAt: Timestamp.now(),
        createdBy: pendingUser.uid,
        plan: {
          status: "pending_plan",
          createdAt: Timestamp.now(),
        },
      });

      // Create user document
      const userRef = doc(db, "users", pendingUser.uid);
      await setDoc(userRef, {
        email: pendingUser.email,
        name: orgData.ownerName || pendingUser.displayName || "",
        organizationId: orgData.id,
        role: "admin",
        createdAt: Timestamp.now(),
      });

      // Send welcome email
      try {
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-API-Key": import.meta.env.VITE_API_KEY,
            },
            body: JSON.stringify({
              to: pendingUser.email,
              subject: "Welcome to TenantIO! 🏠",
              html: `
              <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
                <h2>Welcome to TenantIO! 🎉</h2>
                <p>Hello ${
                  orgData.ownerName || pendingUser.displayName || "there"
                },</p>
                <p>Thank you for joining TenantIO! We're excited to have you on board.</p>
                
                <div style="background-color: #f8fafc; padding: 20px; border-radius: 8px; margin: 20px 0;">
                  <h3 style="margin-top: 0;">Getting Started:</h3>
                  <ul style="margin: 10px 0;">
                    <li>Check out our <a href="https://www.tenantio.org/how-to-guides" style="color: #2563eb;">How-to Guides</a> to make the most of your account</li>
                    <li>Set up your profile and preferences</li>
                    <li>Explore the platform features</li>
                  </ul>
                </div>

                <p>Need help? Our support team is here to assist you!</p>
                <p style="margin-top: 30px;">Best regards,<br>The TenantIO Team</p>
              </div>
            `,
            }),
          }
        );

        if (!response.ok) {
          console.error("Failed to send welcome email");
        }
      } catch (emailError) {
        console.error("Error sending welcome email:", emailError);
      }

      setOrganization(orgData.id);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error completing organization setup:", error);
      setError(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Register | TenantIO - Property Management Software | 14-Day Free Trial
        </title>
        <meta
          name="description"
          content="Sign up for TenantIO's property management platform. Start your 14-day free trial, no credit card required. Streamline rent collection, maintenance, and tenant communication."
        />
      </Helmet>

      {/* Verification Dialog */}
      <Dialog open={showVerificationDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <VerifiedUserIcon color="primary" />
            <Typography variant="h6">Verify Your Email</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography paragraph>
            A verification email has been sent to{" "}
            <strong>{formData.email}</strong>
          </Typography>
          <Typography>
            Please check your email and click the verification link to activate
            your account. After verification, you can log in to access your
            dashboard.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            component={RouterLink}
            to="/login"
            variant="contained"
            fullWidth
            sx={{
              height: 48,
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Go to Login
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          background: "linear-gradient(135deg, #f6f9fc 0%, #ecf0f5 100%)",
          position: "relative",
          overflow: { xs: "hidden", md: "hidden" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -100,
            right: -100,
            width: { xs: 300, md: 400 },
            height: { xs: 300, md: 400 },
            borderRadius: "50%",
            background: "linear-gradient(45deg, #2563eb33, #7c3aed33)",
            filter: "blur(80px)",
            display: { xs: "none", md: "block" },
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: -100,
            left: -100,
            width: { xs: 300, md: 400 },
            height: { xs: 300, md: 400 },
            borderRadius: "50%",
            background: "linear-gradient(45deg, #7c3aed33, #2563eb33)",
            filter: "blur(80px)",
            display: { xs: "none", md: "block" },
          }}
        />

        <Container
          maxWidth="xl"
          sx={{
            py: { xs: 0, md: 4 },
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            {/* Left side - Features - Hidden on mobile */}
            <Grid
              item
              md={7}
              lg={8}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Box sx={{ p: 4 }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    mb: 4,
                    background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Streamline Your Property Management
                </Typography>

                <Grid container spacing={3}>
                  {[
                    {
                      icon: <LockIcon />,
                      title: "Secure Platform",
                      description:
                        "Bank-level security for your data and transactions",
                    },
                    {
                      icon: <ApartmentIcon />,
                      title: "Property Management",
                      description:
                        "Efficiently manage multiple properties in one place",
                    },
                    {
                      icon: <AccountBalanceIcon />,
                      title: "Rent Managment",
                      description: "Automated rent tracking",
                    },
                    {
                      icon: <BuildIcon />,
                      title: "Maintenance",
                      description: "Streamlined maintenance request handling",
                    },
                    {
                      icon: <PeopleIcon />,
                      title: "Tenant Portal",
                      description: "Self-service portal for your tenants",
                    },
                    {
                      icon: <AssignmentIcon />,
                      title: "Digital Contracts",
                      description:
                        "Electronic signatures and document management",
                    },
                    {
                      icon: <TimelineIcon />,
                      title: "Financial Tracking",
                      description:
                        "Comprehensive financial reporting and analytics",
                    },
                    {
                      icon: <SecurityIcon />,
                      title: "Compliance",
                      description: "Stay compliant with property regulations",
                    },
                  ].map((feature, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 2,
                          p: 2,
                          borderRadius: 2,
                          transition: "transform 0.2s",
                          "&:hover": {
                            transform: "translateY(-4px)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            p: 1,
                            borderRadius: 2,
                            background:
                              "linear-gradient(135deg, #2563eb08 0%, #7c3aed08 100%)",
                            color: "primary.main",
                          }}
                        >
                          {feature.icon}
                        </Box>
                        <Box>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 600, mb: 0.5 }}
                          >
                            {feature.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ lineHeight: 1.6 }}
                          >
                            {feature.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>

            {/* Right side - Registration Form */}
            <Grid item xs={12} sm={10} md={5} lg={4}>
              <Paper
                elevation={24}
                sx={{
                  p: { xs: 2, sm: 4 },
                  borderRadius: 3,
                  bgcolor: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(20px)",
                  mx: { xs: 2, sm: 0 },
                }}
              >
                <Box sx={{ mb: 4, textAlign: "center" }}>
                  <Box
                    component="img"
                    src="/logo.png"
                    alt="TenantIO Logo"
                    sx={{
                      width: { xs: 140, sm: 180 },
                      height: "auto",
                      mb: 3,
                      mx: "auto",
                      display: "block",
                    }}
                  />
                  <Typography
                    component="h1"
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                      backgroundClip: "text",
                      color: "transparent",
                      mb: 1,
                      fontSize: { xs: "1.75rem", sm: "2.125rem" },
                    }}
                  >
                    Create Account
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    Get started with property management
                  </Typography>

                  <Paper
                    elevation={0}
                    sx={{
                      mt: 3,
                      p: 2,
                      background:
                        "linear-gradient(135deg, #2563eb08 0%, #7c3aed08 100%)",
                      border: "2px solid",
                      borderColor: "primary.main",
                      borderRadius: 3,
                      position: "relative",
                      overflow: "hidden",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        height: "3px",
                        background: "linear-gradient(90deg, #2563eb, #7c3aed)",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <VerifiedUserIcon color="primary" />
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: 600, mb: 0.5 }}
                        >
                          14-Day Free Trial
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          No credit card required
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>

                {error && (
                  <Alert
                    severity="error"
                    sx={{
                      mb: 2,
                      borderRadius: 2,
                      "& .MuiAlert-message": {
                        width: "100%",
                      },
                    }}
                  >
                    {error}
                  </Alert>
                )}

                <Box component="form" onSubmit={handleRegister}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Full Name"
                    name="name"
                    autoComplete="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Company Name"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />

                  <Box
                    sx={{
                      my: { xs: 1, sm: 2 },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                      onChange={(token) => setCaptchaToken(token)}
                      size={window.innerWidth < 400 ? "compact" : "normal"}
                    />
                  </Box>

                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={acceptedPrivacyPolicy}
                        onChange={(e) =>
                          setAcceptedPrivacyPolicy(e.target.checked)
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I agree to the{" "}
                        <Link
                          component={RouterLink}
                          to="/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>{" "}
                        and{" "}
                        <Link
                          component={RouterLink}
                          to="/terms-of-service"
                          target="_blank"
                        >
                          Terms of Service
                        </Link>
                      </Typography>
                    }
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{
                      mt: 3,
                      mb: 2,
                      height: 48,
                      background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                      "&:hover": {
                        background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                      },
                    }}
                  >
                    {isSubmitting ? "Creating Account..." : "Create Account"}
                  </Button>

                  <Box sx={{ textAlign: "center", mt: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      Already have an account?{" "}
                      <Link component={RouterLink} to="/login">
                        Sign in
                      </Link>
                    </Typography>
                  </Box>

                  <Box sx={{ my: 2, textAlign: "center" }}>
                    <Typography variant="body2" color="text.secondary">
                      OR
                    </Typography>
                  </Box>

                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<GoogleIcon />}
                    onClick={handleGoogleLogin}
                    sx={{
                      height: 48,
                      borderColor: "divider",
                      "&:hover": {
                        borderColor: "primary.main",
                        background: "rgba(37, 99, 235, 0.04)",
                      },
                    }}
                  >
                    Continue with Google
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <OrganizationSetup
        open={showOrgSetup}
        onClose={() => setShowOrgSetup(false)}
        user={pendingUser}
        onComplete={handleOrgSetupComplete}
      />
    </>
  );
};

export default Register;
