import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { useOrganization } from "../contexts/OrganizationContext";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import PricingPlans from "./PricingPlans";
import { createSubscriptionSession } from "../services/stripe";
import { loadStripe } from "@stripe/stripe-js";

const TrialCheck = () => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { organization, setOrganization } = useOrganization();
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const checkStatus = async () => {
      if (!organization?.id) return;

      const orgDoc = await getDoc(doc(db, "organizations", organization.id));
      const orgData = orgDoc.data();

      // Check for active status first
      if (orgData.plan?.status === "active") {
        setShowPaymentDialog(false);
        return;
      }

      // For cancelled subscriptions, only show dialog if we've passed the deactivation date
      if (orgData.plan?.status === "cancelled") {
        const deactivationDate = orgData.plan.deactivationDate
          ? new Date(orgData.plan.deactivationDate)
          : null;
        const now = new Date();

        if (!deactivationDate || now < deactivationDate) {
          // Still has active access until deactivation date
          setShowPaymentDialog(false);
          return;
        }
      }

      // Always show dialog for pending_plan or pending_payment status
      if (["pending_plan", "pending_payment"].includes(orgData.plan?.status)) {
        setShowPaymentDialog(true);
        return;
      }

      // Then check trial status
      if (orgData.plan?.status === "trial") {
        const trialEnd = new Date(orgData.plan.trialEndsAt);
        const now = new Date();

        if (now > trialEnd) {
          // Update to trial_expired if trial has ended
          const updatedPlan = {
            ...orgData.plan,
            status: "trial_expired",
            trialUsed: true,
          };

          await updateDoc(doc(db, "organizations", organization.id), {
            plan: updatedPlan,
          });

          setOrganization({
            ...orgData,
            plan: updatedPlan,
          });

          setShowPaymentDialog(true);
        } else {
          // Trial is still active
          setShowPaymentDialog(false);
        }
      } else if (["trial_expired"].includes(orgData.plan?.status)) {
        setShowPaymentDialog(true);
      }
    };

    checkStatus();
  }, [organization?.id]);

  const handlePlanSelect = async (planData) => {
    try {
      setIsProcessing(true);

      // If status is pending_plan, start a trial instead of payment
      if (organization?.plan?.status === "pending_plan") {
        const trialEndDate = new Date();
        trialEndDate.setDate(trialEndDate.getDate() + 14); // 14 day trial

        const updatedPlan = {
          ...planData,
          status: "trial",
          trialUsed: false,
          startDate: new Date().toISOString(),
          trialEndsAt: trialEndDate.toISOString(),
        };

        // Update Firestore first
        await updateDoc(doc(db, "organizations", organization.id), {
          plan: updatedPlan,
        });

        // Then update local state
        setOrganization((prev) => ({
          ...prev,
          plan: updatedPlan,
        }));

        // Close dialog and reset processing state
        setShowPaymentDialog(false);
        setIsProcessing(false);

        // Force a page reload to ensure clean state
        window.location.reload();
        return;
      }

      // For non-trial cases (expired, cancelled, etc), proceed with payment
      const updatedPlan = {
        ...planData,
        status: "pending_payment",
        trialUsed: true,
        startDate: new Date().toISOString(),
        trialEndsAt: null,
      };

      await updateDoc(doc(db, "organizations", organization.id), {
        plan: updatedPlan,
      });

      setOrganization((prev) => ({
        ...prev,
        plan: updatedPlan,
      }));

      // Create Stripe checkout session after updating plan
      const { sessionId } = await createSubscriptionSession(
        planData.priceId,
        organization.stripeCustomerId
      );

      // Initialize Stripe and redirect to checkout
      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
      if (!stripe) throw new Error("Stripe failed to initialize");

      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Error updating plan:", error);
      setIsProcessing(false);
    }
  };

  const handleClose = async () => {
    // Only allow closing if in trial or active status
    const isActive = organization?.plan?.status === "active";
    const isInTrial =
      organization?.plan?.status === "trial" &&
      new Date(organization.plan.trialEndsAt) > new Date();

    // Never allow closing if status is pending_plan
    if (organization?.plan?.status === "pending_plan") {
      return;
    }

    if (!isActive && !isInTrial) {
      return;
    }

    setShowPaymentDialog(false);
  };

  const getDialogTitle = () => {
    if (organization?.plan?.status === "pending_payment") {
      return "Complete Your Payment";
    }
    if (organization?.plan?.status === "cancelled") {
      const deactivationDate = organization.plan.deactivationDate
        ? new Date(organization.plan.deactivationDate)
        : null;
      if (deactivationDate) {
        const now = new Date();
        if (now < deactivationDate) {
          const daysRemaining = Math.ceil(
            (deactivationDate - now) / (1000 * 60 * 60 * 24)
          );
          return `Subscription Will Cancel in ${daysRemaining} Days`;
        }
      }
      return "Subscription Cancelled";
    }
    if (organization?.plan?.status === "pending_plan") {
      return "Choose Your Trial Plan";
    }
    return "Free Trial Expired";
  };

  const getDialogMessage = () => {
    if (organization?.plan?.status === "pending_payment") {
      return "Please complete your payment to continue using all features. Your selected plan will be activated immediately after payment confirmation.";
    }
    if (organization?.plan?.status === "cancelled") {
      const deactivationDate = organization.plan.deactivationDate
        ? new Date(organization.plan.deactivationDate)
        : null;
      if (deactivationDate) {
        const now = new Date();
        if (now < deactivationDate) {
          return `Your subscription will remain active until ${deactivationDate.toLocaleDateString()}. After this date, you'll need to resubscribe to continue using all features.`;
        }
      }
      return "Your subscription has been cancelled. Please select a plan to continue using all features.";
    }
    if (organization?.plan?.status === "pending_plan") {
      return "Welcome to TenantIO! Please select a plan to start your 14-day free trial. No credit card required during the trial period.";
    }
    return "Your trial period has ended. Please select a plan to continue using all features. Your data will be preserved for 7 days after trial expiration.";
  };

  useEffect(() => {
    // Force dialog to show for pending_payment, cancelled (past deactivation), or pending_plan status
    if (
      ["pending_payment", "pending_plan"].includes(
        organization?.plan?.status
      ) ||
      (organization?.plan?.status === "cancelled" &&
        new Date() >= new Date(organization?.plan?.deactivationDate) &&
        !showPaymentDialog)
    ) {
      setShowPaymentDialog(true);
    }
  }, [
    organization?.plan?.status,
    organization?.plan?.deactivationDate,
    showPaymentDialog,
  ]);

  return (
    <>
      {showPaymentDialog && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            if (
              ![
                "pending_payment",
                "cancelled",
                "trial_expired",
                "pending_plan",
              ].includes(organization?.plan?.status) &&
              !isProcessing
            ) {
              handleClose();
            }
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 2,
              p: 4,
              maxWidth: "1200px",
              width: "90%",
              maxHeight: "90vh",
              overflow: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <Typography
                variant="h4"
                gutterBottom
                color="error.main"
                sx={{ fontWeight: 600 }}
              >
                {getDialogTitle()}
              </Typography>
              <Typography color="text.secondary" sx={{ mb: 3 }}>
                {getDialogMessage()}
              </Typography>
            </Box>

            <PricingPlans
              onPlanSelect={handlePlanSelect}
              isProcessing={isProcessing}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default TrialCheck;
