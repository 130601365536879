import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Alert,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  validateTenancyPeriod,
  generateRentPayments,
} from "../../utils/rentUtils";

const PropertyTenancyForm = ({ property, tenant, onSave, organizationId }) => {
  const [tenancyData, setTenancyData] = useState({
    startDate: "",
    endDate: "",
    monthlyRent: property?.monthlyRent || "",
  });
  const [error, setError] = useState("");

  const handleSaveTenancy = async () => {
    try {
      // Validate tenancy period
      const validation = validateTenancyPeriod(
        tenancyData.startDate,
        tenancyData.endDate
      );
      if (!validation.valid) {
        setError(validation.message);
        return;
      }

      // Prepare tenancy data
      const fullTenancyData = {
        ...tenancyData,
        propertyId: property.id,
        propertyName: property.name,
        tenantId: tenant.id,
        tenantName: `${tenant.firstName} ${tenant.lastName}`,
      };

      // Generate rent payments
      await generateRentPayments(fullTenancyData, organizationId);

      // Call the parent's onSave handler
      onSave(fullTenancyData);
    } catch (error) {
      console.error("Error saving tenancy:", error);
      setError("Failed to save tenancy details");
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            value={tenancyData.startDate}
            onChange={(e) =>
              setTenancyData({ ...tenancyData, startDate: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
            helperText="Must be the first day of a month"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="End Date"
            type="date"
            value={tenancyData.endDate}
            onChange={(e) =>
              setTenancyData({ ...tenancyData, endDate: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
            helperText="Must be in multiples of 6 months from start date"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Monthly Rent"
            type="number"
            value={tenancyData.monthlyRent}
            onChange={(e) =>
              setTenancyData({ ...tenancyData, monthlyRent: e.target.value })
            }
            InputProps={{
              startAdornment: <span>£</span>,
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={handleSaveTenancy}
          sx={{
            background: "linear-gradient(45deg, #2563eb, #7c3aed)",
            "&:hover": {
              background: "linear-gradient(45deg, #1e40af, #5b21b6)",
            },
          }}
        >
          Save Tenancy
        </Button>
      </Box>
    </Box>
  );
};

export default PropertyTenancyForm;
