import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  Card,
  CardContent,
  Chip,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ImageList,
  ImageListItem,
  FormControlLabel,
  Switch,
  InputAdornment,
  FormHelperText,
  Alert,
} from "@mui/material";
import {
  Business as BusinessIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Build as BuildIcon,
  VideoLibrary as VideoIcon,
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  Description as DescriptionIcon,
  Download as DownloadIcon,
  Person as PersonIcon,
  CalendarToday as CalendarIcon,
  AttachMoney as AttachMoneyIcon,
  Home as HomeIcon,
  History as HistoryIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Schedule as ScheduleIcon,
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
  Folder as FolderIcon,
} from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  updateDoc,
  writeBatch,
  addDoc,
  orderBy,
  arrayUnion,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useOrganization } from "../../contexts/OrganizationContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import FileUpload from "../common/FileUpload";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { generateRentPayments } from "../../utils/rentUtils";

// Initialize pdfMake with proper error handling
let pdfMake;
const loadPdfMake = async () => {
  try {
    const pdfMakeModule = await import("pdfmake/build/pdfmake");
    pdfMake = pdfMakeModule.default;
    const vfsFonts = await import("pdfmake/build/vfs_fonts");
    // Check if vfsFonts has pdfMake or default property
    const fonts =
      vfsFonts.pdfMake?.vfs || vfsFonts.default?.vfs || vfsFonts.vfs;
    if (fonts) {
      pdfMake.vfs = fonts;
    } else {
      console.warn(
        "PDF fonts not loaded properly, falling back to default fonts"
      );
    }
  } catch (error) {
    console.error("Error loading PDF generator:", error);
  }
};

loadPdfMake();

const PhotoGallery = ({ propertyId, property, unitId }) => {
  const [selectedFolder, setSelectedFolder] = useState("all");
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [editingPhoto, setEditingPhoto] = useState(null);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [newFolder, setNewFolder] = useState("");
  const [uploadFolder, setUploadFolder] = useState("");
  const { organization } = useOrganization();
  const db = getFirestore();
  const storage = getStorage();
  const queryClient = useQueryClient();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  // Use folders from property data
  const folders = property.photoFolders || [];

  // Fetch photos query
  const { data: photosData = { photos: [] }, isLoading } = useQuery({
    queryKey: ["photos", propertyId, unitId],
    queryFn: async () => {
      try {
        // Fetch photos
        const q = query(
          collection(db, "files"),
          where("entityId", "==", propertyId),
          where("unitId", "==", unitId),
          where("folder", "==", "property-photos")
        );

        const querySnapshot = await getDocs(q);
        const photosList = [];

        querySnapshot.forEach((doc) => {
          const photoData = { id: doc.id, ...doc.data() };
          photosList.push(photoData);
        });

        // Sort photos by date
        photosList.sort(
          (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
        );

        return {
          photos: photosList,
        };
      } catch (error) {
        console.error("Error fetching photos:", error);
        throw error;
      }
    },
    enabled: !!propertyId && !!unitId,
  });

  const filteredPhotos = photosData.photos.filter((photo) =>
    selectedFolder === "all" ? true : photo.photoFolder === selectedFolder
  );

  const handleCreateFolder = async () => {
    if (newFolder && !folders.includes(newFolder)) {
      try {
        // Update the property document with the new folder
        const propertyRef = doc(db, "properties", propertyId);
        await updateDoc(propertyRef, {
          photoFolders: arrayUnion(newFolder),
        });

        // If we're in the upload dialog, set the upload folder
        if (showUploadDialog) {
          setUploadFolder(newFolder);
        }
        setNewFolder("");
        setShowFolderDialog(false);
        queryClient.invalidateQueries(["property", propertyId]);
      } catch (error) {
        console.error("Error creating folder:", error);
      }
    }
  };

  const handleDelete = async (photo) => {
    try {
      const fileRef = ref(storage, photo.filePath);
      await deleteObject(fileRef);
      await deleteDoc(doc(db, "files", photo.id));
      queryClient.invalidateQueries(["photos", propertyId, unitId]);
      setDeleteConfirmation(null);
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  const handleDeleteClick = (photo, e) => {
    e.stopPropagation();
    setDeleteConfirmation(photo);
  };

  const handleRename = async () => {
    try {
      const updates = {};

      // Only include photoName in updates if it's defined and different from fileName
      if (
        editingPhoto.photoName &&
        editingPhoto.photoName !== editingPhoto.fileName
      ) {
        updates.photoName = editingPhoto.photoName;
      }

      // Always include photoFolder in updates (null if not selected)
      updates.photoFolder = editingPhoto.photoFolder || null;

      await updateDoc(doc(db, "files", editingPhoto.id), updates);
      queryClient.invalidateQueries(["photos", propertyId, unitId]);
      setEditingPhoto(null);
    } catch (error) {
      console.error("Error updating photo:", error);
    }
  };

  const handleSetAsThumbnail = async () => {
    try {
      await updateDoc(doc(db, "properties", propertyId), {
        thumbnailUrl: editingPhoto.fileUrl,
      });
      queryClient.invalidateQueries(["property", propertyId]);
      setEditingPhoto(null);
    } catch (error) {
      console.error("Error setting thumbnail:", error);
    }
  };

  const handleDeleteFolder = async (folderToDelete) => {
    try {
      // First, update all photos in this folder to have no folder
      const photosInFolder = photosData.photos.filter(
        (photo) => photo.photoFolder === folderToDelete
      );

      const batch = writeBatch(db);

      // Update each photo to remove the folder
      photosInFolder.forEach((photo) => {
        const photoRef = doc(db, "files", photo.id);
        batch.update(photoRef, { photoFolder: null });
      });

      // Remove the folder from the property's folder list
      const propertyRef = doc(db, "properties", propertyId);
      const updatedFolders = folders.filter(
        (folder) => folder !== folderToDelete
      );
      batch.update(propertyRef, { photoFolders: updatedFolders });

      await batch.commit();

      // Invalidate queries to refresh the data
      queryClient.invalidateQueries(["photos", propertyId, unitId]);
      queryClient.invalidateQueries(["property", propertyId]);
      setDeleteConfirmation(null);
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 3 }}>
      {/* Folders Sidebar */}
      <Card sx={{ width: 280, height: "fit-content" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Folders</Typography>
            <Button
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setShowFolderDialog(true)}
            >
              New Folder
            </Button>
          </Box>
          <List sx={{ width: "100%" }}>
            <ListItem
              button
              selected={selectedFolder === "all"}
              onClick={() => setSelectedFolder("all")}
            >
              <ListItemIcon>
                <HomeIcon
                  color={selectedFolder === "all" ? "primary" : "inherit"}
                />
              </ListItemIcon>
              <ListItemText primary="All Photos" />
              <Typography variant="body2" color="text.secondary">
                {photosData.photos.length}
              </Typography>
            </ListItem>
            {folders.map((folder) => {
              const folderPhotoCount = photosData.photos.filter(
                (photo) => photo.photoFolder === folder
              ).length;
              return (
                <ListItem
                  key={folder}
                  button
                  selected={selectedFolder === folder}
                  onClick={() => setSelectedFolder(folder)}
                >
                  <ListItemIcon>
                    <FolderIcon
                      color={selectedFolder === folder ? "primary" : "inherit"}
                    />
                  </ListItemIcon>
                  <ListItemText primary={folder} />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mr: 1 }}
                    >
                      {folderPhotoCount}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteConfirmation({ type: "folder", item: folder });
                      }}
                      sx={{
                        opacity: 0.6,
                        "&:hover": { opacity: 1 },
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              );
            })}
          </List>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setShowUploadDialog(true)}
            fullWidth
            sx={{ mt: 2 }}
          >
            Upload Photos
          </Button>
        </CardContent>
      </Card>

      {/* Photos Content */}
      <Box sx={{ flex: 1 }}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {filteredPhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={photo.id}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    "&:hover": {
                      boxShadow: (theme) => theme.shadows[4],
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      paddingTop: "75%",
                      cursor: "pointer",
                      "&:hover .photo-overlay": {
                        opacity: 1,
                      },
                    }}
                    onClick={() => setPreviewPhoto(photo)}
                  >
                    <Box
                      component="img"
                      src={photo.fileUrl}
                      alt={photo.photoName || photo.fileName}
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      className="photo-overlay"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: 0,
                        transition: "opacity 0.2s",
                      }}
                    >
                      <IconButton
                        sx={{ color: "white" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPreviewPhoto(photo);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        sx={{ color: "white" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingPhoto(photo);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        sx={{ color: "white" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(photo, e);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2" noWrap>
                      {photo.photoName || photo.fileName}
                    </Typography>
                    {photo.photoFolder && (
                      <Typography variant="caption" color="text.secondary">
                        {photo.photoFolder}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {filteredPhotos.length === 0 && (
              <Grid item xs={12}>
                <Typography
                  color="text.secondary"
                  sx={{ textAlign: "center", py: 4 }}
                >
                  No photos in{" "}
                  {selectedFolder === "all"
                    ? "gallery"
                    : `folder "${selectedFolder}"`}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Box>

      {/* Upload Dialog */}
      <Dialog
        open={showUploadDialog}
        onClose={() => {
          setShowUploadDialog(false);
          setUploadFolder("");
          setNewFolder("");
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Photos</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Select Folder
            </Typography>
            <FormControl fullWidth error={!uploadFolder}>
              <Select
                value={uploadFolder}
                onChange={(e) => setUploadFolder(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select a folder</em>
                </MenuItem>
                {folders.map((folder) => (
                  <MenuItem key={folder} value={folder}>
                    {folder}
                  </MenuItem>
                ))}
              </Select>
              {!uploadFolder && (
                <FormHelperText>Please select a folder first</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Or Create New Folder
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                fullWidth
                size="small"
                value={newFolder}
                onChange={(e) => setNewFolder(e.target.value)}
                placeholder="Enter folder name"
              />
              <Button
                variant="outlined"
                onClick={handleCreateFolder}
                disabled={!newFolder || folders.includes(newFolder)}
              >
                Create
              </Button>
            </Box>
          </Box>

          {uploadFolder ? (
            <FileUpload
              folder="property-photos"
              entityId={propertyId}
              multiple={true}
              isPhoto={true}
              onUploadComplete={() => {
                queryClient.invalidateQueries(["photos", propertyId, unitId]);
                setShowUploadDialog(false);
                setUploadFolder("");
              }}
              acceptedFiles="image/*"
              additionalData={{
                photoFolder: uploadFolder,
                type: "photo",
                unitId: unitId,
              }}
            />
          ) : (
            <Box sx={{ textAlign: "center", color: "text.secondary", py: 2 }}>
              Please select or create a folder first to upload photos
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowUploadDialog(false);
              setUploadFolder("");
              setNewFolder("");
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog
        open={Boolean(previewPhoto)}
        onClose={() => setPreviewPhoto(null)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ p: 0, position: "relative" }}>
          {previewPhoto && (
            <>
              <IconButton
                onClick={() => setPreviewPhoto(null)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  bgcolor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  "&:hover": {
                    bgcolor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box
                component="img"
                src={previewPhoto.fileUrl}
                alt={previewPhoto.photoName || previewPhoto.fileName}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "90vh",
                  objectFit: "contain",
                  display: "block",
                }}
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* Edit Photo Dialog */}
      <Dialog
        open={Boolean(editingPhoto)}
        onClose={() => setEditingPhoto(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Photo Details</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
            <TextField
              label="Photo Name"
              fullWidth
              defaultValue={
                editingPhoto?.photoName || editingPhoto?.fileName || ""
              }
              onChange={(e) =>
                setEditingPhoto({
                  ...editingPhoto,
                  photoName: e.target.value,
                  originalName: editingPhoto?.fileName,
                })
              }
              helperText="Leave unchanged to use original filename"
            />
            <FormControl fullWidth>
              <InputLabel>Folder</InputLabel>
              <Select
                value={editingPhoto?.photoFolder || ""}
                onChange={(e) =>
                  setEditingPhoto({
                    ...editingPhoto,
                    photoFolder: e.target.value,
                  })
                }
                label="Folder"
              >
                <MenuItem value="">
                  <em>No folder</em>
                </MenuItem>
                {folders.map((folder) => (
                  <MenuItem key={folder} value={folder}>
                    {folder}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select a folder to move this photo to
              </FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingPhoto(null)}>Cancel</Button>
          <Button onClick={handleSetAsThumbnail} color="secondary">
            Set as Property Thumbnail
          </Button>
          <Button onClick={handleRename} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Folder Dialog */}
      <Dialog
        open={showFolderDialog}
        onClose={() => setShowFolderDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Folder Name"
            value={newFolder}
            onChange={(e) => setNewFolder(e.target.value)}
            sx={{ mt: 1 }}
            error={folders.includes(newFolder)}
            helperText={
              folders.includes(newFolder) ? "Folder already exists" : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFolderDialog(false)}>Cancel</Button>
          <Button
            onClick={handleCreateFolder}
            variant="contained"
            disabled={!newFolder || folders.includes(newFolder)}
          >
            Create Folder
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={Boolean(deleteConfirmation)}
        onClose={() => setDeleteConfirmation(null)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {deleteConfirmation?.type === "folder"
            ? "Delete Folder"
            : "Delete Photo"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {deleteConfirmation?.type === "folder"
              ? `Are you sure you want to delete this folder? Photos inside the folder will be moved to the root level.`
              : `Are you sure you want to delete this photo? This action cannot be undone.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
          <Button
            onClick={() =>
              deleteConfirmation?.type === "folder"
                ? handleDeleteFolder(deleteConfirmation.item)
                : handleDelete(deleteConfirmation)
            }
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const DocumentList = ({ propertyId, property, unitId }) => {
  const { organization } = useOrganization();
  const db = getFirestore();
  const storage = getStorage();
  const queryClient = useQueryClient();
  const [editingDoc, setEditingDoc] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("all");
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [newFolder, setNewFolder] = useState("");
  const [uploadFolder, setUploadFolder] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  // Get the selected unit data from property
  const selectedUnitData = property.units.find((unit) => unit.id === unitId);
  // Use folders from unit data
  const folders = selectedUnitData?.documentFolders || [];

  // Fetch documents query
  const { data: documents = [], isLoading } = useQuery({
    queryKey: ["documents", propertyId, unitId],
    queryFn: async () => {
      const q = query(
        collection(db, "files"),
        where("entityId", "==", propertyId),
        where("unitId", "==", unitId),
        where("folder", "==", "property-documents")
      );
      const querySnapshot = await getDocs(q);
      const documentsList = [];
      querySnapshot.forEach((doc) => {
        documentsList.push({ id: doc.id, ...doc.data() });
      });
      return documentsList.sort(
        (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
      );
    },
    enabled: !!propertyId && !!unitId,
  });

  const filteredDocuments = documents.filter((doc) =>
    selectedFolder === "all" ? true : doc.documentFolder === selectedFolder
  );

  const handleCreateFolder = async () => {
    if (newFolder && !folders.includes(newFolder)) {
      try {
        // Update the property document with the new folder for the specific unit
        const propertyRef = doc(db, "properties", propertyId);
        const propertyDoc = await getDoc(propertyRef);
        const propertyData = propertyDoc.data();

        const updatedUnits = propertyData.units.map((unit) => {
          if (unit.id === unitId) {
            return {
              ...unit,
              documentFolders: [...(unit.documentFolders || []), newFolder],
            };
          }
          return unit;
        });

        await updateDoc(propertyRef, {
          units: updatedUnits,
        });

        // If we're in the upload dialog, set the upload folder
        if (showUploadDialog) {
          setUploadFolder(newFolder);
        }
        setNewFolder("");
        setShowFolderDialog(false);
        queryClient.invalidateQueries(["property", propertyId]);
      } catch (error) {
        console.error("Error creating folder:", error);
      }
    }
  };

  const handleDeleteFolder = async (folderToDelete) => {
    try {
      // First, update all documents in this folder to have no folder
      const docsInFolder = documents.filter(
        (doc) => doc.documentFolder === folderToDelete
      );

      const batch = writeBatch(db);

      // Update each document to remove the folder
      docsInFolder.forEach((doc) => {
        const docRef = doc(db, "files", doc.id);
        batch.update(docRef, { documentFolder: null });
      });

      // Remove the folder from the unit's folder list
      const propertyRef = doc(db, "properties", propertyId);
      const propertyDoc = await getDoc(propertyRef);
      const propertyData = propertyDoc.data();

      const updatedUnits = propertyData.units.map((unit) => {
        if (unit.id === unitId) {
          return {
            ...unit,
            documentFolders: (unit.documentFolders || []).filter(
              (folder) => folder !== folderToDelete
            ),
          };
        }
        return unit;
      });

      batch.update(propertyRef, { units: updatedUnits });

      await batch.commit();

      // Invalidate queries to refresh the data
      queryClient.invalidateQueries(["documents", propertyId, unitId]);
      queryClient.invalidateQueries(["property", propertyId]);
      setDeleteConfirmation(null);
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  const handleDownload = (document) => {
    window.open(document.fileUrl, "_blank");
  };

  const handleDelete = async (document) => {
    try {
      const fileRef = ref(storage, document.filePath);
      await deleteObject(fileRef);
      await deleteDoc(doc(db, "files", document.id));
      queryClient.invalidateQueries(["documents", propertyId]);
      setDeleteConfirmation(null);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleRename = async () => {
    try {
      const updates = {};

      // Only include fileName in updates if it's defined and different
      if (
        editingDoc.fileName &&
        editingDoc.fileName !== editingDoc.originalName
      ) {
        updates.fileName = editingDoc.fileName;
      }

      // Always include documentFolder in updates (null if not selected)
      updates.documentFolder = editingDoc.documentFolder || null;

      await updateDoc(doc(db, "files", editingDoc.id), updates);
      queryClient.invalidateQueries(["documents", propertyId]);
      setEditingDoc(null);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 3 }}>
      {/* Folders Sidebar */}
      <Card sx={{ width: 280, height: "fit-content" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6">Folders</Typography>
            <Button
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setShowFolderDialog(true)}
            >
              New Folder
            </Button>
          </Box>
          <List sx={{ width: "100%" }}>
            <ListItem
              button
              selected={selectedFolder === "all"}
              onClick={() => setSelectedFolder("all")}
            >
              <ListItemIcon>
                <HomeIcon
                  color={selectedFolder === "all" ? "primary" : "inherit"}
                />
              </ListItemIcon>
              <ListItemText primary="All Documents" />
              <Typography variant="body2" color="text.secondary">
                {documents.length}
              </Typography>
            </ListItem>
            {folders.map((folder) => {
              const folderDocCount = documents.filter(
                (doc) => doc.documentFolder === folder
              ).length;
              return (
                <ListItem
                  key={folder}
                  button
                  selected={selectedFolder === folder}
                  onClick={() => setSelectedFolder(folder)}
                >
                  <ListItemIcon>
                    <FolderIcon
                      color={selectedFolder === folder ? "primary" : "inherit"}
                    />
                  </ListItemIcon>
                  <ListItemText primary={folder} />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mr: 1 }}
                    >
                      {folderDocCount}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteConfirmation({ type: "folder", item: folder });
                      }}
                      sx={{
                        opacity: 0.6,
                        "&:hover": { opacity: 1 },
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              );
            })}
          </List>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setShowUploadDialog(true)}
            fullWidth
            sx={{ mt: 2 }}
          >
            Upload Documents
          </Button>
        </CardContent>
      </Card>

      {/* Documents Content */}
      <Box sx={{ flex: 1 }}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {filteredDocuments.map((document) => (
              <Grid item xs={12} sm={6} md={4} key={document.id}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    "&:hover": {
                      boxShadow: (theme) => theme.shadows[4],
                    },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, position: "relative" }}>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => setEditingDoc(document)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDownload(document)}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteConfirmation({
                            type: "document",
                            item: document,
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 2,
                        mt: 4,
                      }}
                    >
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          borderRadius: 1,
                          bgcolor: "primary.lighter",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <DescriptionIcon sx={{ color: "primary.main" }} />
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 600,
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {document.fileName}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Added{" "}
                          {new Date(document.uploadDate).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      {document.documentFolder && (
                        <Chip
                          label={document.documentFolder}
                          size="small"
                          sx={{ mr: 1 }}
                        />
                      )}
                      <Chip
                        label={document.type?.toUpperCase() || "DOCUMENT"}
                        size="small"
                        sx={{ bgcolor: "primary.lighter" }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {filteredDocuments.length === 0 && (
              <Grid item xs={12}>
                <Typography
                  color="text.secondary"
                  sx={{ textAlign: "center", py: 4 }}
                >
                  No documents in{" "}
                  {selectedFolder === "all"
                    ? "library"
                    : `folder "${selectedFolder}"`}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Box>

      {/* Upload Dialog */}
      <Dialog
        open={showUploadDialog}
        onClose={() => {
          setShowUploadDialog(false);
          setUploadFolder("");
          setNewFolder("");
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Documents</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Select Folder
            </Typography>
            <FormControl fullWidth error={!uploadFolder}>
              <Select
                value={uploadFolder}
                onChange={(e) => setUploadFolder(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select a folder</em>
                </MenuItem>
                {folders.map((folder) => (
                  <MenuItem key={folder} value={folder}>
                    {folder}
                  </MenuItem>
                ))}
              </Select>
              {!uploadFolder && (
                <FormHelperText>Please select a folder first</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Or Create New Folder
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                fullWidth
                size="small"
                value={newFolder}
                onChange={(e) => setNewFolder(e.target.value)}
                placeholder="Enter folder name"
              />
              <Button
                variant="outlined"
                onClick={handleCreateFolder}
                disabled={!newFolder || folders.includes(newFolder)}
              >
                Create
              </Button>
            </Box>
          </Box>

          {uploadFolder ? (
            <FileUpload
              folder="property-documents"
              entityId={propertyId}
              multiple={true}
              onUploadComplete={() => {
                queryClient.invalidateQueries([
                  "documents",
                  propertyId,
                  unitId,
                ]);
                setShowUploadDialog(false);
                setUploadFolder("");
              }}
              acceptedFiles=".pdf,.doc,.docx,.txt,.xls,.xlsx"
              additionalData={{
                documentFolder: uploadFolder,
                type: "document",
                unitId: unitId,
              }}
            />
          ) : (
            <Box sx={{ textAlign: "center", color: "text.secondary", py: 2 }}>
              Please select or create a folder first to upload documents
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowUploadDialog(false);
              setUploadFolder("");
              setNewFolder("");
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Document Dialog */}
      <Dialog
        open={Boolean(editingDoc)}
        onClose={() => setEditingDoc(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Document Details</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
            <TextField
              label="Document Name"
              fullWidth
              defaultValue={editingDoc?.fileName || ""}
              onChange={(e) =>
                setEditingDoc({
                  ...editingDoc,
                  fileName: e.target.value,
                  originalName: editingDoc?.fileName,
                })
              }
              helperText="Leave unchanged to use original filename"
            />
            <FormControl fullWidth>
              <InputLabel>Folder</InputLabel>
              <Select
                value={editingDoc?.documentFolder || ""}
                onChange={(e) =>
                  setEditingDoc({
                    ...editingDoc,
                    documentFolder: e.target.value,
                  })
                }
                label="Folder"
              >
                <MenuItem value="">
                  <em>No folder</em>
                </MenuItem>
                {folders.map((folder) => (
                  <MenuItem key={folder} value={folder}>
                    {folder}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select a folder to move this document to
              </FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingDoc(null)}>Cancel</Button>
          <Button onClick={handleRename} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Folder Dialog */}
      <Dialog
        open={showFolderDialog}
        onClose={() => setShowFolderDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Folder Name"
            value={newFolder}
            onChange={(e) => setNewFolder(e.target.value)}
            sx={{ mt: 1 }}
            error={folders.includes(newFolder)}
            helperText={
              folders.includes(newFolder) ? "Folder already exists" : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFolderDialog(false)}>Cancel</Button>
          <Button
            onClick={handleCreateFolder}
            variant="contained"
            disabled={!newFolder || folders.includes(newFolder)}
          >
            Create Folder
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={Boolean(deleteConfirmation)}
        onClose={() => setDeleteConfirmation(null)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {deleteConfirmation?.type === "folder"
            ? "Delete Folder"
            : "Delete Document"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {deleteConfirmation?.type === "folder"
              ? `Are you sure you want to delete this folder? Photos inside the folder will be moved to the root level.`
              : `Are you sure you want to delete this photo? This action cannot be undone.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
          <Button
            onClick={() =>
              deleteConfirmation?.type === "folder"
                ? handleDeleteFolder(deleteConfirmation.item)
                : handleDelete(deleteConfirmation.item)
            }
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const TenancyDetails = ({ propertyId, property, unitId }) => {
  const { organization } = useOrganization();
  const db = getFirestore();
  const queryClient = useQueryClient();
  const [showTenancyForm, setShowTenancyForm] = useState(false);
  const [tenancyDetails, setTenancyDetails] = useState({
    leaseStart: "",
    leaseEnd: "",
    deposit: "",
    isSharedTenancy: false,
    tenants: [
      {
        tenantId: "",
        rentPortion: 0,
        isLeadTenant: true,
      },
    ],
  });
  const [formErrors, setFormErrors] = useState({});
  const [showEndTenancyDialog, setShowEndTenancyDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Fetch approved tenants
  const { data: approvedTenants = [], isLoading: isLoadingTenants } = useQuery({
    queryKey: ["approvedTenants", organization?.id],
    queryFn: async () => {
      const q = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id),
        where("status", "==", "active")
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!organization?.id,
  });

  // Initialize tenancy details from property data
  useEffect(() => {
    if (property?.units) {
      const selectedUnitData = property.units.find(
        (unit) => unit.id === unitId
      );
      if (selectedUnitData?.tenancy) {
        setTenancyDetails(selectedUnitData.tenancy);
      } else {
        setTenancyDetails({
          leaseStart: "",
          leaseEnd: "",
          deposit: "",
          isSharedTenancy: false,
          tenants: [
            {
              tenantId: "",
              rentPortion: 0,
              isLeadTenant: true,
            },
          ],
        });
      }
    }
  }, [property, unitId]);

  const handleAddTenant = () => {
    setTenancyDetails((prev) => ({
      ...prev,
      tenants: [
        ...prev.tenants,
        {
          tenantId: "",
          rentPortion: prev.isSharedTenancy
            ? 100 / (prev.tenants.length + 1)
            : 0,
          isLeadTenant: prev.tenants.length === 0,
        },
      ],
    }));
  };

  const handleRemoveTenant = (index) => {
    setTenancyDetails((prev) => ({
      ...prev,
      tenants: prev.tenants.filter((_, i) => i !== index),
    }));
  };

  const handleTenantChange = (index, field, value) => {
    setTenancyDetails((prev) => {
      const newTenants = [...prev.tenants];
      newTenants[index] = {
        ...newTenants[index],
        [field]: value,
      };
      return {
        ...prev,
        tenants: newTenants,
      };
    });
  };

  // Validate form fields
  const validateForm = () => {
    const errors = {};

    // Validate deposit
    if (!tenancyDetails.deposit) {
      errors.deposit = "Security deposit is required";
    } else if (Number(tenancyDetails.deposit) <= 0) {
      errors.deposit = "Security deposit must be greater than 0";
    }

    // Validate lease dates
    if (!tenancyDetails.leaseStart) {
      errors.leaseStart = "Lease start date is required";
    }
    if (!tenancyDetails.leaseEnd) {
      errors.leaseEnd = "Lease end date is required";
    }
    if (tenancyDetails.leaseStart && tenancyDetails.leaseEnd) {
      const start = new Date(tenancyDetails.leaseStart);
      const end = new Date(tenancyDetails.leaseEnd);
      if (end <= start) {
        errors.leaseEnd = "Lease end date must be after start date";
      }
    }

    // Validate tenants
    if (!tenancyDetails.tenants[0].tenantId) {
      errors.tenants = "At least one tenant must be selected";
    }

    // Validate rent portions for shared tenancy
    if (tenancyDetails.isSharedTenancy && tenancyDetails.tenants.length > 0) {
      const totalPortion = tenancyDetails.tenants.reduce(
        (sum, tenant) => sum + Number(tenant.rentPortion),
        0
      );
      if (totalPortion !== 100) {
        errors.rentPortion = "Total rent portions must equal 100%";
      }
    }

    return errors;
  };

  const handleTenancyUpdate = async () => {
    try {
      // Validate form
      const errors = validateForm();
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
      setFormErrors({});
      setSubmitting(true);

      // Get the payment due date from lease start date
      const moveInDate = new Date(tenancyDetails.leaseStart);
      const rentDueDay = moveInDate.getDate();

      // Validate total rent portions equal 100% for shared tenancy
      if (tenancyDetails.isSharedTenancy && tenancyDetails.tenants.length > 0) {
        const totalPortion = tenancyDetails.tenants.reduce(
          (sum, tenant) => sum + Number(tenant.rentPortion),
          0
        );
        if (totalPortion !== 100) {
          throw new Error("Total rent portions must equal 100%");
        }
      }

      // Update property with tenancy information
      const propertyRef = doc(db, "properties", propertyId);
      const propertyDoc = await getDoc(propertyRef);
      const propertyData = propertyDoc.data();
      const updatedUnits = propertyData.units.map((unit) => {
        if (unit.id === unitId) {
          return {
            ...unit,
            tenancy: {
              ...tenancyDetails,
              rentDueDay, // Store the day of month for future reference
              updatedAt: new Date().toISOString(),
            },
          };
        }
        return unit;
      });

      await updateDoc(propertyRef, {
        units: updatedUnits,
      });

      // Update tenant records and generate rent payments
      const batch = writeBatch(db);
      const leaseEndDate = new Date(tenancyDetails.leaseEnd);

      // Get the selected unit's monthly rent
      const selectedUnitData = propertyData.units.find(
        (unit) => unit.id === unitId
      );
      const unitMonthlyRent = selectedUnitData.monthlyRent;

      for (const tenant of tenancyDetails.tenants) {
        const tenantDoc = approvedTenants.find((t) => t.id === tenant.tenantId);
        if (!tenantDoc) continue;

        const tenantName = `${tenantDoc.firstName} ${tenantDoc.lastName}`;
        const rentAmount = tenancyDetails.isSharedTenancy
          ? (Number(unitMonthlyRent) * Number(tenant.rentPortion)) / 100
          : Number(unitMonthlyRent) / tenancyDetails.tenants.length;

        const depositAmount = tenancyDetails.isSharedTenancy
          ? (Number(tenancyDetails.deposit) * Number(tenant.rentPortion)) / 100
          : Number(tenancyDetails.deposit) / tenancyDetails.tenants.length;

        // Update tenant's record
        const tenantRef = doc(db, "tenants", tenant.tenantId);
        batch.update(tenantRef, {
          propertyId: propertyId,
          propertyName: property.name,
          unit: unitId,
          leaseStart: tenancyDetails.leaseStart,
          leaseEnd: tenancyDetails.leaseEnd,
          rentAmount: rentAmount,
          deposit: depositAmount,
          rentDueDay: rentDueDay,
          isSharedTenancy: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          status: "active",
          updatedAt: new Date().toISOString(),
        });

        // Create deposit payment
        const depositRef = doc(collection(db, "rentPayments"));
        batch.set(depositRef, {
          rentDueDate: rentDueDay,
          dueDate: tenancyDetails.leaseStart,
          propertyId: propertyId,
          propertyName: property.name,
          propertyAddress: property.address,
          unit: unitId,
          unitNumber: selectedUnitData.number,
          tenantId: tenant.tenantId,
          tenantName: tenantName,
          amount: depositAmount,
          status: "payment_due",
          paymentType: "deposit",
          organizationId: organization.id,
          isSharedPayment: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });

        // Create first month's rent payment
        const rentRef = doc(collection(db, "rentPayments"));
        batch.set(rentRef, {
          rentDueDate: rentDueDay,
          dueDate: tenancyDetails.leaseStart,
          propertyId: propertyId,
          propertyName: property.name,
          propertyAddress: property.address,
          unit: unitId,
          unitNumber: selectedUnitData.number,
          tenantId: tenant.tenantId,
          tenantName: tenantName,
          amount: rentAmount,
          status: "payment_due",
          paymentType: "rent",
          organizationId: organization.id,
          isSharedPayment: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });

        // Create a new Date object for the next month's rent
        const nextMonthDate = new Date(moveInDate);
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

        // Generate future rent payments for this tenant
        await generateRentPayments(
          {
            rentDueDate: rentDueDay,
            leaseStart: nextMonthDate.toISOString(),
            leaseEnd: tenancyDetails.leaseEnd,
            propertyId: propertyId,
            propertyName: property.name,
            propertyAddress: property.address,
            unit: unitId,
            unitNumber: selectedUnitData.number,
            tenantId: tenant.tenantId,
            tenantName: tenantName,
            rentAmount: rentAmount,
            isSharedPayment: tenancyDetails.isSharedTenancy,
            rentPortion: tenant.rentPortion,
            isLeadTenant: tenant.isLeadTenant,
          },
          organization.id
        );
      }

      await batch.commit();
      queryClient.invalidateQueries(["property", propertyId]);
      queryClient.invalidateQueries(["rentPayments"]);
      queryClient.invalidateQueries(["tenants"]);
      setShowTenancyForm(false);
    } catch (error) {
      console.error("Error updating tenancy:", error);
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteTenancy = async () => {
    try {
      // Get current tenants from the unit's tenancy
      const currentTenants = selectedUnitData?.tenancy?.tenants || [];
      const batch = writeBatch(db);

      // Store tenancy history before removing it
      const tenancyHistoryRef = doc(collection(db, "tenancyHistory"));
      const tenancyHistoryData = {
        propertyId,
        propertyName: property.name,
        unitId,
        unitNumber: selectedUnitData.number,
        organizationId: organization.id,
        tenancyDetails: {
          ...selectedUnitData.tenancy,
          endedAt: new Date().toISOString(),
          endReason: "manual_end",
        },
        createdAt: new Date().toISOString(),
      };
      batch.set(tenancyHistoryRef, tenancyHistoryData);

      // Update property to remove tenancy from the unit
      const propertyRef = doc(db, "properties", propertyId);
      const propertyDoc = await getDoc(propertyRef);
      const propertyData = propertyDoc.data();
      const updatedUnits = propertyData.units.map((unit) => {
        if (unit.id === unitId) {
          const { tenancy, ...restUnit } = unit;
          return restUnit;
        }
        return unit;
      });

      batch.update(propertyRef, {
        units: updatedUnits,
      });

      // Update tenant records for this specific property/unit
      for (const tenant of currentTenants) {
        if (!tenant.tenantId) continue;

        const tenantRef = doc(db, "tenants", tenant.tenantId);
        const tenantDoc = await getDoc(tenantRef);
        if (!tenantDoc.exists()) continue;

        // Store the current payment schedule in the tenant document
        const paymentsQuery = query(
          collection(db, "rentPayments"),
          where("tenantId", "==", tenant.tenantId),
          where("unit", "==", unitId)
        );
        const paymentsSnapshot = await getDocs(paymentsQuery);
        const paymentHistory = paymentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Update tenant with inactive status but retain payment history
        batch.update(tenantRef, {
          propertyId: null,
          propertyName: null,
          unit: null,
          leaseStart: null,
          leaseEnd: null,
          rentAmount: null,
          deposit: null,
          rentDueDay: null,
          isSharedTenancy: null,
          rentPortion: null,
          isLeadTenant: null,
          status: "inactive",
          updatedAt: new Date().toISOString(),
          paymentHistory: arrayUnion(...paymentHistory), // Store payment history in tenant document
        });

        // Only delete future unpaid payments
        const currentDate = new Date().toISOString();
        paymentsSnapshot.docs.forEach((paymentDoc) => {
          const paymentData = paymentDoc.data();
          // Only delete future unpaid payments
          if (
            paymentData.dueDate >= currentDate &&
            paymentData.status !== "paid"
          ) {
            const paymentRef = doc(db, "rentPayments", paymentDoc.id);
            batch.delete(paymentRef);
          }
        });

        // Add to activity log
        const activityLogRef = doc(collection(db, "activityLog"));
        batch.set(activityLogRef, {
          type: "tenancy_ended",
          message: `Tenancy ended for ${tenantDoc.data().firstName} ${
            tenantDoc.data().lastName
          } at ${property.name} - Unit ${selectedUnitData.number}`,
          entityId: tenant.tenantId,
          entityType: "tenant",
          propertyId,
          unitId,
          organizationId: organization.id,
          timestamp: new Date(),
          status: "completed",
        });
      }

      await batch.commit();
      queryClient.invalidateQueries(["property", propertyId]);
      queryClient.invalidateQueries(["rentPayments"]);
      queryClient.invalidateQueries(["tenants"]);
      queryClient.invalidateQueries(["tenancyHistory"]);
      setShowEndTenancyDialog(false);
    } catch (error) {
      console.error("Error deleting tenancy:", error);
      throw error;
    }
  };

  if (!property) return null;

  const selectedUnitData = property.units.find((unit) => unit.id === unitId);
  const hasActiveTenancy = selectedUnitData?.tenancy?.tenants?.length > 0;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}
            >
              <Typography variant="h6">Current Tenancy</Typography>
              {!hasActiveTenancy ? (
                <Button
                  variant="contained"
                  onClick={() => setShowTenancyForm(true)}
                  startIcon={<PersonIcon />}
                >
                  Add Tenancy
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setShowEndTenancyDialog(true)}
                  startIcon={<DeleteIcon />}
                >
                  End Tenancy
                </Button>
              )}
            </Box>

            {hasActiveTenancy ? (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        Lease Period
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <CalendarIcon color="primary" />
                        <Typography>
                          {new Date(
                            selectedUnitData.tenancy.leaseStart
                          ).toLocaleDateString()}{" "}
                          -{" "}
                          {new Date(
                            selectedUnitData.tenancy.leaseEnd
                          ).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        Monthly Rent
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <AttachMoneyIcon color="primary" />
                        <Typography>
                          £
                          {selectedUnitData.monthlyRent
                            ? selectedUnitData.monthlyRent.toLocaleString()
                            : "0"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        Security Deposit
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <AttachMoneyIcon color="primary" />
                        <Typography>
                          £
                          {selectedUnitData.tenancy.deposit
                            ? selectedUnitData.tenancy.deposit.toLocaleString()
                            : "0"}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      Current Tenants
                    </Typography>
                    {selectedUnitData.tenancy.tenants.map((tenant) => {
                      const tenantDetails = approvedTenants.find(
                        (t) => t.id === tenant.tenantId
                      );
                      if (!tenantDetails) return null;

                      return (
                        <Box
                          key={tenant.tenantId}
                          sx={{
                            p: 2,
                            mb: 2,
                            border: "1px solid",
                            borderColor: "divider",
                            borderRadius: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              mb: 1,
                            }}
                          >
                            <Box
                              sx={{
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                bgcolor: "primary.lighter",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <PersonIcon sx={{ color: "primary.main" }} />
                            </Box>
                            <Box>
                              <Typography variant="subtitle1">
                                {tenantDetails.firstName}{" "}
                                {tenantDetails.lastName}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {tenantDetails.email}
                              </Typography>
                            </Box>
                          </Box>
                          {selectedUnitData.tenancy.isSharedTenancy && (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ mt: 1 }}
                            >
                              Rent Portion: {tenant.rentPortion}%
                            </Typography>
                          )}
                          {tenant.isLeadTenant && (
                            <Chip
                              label="Lead Tenant"
                              size="small"
                              sx={{
                                mt: 1,
                                bgcolor: "primary.lighter",
                                color: "primary.main",
                              }}
                            />
                          )}
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography color="text.secondary">
                No active tenancy for this unit
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>

      {/* Add Tenancy Dialog - Only show when creating new tenancy */}
      <Dialog
        open={showTenancyForm && !hasActiveTenancy}
        onClose={() => {
          setShowTenancyForm(false);
          setFormErrors({});
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add New Tenancy</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Security Deposit"
                type="number"
                value={tenancyDetails.deposit}
                onChange={(e) =>
                  setTenancyDetails({
                    ...tenancyDetails,
                    deposit: e.target.value,
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
                error={Boolean(formErrors.deposit)}
                helperText={formErrors.deposit}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Lease Start Date"
                type="date"
                value={tenancyDetails.leaseStart}
                onChange={(e) =>
                  setTenancyDetails({
                    ...tenancyDetails,
                    leaseStart: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                error={Boolean(formErrors.leaseStart)}
                helperText={
                  formErrors.leaseStart ||
                  "Monthly payments will be due on the same day of each month"
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Lease End Date"
                type="date"
                value={tenancyDetails.leaseEnd}
                onChange={(e) =>
                  setTenancyDetails({
                    ...tenancyDetails,
                    leaseEnd: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                error={Boolean(formErrors.leaseEnd)}
                helperText={formErrors.leaseEnd}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={tenancyDetails.isSharedTenancy}
                    onChange={(e) =>
                      setTenancyDetails((prev) => ({
                        ...prev,
                        isSharedTenancy: e.target.checked,
                        tenants: prev.tenants.map((t, i) => ({
                          ...t,
                          rentPortion: e.target.checked
                            ? 100 / prev.tenants.length
                            : 0,
                        })),
                      }))
                    }
                  />
                }
                label="Shared Tenancy (Split Rent)"
              />
              {formErrors.rentPortion && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ display: "block", mt: 1 }}
                >
                  {formErrors.rentPortion}
                </Typography>
              )}
            </Grid>

            {/* Tenant Selection */}
            {tenancyDetails.tenants.map((tenant, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    borderColor:
                      formErrors.tenants && index === 0
                        ? "error.main"
                        : "divider",
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <FormControl
                        fullWidth
                        error={formErrors.tenants && index === 0}
                      >
                        <InputLabel>Select Tenant</InputLabel>
                        <Select
                          value={tenant.tenantId}
                          onChange={(e) =>
                            handleTenantChange(
                              index,
                              "tenantId",
                              e.target.value
                            )
                          }
                          label="Select Tenant"
                        >
                          {approvedTenants.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                              {t.firstName} {t.lastName}
                            </MenuItem>
                          ))}
                        </Select>
                        {formErrors.tenants && index === 0 && (
                          <FormHelperText>{formErrors.tenants}</FormHelperText>
                        )}
                      </FormControl>

                      {tenancyDetails.isSharedTenancy && (
                        <TextField
                          label="Rent Portion (%)"
                          type="number"
                          value={tenant.rentPortion}
                          onChange={(e) =>
                            handleTenantChange(
                              index,
                              "rentPortion",
                              Number(e.target.value)
                            )
                          }
                          sx={{ width: 150 }}
                          error={Boolean(formErrors.rentPortion)}
                        />
                      )}

                      {index > 0 && (
                        <IconButton
                          color="error"
                          onClick={() => handleRemoveTenant(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button startIcon={<PersonIcon />} onClick={handleAddTenant}>
                Add Tenant
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowTenancyForm(false);
              setFormErrors({});
            }}
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleTenancyUpdate}
            variant="contained"
            disabled={submitting}
          >
            {submitting ? "Saving..." : "Save Tenancy"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* End Tenancy Confirmation Dialog */}
      <Dialog
        open={showEndTenancyDialog}
        onClose={() => setShowEndTenancyDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>End Tenancy</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to end this tenancy? This will:
          </Typography>
          <ul style={{ marginLeft: "20px", color: "text.secondary" }}>
            <li>Remove all tenants from this unit</li>
            <li>Delete all future rent payments</li>
            <li>Mark all tenants as inactive</li>
            <li>This action cannot be undone</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEndTenancyDialog(false)}>Cancel</Button>
          <Button
            onClick={handleDeleteTenancy}
            variant="contained"
            color="error"
          >
            End Tenancy
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const TenancyHistory = ({ propertyId, unitId }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchTenancyHistory = async () => {
      try {
        setLoading(true);
        // First get all tenants that were ever associated with this unit
        const tenantsQuery = query(
          collection(db, "tenants"),
          where("propertyId", "==", propertyId),
          where("unit", "==", unitId)
        );
        const tenantsSnapshot = await getDocs(tenantsQuery);
        const tenants = tenantsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Then get all tenancy history records
        const historyQuery = query(
          collection(db, "tenancyHistory"),
          where("propertyId", "==", propertyId),
          where("unitId", "==", unitId),
          orderBy("createdAt", "desc")
        );
        const historySnapshot = await getDocs(historyQuery);
        const historyData = historySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // For each tenancy history record, fetch the payment history
        const historyWithPayments = await Promise.all(
          historyData.map(async (record) => {
            const tenancyTenants = record.tenancyDetails.tenants || [];
            const tenantIds = tenancyTenants.map((t) => t.tenantId);

            // Fetch payments for all tenants in this tenancy
            const paymentsQuery = query(
              collection(db, "rentPayments"),
              where("tenantId", "in", tenantIds),
              where("unit", "==", unitId),
              where("isPaid", "==", true),
              orderBy("dueDate", "asc")
            );

            const paymentsSnapshot = await getDocs(paymentsQuery);
            const payments = paymentsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            // Enrich tenant data with full tenant information
            const enrichedTenants = tenancyTenants.map((tenant) => {
              const fullTenantData = tenants.find(
                (t) => t.id === tenant.tenantId
              );
              return {
                ...tenant,
                ...fullTenantData,
              };
            });

            return {
              ...record,
              tenancyDetails: {
                ...record.tenancyDetails,
                tenants: enrichedTenants,
              },
              payments,
            };
          })
        );

        setHistory(historyWithPayments);
      } catch (err) {
        console.error("Error fetching tenancy history:", err);
        setError("Failed to load tenancy history");
      } finally {
        setLoading(false);
      }
    };

    if (propertyId && unitId) {
      fetchTenancyHistory();
    }
  }, [propertyId, unitId]);

  const handleDownloadPaymentHistory = async (record) => {
    try {
      const csvContent = [
        // CSV Headers
        [
          "Due Date",
          "Tenant Name",
          "Amount",
          "Payment Type",
          "Shared Payment",
          "Rent Portion",
          "Payment Date",
        ].join(","),
        // CSV Data
        ...record.payments.map((payment) =>
          [
            new Date(payment.dueDate).toLocaleDateString(),
            payment.tenantName,
            `£${payment.amount.toLocaleString()}`,
            payment.paymentType || "Standard",
            payment.isSharedPayment ? "Yes" : "No",
            payment.rentPortion ? `${payment.rentPortion}%` : "N/A",
            new Date(payment.paidDate).toLocaleDateString(),
          ].join(",")
        ),
      ].join("\n");

      // Create and download the file
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `payment_history_${record.tenancyDetails.tenants
        .map((t) => t.lastName)
        .join("_")}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading payment history:", error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 3 }}>
        {error}
      </Alert>
    );
  }

  if (history.length === 0) {
    return (
      <Box
        sx={{
          p: 4,
          textAlign: "center",
          bgcolor: "rgba(37, 99, 235, 0.02)",
          borderRadius: 2,
          border: "1px solid",
          borderColor: "rgba(37, 99, 235, 0.1)",
        }}
      >
        <Typography color="text.secondary">
          No tenancy history available for this unit
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {history.map((record) => (
        <Card key={record.id} sx={{ mb: 3 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 3,
              }}
            >
              <Box>
                <Typography variant="h6" gutterBottom>
                  Tenancy Period
                </Typography>
                <Typography color="text.secondary">
                  {new Date(
                    record.tenancyDetails.leaseStart
                  ).toLocaleDateString()}{" "}
                  -{" "}
                  {new Date(
                    record.tenancyDetails.leaseEnd
                  ).toLocaleDateString()}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Ended on:{" "}
                  {new Date(record.tenancyDetails.endedAt).toLocaleDateString()}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                onClick={() => handleDownloadPaymentHistory(record)}
                size="small"
              >
                Download Payment History
              </Button>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Tenants
              </Typography>
              <Grid container spacing={2}>
                {record.tenancyDetails.tenants.map((tenant) => (
                  <Grid item xs={12} sm={6} key={tenant.tenantId}>
                    <Box
                      sx={{
                        p: 2,
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="subtitle1">
                        {tenant.firstName} {tenant.lastName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tenant.email}
                      </Typography>
                      {record.tenancyDetails.isSharedTenancy && (
                        <Typography variant="body2" color="text.secondary">
                          Rent Portion: {tenant.rentPortion}%
                        </Typography>
                      )}
                      {tenant.isLeadTenant && (
                        <Chip
                          label="Lead Tenant"
                          size="small"
                          sx={{ mt: 1 }}
                          color="primary"
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box>
              <Typography variant="h6" gutterBottom>
                Payment Summary
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "success.light",
                      borderRadius: 1,
                      color: "success.contrastText",
                    }}
                  >
                    <Typography variant="subtitle2">Total Rent Paid</Typography>
                    <Typography variant="h6">
                      £
                      {record.payments
                        ? record.payments
                            .filter((payment) => payment.paymentType === "rent")
                            .reduce(
                              (sum, payment) => sum + (payment.amount || 0),
                              0
                            )
                            .toLocaleString()
                        : "0"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "info.light",
                      borderRadius: 1,
                      color: "info.contrastText",
                    }}
                  >
                    <Typography variant="subtitle2">Monthly Rent</Typography>
                    <Typography variant="h6">
                      £
                      {record.tenancyDetails.rentAmount
                        ? record.tenancyDetails.rentAmount.toLocaleString()
                        : "0"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "warning.light",
                      borderRadius: 1,
                      color: "warning.contrastText",
                    }}
                  >
                    <Typography variant="subtitle2">
                      Security Deposit
                    </Typography>
                    <Typography variant="h6">
                      £
                      {record.tenancyDetails.deposit
                        ? record.tenancyDetails.deposit.toLocaleString()
                        : "0"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Payment History
                </Typography>
                <Box sx={{ maxHeight: 300, overflow: "auto" }}>
                  {record.payments.map((payment) => (
                    <Box
                      key={payment.id}
                      sx={{
                        p: 2,
                        mb: 1,
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle2">
                          {new Date(payment.dueDate).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {payment.tenantName}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1" color="success.main">
                        £{payment.amount.toLocaleString()}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const MaintenanceHistory = ({ propertyId, unitId }) => {
  const { organization } = useOrganization();
  const db = getFirestore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  // Fetch maintenance requests
  const { data: maintenanceRequests = [], isLoading } = useQuery({
    queryKey: ["maintenanceRequests", propertyId, unitId],
    queryFn: async () => {
      const q = query(
        collection(db, "maintenanceRequests"),
        where("propertyId", "==", propertyId),
        where("unit", "==", unitId),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setHasInitialFetch(true);
      return data;
    },
    enabled: !!propertyId && !!unitId && !hasInitialFetch,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    initialData: [],
  });

  const handleStatusUpdate = async (requestId, newStatus) => {
    try {
      await updateDoc(doc(db, "maintenanceRequests", requestId), {
        status: newStatus,
        updatedAt: new Date().toISOString(),
      });
      queryClient.invalidateQueries([
        "maintenanceRequests",
        propertyId,
        unitId,
      ]);
    } catch (error) {
      console.error("Error updating maintenance status:", error);
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "high":
        return "error";
      case "medium":
        return "warning";
      case "low":
        return "success";
      default:
        return "info";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "success";
      case "in_progress":
        return "info";
      case "pending":
        return "warning";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Maintenance Requests</Typography>
        <Button
          variant="contained"
          startIcon={<BuildIcon />}
          onClick={() => navigate("/dashboard/maintenance")}
        >
          Add Maintenance
        </Button>
      </Box>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {maintenanceRequests.map((request) => (
            <Grid item xs={12} md={6} key={request.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {request.title}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Chip
                        size="small"
                        label={request.priority.toUpperCase()}
                        color={getPriorityColor(request.priority)}
                      />
                      <Chip
                        size="small"
                        label={request.status.replace(/_/g, " ").toUpperCase()}
                        color={getStatusColor(request.status)}
                      />
                    </Box>
                  </Box>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {request.description}
                  </Typography>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    {request.category && (
                      <Grid item xs={6}>
                        <Typography variant="caption" color="text.secondary">
                          Category
                        </Typography>
                        <Typography variant="body2">
                          {request.category}
                        </Typography>
                      </Grid>
                    )}
                    {request.cost && (
                      <Grid item xs={6}>
                        <Typography variant="caption" color="text.secondary">
                          Estimated Cost
                        </Typography>
                        <Typography variant="body2">£{request.cost}</Typography>
                      </Grid>
                    )}
                    {request.dueDate && (
                      <Grid item xs={6}>
                        <Typography variant="caption" color="text.secondary">
                          Due Date
                        </Typography>
                        <Typography variant="body2">
                          {new Date(request.dueDate).toLocaleDateString()}
                        </Typography>
                      </Grid>
                    )}
                    {request.assignedTo && (
                      <Grid item xs={6}>
                        <Typography variant="caption" color="text.secondary">
                          Assigned To
                        </Typography>
                        <Typography variant="body2">
                          {request.assignedTo}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  <Box sx={{ mt: "auto" }}>
                    <FormControl fullWidth size="small">
                      <Select
                        value={request.status}
                        onChange={(e) =>
                          handleStatusUpdate(request.id, e.target.value)
                        }
                      >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="in_progress">In Progress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
          {maintenanceRequests.length === 0 && (
            <Grid item xs={12}>
              <Typography
                color="text.secondary"
                sx={{ textAlign: "center", py: 4 }}
              >
                No maintenance requests found
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

const PropertyDetails = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const [activeTab, setActiveTab] = useState(0);
  const db = getFirestore();
  const queryClient = useQueryClient();
  const [showEditPropertyDialog, setShowEditPropertyDialog] = useState(false);
  const [editingProperty, setEditingProperty] = useState(null);
  const [deleteUnitConfirmation, setDeleteUnitConfirmation] = useState(null);
  const [deletePropertyConfirmation, setDeletePropertyConfirmation] =
    useState(false);

  // Add unit selection state
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [showAddUnitDialog, setShowAddUnitDialog] = useState(false);
  const [showEditUnitDialog, setShowEditUnitDialog] = useState(false);
  const [editingUnit, setEditingUnit] = useState(null);
  const [newUnit, setNewUnit] = useState({
    number: "",
    type: "Standard",
    monthlyRent: "",
    status: "Available",
    floor: "",
    size: "",
    description: "",
  });

  const handleEditProperty = async () => {
    try {
      if (!editingProperty) return;

      const propertyData = {
        ...editingProperty,
        updatedAt: new Date().toISOString(),
      };

      // Get the current property data to check if name or monthly rent has changed
      const propertyRef = doc(db, "properties", propertyId);
      const propertyDoc = await getDoc(propertyRef);
      const currentPropertyData = propertyDoc.data();

      // Create a batch for updates
      const batch = writeBatch(db);

      // Check if property name has changed
      const hasNameChanged = currentPropertyData.name !== editingProperty.name;

      // Check if any unit's monthly rent has changed
      const hasRentChanged = currentPropertyData.units.some((currentUnit) => {
        const editedUnit = editingProperty.units.find(
          (unit) => unit.id === currentUnit.id
        );
        return editedUnit && editedUnit.monthlyRent !== currentUnit.monthlyRent;
      });

      if (hasNameChanged || hasRentChanged) {
        // Get all rent payments for this property
        const paymentsQuery = query(
          collection(db, "rentPayments"),
          where("propertyId", "==", propertyId)
        );

        if (hasRentChanged) {
          // Only get future unpaid payments for rent updates
          paymentsQuery
            .where("paymentType", "==", "rent")
            .where("status", "==", "payment_due")
            .where("dueDate", ">=", new Date().toISOString());
        }

        const paymentsSnapshot = await getDocs(paymentsQuery);

        // Group payments by unit to calculate tenants per unit (only needed for rent changes)
        const paymentsByUnit = {};
        if (hasRentChanged) {
          paymentsSnapshot.docs.forEach((doc) => {
            const payment = doc.data();
            if (!paymentsByUnit[payment.unit]) {
              paymentsByUnit[payment.unit] = {
                payments: [],
                tenantCount: 0,
              };
            }
            paymentsByUnit[payment.unit].payments.push({
              id: doc.id,
              ...payment,
            });
            paymentsByUnit[payment.unit].tenantCount = Math.max(
              paymentsByUnit[payment.unit].tenantCount,
              paymentsByUnit[payment.unit].payments.length
            );
          });
        }

        // Update each payment
        paymentsSnapshot.docs.forEach((doc) => {
          const payment = doc.data();
          const updates = {};

          // Update property name for all payments if name changed
          if (hasNameChanged) {
            updates.propertyName = editingProperty.name;
            updates.propertyAddress = editingProperty.address;
            updates.updatedAt = new Date().toISOString();
          }

          // Update rent amount for future unpaid rent payments if rent changed
          if (
            hasRentChanged &&
            payment.paymentType === "rent" &&
            payment.status === "payment_due"
          ) {
            const unit = editingProperty.units.find(
              (u) => u.id === payment.unit
            );
            if (unit) {
              const unitData = paymentsByUnit[payment.unit];
              const newRentAmount = payment.isSharedPayment
                ? (Number(unit.monthlyRent) * Number(payment.rentPortion)) / 100
                : Number(unit.monthlyRent) / unitData.tenantCount;

              updates.amount = newRentAmount;
              updates.unitNumber = unit.number;
              updates.updatedAt = new Date().toISOString();
            }
          }

          if (Object.keys(updates).length > 0) {
            batch.update(doc.ref, updates);
          }
        });

        // Update the property document
        batch.update(propertyRef, propertyData);

        // Commit all updates
        await batch.commit();
      } else {
        // If neither name nor rent has changed, just update the property
        await updateDoc(propertyRef, propertyData);
      }

      setShowEditPropertyDialog(false);
      setEditingProperty(null);
      queryClient.invalidateQueries(["property", propertyId]);
      queryClient.invalidateQueries(["rentPayments"]);
    } catch (error) {
      console.error("Error updating property:", error);
    }
  };

  const handleEditUnit = async () => {
    try {
      if (!editingUnit.number || !editingUnit.type) {
        throw new Error("Please fill in all required fields");
      }

      const propertyRef = doc(db, "properties", propertyId);

      // Get current units and update the edited one
      const updatedUnits = property.units.map((unit) =>
        unit.id === editingUnit.id
          ? {
              ...editingUnit,
              monthlyRent: Number(editingUnit.monthlyRent) || 0,
              size: Number(editingUnit.size) || null,
            }
          : unit
      );

      // Update property with modified units array
      await updateDoc(propertyRef, {
        units: updatedUnits,
      });

      // Reset form and close dialog
      setEditingUnit(null);
      setShowEditUnitDialog(false);

      // Refresh property data
      queryClient.invalidateQueries(["property", propertyId]);
    } catch (error) {
      console.error("Error editing unit:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleAddUnit = async () => {
    try {
      if (!newUnit.number || !newUnit.type) {
        throw new Error("Please fill in all required fields");
      }

      const propertyRef = doc(db, "properties", propertyId);

      // Generate a unique ID for the new unit
      const unitId = `unit-${Date.now()}`;

      // Add the new unit to the property's units array
      await updateDoc(propertyRef, {
        units: arrayUnion({
          ...newUnit,
          id: unitId,
          monthlyRent: Number(newUnit.monthlyRent) || 0,
          size: Number(newUnit.size) || null,
        }),
      });

      // Reset form and close dialog
      setNewUnit({
        number: "",
        type: "Standard",
        monthlyRent: "",
        status: "Available",
        floor: "",
        size: "",
        description: "",
      });
      setShowAddUnitDialog(false);

      // Refresh property data
      queryClient.invalidateQueries(["property", propertyId]);
    } catch (error) {
      console.error("Error adding unit:", error);
      // You might want to show an error message to the user here
    }
  };

  // Fetch property details with units
  const { data: property, isLoading } = useQuery({
    queryKey: ["property", propertyId],
    queryFn: async () => {
      const docRef = doc(db, "properties", propertyId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error("Property not found");
      }

      const propertyData = docSnap.data();

      // Ensure units is always an array with proper structure
      const units = Array.isArray(propertyData.units)
        ? propertyData.units.map((unit) => ({
            ...unit,
            photoFolders: unit.photoFolders || [],
            documentFolders: unit.documentFolders || [],
          }))
        : [
            {
              id: "unit-1",
              number: "1",
              type: "Standard",
              monthlyRent: propertyData.monthlyRent,
              status: "Available",
              photoFolders: [],
              documentFolders: [],
            },
          ];

      return {
        id: docSnap.id,
        ...propertyData,
        units: units,
      };
    },
    enabled: !!propertyId,
  });

  // Set first unit as default when property data loads
  useEffect(() => {
    if (property?.units && property.units.length > 0 && !selectedUnit) {
      setSelectedUnit(property.units[0].id);
    }
  }, [property, selectedUnit]);

  const handleRemoveUnit = async (unitId) => {
    try {
      const propertyRef = doc(db, "properties", propertyId);

      // Get current units and filter out the one to remove
      const updatedUnits = property.units.filter((unit) => unit.id !== unitId);

      // Update property with new units array
      await updateDoc(propertyRef, {
        units: updatedUnits,
      });

      // If the removed unit was selected, select the first available unit
      if (selectedUnit === unitId && updatedUnits.length > 0) {
        setSelectedUnit(updatedUnits[0].id);
      } else if (updatedUnits.length === 0) {
        setSelectedUnit(null);
      }

      // Refresh property data
      queryClient.invalidateQueries(["property", propertyId]);
      setDeleteUnitConfirmation(null);
    } catch (error) {
      console.error("Error removing unit:", error);
    }
  };

  const handleDeleteProperty = async () => {
    try {
      await deleteDoc(doc(db, "properties", propertyId));
      navigate("/dashboard/properties");
    } catch (error) {
      console.error("Error deleting property:", error);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!property) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" color="error">
          Property not found
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/dashboard/properties")}
          sx={{ mt: 2 }}
        >
          Back to Properties
        </Button>
      </Box>
    );
  }

  // Find the selected unit
  const selectedUnitData =
    property.units.find((unit) => unit.id === selectedUnit) ||
    property.units[0];

  return (
    <Box sx={{ p: 3 }}>
      {/* Navigation and Actions */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          alignItems: "center",
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/dashboard/properties")}
          sx={{ color: "text.secondary" }}
        >
          Back to Properties
        </Button>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => {
              setEditingProperty(property);
              setShowEditPropertyDialog(true);
            }}
          >
            Edit Property
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setDeletePropertyConfirmation(true)}
          >
            Delete Property
          </Button>
        </Box>
      </Box>

      {/* Header Section */}
      <Card
        sx={{
          mb: 4,
          height: "100%",
          display: "flex",
          position: "relative",
          overflow: "hidden",
          "&:hover": {
            transform: "translateY(-2px)",
            transition: "transform 0.2s ease-in-out",
          },
        }}
      >
        <CardContent sx={{ width: "100%", p: 3 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              {property.thumbnailUrl ? (
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Box
                    component="img"
                    src={property.thumbnailUrl}
                    alt={property.name}
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: 2,
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -8,
                      right: -8,
                      bgcolor: "primary.main",
                      color: "white",
                      borderRadius: "50%",
                      width: 28,
                      height: 28,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                      border: "2px solid white",
                    }}
                  >
                    {property.units?.length || 0}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Box
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: 2,
                      bgcolor: "primary.lighter",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BusinessIcon
                      sx={{ fontSize: 40, color: "primary.main" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -8,
                      right: -8,
                      bgcolor: "primary.main",
                      color: "white",
                      borderRadius: "50%",
                      width: 28,
                      height: 28,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                      border: "2px solid white",
                    }}
                  >
                    {property.units?.length || 0}
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs>
              <Typography variant="h4" sx={{ mb: 1, fontWeight: 600 }}>
                {property.name}
              </Typography>
              <Typography color="text.secondary" variant="subtitle1">
                {property.address}
              </Typography>
              <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
                <Chip
                  label={`£${property.units
                    .reduce(
                      (total, unit) => total + (Number(unit.monthlyRent) || 0),
                      0
                    )
                    .toLocaleString()}/month`}
                  size="small"
                  color="primary"
                />
                {property.type && (
                  <Chip
                    label={property.type}
                    size="small"
                    sx={{ bgcolor: "primary.lighter" }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Unit Selector */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Select Unit:
            </Typography>
            <FormControl sx={{ minWidth: 300 }}>
              <Select
                value={selectedUnit || ""}
                onChange={(e) => setSelectedUnit(e.target.value)}
                size="small"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>Select a unit</em>
                </MenuItem>
                {property.units?.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Typography>Unit {unit.number}</Typography>
                      {unit.type && (
                        <Chip
                          label={unit.type}
                          size="small"
                          sx={{ bgcolor: "primary.lighter" }}
                        />
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedUnit && (
              <IconButton
                size="small"
                onClick={() => setDeleteUnitConfirmation(selectedUnit)}
                sx={{
                  color: "error.main",
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                    bgcolor: "error.lighter",
                  },
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setShowAddUnitDialog(true)}
            >
              Add Unit
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Add Unit Dialog */}
      <Dialog
        open={showAddUnitDialog}
        onClose={() => setShowAddUnitDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add New Unit</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Unit Number"
                value={newUnit.number}
                onChange={(e) =>
                  setNewUnit({ ...newUnit, number: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Unit Type</InputLabel>
                <Select
                  value={newUnit.type}
                  onChange={(e) =>
                    setNewUnit({ ...newUnit, type: e.target.value })
                  }
                  label="Unit Type"
                >
                  <MenuItem value="Standard">Standard</MenuItem>
                  <MenuItem value="Studio">Studio</MenuItem>
                  <MenuItem value="Apartment">Apartment</MenuItem>
                  <MenuItem value="House">House</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Monthly Rent"
                type="number"
                value={newUnit.monthlyRent}
                onChange={(e) =>
                  setNewUnit({ ...newUnit, monthlyRent: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Floor"
                value={newUnit.floor}
                onChange={(e) =>
                  setNewUnit({ ...newUnit, floor: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Size (sq ft)"
                type="number"
                value={newUnit.size}
                onChange={(e) =>
                  setNewUnit({ ...newUnit, size: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={3}
                value={newUnit.description}
                onChange={(e) =>
                  setNewUnit({ ...newUnit, description: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddUnitDialog(false)}>Cancel</Button>
          <Button onClick={handleAddUnit} variant="contained">
            Add Unit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Unit Dialog */}
      <Dialog
        open={showEditUnitDialog}
        onClose={() => setShowEditUnitDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Unit Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Unit Number"
                value={editingUnit?.number || ""}
                onChange={(e) =>
                  setEditingUnit({ ...editingUnit, number: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Unit Type</InputLabel>
                <Select
                  value={editingUnit?.type || ""}
                  onChange={(e) =>
                    setEditingUnit({ ...editingUnit, type: e.target.value })
                  }
                  label="Unit Type"
                >
                  <MenuItem value="Standard">Standard</MenuItem>
                  <MenuItem value="Studio">Studio</MenuItem>
                  <MenuItem value="Apartment">Apartment</MenuItem>
                  <MenuItem value="House">House</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Monthly Rent"
                type="number"
                value={editingUnit?.monthlyRent || ""}
                onChange={(e) =>
                  setEditingUnit({
                    ...editingUnit,
                    monthlyRent: e.target.value,
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Floor"
                value={editingUnit?.floor || ""}
                onChange={(e) =>
                  setEditingUnit({ ...editingUnit, floor: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Size (sq ft)"
                type="number"
                value={editingUnit?.size || ""}
                onChange={(e) =>
                  setEditingUnit({ ...editingUnit, size: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={3}
                value={editingUnit?.description || ""}
                onChange={(e) =>
                  setEditingUnit({
                    ...editingUnit,
                    description: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditUnitDialog(false)}>Cancel</Button>
          <Button onClick={handleEditUnit} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Property Dialog */}
      <Dialog
        open={showEditPropertyDialog}
        onClose={() => {
          setShowEditPropertyDialog(false);
          setEditingProperty(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Property</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Property Name"
                value={editingProperty ? editingProperty.name : ""}
                onChange={(e) =>
                  setEditingProperty({
                    ...editingProperty,
                    name: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                value={editingProperty ? editingProperty.address : ""}
                onChange={(e) =>
                  setEditingProperty({
                    ...editingProperty,
                    address: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Property Type</InputLabel>
                <Select
                  value={editingProperty ? editingProperty.type : ""}
                  onChange={(e) =>
                    setEditingProperty({
                      ...editingProperty,
                      type: e.target.value,
                    })
                  }
                  label="Property Type"
                >
                  <MenuItem value="Residential">Residential</MenuItem>
                  <MenuItem value="Commercial">Commercial</MenuItem>
                  <MenuItem value="Mixed Use">Mixed Use</MenuItem>
                  <MenuItem value="Industrial">Industrial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                value={editingProperty ? editingProperty.description : ""}
                onChange={(e) =>
                  setEditingProperty({
                    ...editingProperty,
                    description: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowEditPropertyDialog(false);
              setEditingProperty(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleEditProperty} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Unit Confirmation Dialog */}
      <Dialog
        open={Boolean(deleteUnitConfirmation)}
        onClose={() => setDeleteUnitConfirmation(null)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Delete Unit</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this unit? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteUnitConfirmation(null)}>
            Cancel
          </Button>
          <Button
            onClick={() => handleRemoveUnit(deleteUnitConfirmation)}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Property Confirmation Dialog */}
      <Dialog
        open={deletePropertyConfirmation}
        onClose={() => setDeletePropertyConfirmation(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Delete Property</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this property? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePropertyConfirmation(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteProperty}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {selectedUnit ? (
        <>
          {/* Tabs Navigation */}
          <Tabs
            value={activeTab}
            onChange={(e, v) => setActiveTab(v)}
            sx={{
              mb: 4,
              borderBottom: "1px solid",
              borderColor: "divider",
              "& .MuiTabs-indicator": {
                backgroundColor: "#2563eb",
                height: 3,
              },
              "& .MuiTab-root": {
                fontWeight: 600,
                fontSize: "0.95rem",
                textTransform: "none",
                minWidth: 100,
                "&.Mui-selected": {
                  color: "#2563eb",
                },
              },
            }}
          >
            <Tab label="Overview" />
            <Tab label="Photos" />
            <Tab label="Documents" />
            <Tab label="Tenancy" />
            <Tab label="Tenancy History" />
            <Tab label="Maintenance" />
          </Tabs>

          {/* Tab Content */}
          <Box sx={{ mt: 3 }}>
            {activeTab === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 3,
                        }}
                      >
                        <Typography variant="h6">Unit Details</Typography>
                        <Button
                          variant="outlined"
                          startIcon={<EditIcon />}
                          onClick={() => {
                            setEditingUnit(selectedUnitData);
                            setShowEditUnitDialog(true);
                          }}
                        >
                          Edit Unit
                        </Button>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            Unit Number
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {selectedUnitData.number}
                          </Typography>

                          <Typography
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            Unit Type
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {selectedUnitData.type || "Not specified"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            Monthly Rent
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            £{selectedUnitData.monthlyRent?.toLocaleString()}
                          </Typography>

                          <Typography
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            Status
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {selectedUnitData.status || "Available"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            Floor
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {selectedUnitData.floor || "Not specified"}
                          </Typography>

                          <Typography
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            Size
                          </Typography>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {selectedUnitData.size
                              ? `${selectedUnitData.size} sq ft`
                              : "Not specified"}
                          </Typography>
                        </Grid>
                        {selectedUnitData.description && (
                          <Grid item xs={12}>
                            <Typography
                              color="text.secondary"
                              variant="subtitle2"
                            >
                              Description
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                              {selectedUnitData.description}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
            {activeTab === 1 && (
              <PhotoGallery
                propertyId={propertyId}
                property={property}
                unitId={selectedUnit}
              />
            )}
            {activeTab === 2 && (
              <DocumentList
                propertyId={propertyId}
                property={property}
                unitId={selectedUnit}
              />
            )}
            {activeTab === 3 && (
              <TenancyDetails
                propertyId={propertyId}
                property={property}
                unitId={selectedUnit}
              />
            )}
            {activeTab === 4 && (
              <TenancyHistory propertyId={propertyId} unitId={selectedUnit} />
            )}
            {activeTab === 5 && (
              <MaintenanceHistory
                propertyId={propertyId}
                unitId={selectedUnit}
              />
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Typography color="text.secondary">
            Please select a unit to view its details
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PropertyDetails;
