import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Chip,
  IconButton,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControlLabel,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Business as BusinessIcon,
  Description as DescriptionIcon,
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
  CalendarToday as CalendarIcon,
  Person as PersonIcon,
  Apartment as ApartmentIcon,
  People as PeopleIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
  writeBatch,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useOrganization } from "../../contexts/OrganizationContext";
import DataList from "../common/DataList";
import FileUpload from "../common/FileUpload";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { checkResourceLimit } from "../../utils/planLimits";
import PropertyTenancyForm from "../property/PropertyTenancyForm";
import { generateRentPayments } from "../../utils/rentUtils";
import { useNavigate } from "react-router-dom";

const Properties = () => {
  const propertyTypes = ["House", "Apartment", "HMO", "Other"];
  const [properties, setProperties] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingProperty, setEditingProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newProperty, setNewProperty] = useState({
    name: "",
    address: "",
    type: "",
    units: "1",
    monthlyRent: "",
    description: "",
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tenancyDetails, setTenancyDetails] = useState({
    rentDueDate: "",
    tenantId: "",
    leaseStart: "",
    leaseEnd: "",
    deposit: "",
    isSharedTenancy: false,
    tenants: [], // Array of tenant objects with their rent portions
  });
  const [approvedTenants, setApprovedTenants] = useState([]);
  const [showTenancyForm, setShowTenancyForm] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [unitDetails, setUnitDetails] = useState([
    {
      number: "1",
      type: "Standard",
      monthlyRent: "",
      status: "Available",
    },
  ]);

  const { organization } = useOrganization();
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Organization context:", organization);
    fetchProperties();
    fetchApprovedTenants();
  }, [organization]);

  const fetchProperties = async () => {
    if (!organization?.id) {
      console.log("No organization ID available");
      return;
    }

    try {
      console.log("Fetching properties for org:", organization.id);
      const q = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const querySnapshot = await getDocs(q);
      const propertiesList = [];
      querySnapshot.forEach((doc) => {
        propertiesList.push({ id: doc.id, ...doc.data() });
      });
      console.log("Fetched properties:", propertiesList);
      setProperties(propertiesList);
    } catch (error) {
      console.error("Error fetching properties:", error);
      setError("Failed to load properties");
    } finally {
      setLoading(false);
    }
  };

  const fetchApprovedTenants = async () => {
    if (!organization?.id) {
      console.log("No organization ID available");
      return;
    }

    try {
      const q = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id),
        where("approved", "==", true)
      );
      const snapshot = await getDocs(q);
      const tenants = [];
      snapshot.forEach((doc) => {
        tenants.push({ id: doc.id, ...doc.data() });
      });
      setApprovedTenants(tenants);
    } catch (error) {
      console.error("Error fetching approved tenants:", error);
    }
  };

  const handleUnitCountChange = (e) => {
    const count = parseInt(e.target.value) || 1;
    setNewProperty({ ...newProperty, units: count.toString() });

    // Update unit details array
    if (count > unitDetails.length) {
      // Add new units
      const newUnits = [...unitDetails];
      for (let i = unitDetails.length; i < count; i++) {
        newUnits.push({
          number: (i + 1).toString(),
          type: "Standard",
          monthlyRent: "",
          status: "Available",
        });
      }
      setUnitDetails(newUnits);
    } else if (count < unitDetails.length) {
      // Remove excess units
      setUnitDetails(unitDetails.slice(0, count));
    }
  };

  const handleUnitDetailChange = (index, field, value) => {
    const newUnits = [...unitDetails];
    newUnits[index] = {
      ...newUnits[index],
      [field]: value,
    };
    setUnitDetails(newUnits);
  };

  const handleAddProperty = async () => {
    if (!organization?.id) {
      console.log("No organization ID available for adding property");
      setError("Organization context not available");
      return;
    }

    try {
      // Check resource limit
      const limitCheck = await checkResourceLimit(
        organization.id,
        "properties",
        organization.plan
      );
      if (!limitCheck.allowed) {
        setError(limitCheck.message);
        return;
      }

      // Validate input
      if (!newProperty.name || !newProperty.address) {
        setError("Please fill in all required fields");
        return;
      }

      // Check if property name already exists
      const nameCheckQuery = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id),
        where("name", "==", newProperty.name)
      );
      const nameCheckSnapshot = await getDocs(nameCheckQuery);
      if (!nameCheckSnapshot.empty) {
        setError("A property with this name already exists");
        return;
      }

      // Calculate total monthly rent from units
      const totalMonthlyRent = unitDetails.reduce(
        (sum, unit) => sum + (Number(unit.monthlyRent) || 0),
        0
      );

      const propertyData = {
        ...newProperty,
        organizationId: organization.id,
        createdAt: new Date().toISOString(),
        monthlyRent: totalMonthlyRent,
        units: unitDetails.map((unit, index) => ({
          ...unit,
          id: `unit-${index + 1}`,
          monthlyRent: Number(unit.monthlyRent) || 0,
        })),
      };

      console.log("Adding property:", propertyData);

      await addDoc(collection(db, "properties"), propertyData);

      setNewProperty({
        name: "",
        address: "",
        type: "",
        units: "1",
        monthlyRent: "",
        description: "",
      });
      setUnitDetails([
        {
          number: "1",
          type: "Standard",
          monthlyRent: "",
          status: "Available",
        },
      ]);
      setOpenDialog(false);
      fetchProperties();
    } catch (error) {
      console.error("Error adding property:", error);
      setError(error.message || "Error adding property");
    }
  };

  const handleDeleteProperty = async (propertyId) => {
    if (window.confirm("Are you sure you want to delete this property?")) {
      try {
        await deleteDoc(doc(db, "properties", propertyId));
        fetchProperties();
      } catch (error) {
        console.error("Error deleting property:", error);
        setError("Failed to delete property");
      }
    }
  };

  const handleEdit = (property) => {
    setEditingProperty({
      ...property,
      name: property.name || "",
      address: property.address || "",
      description: property.description || "",
    });
  };

  const handleUpdateProperty = async () => {
    try {
      // Check if property name already exists (excluding current property)
      const nameCheckQuery = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id),
        where("name", "==", editingProperty.name)
      );
      const nameCheckSnapshot = await getDocs(nameCheckQuery);

      // Check if any other property has the same name
      const nameExists = nameCheckSnapshot.docs.some(
        (doc) => doc.id !== editingProperty.id
      );

      if (nameExists) {
        setError("A property with this name already exists");
        return;
      }

      const propertyData = {
        ...editingProperty,
        updatedAt: new Date().toISOString(),
      };

      // Get the current property data to check if name has changed
      const propertyRef = doc(db, "properties", editingProperty.id);
      const propertyDoc = await getDoc(propertyRef);
      const currentPropertyData = propertyDoc.data();

      // Check if property name has changed
      if (currentPropertyData.name !== editingProperty.name) {
        // Get all rent payments for this property
        const paymentsQuery = query(
          collection(db, "rentPayments"),
          where("propertyId", "==", editingProperty.id)
        );
        const paymentsSnapshot = await getDocs(paymentsQuery);

        // Create a batch for updating payments
        const batch = writeBatch(db);

        // Update each payment with the new property name
        paymentsSnapshot.docs.forEach((doc) => {
          batch.update(doc.ref, {
            propertyName: editingProperty.name,
            updatedAt: new Date().toISOString(),
          });
        });

        // Update the property document
        batch.update(propertyRef, propertyData);

        // Commit all updates
        await batch.commit();
      } else {
        // If name hasn't changed, just update the property
        await updateDoc(propertyRef, propertyData);
      }

      setEditingProperty(null);
      setError(""); // Clear any existing errors
      fetchProperties();
      queryClient.invalidateQueries(["rentPayments"]);
    } catch (error) {
      console.error("Error updating property:", error);
      setError(error.message || "Error updating property");
    }
  };

  const handleTenancyUpdate = async () => {
    try {
      setSubmitting(true);

      // Validate rent due date
      const rentDueDay = parseInt(tenancyDetails.rentDueDate);
      if (isNaN(rentDueDay) || rentDueDay < 1 || rentDueDay > 28) {
        throw new Error(
          "Rent due date must be between 1st and 28th of the month"
        );
      }

      // Validate total rent portions equal 100% for shared tenancy
      if (tenancyDetails.isSharedTenancy && tenancyDetails.tenants.length > 0) {
        const totalPortion = tenancyDetails.tenants.reduce(
          (sum, tenant) => sum + Number(tenant.rentPortion),
          0
        );
        if (totalPortion !== 100) {
          throw new Error("Total rent portions must equal 100%");
        }
      }

      // Update property with tenancy information
      const propertyRef = doc(db, "properties", property.id);
      await updateDoc(propertyRef, {
        tenancy: {
          ...tenancyDetails,
          updatedAt: new Date().toISOString(),
        },
      });

      // Generate payments for each tenant
      const batch = writeBatch(db);
      const moveInDate = new Date(tenancyDetails.leaseStart);

      for (const tenant of tenancyDetails.tenants) {
        const tenantDoc = approvedTenants.find((t) => t.id === tenant.tenantId);
        if (!tenantDoc) continue;

        const tenantName = `${tenantDoc.firstName} ${tenantDoc.lastName}`;
        const rentAmount = tenancyDetails.isSharedTenancy
          ? (Number(property.monthlyRent) * Number(tenant.rentPortion)) / 100
          : Number(property.monthlyRent) / tenancyDetails.tenants.length;

        const depositAmount = tenancyDetails.isSharedTenancy
          ? (Number(tenancyDetails.deposit) * Number(tenant.rentPortion)) / 100
          : Number(tenancyDetails.deposit) / tenancyDetails.tenants.length;

        // Create deposit payment
        const depositRef = doc(collection(db, "rentPayments"));
        batch.set(depositRef, {
          rentDueDate: moveInDate.getDate(),
          dueDate: tenancyDetails.leaseStart,
          propertyId: property.id,
          propertyName: property.name,
          tenantId: tenant.tenantId,
          tenantName: tenantName,
          amount: depositAmount,
          status: "payment_due",
          paymentType: "deposit",
          organizationId: organization.id,
          isSharedPayment: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });

        // Create first month's rent payment
        const rentRef = doc(collection(db, "rentPayments"));
        batch.set(rentRef, {
          rentDueDate: moveInDate.getDate(),
          dueDate: tenancyDetails.leaseStart,
          propertyId: property.id,
          propertyName: property.name,
          tenantId: tenant.tenantId,
          tenantName: tenantName,
          amount: rentAmount,
          status: "payment_due",
          paymentType: "rent",
          organizationId: organization.id,
          isSharedPayment: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });

        // Update tenant's record
        const tenantRef = doc(db, "tenants", tenant.tenantId);
        batch.update(tenantRef, {
          propertyId: property.id,
          propertyName: property.name,
          leaseStart: tenancyDetails.leaseStart,
          leaseEnd: tenancyDetails.leaseEnd,
          rentAmount: rentAmount,
          deposit: depositAmount,
          rentDueDate: parseInt(tenancyDetails.rentDueDate),
          isSharedTenancy: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          status: "active",
          updatedAt: new Date().toISOString(),
        });

        // Generate future rent payments for this tenant
        await generateRentPayments(
          {
            rentDueDate: parseInt(tenancyDetails.rentDueDate),
            leaseStart: new Date(
              moveInDate.setMonth(moveInDate.getMonth() + 1)
            ).toISOString(),
            leaseEnd: tenancyDetails.leaseEnd,
            propertyId: property.id,
            propertyName: property.name,
            tenantId: tenant.tenantId,
            tenantName: tenantName,
            rentAmount: rentAmount,
            isSharedPayment: tenancyDetails.isSharedTenancy,
            rentPortion: tenant.rentPortion,
            isLeadTenant: tenant.isLeadTenant,
          },
          organization.id
        );
      }

      await batch.commit();

      // Invalidate queries to refresh data
      queryClient.invalidateQueries(["properties"]);
      queryClient.invalidateQueries(["approvedTenants"]);
      queryClient.invalidateQueries(["rentPayments"]);

      onClose();
      setSnackbar({
        open: true,
        message: "Tenancy has been successfully added",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating tenancy:", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to update tenancy",
        severity: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleAddTenant = () => {
    setTenancyDetails((prev) => ({
      ...prev,
      tenants: [
        ...prev.tenants,
        {
          tenantId: "",
          rentPortion: 0,
          isLeadTenant: prev.tenants.length === 0, // First tenant is lead by default
        },
      ],
    }));
  };

  const handleRemoveTenant = (index) => {
    setTenancyDetails((prev) => ({
      ...prev,
      tenants: prev.tenants.filter((_, i) => i !== index),
    }));
  };

  const handleTenantChange = (index, field, value) => {
    setTenancyDetails((prev) => {
      const newTenants = [...prev.tenants];
      newTenants[index] = {
        ...newTenants[index],
        [field]: value,
      };
      return {
        ...prev,
        tenants: newTenants,
      };
    });
  };

  const renderPropertyCard = (property) => {
    // Calculate months remaining in tenancy if lease end date exists
    const getMonthsRemaining = () => {
      if (!property.tenancy?.leaseEnd) return null;
      const today = new Date();
      const leaseEnd = new Date(property.tenancy.leaseEnd);
      const monthsDiff =
        (leaseEnd.getFullYear() - today.getFullYear()) * 12 +
        (leaseEnd.getMonth() - today.getMonth());
      return monthsDiff;
    };

    const monthsRemaining = getMonthsRemaining();

    // Get color and text based on months remaining
    const getTenancyStatusStyle = (months) => {
      if (months === null) return null;
      if (months <= 3) {
        return {
          color: "error.main",
          bgcolor: "error.lighter",
          text: `${months} months remaining`,
        };
      }
      if (months <= 5) {
        return {
          color: "warning.main",
          bgcolor: "warning.lighter",
          text: `${months} months remaining`,
        };
      }
      if (months <= 12) {
        return {
          color: "success.main",
          bgcolor: "success.lighter",
          text: `${months} months remaining`,
        };
      }
      return {
        color: "info.main",
        bgcolor: "info.lighter",
        text: `${months} months remaining`,
      };
    };

    const tenancyStatus = getTenancyStatusStyle(monthsRemaining);

    return (
      <Card
        key={property.id}
        sx={{
          mb: 2,
          border: "1px solid",
          borderColor: "divider",
          boxShadow: "none",
          height: 100,
          "&:hover": {
            borderColor: "primary.main",
            bgcolor: "background.paper",
          },
        }}
      >
        <CardContent
          sx={{
            height: "100%",
            p: "16px !important",
            // Mobile styles
            overflowX: { xs: "auto", md: "visible" },
            "&::-webkit-scrollbar": {
              height: 8,
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: 4,
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: 4,
              "&:hover": {
                background: "#666",
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: { xs: "fit-content", md: "auto" },
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                minWidth: { xs: "fit-content", md: "auto" },
                alignItems: "center",
              }}
            >
              {property.thumbnailUrl ? (
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Box
                    component="img"
                    src={property.thumbnailUrl}
                    alt={property.name}
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: 2,
                      objectFit: "cover",
                      flexShrink: 0,
                    }}
                    loading="lazy"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -8,
                      right: -8,
                      bgcolor: "primary.main",
                      color: "white",
                      borderRadius: "50%",
                      width: 24,
                      height: 24,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      border: "2px solid white",
                    }}
                  >
                    {Array.isArray(property.units) ? property.units.length : 0}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Box
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: 2,
                      bgcolor: "primary.lighter",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexShrink: 0,
                    }}
                  >
                    <BusinessIcon sx={{ color: "primary.main" }} />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -8,
                      right: -8,
                      bgcolor: "primary.main",
                      color: "white",
                      borderRadius: "50%",
                      width: 24,
                      height: 24,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      border: "2px solid white",
                    }}
                  >
                    {Array.isArray(property.units) ? property.units.length : 0}
                  </Box>
                </Box>
              )}

              <Box sx={{ minWidth: "fit-content" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, whiteSpace: "nowrap" }}
                >
                  {property.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {property.address}
                </Typography>
                <Box
                  sx={{
                    mt: 0.5,
                    display: "flex",
                    gap: 1,
                    minWidth: "fit-content",
                  }}
                >
                  <Chip
                    label={`£${
                      Array.isArray(property.units)
                        ? property.units
                            .reduce(
                              (total, unit) =>
                                total + (Number(unit.monthlyRent) || 0),
                              0
                            )
                            .toLocaleString()
                        : 0
                    }/month`}
                    size="small"
                    sx={{
                      bgcolor: "success.lighter",
                      color: "success.main",
                      flexShrink: 0,
                    }}
                  />
                  {tenancyStatus && (
                    <Chip
                      label={tenancyStatus.text}
                      size="small"
                      sx={{
                        bgcolor: tenancyStatus.bgcolor,
                        color: tenancyStatus.color,
                        "& .MuiChip-label": {
                          fontWeight: 500,
                        },
                        flexShrink: 0,
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                ml: 2,
                flexShrink: 0,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => navigate(`/dashboard/properties/${property.id}`)}
                sx={{ flexShrink: 0 }}
              >
                View
              </Button>
              <IconButton
                size="small"
                sx={{ color: "primary.main", flexShrink: 0 }}
                onClick={() => handleEdit(property)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                sx={{ color: "error.main", flexShrink: 0 }}
                onClick={() => handleDeleteProperty(property.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      <DataList
        items={properties}
        renderItem={renderPropertyCard}
        searchPlaceholder="Search properties..."
        searchField="name"
        pageSize={8}
        filters={[
          { label: "All Properties", value: "all" },
          { label: "Residential", value: "residential" },
          { label: "Commercial", value: "commercial" },
        ]}
        actionButtons={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Add Property
          </Button>
        }
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add New Property</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Property Name"
                fullWidth
                required
                value={newProperty.name}
                onChange={(e) =>
                  setNewProperty({ ...newProperty, name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                fullWidth
                required
                value={newProperty.address}
                onChange={(e) =>
                  setNewProperty({ ...newProperty, address: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Property Type</InputLabel>
                <Select
                  value={newProperty.type || ""}
                  onChange={(e) =>
                    setNewProperty({ ...newProperty, type: e.target.value })
                  }
                  label="Property Type"
                >
                  {propertyTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Number of Units"
                type="number"
                fullWidth
                value={newProperty.units}
                onChange={handleUnitCountChange}
                inputProps={{ min: "1" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mb: 2, mt: 2 }}>
                Unit Details
              </Typography>
            </Grid>
            {unitDetails.map((unit, index) => (
              <Grid item xs={12} key={index}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" sx={{ mb: 2 }}>
                          Unit {index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Unit Number/Address"
                          value={unit.number}
                          onChange={(e) =>
                            handleUnitDetailChange(
                              index,
                              "number",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Monthly Rent"
                          type="number"
                          value={unit.monthlyRent}
                          onChange={(e) =>
                            handleUnitDetailChange(
                              index,
                              "monthlyRent",
                              e.target.value
                            )
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>Unit Type</InputLabel>
                          <Select
                            value={unit.type}
                            onChange={(e) =>
                              handleUnitDetailChange(
                                index,
                                "type",
                                e.target.value
                              )
                            }
                            label="Unit Type"
                          >
                            <MenuItem value="Standard">Standard</MenuItem>
                            <MenuItem value="Studio">Studio</MenuItem>
                            <MenuItem value="Apartment">Apartment</MenuItem>
                            <MenuItem value="House">House</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            <Grid item xs={12}>
              <TextField
                label="Description"
                fullWidth
                multiline
                rows={3}
                value={newProperty.description}
                onChange={(e) =>
                  setNewProperty({
                    ...newProperty,
                    description: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddProperty} variant="contained">
            Add Property
          </Button>
        </DialogActions>
      </Dialog>

      <PropertyDetailsDialog
        property={selectedProperty}
        onClose={() => setSelectedProperty(null)}
        setSnackbar={setSnackbar}
      />

      <Dialog
        open={Boolean(editingProperty)}
        onClose={() => setEditingProperty(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Property</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Property Name"
            fullWidth
            required
            value={editingProperty?.name || ""}
            onChange={(e) =>
              setEditingProperty({ ...editingProperty, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Address"
            fullWidth
            required
            value={editingProperty?.address || ""}
            onChange={(e) =>
              setEditingProperty({
                ...editingProperty,
                address: e.target.value,
              })
            }
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={editingProperty?.description || ""}
            onChange={(e) =>
              setEditingProperty({
                ...editingProperty,
                description: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingProperty(null)}>Cancel</Button>
          <Button onClick={handleUpdateProperty} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {showTenancyForm && selectedProperty && (
        <Dialog
          open={showTenancyForm}
          onClose={() => setShowTenancyForm(false)}
        >
          <DialogTitle>Set Tenancy Details</DialogTitle>
          <DialogContent>
            <PropertyTenancyForm
              property={selectedProperty}
              tenant={selectedTenant}
              onSave={handleSaveTenancy}
              organizationId={organization.id}
            />
          </DialogContent>
        </Dialog>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const PropertyDetailsDialog = ({ property, onClose, setSnackbar }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tenancyDetails, setTenancyDetails] = useState({
    rentDueDate: "",
    leaseStart: "",
    leaseEnd: "",
    deposit: "",
    isSharedTenancy: false,
    tenants: [
      {
        tenantId: "",
        rentPortion: 0,
        isLeadTenant: true,
      },
    ], // Initialize with one empty tenant
  });
  const { organization } = useOrganization();
  const db = getFirestore();
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState(false);

  // Fetch documents query
  const { data: documents = [] } = useQuery({
    queryKey: ["documents", property?.id],
    queryFn: async ({ queryKey }) => {
      const [_, propertyId] = queryKey;
      const q = query(
        collection(db, "files"),
        where("entityId", "==", propertyId),
        where("folder", "==", "property-documents")
      );
      const querySnapshot = await getDocs(q);
      const documentsList = [];
      querySnapshot.forEach((doc) => {
        documentsList.push({ id: doc.id, ...doc.data() });
      });
      return documentsList.sort(
        (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
      );
    },
    enabled: !!property?.id && activeTab === 1,
  });

  // Fetch approved tenants for tenant selection
  const { data: approvedTenants = [], isLoading: isLoadingTenants } = useQuery({
    queryKey: ["approvedTenants", organization?.id],
    queryFn: async () => {
      if (!organization?.id) {
        console.log("Organization ID not available yet");
        return [];
      }
      const q = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id),
        where("status", "==", "active")
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!organization?.id,
  });

  // Fetch current tenancy details
  useEffect(() => {
    if (property?.tenancy) {
      setTenancyDetails({
        rentDueDate: property.tenancy.rentDueDate || "",
        leaseStart: property.tenancy.leaseStart || "",
        leaseEnd: property.tenancy.leaseEnd || "",
        deposit: property.tenancy.deposit || "",
        isSharedTenancy: property.tenancy.isSharedTenancy || false,
        tenants:
          property.tenancy.tenants?.length > 0
            ? property.tenancy.tenants
            : [
                {
                  tenantId: "",
                  rentPortion: 0,
                  isLeadTenant: true,
                },
              ],
      });
    }
  }, [property]);

  const handleAddTenant = () => {
    setTenancyDetails((prev) => ({
      ...prev,
      tenants: [
        ...prev.tenants,
        {
          tenantId: "",
          rentPortion: 0,
          isLeadTenant: prev.tenants.length === 0, // First tenant is lead by default
        },
      ],
    }));
  };

  const handleRemoveTenant = (index) => {
    setTenancyDetails((prev) => ({
      ...prev,
      tenants: prev.tenants.filter((_, i) => i !== index),
    }));
  };

  const handleTenantChange = (index, field, value) => {
    setTenancyDetails((prev) => {
      const newTenants = [...prev.tenants];
      newTenants[index] = {
        ...newTenants[index],
        [field]: value,
      };
      return {
        ...prev,
        tenants: newTenants,
      };
    });
  };

  const handleTenancyUpdate = async () => {
    try {
      setSubmitting(true);

      // Validate rent due date
      const rentDueDay = parseInt(tenancyDetails.rentDueDate);
      if (isNaN(rentDueDay) || rentDueDay < 1 || rentDueDay > 28) {
        throw new Error(
          "Rent due date must be between 1st and 28th of the month"
        );
      }

      // Validate total rent portions equal 100% for shared tenancy
      if (tenancyDetails.isSharedTenancy && tenancyDetails.tenants.length > 0) {
        const totalPortion = tenancyDetails.tenants.reduce(
          (sum, tenant) => sum + Number(tenant.rentPortion),
          0
        );
        if (totalPortion !== 100) {
          throw new Error("Total rent portions must equal 100%");
        }
      }

      // Update property with tenancy information
      const propertyRef = doc(db, "properties", property.id);
      await updateDoc(propertyRef, {
        tenancy: {
          ...tenancyDetails,
          updatedAt: new Date().toISOString(),
        },
      });

      // Generate payments for each tenant
      const batch = writeBatch(db);
      const moveInDate = new Date(tenancyDetails.leaseStart);

      for (const tenant of tenancyDetails.tenants) {
        const tenantDoc = approvedTenants.find((t) => t.id === tenant.tenantId);
        if (!tenantDoc) continue;

        const tenantName = `${tenantDoc.firstName} ${tenantDoc.lastName}`;
        const rentAmount = tenancyDetails.isSharedTenancy
          ? (Number(property.monthlyRent) * Number(tenant.rentPortion)) / 100
          : Number(property.monthlyRent) / tenancyDetails.tenants.length;

        const depositAmount = tenancyDetails.isSharedTenancy
          ? (Number(tenancyDetails.deposit) * Number(tenant.rentPortion)) / 100
          : Number(tenancyDetails.deposit) / tenancyDetails.tenants.length;

        // Create deposit payment
        const depositRef = doc(collection(db, "rentPayments"));
        batch.set(depositRef, {
          rentDueDate: moveInDate.getDate(),
          dueDate: tenancyDetails.leaseStart,
          propertyId: property.id,
          propertyName: property.name,
          tenantId: tenant.tenantId,
          tenantName: tenantName,
          amount: depositAmount,
          status: "payment_due",
          paymentType: "deposit",
          organizationId: organization.id,
          isSharedPayment: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });

        // Create first month's rent payment
        const rentRef = doc(collection(db, "rentPayments"));
        batch.set(rentRef, {
          rentDueDate: moveInDate.getDate(),
          dueDate: tenancyDetails.leaseStart,
          propertyId: property.id,
          propertyName: property.name,
          tenantId: tenant.tenantId,
          tenantName: tenantName,
          amount: rentAmount,
          status: "payment_due",
          paymentType: "rent",
          organizationId: organization.id,
          isSharedPayment: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });

        // Update tenant's record
        const tenantRef = doc(db, "tenants", tenant.tenantId);
        batch.update(tenantRef, {
          propertyId: property.id,
          propertyName: property.name,
          leaseStart: tenancyDetails.leaseStart,
          leaseEnd: tenancyDetails.leaseEnd,
          rentAmount: rentAmount,
          deposit: depositAmount,
          rentDueDate: parseInt(tenancyDetails.rentDueDate),
          isSharedTenancy: tenancyDetails.isSharedTenancy,
          rentPortion: tenant.rentPortion,
          isLeadTenant: tenant.isLeadTenant,
          status: "active",
          updatedAt: new Date().toISOString(),
        });

        // Generate future rent payments for this tenant
        await generateRentPayments(
          {
            rentDueDate: parseInt(tenancyDetails.rentDueDate),
            leaseStart: new Date(
              moveInDate.setMonth(moveInDate.getMonth() + 1)
            ).toISOString(),
            leaseEnd: tenancyDetails.leaseEnd,
            propertyId: property.id,
            propertyName: property.name,
            tenantId: tenant.tenantId,
            tenantName: tenantName,
            rentAmount: rentAmount,
            isSharedPayment: tenancyDetails.isSharedTenancy,
            rentPortion: tenant.rentPortion,
            isLeadTenant: tenant.isLeadTenant,
          },
          organization.id
        );
      }

      await batch.commit();

      // Invalidate queries to refresh data
      queryClient.invalidateQueries(["properties"]);
      queryClient.invalidateQueries(["approvedTenants"]);
      queryClient.invalidateQueries(["rentPayments"]);

      onClose();
      setSnackbar({
        open: true,
        message: "Tenancy has been successfully added",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating tenancy:", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to update tenancy",
        severity: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleUpdateRequestStatus = async (requestId, newStatus) => {
    try {
      await updateDoc(doc(db, "maintenanceRequests", requestId), {
        status: newStatus,
        updatedAt: new Date().toISOString(),
      });
      queryClient.invalidateQueries(["maintenanceRequests", property.id]);
    } catch (error) {
      console.error("Error updating maintenance request:", error);
    }
  };

  // Add new query for tenancy history
  const { data: tenancyHistory = [], isLoading: isLoadingHistory } = useQuery({
    queryKey: ["tenancyHistory", property?.id],
    queryFn: async () => {
      // First get the property's tenancy data
      const propertyDoc = await getDoc(doc(db, "properties", property.id));
      const propertyData = propertyDoc.data();
      const currentTenants = propertyData?.tenancy?.tenants || [];

      // Then get all tenants associated with this property
      const q = query(
        collection(db, "tenants"),
        where("propertyId", "==", property.id)
      );
      const snapshot = await getDocs(q);
      const tenants = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Combine and deduplicate tenants
      const allTenants = [...tenants];
      currentTenants.forEach((currentTenant) => {
        if (!allTenants.some((t) => t.id === currentTenant.tenantId)) {
          const matchingTenant = approvedTenants.find(
            (t) => t.id === currentTenant.tenantId
          );
          if (matchingTenant) {
            allTenants.push({
              ...matchingTenant,
              ...currentTenant,
              id: currentTenant.tenantId,
              isLeadTenant: currentTenant.isLeadTenant,
              rentPortion: currentTenant.rentPortion,
              isSharedTenancy: propertyData.tenancy.isSharedTenancy,
            });
          }
        }
      });

      // Filter out tenants without lease details and sort by lease start date
      return allTenants
        .filter((tenant) => tenant.leaseStart && tenant.leaseEnd)
        .sort((a, b) => new Date(b.leaseStart) - new Date(a.leaseStart));
    },
    enabled: !!property?.id && activeTab === 3,
  });

  if (!property) return null;

  return (
    <Dialog
      open={Boolean(property)}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: "linear-gradient(180deg, #f0f7ff 0%, #FFFFFF 30.21%)",
          borderRadius: "16px",
          boxShadow: `0px 187px 75px rgba(0, 0, 0, 0.01),
            0px 105px 63px rgba(0, 0, 0, 0.05),
            0px 47px 47px rgba(0, 0, 0, 0.09),
            0px 12px 26px rgba(0, 0, 0, 0.1),
            0px 0px 0px rgba(0, 0, 0, 0.1)`,
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid",
          borderColor: "divider",
          pb: 2,
          background: "linear-gradient(90deg, #2563eb 0%, #7c3aed 100%)",
          color: "white",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              bgcolor: "white",
              color: "#2563eb",
              width: 56,
              height: 56,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.5rem",
            }}
          >
            <BusinessIcon sx={{ fontSize: "2rem" }} />
          </Box>
          <Box>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 0.5 }}>
              {property.name}
            </Typography>
            <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
              {property.address}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 3 }}>
        <Tabs
          value={activeTab}
          onChange={(e, v) => setActiveTab(v)}
          sx={{
            mb: 4,
            borderBottom: "1px solid",
            borderColor: "divider",
            "& .MuiTabs-indicator": {
              backgroundColor: "#2563eb",
              height: 3,
            },
            "& .MuiTab-root": {
              fontWeight: 600,
              fontSize: "0.95rem",
              textTransform: "none",
              minWidth: 100,
              "&.Mui-selected": {
                color: "#2563eb",
              },
            },
          }}
        >
          <Tab label="Photos" />
          <Tab label="Documents" />
          <Tab label="Tenancy" />
          <Tab label="Tenancy History" />
        </Tabs>

        {activeTab === 0 && (
          <Box>
            <PhotoGallery propertyId={property.id} />
          </Box>
        )}

        {activeTab === 1 && (
          <Box>
            <FileUpload
              folder="property-documents"
              entityId={property.id}
              multiple={true}
              onUploadComplete={() =>
                queryClient.invalidateQueries(["documents", property.id])
              }
            />
            <DocumentList
              documents={documents}
              onDelete={() =>
                queryClient.invalidateQueries(["documents", property.id])
              }
            />
          </Box>
        )}

        {activeTab === 2 && (
          <Box sx={{ py: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 3,
                    bgcolor: "rgba(37, 99, 235, 0.05)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "rgba(37, 99, 235, 0.1)",
                    mb: 3,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#2563eb",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box component="span" sx={{ fontSize: "1.2rem" }}>
                      👥
                    </Box>
                    Tenancy Type
                  </Typography>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={tenancyDetails.isSharedTenancy}
                        onChange={(e) =>
                          setTenancyDetails((prev) => ({
                            ...prev,
                            isSharedTenancy: e.target.checked,
                            tenants: prev.tenants.map((t, i) => ({
                              ...t,
                              rentPortion: e.target.checked
                                ? 100 / prev.tenants.length
                                : 0,
                            })),
                          }))
                        }
                      />
                    }
                    label="Shared Tenancy (Split Rent)"
                  />

                  {Array.isArray(tenancyDetails.tenants) &&
                    tenancyDetails.tenants.map((tenant, index) => (
                      <Box
                        key={index}
                        sx={{
                          mt: 2,
                          p: 2,
                          bgcolor: "background.paper",
                          borderRadius: 1,
                          border: "1px solid",
                          borderColor: "divider",
                        }}
                      >
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Tenant {index + 1}</InputLabel>
                              <Select
                                value={tenant.tenantId}
                                onChange={(e) =>
                                  handleTenantChange(
                                    index,
                                    "tenantId",
                                    e.target.value
                                  )
                                }
                                label={`Tenant ${index + 1}`}
                              >
                                {approvedTenants.map((t) => (
                                  <MenuItem key={t.id} value={t.id}>
                                    {t.firstName} {t.lastName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          {tenancyDetails.isSharedTenancy && (
                            <Grid item xs={12} sm={4}>
                              <TextField
                                fullWidth
                                label="Rent Portion (%)"
                                type="number"
                                value={tenant.rentPortion}
                                onChange={(e) =>
                                  handleTenantChange(
                                    index,
                                    "rentPortion",
                                    Number(e.target.value)
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          )}

                          <Grid item xs={12} sm={2}>
                            {index > 0 && (
                              <IconButton
                                color="error"
                                onClick={() => handleRemoveTenant(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    ))}

                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddTenant}
                    sx={{ mt: 2 }}
                  >
                    Add Tenant
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    p: 3,
                    bgcolor: "rgba(124, 58, 237, 0.05)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "rgba(124, 58, 237, 0.1)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#7c3aed",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box component="span" sx={{ fontSize: "1.2rem" }}>
                      📅
                    </Box>
                    Lease Period
                  </Typography>
                  <TextField
                    fullWidth
                    label="Lease Start Date"
                    type="date"
                    value={tenancyDetails.leaseStart}
                    onChange={(e) =>
                      setTenancyDetails({
                        ...tenancyDetails,
                        leaseStart: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    fullWidth
                    label="Lease End Date"
                    type="date"
                    value={tenancyDetails.leaseEnd}
                    onChange={(e) =>
                      setTenancyDetails({
                        ...tenancyDetails,
                        leaseEnd: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    p: 3,
                    bgcolor: "rgba(37, 99, 235, 0.05)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "rgba(37, 99, 235, 0.1)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#2563eb",
                      fontWeight: "bold",
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box component="span" sx={{ fontSize: "1.2rem" }}>
                      💰
                    </Box>
                    Payment Details
                  </Typography>
                  <TextField
                    fullWidth
                    label="Rent Due Date"
                    type="number"
                    value={tenancyDetails.rentDueDate}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (!isNaN(value) && value >= 1 && value <= 28) {
                        setTenancyDetails({
                          ...tenancyDetails,
                          rentDueDate: value.toString(),
                        });
                      }
                    }}
                    helperText="Day of the month when rent is due (1-28)"
                    inputProps={{
                      min: 1,
                      max: 28,
                    }}
                    error={
                      tenancyDetails.rentDueDate &&
                      (parseInt(tenancyDetails.rentDueDate) < 1 ||
                        parseInt(tenancyDetails.rentDueDate) > 28)
                    }
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    fullWidth
                    label="Security Deposit"
                    type="number"
                    value={tenancyDetails.deposit || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      setTenancyDetails({
                        ...tenancyDetails,
                        deposit: value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                    helperText="Security deposit amount required before move-in"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleTenancyUpdate}
                    disabled={submitting}
                    sx={{
                      borderRadius: 2,
                      textTransform: "none",
                      background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                      "&:hover": {
                        background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                      },
                    }}
                  >
                    {submitting ? "Saving..." : "Save Tenancy Details"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {activeTab === 3 && (
          <Box sx={{ py: 2 }}>
            {isLoadingHistory ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 3,
                      bgcolor: "rgba(37, 99, 235, 0.05)",
                      borderRadius: 2,
                      border: "1px solid",
                      borderColor: "rgba(37, 99, 235, 0.1)",
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2563eb",
                        fontWeight: "bold",
                        mb: 2,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box component="span" sx={{ fontSize: "1.2rem" }}>
                        👥
                      </Box>
                      Current & Past Tenants
                    </Typography>

                    <TableContainer
                      component={Paper}
                      sx={{ boxShadow: "none", bgcolor: "transparent" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Tenant</TableCell>
                            <TableCell>Lease Period</TableCell>
                            <TableCell>Rent Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Type</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tenancyHistory.map((tenant) => (
                            <TableRow key={tenant.id}>
                              <TableCell>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <PersonIcon sx={{ color: "primary.main" }} />
                                  <Box>
                                    <Typography variant="subtitle2">
                                      {tenant.firstName} {tenant.lastName}
                                    </Typography>
                                    {tenant.isLeadTenant && (
                                      <Chip
                                        label="Lead Tenant"
                                        size="small"
                                        sx={{
                                          bgcolor: "primary.lighter",
                                          color: "primary.main",
                                          fontSize: "0.75rem",
                                          height: 20,
                                          mt: 0.5,
                                        }}
                                      />
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2">
                                  {new Date(
                                    tenant.leaseStart
                                  ).toLocaleDateString()}{" "}
                                  -{" "}
                                  {new Date(
                                    tenant.leaseEnd
                                  ).toLocaleDateString()}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant="body2">
                                  £{tenant.rentAmount?.toLocaleString()}
                                  {tenant.isSharedTenancy && (
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      display="block"
                                    >
                                      ({tenant.rentPortion}% of total)
                                    </Typography>
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Chip
                                  label={tenant.status}
                                  size="small"
                                  sx={{
                                    bgcolor:
                                      tenant.status === "active"
                                        ? "success.lighter"
                                        : "default.lighter",
                                    color:
                                      tenant.status === "active"
                                        ? "success.main"
                                        : "text.secondary",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {tenant.isSharedTenancy ? "Shared" : "Single"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{ p: 3, borderTop: "1px solid", borderColor: "divider" }}
      >
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            background: "linear-gradient(45deg, #2563eb, #7c3aed)",
            px: 4,
            py: 1,
            "&:hover": {
              background: "linear-gradient(45deg, #1e40af, #5b21b6)",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PhotoGallery = ({ propertyId }) => {
  const [selectedFolder, setSelectedFolder] = useState("all");
  const [folders, setFolders] = useState([]);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [editingPhoto, setEditingPhoto] = useState(null);
  const { organization } = useOrganization();
  const db = getFirestore();
  const storage = getStorage();
  const queryClient = useQueryClient();

  const fetchPhotos = async ({ queryKey }) => {
    const [_, propertyId, selectedFolder] = queryKey;
    try {
      let q;
      if (selectedFolder === "all") {
        q = query(
          collection(db, "files"),
          where("entityId", "==", propertyId),
          where("folder", "==", "property-photos")
        );
      } else {
        q = query(
          collection(db, "files"),
          where("entityId", "==", propertyId),
          where("folder", "==", "property-photos"),
          where("photoFolder", "==", selectedFolder)
        );
      }

      const querySnapshot = await getDocs(q);
      const photosList = [];
      querySnapshot.forEach((doc) => {
        photosList.push({ id: doc.id, ...doc.data() });
      });
      photosList.sort(
        (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
      );

      const uniqueFolders = [
        ...new Set(photosList.map((photo) => photo.photoFolder)),
      ].filter(Boolean);
      setFolders(uniqueFolders);
      return photosList;
    } catch (error) {
      console.error("Error fetching photos:", error);
      throw error;
    }
  };

  const handleDelete = async (photo) => {
    if (!window.confirm("Are you sure you want to delete this photo?")) {
      return;
    }

    try {
      const fileRef = ref(storage, photo.filePath);
      await deleteObject(fileRef);
      await deleteDoc(doc(db, "files", photo.id));
      // Invalidate the cache to trigger a refetch
      queryClient.invalidateQueries(["photos", propertyId]);
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  const handleRename = async () => {
    try {
      await updateDoc(doc(db, "files", editingPhoto.id), {
        photoName: editingPhoto.photoName,
        photoFolder: editingPhoto.photoFolder,
      });
      queryClient.invalidateQueries(["photos", propertyId]);
      setEditingPhoto(null);
    } catch (error) {
      console.error("Error updating photo:", error);
    }
  };

  const handleSetAsThumbnail = async () => {
    try {
      await updateDoc(doc(db, "properties", propertyId), {
        thumbnailUrl: editingPhoto.fileUrl,
      });
      queryClient.invalidateQueries(["properties"]);
      setEditingPhoto(null);
    } catch (error) {
      console.error("Error setting thumbnail:", error);
    }
  };

  const { data: photos = [], isLoading } = useQuery({
    queryKey: ["photos", propertyId, selectedFolder],
    queryFn: fetchPhotos,
    enabled: !!propertyId,
  });

  return (
    <>
      <Dialog
        open={Boolean(previewPhoto)}
        onClose={() => setPreviewPhoto(null)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ p: 0, position: "relative" }}>
          {previewPhoto && (
            <>
              <IconButton
                onClick={() => setPreviewPhoto(null)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  bgcolor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  "&:hover": {
                    bgcolor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box
                component="img"
                src={previewPhoto.fileUrl}
                alt={previewPhoto.photoName || previewPhoto.fileName}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "90vh",
                  objectFit: "contain",
                  display: "block",
                }}
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          mb: 4,
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Filter by Folder</InputLabel>
          <Select
            value={selectedFolder}
            onChange={(e) => setSelectedFolder(e.target.value)}
            label="Filter by Folder"
          >
            <MenuItem value="all">All Photos</MenuItem>
            {folders.map((folder) => (
              <MenuItem key={folder} value={folder}>
                {folder}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FileUpload
          folder="property-photos"
          entityId={propertyId}
          multiple={true}
          isPhoto={true}
          onUploadComplete={() =>
            queryClient.invalidateQueries(["photos", propertyId])
          }
          acceptedFiles="image/*"
        />
      </Box>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {photos.map((photo) => (
            <ListItem
              key={photo.id}
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                mb: 1,
              }}
            >
              <ListItemIcon>
                <Box
                  component="img"
                  src={photo.fileUrl}
                  alt={photo.fileName}
                  sx={{
                    width: 80,
                    height: 80,
                    objectFit: "cover",
                    borderRadius: 1,
                    mr: 1,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography variant="subtitle2">
                      {photo.photoName || photo.fileName}
                    </Typography>
                    {photo.photoFolder && (
                      <Chip
                        label={photo.photoFolder}
                        size="small"
                        sx={{ bgcolor: "primary.lighter" }}
                      />
                    )}
                  </Box>
                }
                secondary={
                  <>
                    <Typography variant="caption" color="text.secondary">
                      Added {new Date(photo.uploadDate).toLocaleString()}
                    </Typography>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => setEditingPhoto(photo)}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="preview"
                  onClick={() => setPreviewPhoto(photo)}
                  sx={{ mr: 1 }}
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(photo)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {photos.length === 0 && (
            <Typography
              color="text.secondary"
              sx={{ py: 2, textAlign: "center" }}
            >
              No photos uploaded yet
            </Typography>
          )}
        </List>
      )}

      {/* Edit Photo Dialog */}
      <Dialog
        open={Boolean(editingPhoto)}
        onClose={() => setEditingPhoto(null)}
      >
        <DialogTitle>Edit Photo Details</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Photo Name"
            fullWidth
            value={editingPhoto?.photoName || editingPhoto?.fileName || ""}
            onChange={(e) =>
              setEditingPhoto({ ...editingPhoto, photoName: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Folder"
            fullWidth
            value={editingPhoto?.photoFolder || ""}
            onChange={(e) =>
              setEditingPhoto({ ...editingPhoto, photoFolder: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingPhoto(null)}>Cancel</Button>
          <Button onClick={handleSetAsThumbnail} color="secondary">
            Set as Property Thumbnail
          </Button>
          <Button onClick={handleRename} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DocumentList = ({ documents, onDelete }) => {
  const { organization } = useOrganization();
  const db = getFirestore();
  const [editingDoc, setEditingDoc] = useState(null);
  const queryClient = useQueryClient();

  const handleDownload = (document) => {
    window.open(document.fileUrl, "_blank");
  };

  const handleDelete = async (document) => {
    if (!window.confirm("Are you sure you want to delete this document?")) {
      return;
    }

    try {
      const storage = getStorage();
      const fileRef = ref(storage, document.filePath);
      await deleteObject(fileRef);
      await deleteDoc(doc(db, "files", document.id));
      onDelete();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleRename = async () => {
    try {
      await updateDoc(doc(db, "files", editingDoc.id), {
        fileName: editingDoc.fileName,
      });
      onDelete(); // Trigger refresh
      setEditingDoc(null);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  return (
    <>
      <Dialog open={Boolean(editingDoc)} onClose={() => setEditingDoc(null)}>
        <DialogTitle>Rename Document</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Document Name"
            fullWidth
            value={editingDoc?.fileName || ""}
            onChange={(e) =>
              setEditingDoc({ ...editingDoc, fileName: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingDoc(null)}>Cancel</Button>
          <Button onClick={handleRename} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <List>
        {documents.map((document) => (
          <ListItem
            key={document.id}
            sx={{
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 1,
              mb: 1,
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary={document.fileName}
              secondary={new Date(document.uploadDate).toLocaleDateString()}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => setEditingDoc(document)}
                sx={{ mr: 1 }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="download"
                onClick={() => handleDownload(document)}
                sx={{ mr: 1 }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(document)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {documents.length === 0 && (
          <Typography
            color="text.secondary"
            sx={{ py: 2, textAlign: "center" }}
          >
            No documents uploaded yet
          </Typography>
        )}
      </List>
    </>
  );
};

export default Properties;
